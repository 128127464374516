@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.marginTop {
	margin-top: 40px;
}

.title {
	margin: 0px;
	margin-top: 64px;
	font-family: 'geomanistmedium';
	letter-spacing: 0.6px;
	color: #1e1f24;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.transactionDetail {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	cursor: pointer;
}

.expandIcon {
	margin-left: 6px;
	width: 8.75px;
	height: 4.58px;
	cursor: pointer;
}

.detailTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.detailValue {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}

.history {
	width: 100%;
	height: 12px;
	background: #efeff1;
	border-radius: 16px;
	position: relative;
}

.completed {
	/* width: 100px; */
	height: 12px;
	background: #13b948;
	border-radius: 16px;
}

.lrsIdBox{
	display: inline-block;
    margin-left: 10px;
	background: #D3D4DA;
	border-radius: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	padding-right:10px;
	padding-left: 10px;
}

.orderContainer{
	padding-left: 120px;
  padding-right: 120px;
}

.imageDiv {
	border: 0.928571px solid #efeff1;
	background-color: white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.stockImage {
	width: 37px;
	height: 37px;
	padding-left: 2px;
	padding-top: 2px;
}

.letterSize{
  font-size: 25px;
}

.imageCard {
	border: 0.928571px solid #efeff1;
	background-color: white;
	border-radius: 50%;
	width: 60px;
	height: 60px;
}

.stockTitle{
   font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #1E1F24;
}

.stockBuyDate{
    font-family:'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    color: #626777;
}
.stockStatus{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.6px;
    color: #1E1F24;
}
.stockQuantity{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.6px;
    color: #626777;
}

.cancelOrderButton{
	display: block;
	border: none;
	background-color: white;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	/* text-transform: capitalize; */
	color: #E61446;
	float: right;
  height: 100%;
}

.fullRowWidth{
  width: 100%;
  height: 100%;
}

.YesNoButtonSection{
  /* text-align: right; */
  display: inline-block;
  float: right;
}

.cancelText{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #505562;
}

.YesButton{
  border: 1px solid #FA274A;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  letter-spacing: 0.6px;
  padding: 8px 16px 8px 16px ;
  color: #FA274A;
  margin-left: 16px;
}

.NoButton{
  border: 1px solid #9096A8;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  letter-spacing: 0.6px;
  padding: 8px 16px 8px 16px ;
  color: #9096A8;
  margin-left: 16px;
}

 .cancelOrderButton:active,
 .cancelOrderButton:focus {
	border:0.1em solid red;
  /* width: 100%; */
  height: 100%;
}

.imageMarginLeft{
	margin-left:10px;
}

.orderBuyDate{
	display: inline;
}

.mobileCancelOrderButton{
	display: none;
}

.queSectionMobile{
	display: none;
}

.queSectionLaptop{
	display: block;
}

.borderMargin{
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (max-width: 770px) {
	.imageMarginLeft{
		margin-left:0px;
	}
	/* .orderBuyDate{
		display: none;
	} */
	.mobileCancelOrderButton{
	width:100%;
	border:2px solid#FA274A;
	letter-spacing: 0.6px;
	font-family: 'geomanistbook';
	background-color: white;
	color: #FA274A;
	border-radius: 5px;
	padding-top: 12px;
	padding-bottom: 12px;
	display: block;
	margin-top:22px;
	}
	.cancelOrderButton{
	display: none;
	}
	.queSectionLaptop{
	display: none;
	}
	.queSectionMobile{
	display: block;
	}

}

@media (max-width: 992px) {
  .imageMarginLeft{
		margin-left:0px;
	}
}


@media (min-width: 300px) and (max-width: 480px) {
  .stockBuyDate{
    font-size: 10px;
  }
  .stockQuantity{
    font-size: 10px;
  }
}
