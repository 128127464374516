@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.trendingCard {
	width: 100%;
	border: 0px;
	border-bottom: 1px solid #efeff1;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent ;
  border-top: 1px solid transparent ;
  transition: all 200ms ease 0s;
  border-radius: 0px;
}

.trendingCard:hover {
	border: 1px solid #efeff1;
  /* border-bottom: 1px solid #efeff1 !important; */
	box-sizing: border-box;
  /* border-bottom: 1px solid #efeff1;
  border-top: 1px solid #efeff1 ; */
  /* box-shadow: rgb(0 0 0 / 7%) 0px 6px 15px; */
	box-shadow: 0px 2px 5px 1px rgba(30, 31, 36, 0.15);
	border-radius: 16px;
  transition: all 200ms ease 0s;
  z-index: 2;
}

.cardBody {
	padding-top: 24px;
  padding-bottom: 24px;
  padding-left:24px;
  padding-right:24px;
}
.imageDiv {
	border: 0.928571px solid #efeff1;
	background-color: white;
	border-radius: 50%;
	width: 48px;
	height: 48px;
}

.stockImage {
	width: 40px;
	height: 40px;
	padding-left: 9px;
	padding-top: 8px;
}

.imageCard {
	border: 0.928571px solid #efeff1;
	background-color: white;
	border-radius: 50%;
	width: 60px;
	height: 60px;
}

.stockTitle {
	font-family: 'geomanistmedium';
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: 0.56px;
	color: #1e1f24;
	white-space: nowrap;
}

.stockName {
	font-family: 'geomanistregular';
	padding-top: 4px;
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	white-space: nowrap;
	width: inherit;
	overflow: hidden;
	text-overflow: ellipsis;
}
.stockValue {
	font-family: 'geomanistbook';
	font-size: 20px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: right;
	white-space: nowrap;
  color: #1E1F24;
}

.stockIncrement {
	font-family: 'geomanistbook';
	text-align: right;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.6px;
	white-space: nowrap;
}
.redPercentChange{
  font-family: 'geomanistbook';
  text-align: right;
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #FA274A;
}
.imagePaddingRemove {
  color:black;
	padding-top: 5px;
}
.imagePadding {
  color:black;
	padding-top: 5px;
}
.graphImage{
  width:100%;
  height: 37px;
  margin-top: 10px;
}
.titleMargin{
  padding-left: 10px;
}
.leftPaddingRemove{
  padding-left: 0px;
}

@media (max-width: 375px) {
	.mobilePadding{
		padding-left: 10px;
	}
	.titleMargin{
		padding-right: 10px;
	  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .cardBody{
    padding-left:0px;
    padding-right: 0px;
    padding-top:25px;
    padding-bottom: 25px;
  }
	.imageDiv {
		width: 40px;
		height: 40px;
	}
	.stockTitle {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.6px;
	}
	.stockName {
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.6px;
		color: #000000;
		opacity: 0.6;
	}
	.stockValue {
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.6px;
		color: #021241;
		white-space: nowrap;
	}

	.stockIncrement {
		font-weight: normal;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 0.6px;
		white-space: nowrap;
	}

	.stockImage {
		width: 39px;
		height: 39px;
		padding: 10px;
	}
	.imagePaddingRemove {
		padding-top: 0px;
	}
	.imagePadding {
		padding-top: 0px;
    color:black;
	}
}
@media (min-width:375px) and (max-width:480px){
	.leftPaddingRemove{
		padding-right: 25px;
	  }
}
@media (min-width:410px) and (max-width:480px){
	.mobilePadding{
		padding-right: 25px;
	  }
}
@media (min-width: 700px) and (max-width: 990px) {
	.cardBody {
		padding-left: 5px;
    padding-right: 5px;
    padding-top:20px;
    padding-bottom: 20px;
	}
	.stockTitle {
		font-size: 16px;
	}
	.stockName {
		font-size: 12px;
	}
	.stockValue {
		font-size: 18px;
	}
	.stockIncrement {
		font-size: 12px;
	}
  .stockImage {
		width: 37px;
		height: 37px;
		padding-left: 3px;
    padding-bottom: 3px;
	}
  .imageDiv {
		width: 40px;
		height: 40px;
	}
}
