@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.TableMarginTop{
  margin-top: 30px;
  margin-left: 0px;
}

.stocksEtf {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  padding-left: 0px;
}

.totalStocks {
	text-align: end;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #626777;
}

.dataTable td,
.dataTable th {
	vertical-align: middle !important;
	padding: 16px;
}

.tableborder {
	border-top: 0px;
	border-bottom: 1px solid #eaeaea !important;
}

.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}

.tableHead {
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  color: #767C8F;
  border-bottom: 1px solid #eaeaea !important;
}

.symbol {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	border-bottom: 1px solid #eaeaea !important;
	color: #1e1f24;
}

.tableData {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1E1F24;
	border-bottom: 1px solid #eaeaea !important;
}

.rating {
	height: 28px;
	width: fit-content;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 0.333333px;
	color: #13b948;
	padding: 8px;
	background: rgb(19, 185, 72, 0.1);
	border-radius: 8px;
}

.incrementImage {
	margin-bottom: 3px;
	margin-right: 5px;
}

.tableRow {
  cursor: pointer;
	position: relative;
}

.badge {
	position: static;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #0e2a78;
	background-color: rgb(14, 42, 120, 0.1);
	opacity: 0.8;
}

.stockImage {
	border-bottom: 1px solid #eaeaea !important;
	/* padding-top: 0px !important; */
}
.imageCompany {
	width: 48px;
	height: 48px;
	padding-left: 0px;
  border: 1px solid #EFEFF1;
  border-radius: 50%;
}

.stockTitle{
  font-family: 'geomainstmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #1E1F24;
}

.mobileRating{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #626777;
}

.mobilePrice{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: right;
letter-spacing: 0.6px;
color: #021241;
}

.changeColor{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;

text-align: right;
letter-spacing: 0.6px;

color: #0DC014;
}

.exploreCard {
	width: 100%;
	border: 0px;
	border-bottom: 1px solid #f0efef;
}

.cardBody {
  padding: 15px;
}
.imageDiv {
  width: 40px;
  height: 40px;
}
.stockImage {
  width: 39px;
  height: 39px;
  padding: 10px;
}
.imagePaddingRemove {
  font-family: 'geomanistmedium';
  padding-top: 0px;
}

.linkStyle{
  width: 100%;
  color:#000000;
}
.linkStyle:hover{
  text-decoration: none;
  color:#000000;
}

.scrollDiv{
  height: 700px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notFound{
  justify-content: center;
  padding-top: 100px;
}

.stickyHeader{
  position: sticky;
    top: 0;
    z-index: 100;
    background: white;
}
.ratingColor{
  margin-right: 8px;
  margin-bottom: 1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
