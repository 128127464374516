.cashModal :global(.modal-content) {
    border-radius: 16px !important;
}

.cashModal :global(.modal-body) {
    padding: 0px !important;
}

.cashModal :global(.modal-dialog-centered) {
	justify-content: center;
}

@media (max-width:380px) {
	.cashModal :global(.modal-content) {
		width:100% !important;
	}
}

@media (min-width:380px) {
    .cashModal :global(.modal-content) {
		width: 380px !important;;
	}
}

.cashModal:global(.fade .modal-dialog) {
	bottom: -50px;
	-webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
	-o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
	transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.cashModal:global(.fade.show .modal-dialog) {
	bottom: 0;
}