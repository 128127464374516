@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 412px;
}

.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
	margin-right: 40px;
}

.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.linkDocument{
  color: #1E1F24;
  text-decoration: none;
}
.linkDocument:hover{
  text-decoration: none;
}
.great {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.lrs {
	margin-top: 8px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
}
.header {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #1e1f24;
	border: none;
	white-space: nowrap;
}
.download {
	cursor: pointer;
	margin-top: 16px;
	align-items: center;
	padding: 12px;
	width: 197px;
	/* height: 40px; */
	background: #f7f7f8;
	border-radius: 8px;
	white-space: nowrap;
}

.downloadText {
	display: flex;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
}
.fixedDiv {
  border-top: 1px solid #efeff1;
  padding-top: 20px;
  margin-top: 25px;
	/* background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	);
	text-align: end;
	position: fixed;
	bottom: 24px;
	margin-right: 0%; */
}
.other {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	white-space: nowrap;
}
.description {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
@media (max-width: 768px) {
  .addfundstext {
    font-size: 14px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .card{
    width: 100%;
  }
  .accordionPadding{
    padding-left: 10px;
    padding-right: 10px;
  }
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
    box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
    border-radius: 16px 16px 0px 0px;
  }
   .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 0px;
    margin-right: 0px;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}


.spinner{
  text-align: center;
}
.spinnerColor{
  width:20px;
  height:20px;
  border: .25em solid #005DD6;
  border-right-color: transparent;
}
.instructionError{
	display: flex;
	flex-direction: row;
	align-items: center;
}
@media (max-width:425px){
	.instructionError{
		display: flex;
		flex-direction: column;
		align-items: start;
	}	
}
