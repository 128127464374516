.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}
.card {
	width: 840px;
  height: max-content;
}
.review {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.noteitalic{
  font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.6px;
  color: #626777;
  margin-top: 10px;
  margin-bottom: 30px;
  font-style: italic;
}

.loadingCenter{
  text-align: center;
  margin-top: 50px;
  font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
}
.note {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-bottom: 4px;
  margin-top: 50px;
  text-align: center;
}
.box {
	height: 16px;
	width: 16px;
}
.agree {
  position: absolute;
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.borderBottom{
	border-bottom: 1px solid #efeff1;
  margin-left: 0px;
  margin-right: 0px;
	/* padding-bottom: 22px; */
}

@media (max-width: 768px) {
	.card {
		width: 100%;
	}
  .spinner{
    padding-top: 50px;
    text-align: center;
  }
  .borderBottom{
    margin-right: 30px;
    /* padding-bottom: 22px; */
  }
}
@media (min-width: 300px) and (max-width: 480px) {

  .card{
    height: max-content;
  }
}

@media (min-width: 400px) and (max-width: 480px) {
  .card{
    width: 100%;
  }
}
@media (min-width: 300px) and (max-width: 348px) {
  .card{
    width: 290px;
  }
}

@media (min-width: 350px) and (max-width: 390px) {
  .card{
    width: 337px;
  }
}

@media (max-width: 500px) {
  .borderBottom{
    border-bottom: 0px;
  }
}


.A2UploadCard{
  width: 840px;
}

.A2UploadTitle{
  font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 8px;
    border-bottom: 1px solid #efeff1;
    padding-bottom: 24px;
}

@media (max-width: 800px) {
	.A2UploadCard {
		width: 80%;
	}
}

@media (min-width:800px) and (max-width:1024px){
  .A2UploadCard {
		width: 750px;
	}
}

@media (max-width: 576px) {
	.A2UploadCard {
		width: 100%;
	}
  .additionalDetailMobile{
    margin-bottom: 120px;
  }
  .fundTransferButton{
    height: 70px;
  }
  .reviewMobile{
    width: 91vw;
  }
}
