.heading{
	/* font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 110%;
	text-align: center;
	color: #162542; */

font-family: 'geomanistmedium';
font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.6px;
color: #1E1F24;
}

.pinkButton{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px;
	/* width: 130px; */
	/* width: 176px; */
width:max-content;
	height: 27px;
	/* Soft Pink */
	background: #FFEBEB;
}
.pinkText{
	position: static;
	/* width: 114px; */
	height: 11px;
	left: 8px;
	top: 8px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 110%;
	/* identical to box height, or 11px */
	letter-spacing: 0.5px;
	color: #8E4040;
	/* Inside Auto Layout */
	flex: none;
	order: 0;
	flex-grow: 0;
	/* margin: 0px 10px; */
}

.price{
	/* width: 47px; */
	height: 26px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 110%;
	/* or 26px */
	color: #162542;
}

.conditionText{
	/* width: 114px; */
	height: 18px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 110%;
	/* identical to box height, or 18px */
	color: #000000;
}

.conditionText1{
	width: 187px;
	height: 40px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	/* or 20px */
	/* Dark Gray 75% */
	color: rgba(22, 37, 66, 0.75);
}
.featureText{
	/* width: 178px; */
	height: 26px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 160%;
	/* identical to box height, or 26px */
	color: #000000;
	margin: 0;
}

.horizontalLine{
/* width: 1070px; */
/* height: 1px; */
/* Light Gray */
background: #F4F4F4;
margin-top: 0;
display: none;
}

.button{
cursor: pointer;
display: flex;
flex-direction: row;
align-items: center;
padding: 20px 20px;

width: 138px;
height: 62px;
background: #0061D3;
}

.buttonText{
	cursor: pointer;
position: static;
left: 21.74%;
right: 21.74%;
top: 32.26%;
bottom: 32.26%;

font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
/* or 22px */

display: flex;
align-items: center;
text-align: right;

color: #FFFFFF;


/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.selectedButton{
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 26px;

	width: 138px;
	height: 62px;
	background:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #0061D3;
}

.footer{
height: auto;
background:  #EFEFF1;
}

.termText{
height: 24px;
font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */
color: #162542;
text-align: center;
}

.couponName{
width: 143px;
height: 24px;
/* Book/Text 2 - 18px */
font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */
letter-spacing: 0.6px;
/* Neutral/700 */
color: #505562;
}

.couponLink{
	cursor: pointer;
	width: 57px;
	height: 20px;
	/* Book/Small text - 14px */
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.6px;
	text-transform: capitalize;
	/* Primary/500 */
	color: #005DD6;
}

.couponText{
/* width: 167px; */
height: 20px;
font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.6px;

/* Neutral/500 */

color: #767C8F;
}
.errorText{
  color: red;
  margin: 20px;
}

.couponInput{
  width: 285.578px;
  height: 62px;
  border: 2px solid rgba(22, 37, 66, 0.25);
  background: #FFFFFF;
  box-sizing: border-box;
  padding-left: 20px;
}

.couponInput::placeholder {
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  margin-left: 20px;
  color: rgba(22, 37, 66, 0.3);
}

.counponApply{
	cursor: pointer;
font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
background-color: #FFFFFF;
border: 2px solid rgba(22, 37, 66, 0.25);
border-left: none;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.6px;
color: #1644C3;
padding-right: 20px;
}

.footerButton{
  cursor: pointer;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* width: 100%; */
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.6px;
  color: #FFFFFF;
  text-align: center;
}

.footerPadding{
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  background:  #EFEFF1;
}

/* .footerText{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.6px;
  color: #FFFFFF;
  margin: 0px 10px;
} */

/* Jayesh CSS */

.popular{
  /* div css */
  padding: 12px 105px;
  background: rgba(145,12,250,0.12);
  border-radius: 8px 8px 0px 0px;
  /* text css */
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #6818A5;
  text-align: center;
}

.goldMargin{
  margin-top: 24px;
}

.title{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #1E1F24;
}

.planCost{
  margin-top: 24px;
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.year{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #626777;
}

.signupDiv{
  margin-top: 12px;
  /* background: #005DD6; */
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 59.5px;
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  /* color: #FFFFFF; */
  display: inline-block;
  width: 208px;
  white-space: nowrap;
  cursor: pointer;
  }

  .featuresCss{
    margin-top: 16px;
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #505562;
  }
  .featuresCss > b{
    font-family: 'geomanistmedium';
      font-weight: 500;
      margin-right: 1px;
  }

  .description{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    color: #767C8F;
    text-align: justify;
    width: 187px;
  }
  .description > p{
    margin: 0px;
    margin-left: 23px;
  }
  .border{
    background: #FFFFFF;
    border: 1px solid #EFEFF1;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .mainDiv{
    justify-content:center;
    margin:0px;
    margin-top:24px;
    /* padding-bottom: 72px; */
  }

  .responsiveModal {
     overflow-y: hidden !important;
     max-height: 100%;
     /* max-width: 94%;
     margin:0.9rem 2.5rem; */
  }

  .form-control, .btn {
    box-shadow: none !important;
    outline: none !important;
}

.removePadding{
  padding:0px;
}
.couponName{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #505562;
}
.remove{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #005DD6;
    margin-left: 8px;
    cursor: pointer;
}
.discountAmount{
  margin-top: 4px;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
}

.closeIcon{
  text-align: end;
  cursor: pointer;
  padding-right: 15px;
  padding-top: 15px;
}
.modalBody{
  min-height:100px;
}

@media (max-width:1199px){
  .border{
    background: #FFFFFF;
    border: 1px solid #EFEFF1;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .mainDiv{
    margin-top:16px;
    /* padding-bottom: 32px; */
  }

  .errorText{
    /* color: red; */
    margin: 0;
  }

  .responsiveModal :global(.modal-dialog){
    margin-top: 24px !important;
  }
  .footerPadding{
    padding: 20px;
    background:  #FFFFFF;
  }
}

@media (min-width:1200px){
  .onlyXl{
    display: inline-block;
  }
}

@media (max-width:1199px){
  .onlyXl{
    margin-top: 16px;
  }
}

@media (min-width: 200px) and (max-width: 768px) {
  .mobileMarginBottom{
    margin-bottom:20px;
  }
}


/* @media only screen and (max-device-width: 480px) {
  .responsiveModal {
    margin: 0.8rem;
 }
}
@media only screen and (device-width: 768px) {
  .responsiveModal {
    margin: 0.9rem 1.3rem;
 }
}
@media only screen and (device-width: 820px) {
  .responsiveModal {
    margin: 0.9rem 1.4rem;
 }
} */
