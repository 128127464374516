@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.stateContainer {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center; 
   padding: 24px 24px 32px 24px;
   background-color: white;
}

.stateContainer p {
    margin: 0px;
    text-align: center;
}

.stateContainer a {
    text-decoration: none;
}

.stateContainer button:focus {
    outline: none;
}

.title {
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color:#3C404A;
    padding: 16px 0px 4px 0px;
}

.description {
    font-family: 'geomanistRegular';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #767C8F;
    padding: 0px 0px 16px 0px;
}

.stateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px 12px;
    border: 1.5px solid #0061D3;
    background-color: white;
    color:#0061D3;
}

.stateButtonSolid {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    background-color: #0061D3;
    border: 1.5px solid #0061D3;
    color:white;
}

.buttonDisabled {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    background-color: #0061D3;
    border: 1.5px solid #0061D3;
    color:white;
    opacity: 0.65; 
    cursor: default !important;
}

.stateContainer :global(.btn-primary:disabled) {
    background-color:#0061D3;
    border-color: #0061D3;
}

.buttonContainer {
    display: flex;
    gap:16px;
}

.buttonContainerColumn {
    display: flex;
    flex-direction: column;
    gap:16px;
    align-items: center;
    justify-content: center;
}
