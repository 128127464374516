/* Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2016 */

@font-face {
	font-family: 'geomanistbook';
	src: url('geomanist-book-webfont.eot');
	src: url('geomanist-book-webfont.eot?#iefix') format('embedded-opentype'),
		url('geomanist-book-webfont.woff2') format('woff2'),
		url('geomanist-book-webfont.woff') format('woff'),
		url('geomanist-book-webfont.ttf') format('truetype'),
		url('geomanist-book-webfont.svg#geomanistregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
