@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 700px;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
	/* border-bottom: 1px solid #efeff1; */
	padding-bottom: 24px;
}
.note {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
  margin-top: 20px;
  margin-left: 0px;
  text-align: center;
}

.contain {
  cursor: pointer;
	padding: 40px;
	margin-top: 40px;
	background: #ffffff;
	box-shadow: 0px 4px 40px rgba(30, 31, 36, 0.12);
	border-radius: 16px;
	width: 360px;
	height: fit-content;
}
.selectBank{
  cursor: pointer;
  padding: 16px 40px 40px 40px;
  border: 2px solid #005DD6;
}
.toastMessage{
  padding: 14px;
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #3C404A;
display: flex;
}
.rec{
  margin-right: 12px;
  width: 4px;
height: 24px;
  background: #0DC014;
border-radius: 8px;
}
.withdrawPosition{
  z-index: 100;
    position: absolute;
    left: 45px;
}

.withdrawcontain {
  /* background-image: url('../../public/images/withdrawrectangle.svg'); */
	padding: 40px 0px 40px 40px;
	margin-top: 40px;
  background-position: right;
	/* background: #ffffff; */
	/* box-shadow: 0px 4px 40px rgba(30, 31, 36, 0.12); */
  border: 1px dashed #D3D4DA;
	border-radius: 16px;
	width: 360px;
	height: 224px;
}

.bank {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.name {
	padding-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.details {
	padding-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}
.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px 35px 12px 30px;
	margin-right: 40px;
  /* width: 25%; */
}
.addfundstext {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.withdraw {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.value {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}
.fixedDiv {
  border-top: 1px solid #efeff1;
  padding-top: 20px;
	/* background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	); */
	/* text-align: end; */
	/* position: fixed; */
	/* height: 60px;
	bottom: 0px;
	right: 0px;
	margin-right: 0%; */
	/* padding-top: 50px; */
	/* width: 740px; */
}
.marginBankAccount{
  margin-bottom: 30px;
}
.erorrMessage{
  text-align: center;
  margin-top: 10px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #FA274A;
}
.addAccount{
  display: inline;
  cursor: pointer;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.4px;
color: #005DD6;
margin-left:12px;
}

.box {
	height: 16px;
	width: 16px;
}
.agree {
  position: absolute;
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.containPadding{
  padding-bottom: 10px !important;
}

.marginAddBank{
  margin-bottom: 40px;
}

@media (max-width: 768px) {
	.card {
		width: 100%;
	}
}
@media (min-width: 320px) and (max-width: 480px) {
  .card{
    width: 100%;
  }
  .contain{
    /* margin-left: 3%; */
    width: 95%;
  }
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
  .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 0px;
    margin-right: 0px;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
  .marginBankAccount{
    margin-bottom: 100px;
  }
}

.itermediaryHeading{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #626777;
  white-space: nowrap;
}

.intermediaryDetails{
font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.4px;
color: #1E1F24;
}

@media (max-width:1300px){
  .fixedDiv {
    background: none;
    /* text-align: end; */
    position: static;
    width: 100%;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
  .erorrMessage{
    position:absolute;
    bottom: -6%;
  }
  .marginAddBank{
    margin-bottom: 150px;
  }
}

@media (min-height: 200px) and (max-height: 500px) {
  .fixedDiv{
    background:none;
    position:static;
    margin-top: 15px;
  }
}
