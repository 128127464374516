@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.headerMargin {
	margin-top: 64px;
}

.navItem {
	/* padding: 0px; */
	padding-left: 0px;
	padding-right: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #9096a8;
	cursor: pointer;
}

.navItemActive {
	padding-left: 0px;
	padding-right: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	border-bottom: 2px solid #005dd6;
	padding-bottom: 7px;
	cursor: pointer;
}

.factSheet{
  padding-top: 5px;
  padding-left: 30px;
	font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #005DD6;
}

.addWatchList{
  white-space: nowrap;
padding:1px 8px 8px 8px;
width: fit-content;
height: 32px;
border: 1.5px solid #0061D3;
box-sizing: border-box;
border-radius: 8px;
white-space: nowrap;
}

.addWatchListText{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  color: #0061D3;
}

.desciptionMargin {
  padding-right: 30px;
	margin-top: 40px;
}


.navWidth {
	width: 100%;
  border-bottom: 1px solid #EFEFF1;
}

.navMargin {
	margin-top: 25px;
	margin-left: 0px;
}

.stackLike{
	margin-left: 0px;
	font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.6px;
color: #1E1F24;
}
.cardMargin{
	margin-top:50px
}

.stacktext{
	margin-left: 0px;
	font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.4px;
color: #626777;
}
.addWatchListPadding{
  padding-left: 7px;
}
.addedWatchListPadding{
  padding-left: 30px;
}
.mobileMargin{
  margin-top: 40px;
  padding-right: 50px;
}
.mobileMarginNonExclusive{
  margin-top: 0px;
  padding-right: 0px;
}

.navMargin:global(.tab-content){
	width: 65%;
}

.graphDiver{
  padding-top: 64px;
  padding-bottom: 64px;
  border-top: 1px solid #EFEFF1;
}

.mobileTab{
  width:100%
}

.rotate {
  -webkit-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.trendingSlider :global(.slick-slide) {
  padding-right: 16px !important;
}



@media (min-width: 320px) and (max-width: 480px) {
  .mobileTab{
    margin-right: 0px;
    margin-left: 0px;
    display: contents;
  }
  .navMargin{
    margin-left: 0px;
    margin-right: 0px;
  }
  .mobilePadding{
    padding-left: 0px;
    padding-right: 0px;
  }
  .navMargin :global(.tab-pane){
    width: 100%;
  }
  .desciptionMargin {
    margin-top: 24px;
    padding-right: 0px;
  }
  .mobileMargin{
    margin-right: 0px;
    padding-right: 0px;
  }
  .mobileMarginNonExclusive{
    margin-right: 0px;
    padding-right: 0px;
  }
  .trendingSlider {
		height: 200px;
	}
  .headerMargin {
    margin-top: 25px;
  }
  .trendingSlider :global(.slick-slide) {
    /* width: 290px !important; */
    padding-right: 16px !important;

  }
  .buyButton{
    width: 95%;
  }
  .cardBorder{
    left: 0 !important;
  }
}


.trendingSlider :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;

}

.trendingSlider :global(.slick-next) {
	display: none !important;
}




/* for mobile Buy / sell */
.buttonDiv{
  background: #FFFFFF;
  box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 16px 16px 0px 0px;
  text-align: center;
}
.buyButton{
  /* width: 335px;
height: 48px; */
padding: 14px;
padding-left: 25%;
padding-right: 25%;
font-family: 'geomanistMedium';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
text-align: center;
letter-spacing: 0.6px;
color: #FFFFFF;
background: #0DC014;
border-radius: 4px;
border: none;
white-space: nowrap;
}
.buyButton:focus {
	box-shadow: none;
	outline: none;
}
.buysellDiv{
  /* width: 375px; */
  width: auto;
  height: 440px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 56.77%);
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 210px;
}
.innerDiv{
  width: auto;
  height: 200px;
}
.circularDiv{
  height: 48px;
width: 48px;
border-radius: 50%;
text-align: center;
background: #EFEFF1;
padding-top: 12px;
align-content: center;
align-items: center;
cursor: pointer;
  margin-top: 10px;
}
.buyPermannet{
white-space: nowrap;
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
align-items: center;
text-align: center;
letter-spacing: 0.6px;
color: #1E1F24;
background: #EFEFF1;
border-radius: 8px;
border: none;
cursor: pointer;
height: 48px;
width: auto;
padding-left: 20%;
padding-right: 20%;
margin: 10px;
}
.buyPermannet:focus {
	box-shadow: none;
	outline: none;
}
.cancelResponsive{
  padding-left:40%;
}
.buyCardPadding{
  margin-top: 41px;
  padding-left: 0px;
  border-top: 1px solid #EFEFF1;
}
.buyCardMargin{
  margin-top: 47px;
}

@media (min-width: 430px ) and (max-width:768px){
  .cancelResponsive{
    padding-left:45%;
  }

  /* .bodyResponsive :global(.modal-dialog) {
    margin: 0px !important;
  } */
}

@media (min-width: 800px ) and (max-width:1200px){
.paddingWatchList{
  padding-left: 0px;
}
}

@media (min-width: 1800px ) and (max-width:3000px){
.widthCol{
  max-width: 73% !important;
  flex: 0 0 73% !important;
}
}

@media (min-width: 575px ) and (max-width:1000px){
.desciptionMargin{
  padding-right: 0px;
}
.fixedBuyTablet{
  left: 2%;
}
.buyButton{
  width: 95%;
}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}

@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}


/* start */

.icon {
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
  display: inline-block;
}

.icon:after {

  animation: shine 2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 1;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
    transition-duration: 10s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.blurrImage {
  width: 752px;
  height: 398px;
}

.absoluteCover{
  position: absolute;
  left:0px;
  bottom: 0px;
  padding-left: 31px;
  padding-right: 31px;
  height: 295px;
}

.cardBorder{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(30, 31, 36, 0.07);
  border-radius: 16px;
  width: 90%;
  left:20px;
}

.imagePadding{
  margin-left: 32px;
  margin-top: 23px;
  margin-bottom: 29px;
  width: 261px;
  height: 196px;
}

.header{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 30px;
}

.description{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #626777;
  margin-top: 8px;
}

.buttonDist{
  margin-top: 16px;
}


.plan {
	background: linear-gradient(90deg, #e0980e 0%, #f4c66c 56.12%, #f2b133 100%);
	border-radius: 8px;
	border: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 130%;
	letter-spacing: 0.6px;
	color: #302001;
	padding: 13px;
	padding-left: 20px;
	padding-right: 20px;
}



@media (min-width: 1025px) and (max-width: 1440px){
  .blurrImage {
    width: 700px;
    height: 398px;
  }
  .cardBorder{
    width: 642px;
    left:20px;
  }
  .absoluteCover{
    height: 300px;
  }
}

@media (max-width: 425px){
  .blurrImage {
    height: 400px;
    width: 100%;
  }
  .cardBorder{
    width: 100%;
    /* left:0px; */
    height: 340px;
  }
  .header{
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
  }
  .description{
    font-size: 8px;
    margin-top: 0px;
    text-align: center;
  }
  .buttonDist{
    margin-top: 10px;
    text-align: center;
  }
  .imagePadding{
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 150px;
  }
  .plan {
    font-size: 8px;
    line-height: 130%;
    padding: 8px;
  }
}

@media (min-width: 425px) and (max-width: 768px){
  .cardBorder{
    width: 90%;
    height: 328px;
  }
  .blurrImage {
    width: 90%;
    height: 335px;
  }
    .imagePadding{
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 150px;
  }
  .header{
    font-size: 14px;
    margin-top: 15px;
  }
  .absoluteCover{
    height: 240px;
    bottom:35px;
  }
  .plan {
    font-size: 12px;
    line-height: 130%;
    padding: 13px;
    padding-left: 20px;
    padding-right: 20px; 
   }
}

@media (min-width: 768px) and (max-width: 1024px){
  .blurrImage {
    width: 600px;
    height: 398px;
  }
  .cardBorder{
    width: 530px;
    left:20px;
    height: auto;
  }
  .absoluteCover{
    height: 270px;
    bottom: 0;
  }
  .imagePadding{
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 150px;
  }
  .header{
    font-size: 14px;
    margin-top: 20px;
  }
  .description{
    font-size: 8px;
    margin-top: 0px;
  }
  .buttonDist{
    margin-top: 10px;
  }
  .plan {
    font-size: 8px;
    line-height: 130%;
    padding: 8px;
  }
}

@media (min-width: 1025px) and (max-width: 1202px){
  .cardBorder{
    width: 90%;
  }
  .blurrImage {
    width: 90%;
  }
}



/* end */
