.carousel-indicators {
	margin-left: 0px;
	margin-right: 0px;
	/* padding-left: 26px; */
	justify-content: center;
	padding-bottom: 15px;
}

.carousel-indicators > li {
  background-color: #1E1F24;
	margin-right: 7px;
	margin-left: 7px;
	border-radius: 50%;
	width: 10px;
	height: 10px;
}
