@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.stackCard {
	width: 352px;
  border: 0px;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
}

.cardBody {
	padding: 32px;
}

.stackPrice {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin: 0px;
	border: 0px;
}

.buyButton {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	width: 288px;
	height: 58px;
	background: #0DC014;
	border-radius: 8px;
	border: 0px;
	box-shadow: none;
}

.buyButton:hover,
.buyButton:focus,
.buyButton:active {
	background: #0DC014;
	border: 0px;
	box-shadow: none;
}

.buyButton:disabled {
	background-color: #9096a8;
	border-color: #9096a8;
}

.upgradeButton {
  font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	width: 100%;
	height: 64px;
	background: #9096a8;
	border-radius: 8px;
	border: 0px;
	box-shadow: none;
}

.upgradeButton:hover,
.upgradeButton:focus,
.upgradeButton:active {
	background: #9096a8;
	border: 0px;
	box-shadow: none;
}

.upgradeButton:not(:disabled):not(.disabled):active,
.upgradeButton:not(:disabled):not(.disabled):active:focus {
	color: #fff;
	background-color: #9096a8;
	border-color: #9096a8;
	box-shadow: none;
}

.buyButton:not(:disabled):not(.disabled):active,
.buyButton:not(:disabled):not(.disabled):active:focus {
	color: #fff;
	background-color: #0DC014;
	border-color: #0DC014;
	box-shadow: none;
}

.createdImage {
	height: 24px;
	margin-left: 12px;
  margin-right: 12px;
}

.stackCreatedBy {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.minInvest {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
}

.inputControl {
	margin: 0px;
	margin-top: 12px;
}

.amountInput {
	font-family: 'geomanistbook' !important;
	padding-left: 25px;
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out !important;
	border: 0px !important;
	background: #efeff1;
	border-radius: 8px !important;
	/* width: 288px; */
	height: 48px;
}

.amountInput:read-only {
	background: #efeff1 !important;
}

.amountInput::placeholder {
	padding-left: 10px;
	font-family: 'geomanistregular' !important;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #9096a8;
}

.amountInput:active,
.amountInput:hover {
	background: #efeff1;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.amountInput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
}

.amountInput:focus {
	background: #efeff1;
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}
.dollarIcon {
	top: -2px;
	visibility: visible;
	position: absolute;
	padding: 13px;
	cursor: pointer;
	color: #9096a8;
	cursor: pointer;
}

/*************************/
/*************************/
/*************************/
/******Review Order*******/
.reviewCard {
  height: calc(100vh - 200px);
	border: 0px;
	border-left: 1px solid #efeff1;
}

.reviewCardBody {
	padding: 0px 32px 40px 32px;
}

.reviewOrderTitle {
	padding: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
}

.closeCard {
	cursor: pointer;
  text-align: end;
	/* top: -15px; */
  padding-top: 17px;
}

.stackName {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.reviewImage{
  padding:0px;
  margin:auto;
}

.stackImage {
	width:100%;
	border-radius: 8px;
}

.stackCreator {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #9096a8;
}

.feeNote {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
}

.enterPinText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
}

.spinInput {
	border: none !important;
	text-align: center;
	width: 40px !important;
	height: 48px;
	background: #efeff1 !important;
	border-radius: 8px !important;
	padding-top: 7px;
}

.spinInput::placeholder {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #d3d4da;
}

.termsConditions {
	/* margin-top: 150px !important; */
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
	display: inline-block;
	align-items: center;
	letter-spacing: 0.6px;
	color: #505562;
	margin-top: 15px !important;
}

.terms {
  color: #005dd6;
  cursor: pointer;
  padding-left: 4px;
}

.orderPlacedText {
	width: auto;
	justify-content: center;
	margin-top: 38px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.cardBorder {
	border: 0px;
	height: 100vh;
}

.investing {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #505562;
}

.footer {
  /* width: 100%; */
  /* position: sticky; */
  /* background: #f7f7f8; */
  height: 64px;
  position: fixed;
  width: 901px;
  margin-left: -32px;
  /* left: 225px; */
  bottom: 0px;
}

.footerStack {
  background: #f7f7f8;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: left;
	padding-top: 20px;
	padding-left: 25px;
	padding-right: 25px;
}

.modalWidth {
	width: 1160px;
}
.detail{
  padding-right: 0px;
  padding-left: 20px;
  padding-top: 5px;
}


.scrollDiv {
  margin-left: 0px;
  margin-top: 24px;
  padding-right: 30px;
	overflow: scroll;
	height: calc(100vh - 180px);
}
.createdBypadding{
  padding-right: 30px;
}
/************************/
/***********************/
/**********************/
/*****Purchase*********/
.purchaseImage {
	margin-top: 130px;
}

.buttonRow{
  margin-top: 32px;
}
.confirmFixed{
  position: fixed;
  bottom: 0;
  padding-bottom: 6px;
  width: 250px;
  background: #fff;
}
.pinPadding{
  padding:16px
}
.reviewScroll{
	overflow-y: auto;
	overflow-x: hidden;
}
@media (min-width: 300px) and (max-width: 768px) {
	.orderPlacedText{
	  width: auto;
	}
	.reviewCard{
	  height: fit-content;
	  border-left: 0px !important;
	}
	.stackCard{
		width: 100%;
		background: #ffffff;
		box-shadow: none !important;
		border-radius: 0px !important;
		border:0px !important;
		margin: 0;
	  }
	  .amountInput{
		width: 100%;
	  }
	  .confirmFixed{
		position: static;
		width: 100%;
	  }
	  .buyButton{
		/* width: 100% !important; */
		height: 48px !important;
	  }
	  .stackImage{
		width: 80px;
	  }
	  /* .mobileFlex{
		flex-direction: row-reverse;
	  } */
	  .modalWidth{
		width: 100% !important;
		margin: 0px !important;
		height: 100vh;
	  }
	  .reviewCardBody{
		padding:15px !important;
	  }
	  .reviewOrderTitle{
		margin-top: 15px;
		margin-bottom: 30px;
		text-align: center;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		letter-spacing: 0.6px;
		color: #1E1F24;
	  }
	  .buttonRow{
		justify-content: center;
		margin-top: 0px;
		position: fixed;
		bottom: 0;
		right: auto;
		text-align: center;
		background: #FFFFFF;
		box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
		padding-top: 8px;
		padding-bottom: 8px;
		left: calc(100vw - 500px);
		width: 500px;
	  }
	  .termsConditions{
		margin-top: 24px !important;
		margin-bottom: 70px !important;
	  }
	  .stocksWeightMobile{
		margin-top: 5px;
		font-family: 'geomanistmedium';
		  font-style: normal;
		  font-weight: normal;
		  font-size: 16px;
		  line-height: 24px;
		  letter-spacing: 0.6px;
		  color: #1E1F24;
	  }
	  .modalDialog{
      max-width: 100%;
		width: 100%;
		margin: 0px 0px 0px auto;
	  }
	  .detail{
		padding-left: 0px;
		padding-top: 0px;
	  }
	  .reviewScroll{
		overflow: auto;
	}
  }
  @media (max-width: 576px) {
	.buttonRow{
		left: 0;
		width: 100%;
	  }
	  .modalDialog{
		max-width: 100%;
	}
  }
  @media (min-width: 768px ) and (max-width:992px){
	/* .mobileFlex{
		flex-direction: row;
	  } */
	.modalWidth{
	  padding-right: 20px;
	  padding-left: 20px;
	}
	.stackImage{
	 	position: static;
		 width: 100%;
	  }
	.footer{
	  left: 32px;
	  width: 62%;
	}
	.reviewCardBody{
	  padding-left: 20px;
	}
	.pinPadding{
	  padding:8px
	}
	.detail{
		padding-right: 0px;
		padding-left: 20px;
		padding-top: 5px;
	}
  }
  @media (min-width: 768px) and (max-width: 1240px) {
	.stackCard{
	  width: 300px;
	  margin:auto;
	  /* box-shadow: none; */
	}
	.cardBody {
	  padding: 20px;
	}
	.modalWidth {
	  width: 100%;
	}
	.reviewOrderTitle {
	  font-size: 20px;
	}
	.stackName{
	  font-size: 16px;
	}
	.otpInput{
	 padding: 10px;
	}
	.termsConditions {
	  margin-top: 15px !important;
	}
	.buyButton{
	  width:257px;
	}
	.confirmFixed{
		position: static;
		width: auto;
	}
	.buttonRow{
		width: 240px;
		height: 64px;
		position: fixed;
		bottom: 0;
		background: #fff;
		padding-top: 0;
		left: auto;
	}
	.reviewCard{
		height: calc(100vh - 130px);
	}
  }
@media (min-width: 992px) and (max-width: 1240px){
	.footer{
		width: 71%;
	  }
}
/* Plan display css */

.noteMessage {
	background: #f7f7f8;
	border-radius: 6px;
	margin-left: 15px;
	margin-right: 15px;
	width: 100%;
	height: 48px;
}
.noteMessageText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #3c317a;
	text-align: left;
}
.info {
	padding-left: 25px;
}

@media (min-width: 760px) and (max-width: 1000px){
 .upgradeButton{
   width: 100%;
 }
}


@media (min-width: 575px) and (max-width: 768px) {
  .buttonRow{
    width: 100%;
    left:0px;
  }
  .buyButton{
    width: 90%;
  }
}
