@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.mobileMarginBottom{
  margin-top: 32px;
  margin-bottom: 20px;
}

.kycUpdateButtonContainer {
	position: fixed;
    display: flex;
	flex-direction: row;
    bottom: 0;
	gap: 12px;
	width: inherit;
	justify-content: space-between;
	align-items: center;
	z-index: 1000;
	padding-top: 24px;
	padding-bottom: 24px;
	background-color: white;
}

@media(max-width:576px) {
	.kycUpdateButtonContainer {
		padding-right: 42px;
	}
}

.name {
	margin: 0;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.nameSection {
	margin: 0px;
	margin-top: 44px;
	padding-bottom: 26px;
	border-bottom: 1px solid #efeff1;
}

.userDetail {
	margin: 0px;
	margin-top: 8px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
  display: block;
}

.changePlanButton {
	width: 152px;
	height: 40px;
	background: #005dd6;
	border-radius: 8px;
	padding: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	text-align: center;
	letter-spacing: 0.4px;
	/* text-transform: lowercase; */
	color: #ffffff;
}

.changePlanButton:hover {
	background: #1644c3;
	border-radius: 8px;
	color: #ffffff;
	border: none;
	box-shadow: none;
}

.changePlanButton:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.changePlanButton:not(:disabled):not(.disabled):active {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.changePlanButton:not(:disabled):not(.disabled):active:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.basicDetail {
	margin: 0px;
	margin-top: 40px;
}

.basicDetailText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.modalHeadingText {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.editButton {
	background: #efeff1;
	border-radius: 6px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.6px;
	border: none;
	box-shadow: none;
	color: #1e1f24;
}
.editButton:hover {
	background: #efeff1;
	border-radius: 6px;
	color: #1e1f24;
	border: none;
	box-shadow: none;
}

.editButton:focus {
	background: #efeff1;
	color: #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.editButton:not(:disabled):not(.disabled):active {
	background: #efeff1;
	color: #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.editButton:not(:disabled):not(.disabled):active:focus {
	background: #efeff1;
	color: #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.label {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #626777;
}

.value {
  word-break: break-word;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.4px;
	color: #1e1f24;
}

/**********************************/
/**********************************/
/********Basic detail Component****/
.borderBottom {
	margin: 0px;
	padding-bottom: 24px;
	border-bottom: 1px solid #efeff1;
}

.saveButton {
	background: #005dd6;
	border-radius: 8px;
	font-family: 'geomanistmedium';
	font-weight: 500;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.6px;
	border: none;
	box-shadow: none;
	color: #ffffff;
}
.saveButton:hover {
	background: #1644c3;
	border-radius: 8px;
	color: #ffffff;
	border: none;
	box-shadow: none;
}

.saveButton:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.saveButton:not(:disabled):not(.disabled):active {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.saveButton:not(:disabled):not(.disabled):active:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

/*********************/
/********************/
/********************/
/****reset password**/
.verifyOtpText {
	margin: 0px;
	/* margin-top: 48px; */
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #000000;
}

.mobileNote {
	margin: 0px;
	margin-top: 8px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.4px;
	color: #626777;
}

.spinInput {
	border: none !important;
	text-align: center;
	width: 40px;
	height: 48px;
	background: #efeff1;
	border-radius: 8px !important;
	padding-top: 7px;
}

.spinInput::placeholder {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #d3d4da;
}

.otpSec {
	margin: 0px;
	margin-top: 16px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
}

.buttonMargin {
	margin: 0px;
	margin-top: 25px;
}

.cancelButton {
	font-family: 'geomanistregular';
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-top: 10px;
	cursor: pointer;
}

.otpText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #3c404a;
}

.profileModalBody {
	width: 400px;
  height: calc(100vh - 130px);
	overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}
.profileModalBody::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.profileModalBody::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
.profileModalBody::-webkit-scrollbar-thumb {
  background: #efeff1;
  border-radius: 10px;
}


.noOtp {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #767c8f;
	cursor: pointer;
}

.otpResend {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #005dd6;
}

.enterOtpText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
	margin: 0px;
	margin-bottom: 24px;
	padding-right: 10px;
	margin-top: 16px;
}

.otpInput {
	background: #efeff1 !important;
	border-radius: 8px !important;
	border: none !important;
}

.inputRowsEdit {
	margin-top: 24px;
}

/* .inputRowsSaveButton{
	bottom: 0px;
	position: absolute;
} */

.editSaveButton {
	width: 100%;
	padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'geomanistMedium';
  font-size: 16px;
  letter-spacing: 0.3px;
  border-radius: 8px;
	/* position: absolute;
	bottom: 0px; */
}

.bottomButton {
	position: absolute;
	bottom: 10px;
	/* text-align: center; */
  margin-left: 50px;
	padding: 15px;
	cursor: pointer;
}

.saveBottomButton {
	right: 35px;
	position: absolute;
	bottom: 10px;
}

.calendar {
	position: absolute;
	z-index: 105;
	top: 45px;
	width: 368px;
}

.formInput {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	font-family: 'geomanistregular';
	border: 0px;
	height: 48px;
	background: #efeff1 !important;
	border-radius: 8px !important;
	border: none !important;
}

.genderSelectWhite {
	background: #efeff1 !important;
	border-radius: 8px;
	border: 1px solid #efeff1;
	cursor: pointer;
  padding-top: 13px;
  padding-bottom: 13px;
}

.genderSelectBlack {
	background: #1e1f24 !important;
	border-radius: 8px;
	/* border: 1px solid #EFEFF1 ;  */
	cursor: pointer;
  padding-top: 13px;
  padding-bottom: 13px;
}

.genderTextWhite {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #ffffff;
	margin-bottom: 0px;
	text-align: center;
}

.genderTextBlack {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 0px;
	text-align: center;
}

.sadText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.cancelReasonGrey {
	background: #efeff1;
	border-radius: 8px;
	cursor: pointer;
}

.cancelReasonBlack {
	background: #1e1f24;
	border-radius: 8px;
	cursor: pointer;
}

.reasonTextBlack {
	font-family: 'geomanistregular';
	padding: 14px;
	padding-left: 24px;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.reasonTextWhite {
	font-family: 'geomanistregular';
	padding: 14px;
	padding-left: 24px;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #ffffff;
}

.cancelTextArea {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	font-family: 'geomanistregular';
	border: 0px;
	/* height: 48px; */
	background: #efeff1;
	border-radius: 8px !important;
	border: none !important;
	font-family: 'Inter';
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	width: 100%;
	padding: 14px;
	height: 88px;
}

.cancelTextArea:focus,
input:focus {
	outline: none;
}

.continueButton {
	background-color: #005dd6;
	width: 100%;
	height: 50px;
}

.ReachOutText {
	font-family: 'geomanistregular';
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	padding-right: 42px;
}

.politciallyCheckbox {
	margin-top: 0.3rem;
	vertical-align: text-bottom;
}

.profileMarginCheckbox {
	margin-top: 24px;
}

.porfileBackArrow {
	margin-right: 20px;
}

.profileSubHeading {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
	margin: 0px;
	margin-bottom: 24px;
	padding-right: 2px;
}

.photoUploadBox {
	border: 1px dashed #c4c4c4;
	box-sizing: border-box;
	border-radius: 4px;
	height: 203px;
}

.profileUploadButtonCenter {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.profileUploadButton {
	background: #efeff1;
	border-radius: 8px;
	width: 175px;
	height: 40px;
	border: none;
}

.profileUploadButton:focus {
	border: none;
	outline: none;
}

.profileUploaded {
	width: 400px;
	height: 237px;
	border-radius: 10px;
}

.profileRemoveButton {
	background: #fa274a;
	border-radius: 6px;
	width: 89px;
	height: 36px;
	border: none;
	color: white;
	letter-spacing: 0.6px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
}

.profileRemoveButton:focus {
	outline: none;
}

.profileRemoveButtonCenter {
	margin: 0;
	position: absolute;
	top: 90%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.requestRow {
	margin-top: 200px;
}

.RequestHead {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
	padding-right: 40px;
	padding-left: 40px;
}

.RequestSubHead {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #626777;
	padding-left: 115px;
	padding-right: 115px;
	padding-top: 8px;
}

.addharCardHeading {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	padding-left: 0px;
	padding-bottom: 8px;
	padding-top: 10px;
	color: #1e1f24;
}

.slashMargin{
  margin-left: 5px;
}

.userNameProfile{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.6px;
  color: #626777;
  margin-left: 8px;
  display: flex;
}

.profileInputBelowText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #9096a8;
	margin-bottom: 0px;
	margin-top:10px;
}

.profileSelectBox {
	outline: none;
}

.profileInputBox {
	font-family: 'geomanistregular';
	background: #efeff1;
	border-radius: 8px;
	border: 0px;
	padding-left: 12px;
	padding-top: 14px;
	padding-bottom: 14px;
	width: 100%;
}

.profileInputBox::placeholder {
	font-size: 14px;
	font-family: 'geomanistregular';
	color: #767c8f;
}

.profileErrorInputBox {
	font-family: 'geomanistregular';
	background: #efeff1;
	border-radius: 8px;
	padding-left: 12px;
	padding-top: 14px;
	padding-bottom: 14px;
	width: 100%;
	border: 1px solid #e61446;
	box-sizing: border-box;
}

.profileErrorInputBox::placeholder {
	font-size: 14px;
	font-family: 'geomanistregular';
	color: #767c8f;
}

.phoneErrorInputBox{
  font-family: 'geomanistregular';
	background: #efeff1;
	border-radius: 8px;
	padding-left: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
	width: 100%;
	border: 1px solid #e61446;
	box-sizing: border-box;
}

.phoneErrorInputBox input::placeholder{
	font-size: 14px !important;
	font-family: 'geomanistregular';
	color: #767c8f;
}

.verifyButtonMargin{
  margin: 0px;
	margin-top: 25px;
}

.phoneInputBox{
  font-family: 'geomanistregular';
	background: #efeff1;
	border-radius: 8px;
	border: 0px;
	padding-left: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
	width: 100%;
}

.phoneInputBox input::placeholder{
  font-size: 14px !important;
	font-family: 'geomanistregular';
  color: #767c8f;
}

.profileErrorText {
	font-family: 'geomanistregular';
	color: #e61446;
	padding-top: 8px;
	margin-bottom: 0px;
	font-size: 14px;
}
.profileSuccessText {
	font-family: 'geomanistregular';
	color: #008000;
	padding-top: 8px;
	margin-bottom: 0px;
	font-size: 14px;
}
.profileInputLabel {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.profileCustomDropdown {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}

.profileCustomSelect {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
	width: 100%;
	border: none;
	border-radius: 8px;
	background: #efeff1;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.profileCustomSelect:focus {
	border: none;
}

.profileExposeName {
	opacity: 0.7;
}

.profileInputBox input {
	/* border:"none" !important; */
	background-color: #efeff1;
	border: 1px solid #efeff1;
}

.phoneInputBox input{
  background-color: #efeff1;
	border: 1px solid #efeff1;
}

.phoneErrorInputBox input{
  background-color: #efeff1;
	border: 1px solid #efeff1;
}

.profileErrorInputBox input {
	background-color: #efeff1;
	border: 1px solid #efeff1;
}

.profilePasswordCheck {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #000000;
	opacity: 0.4;
	margin-bottom: 6px;
}

.passCheckPad {
	margin-bottom: 6px;
}

.profilePasswordCheckGreen {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #1eb11b;
	margin-bottom: 6px;
}

.upgradePlanMobileButton{
	display: none;
}

.upgradeButton{
	display: block;
}

.cancelSubscriptionButton{
	display: block;
}

.pinsaveButton {
	background: #005dd6;
	border-radius: 8px;
	font-family: 'geomanistmedium';
	font-weight: 500;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.6px;
	border: none;
	box-shadow: none;
	color: #ffffff;
	height: 48px;
	width: 130px;
}

.pinsaveButton:focus {
  outline: none;
  box-sizing: border-box;
  box-shadow: none !important;
  border-radius: 8px;
}

.responsiveSpin{
	display: none;
}

.responsivePadding{
	margin-top:20px;
  padding-left: 0px;
  margin-left: -15px;
}

.otpBodyWidth{
	width: 400px;
}

.verifyOtpTextResponsive{
	display: none;
}

.widthProfileSpinInput{
	width: 256px;
}

.verifyOtpSpinResponsive{
	display: none;

}

.changePinOtp{
	display: flex !important;
}
.changePinOtpResponsive{
	display: none;
}
.ResponsiveModalContainer{
	display:none;
    /* position: fixed;
    width: 50;
    width: 100%;
    height: 100vh;
    background: white;
    top: 0px;
    left: 0px;
	padding:20px; */
}

.ResponsiveChangePinOtpContainer{
	display: none;
}

.responsivePinContainerBackground{
	display: none;
}

.ResponsiveTextEnterNewPin{
	display: none;
}

.resetSaveButton{
	background: #005dd6;
	border-radius: 8px;
	font-family: 'geomanistmedium';
	font-weight: 500;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.6px;
	border: none;
	box-shadow: none;
	color: #ffffff;
	height: 48px;
	width: 183px;
}

.resetPasswordHeading{
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	margin:0px;
	margin-top:20px;
	letter-spacing: 0.6px;
	color: #1E1F24;
}

.enterPinText{
	/* margin: 0px; */
	margin-top: 8px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #3C404A;
}

.renewalDate{
  margin-left:10px;
}

.saveButtonSpin {
  background: #9096A8;
  border-radius: 8px;
  font-family: 'geomanistmedium';
  font-weight: 500;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.6px;
  border: none;
  box-shadow: none;
  color: #ffffff;
  width: 219px;
  height: 48px;
}
.saveButtonSpin:focus{
  /* border: none;
  outline: none !important; */
  box-shadow: none;
}
.saveButtonSpin:hover{
  box-shadow: none;
}

.pinHeadingMargin{
  margin-top:40px;
}

.passwordCheckMargin{
  margin-top:20px;
}

.updateBox{
  color: green;
  border-radius: 8px;
  font-family: 'geomanistmedium';
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.redupdateBox{
  color:red;
  border-radius: 8px;
  font-family: 'geomanistmedium';
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdownInputFont {
  font-family: 'geomanistregular';
}

.UpdateKycButton{
  background: #005dd6;
	border-radius: 8px;
	font-family: 'geomanistmedium';
	font-weight: 500;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.6px;
	border: none;
	box-shadow: none;
	color: #ffffff;
	padding-top:15px;
  padding-bottom: 15px;
}

.UpdateKycRow{
  text-align: end;
}

.goBackButton{
  display: inline-block;
  font-family: 'Inter';
  margin-left: 20px;
  margin-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  /* padding-bottom: 4px; */
  border-bottom: 1px solid #0061d3;
  color: #0061d3;
  cursor: pointer;
}

.SuccessOrErrorMessage{
  margin-top: 10px;
  color: red;
  margin-left: 5px;
}

.successMessage{
  margin-top: 10px;
  color: green;
  margin-left: 5px;
}

@media (max-width: 576px) {
  .goBackButton{
    display: inline-block;
    color:#005dd6;
    margin-top: 10px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }
  .userNameProfile{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.6px;
    color: #626777;
    margin-left: 0px;
    display: flex;
  }
  .slashMargin{
    margin-left: 0px !important;
  }

  .updateBox{
   display: none;
  }

  .renewalDate{
    display: block;
     margin-left:0px;
  }
	.enterPinText{
		text-align: center;
		margin-top: 20px;
	}
	.upgradePlanMobileButton{
		display: block;
		margin-top: 20px;
	}
	.upgradeButton{
		display: none;
	}
	.cancelSubscriptionButton{
		display: none;
	}
	.basicDetailText {
		font-family: 'geomanistMedium';
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 0.6px;
		color: #1e1f24;
	}
  .editSaveButton {
    width: 90%;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: 'geomanistMedium';
    font-size: 16px;
    letter-spacing: 0.3px;
    border-radius: 8px;
  }
	.mobileNote {
		margin: 0px;
		margin-top: 8px;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.6px;
		color: #3C404A;
	}
	.pinsaveButton{
		width: 100%;
	}
	.responsiveSpin{
		display: inline-block;
	}
	.responsivePadding{
		margin-top:32px;
	}
	.otpBodyWidth{
		width: 100%;
		height: 50vh;
	}
	.profileModalBody {
		width: 100% !important;
    height: calc(100vh - 200px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.bottomButton {
		position: absolute;
		bottom: 55px;
		text-align: center;
		padding: 15px;
		cursor: pointer;
    margin-left: 0px;
	}
	.saveBottomButton {
		position: absolute;
		bottom: 55px;
		padding:0px;
    left: 50%;
	}
	.changePinOtp{
		display: none !important;
	}
	.changePinOtpResponsive{
		display: inline-flex;
	}
	.verifyOtpTextResponsive{
		display: inline-flex !important;
		margin: 0px;
		/* margin-top: 48px; */
		font-family: 'geomanistmedium';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.6px;
		color: #000000;
	}
	.verifyOtpText {
		display: none;
	}
	.verifyOtpSpinResponsive{
		display: inline-flex;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: 0.6px;
		color: #505562;
		margin-left: 0px;
		margin-top:12px;
	}
	.widthProfileSpinInput{
		width: 100%;
	}

	/* responsive button */

	.saveButtonSpin {
		background: #9096A8;
		border-radius: 8px;
		font-family: 'geomanistmedium';
		font-weight: 500;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		align-items: center;
		letter-spacing: 0.6px;
		border: none;
		box-shadow: none;
		color: #ffffff;
		width: 100%;
		height: 48px;
	}

	.saveButtonSpin:hover {
		background: #9096A8;
		border-radius: 8px;
		color: #ffffff;
		border: none;
		box-shadow: none;
	}

	.saveButtonSpin:focus {
		background: #9096A8;
		color: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}

	.saveButtonSpin:not(:disabled):not(.disabled):active {
		background: #9096A8;
		color: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}

	.ResponsiveModalContainer{
		display: block;
		padding:20px;
		position: fixed;
		width: 50;
		width: 100%;
		height: 100vh;
		background: white;
		top: 0px;
		left: 0px;
    z-index: 10000;
	}

	.ResponsiveChangePinOtpContainer{
		display: block;
		padding:20px;
    padding-bottom: 40px;
		position: fixed;
		width: 100%;
		/* height: 100vh; */
		background: white;
		/* top: 0px; */
		left: 0px;
		position: fixed;
		bottom: 0;
		width: 100%;
		border-radius: 40px;
    z-index: 100;
	}

	.responsivePinContainerBackground{
		display: block;
		position: fixed;
		top:0px;
		height:60%;
		width: 100%;
		background: black;
		left: 0px;
		opacity: 0.5;
    z-index: 100;
	}

	.ResponsiveTextEnterNewPin{
		font-family: 'geomanistmedium';
		font-size: 20px;
		line-height:28px;
		color: #3C404A;
		margin:0px;
		display: block;
		margin-top:24px;
	}
	.pinsaveButton {
		background: #9096A8;
		border-radius: 8px;
		font-family: 'geomanistmedium';
		font-weight: 500;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		align-items: center;
		letter-spacing: 0.6px;
		border: none;
		box-shadow: none;
		color: #ffffff;
		height: 48px;
		width: 100%;
	}
	.pinsaveButton:hover {
		background: #9096A8;
		border-radius: 8px;
		color: #ffffff;
		border: none;
		box-shadow: none;
	}

	.pinsaveButton:focus {
		background: #9096A8;
		color: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}

	.pinsaveButton:not(:disabled):not(.disabled):active {
		background: #9096A8;
		color: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}

	/* reset Screen Button */
	.resetSaveButton {
		background: #9096A8;
		border-radius: 8px;
		font-family: 'geomanistmedium';
		font-weight: 500;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		align-items: center;
		letter-spacing: 0.6px;
		border: none;
		box-shadow: none;
		color: #ffffff;
		height: 48px;
		width: 100%;
		margin-top:50px;
		position: fixed;
		bottom: 20px;
		left: 20px;
		width: 87%;
	}
	.resetSaveButton:hover {
		background: #9096A8;
		border-radius: 8px;
		color: #ffffff;
		border: none;
		box-shadow: none;
	}

	.resetSaveButton:focus {
		background: #9096A8;
		color: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}

	.resetSaveButton:not(:disabled):not(.disabled):active {
		background: #9096A8;
		color: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}
	.ResetScreenHideResponsive{
		display: none;
	}

  .pinCenterMobile{
    text-align: center;
  }
  .responsivePadding{
    margin-left:0px;
  }
  .verifyButtonMargin{
    margin-top:42px;
  }
  .pinHeadingMargin{
    margin-top:20px;
  }
  .UpdateKycRow{
    text-align: left;
    margin-top:20px;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .renewalDate{
    display: block;
    margin-left:0px;
  }
  .changePlanButton {
	  width: 100%;
  }
  .profileModalBody {
    width: 400px;
    height: calc(100vh - 100px)!important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .userNameProfile{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.6px;
    color: #626777;
    margin-left: 0px;
    display: flex;
  }
  .slashMargin{
    margin-left: 0px !important;
  }

}

@media (min-width:1550px) {
  .profileModalBody {
    width: 400px;
    height: calc(100vh - 100px)!important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}


.bottomButton1 {
  /*margin-left: 50px;*/
  padding: 15px;
  cursor: pointer;
}

.yes {
  border: 1px solid #efeff1;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  /* margin-right: 12px; */
  width: 100%;
  /* width: 360px; */
}
.no {
  border: 1px solid #1e1f24;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  /* margin-left: 12px; */
  width: 100%;
  /* width: 360px; */
}

@media (min-width: 580px) and (max-width: 768px){
	.customModalPadding :global(.modal-content){
		padding: 0px 50px;
	}
}
/* reset password, reset pin, redeem coupon should have same css for font*/
.redeemCouponHeading{
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	margin:0px;
	margin-top:20px;
	letter-spacing: 0.6px;
	color: #1E1F24;
}
.redeemCouponConatiner{
	width: 290px
}

.list{
  padding-inline-start:20px;
}
.heading{
  color: #FB264A;
}
.closureModal{
  width: 90%;
  border-radius:16px !important;
}
.cancelBtn{
  padding: 1px 6px;
  display: inline-table;
  border-radius: 50%;
  border: 2.66667px solid #616770;
}
.bodyContent{
  /* width: 340px;
height: 64px; */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
/* or 133% */


/* Neutral/900 */

color: #1E1F24;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;

}
.bodyContentP{
  /* width: 340px;
height: 48px; */

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */


/* Neutral/400 */

color: #616770;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
}
.closureHead{
/* Regular/Small text - 14px */
/* width: 65%; */

font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #626777;
}

.closureLink{
  color:#005DD6;
  cursor: pointer;
}
.closureImg{
  position: relative;
  top: 20px;
}
.processingIcon{
  color:#FA5D05;
  margin-left: 10px;
}
.disclaimer{
  margin:10px 0px 0px 0px;
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #767C8F;
}


/* last name popup */
.namePopup{
  text-align:center;
  padding-top:20px;
}
.nameTitle{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
text-align: center;
color: #3C404A;
}
.nameSubTitle{
  margin-top:12px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #767C8F;
}
/* Security lending profile section */
.seclending{
	background: #F7F7F8;
	border: 1px solid #EFEFF1;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	margin-right: 15px;
	margin-left: 15px;
}

.seclending .content .header{
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 20px;
	color: #3C404A;
	margin-bottom: 4px;
}
.seclending .content .description{
	font-family: 'geomanistRegular';
	font-weight: 400;
	font-size: 14px;
	color: #767C8F;
}
.seclending .content .description span, .actionText{
	color: #0061D3;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
}
.seclending .content .description span:hover{
	text-decoration: underline;
}
.emailContainer{
	margin: 0px 6px 0px 0px;
}
.email{
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	color: #3C404A;
	margin-right: 6px;
}
.seclending .enable{
	border: none;
	background: #0061D3;
	border-radius: 8px;
	padding: 10px 20px;
	white-space: nowrap;
	color: #FFFFFF;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	height: max-content;
}
.seclending .disable{
	border: 1.5px solid #0061D3;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 10px 20px;
	white-space: nowrap;
	color: #0061D3;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	height: max-content;
}
.disableBtn{
	cursor: default !important;
}
.seclending .enable:hover,.seclending .enable:focus{
	background: #004EA9 !important;
}
.seclending .disable:hover,.seclending .disable:focus{
	border: 1.5px solid #004EA9;
	color: #004EA9;
}
.secLendingModal :global(.modal-content){
	border-radius: 8px !important;
	padding: 40px 24px 32px;
}
.crossIcon{
	position: absolute;
	right: 24px;
	top: 24px;
	width: 28px;
	height: 28px;
	cursor: pointer;
	padding: 7px;
}
.warningContainer{
	display: flex;
	width: 100%;
	justify-content: center;
}
.warningIcon{
	width: 80px;
	height: 80px;
}
.secLendingHeader{
	margin: 32px 0px 12px 0px;
	color: #3C404A;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 24px;
	text-align: center;
}
.secLendingDescription{
	font-family: 'geomanistRegular';
	font-weight: 400;
	font-size: 14px;
	color: #767C8F;
	text-align: center;
	margin-bottom: 32px;
}
.secLendingAction{
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 16px;
}
.secLendingPrimary{
	padding: 10px 20px;
	background: #FA274A;
	border-radius: 8px;
	color: #FFFFFF;
	border: 1.5px solid #FA274A;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	height: max-content;
	white-space: nowrap;
	height: max-content;
}
.secLendingPrimary:hover,.secLendingPrimary:focus{
	background: #CF203D;
	border: 1.5px solid #CF203D;
}
.spinnerColorDisable{
    border: .25em solid #FFFFFF;
    border-right-color: #D3D4DA;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.secLendingSecondary{
	border: 1.5px solid #0061D3;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 10px 20px;
	white-space: nowrap;
	color: #0061D3;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	height: max-content;
}
.secLendingSecondary:hover, .secLendingSecondary:focus{
	border: 1.5px solid #004EA9;
	color: #004EA9;
}
.spinnerColor{
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
}
.spinnerSecondaryColor{
	border: .25em solid #FFFFFF;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
@media (max-width:768px){
	.seclending{
		padding: 20px 20px 24px;
		flex-direction: column;
	}
	.seclending .enable, .seclending .disable{
		width: 100%;
	}
}
@media (min-width:768px){
	.seclending{
		padding: 20px 32px 24px 24px;
		flex-direction: row;
	}
	.seclending .enable, .seclending .disable{
		width: auto;
	}
}

/* Security lending otp screen */
.secLendingOTPContainer{
	display: flex;
	justify-content: center;
}
.verifyCard{
	background: #FFFFFF;
	border: 1px solid #F7F7F8;
	box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
	margin-top: 68px;
	padding: 32px 24px;
	display: flex;
	flex-direction: column;
	gap: 0px;
	width: fit-content;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	color: #3c404a;
	margin-left: 0px;
	margin-right: 0px;
}
.subTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 4px;
}
.pinInput {
	margin-top: 28px;
	margin-left: 0;
}
.resendOtp {
	display: flex;
	align-items: baseline;
}
.goBackText {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #0061d3;
}
.otpError {
	display: block;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #fa274a;
	margin-bottom: 4px;
}
.timer {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-right: 10px;
}
.emailContainer{
	margin: 0px;
}
.emailContainer > span{
	margin: 0px 6px 0px 0px;
}
.email{
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	color: #3C404A;
}
@media (max-width: 480px){
	.verifyCard{
		box-shadow: none;
		padding: 32px 0px;
		border: none;
	}
  .mobileCancelButton{
    display:none;
  }
}

@media (min-width:280px) and (max-width: 1100px){
.mobileMarginBottom{
  margin-bottom:40px;
}
}
