.dialogClass {
	max-width: 100%;
	margin: 0px;
}

.background {
	background: #efefef;
	padding: 0px;
	height: 100vh;
}

.header {
	height: 70px;
	background: #ffffff;
	padding-left: 25%;
	padding-right: 25%;
	border-bottom: 2px solid rgba(22, 37, 66, 0.3);
	margin-right: 0px;
}
.investment {
	padding-top: 10px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 110%;
	color: #162542;
}
.brandImg {
	width: 30px;
	height: 30px;
	margin-right: 20px;
}

.brand {
	padding-top: 20px;
}
.save {
	padding-top: 25px;
	text-align: end;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-decoration-line: underline;
	color: #d81331;
}

.container {
	padding-left: 25%;
	padding-right: 25%;
	background: #efefef;
}

.detail {
	margin: 0px;
	margin-top: 50px;
	font-family: 'Inter';
	font-style: normal;
	font-size: 16px;
	line-height: 150%;
	color: #162542;
}

.note {
	margin: 0px;
	margin-top: 20px;
	font-family: 'Inter';
	font-style: normal;
	/* font-weight: bold; */
	font-size: 16px;
	line-height: 150%;
	color: #d81331;
}

.title {
	margin: 0px;
	margin-top: 50px;
	font-family: 'Noticia Text';
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 110%;
	color: #162542;
}

.borderBottom {
	padding-bottom: 50px;
	border-bottom: 2px solid rgba(22, 37, 66, 0.3);
}

.inputRow {
	margin-top: 30px;
}

.inputHeight {
	height: 62px;
}

.circle {
	padding-left: 3px;
}
.validation {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: rgba(22, 37, 66, 0.75);
	margin-left: 8px;
}

.marginCheckBox {
	margin: 0px;
	margin-top: 30px;
}

.completeLrsButton {
	width: 295px;
	height: 62px;
}
.calendaricon {
	position: absolute;
	right: 35px;
	top: 20px;
	cursor: pointer;
}

.calendar {
	position: absolute;
	z-index: 105;
	top: 45px;
	width: 368px;
	/* -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22);
	-moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22);
	box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22); */
}
