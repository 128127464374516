.footerContainer {
	/* position: fixed; */
	bottom: 0px;
	width: 100%;
	background: #ffffff;
	border-top: 1px solid #efeff1;
}

.footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 164px;
	padding-right: 164px;
	padding-top: 21px;
	padding-bottom: 40px;
}

.footerText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	text-align: center;
}

.footerRegular {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #767c8f;
}

.mobileFooter {
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 20px;
	padding-bottom: 80px;
}

.underline {
	text-decoration-line: underline;
}

@media (max-width: 1090px) {
	.footer {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 80px;
		padding-right: 80px;
		padding-top: 21px;
		padding-bottom: 40px;
	}
}

@media (min-width: 1440px) {
	.footer {
		margin-left: auto;
		margin-right: auto;
		max-width: 1440px;
	}
}

@media (max-width: 930px) {
	.footer {
		display: none;
	}
	.footerContainer {
		position: static;
	}
	.mobileFooter {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (min-width: 930px) {
	.mobileFooter {
		display: none;
	}
}

@media (max-width: 500px) {
	.mobileFooter {
		padding-left: 24px;
		padding-right: 18px;
	}
}

@media (min-width: 700px) and (max-width: 930px) {
	.mobileFooter {
		padding-left: 160px;
		padding-right: 160px;
	}
}
