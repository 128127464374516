@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 512px;
	height: 100px;
}

.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	padding-left: 190px;
	padding-right: 190px;
	margin-top: 60px;
	white-space: nowrap;
	margin-bottom: 30px;
}

.title {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.information {
	margin-top: 24px;
	padding: 24px;
	background: #eef4fb;
	border-radius: 8px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #0e2a78;
	width: 500px;
}
.details {
	margin-top: 32px;
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #767c8f;
}
.beneficiary {
	margin-top: 24px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.inputAnkit {
	margin-top: 4px;
	background: #efeff1;
	border-radius: 8px;
	height: 48px;
	width: 500px;
	border: none;
}
.inputAnkit::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
	padding-left: 10px;
}
.inputAnkit,
option {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	padding-left: 10px;
}
.input2 {
	margin-top: 4px;
	background: #efeff1;
	border-radius: 8px;
	border: none;
	height: 120px;
	width: 500px;
}
.input2::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
	padding-left: 10px;
}
