
.name {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 28px; */
    display: flex;
    /* align-items: center; */
    letter-spacing: 0.6px;
    color: #1e1f24;
    width: 100%;
    margin-bottom: 0px;
}
.subtext{
    font-family: 'geomanistregular';
    font-style: normal;
    font-size: 14px;
    color: #1e1f24;
    margin-top: 5px;
}
.section{
    margin-top:48px;
}
.textInput, .textInput:focus{
    background: #efeff1;
    border-radius: 8px !important;
    height: 52px !important;
}
.textBorder{
    border: 0 !important;
}
.acatInputSection{
    margin-top: 20px;
}
.dropdown{
    height: 52px !important;
    border-radius: 8px !important;
    border: none !important;
    background-color: rgb(239, 239, 241) !important;
}
.nomineeInput  {
    margin-bottom: 30px !important;
}

.searchInput {
	padding: 10.25px 13.25px 9.25px 40px !important;
	height: 40px !important;
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	background: #FFFFFF;
	/* border-radius: 6px !important; */
	width: 100%;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
	border-bottom: 1.5px solid #D3D4DA !important;
	padding-left:16px;
	font-family: 'geomanistBook';
}

.searchInput::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	vertical-align: middle;
}

.partialHeading{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 25px;
}

.searchIcon {
	visibility: visible;
	position: absolute;
	padding: 5px;
	left: 10px;
	z-index: 1;
	padding-top: 8px;
}
.searchCard :global(::-webkit-scrollbar-thumb) {
	background-color: #00000033;
	width: 4px;
	height: 50px;
	border-radius: 999px;
}
.cardBody {
	padding: 0px;
	/* padding: 2rem; */
	border-radius: 6px;
}
.searchCard {
    margin-top: 8px;
    width: 360px;
    height: 230px;
    border: 1px solid  #F7F7F8;
    box-sizing: border-box;
    box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
    border-radius: 6px;
    position: absolute;
    z-index: 1000;
}
.searchItem {
	padding: 12px 16px;
	height: 44px;
}

.searchItem:hover {
	background-color: #f7f7f8;
    cursor: pointer;
}


.stockTitle {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #3C404A;
	padding-right: 0px;
}

.stockName {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	padding-left: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.stockType {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #767C8F;
	text-align: end;
}

.addButtonPartial{
    /* height: 40px;
    width: 200px; */
    background-color: white;
    color: #005dd6;
    border: none;
    margin-top: 10px;
    /* border-radius: 8px; */
    font-size: 16px;
}

.stockReviewHeading{
    font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
    color: #3C404A;
}

.imageDiv {
	border: 0.928571px solid #efeff1;
	background-color: white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.letterSize{
    font-size: 25px;
    color: #1e1f24;
}
  
.stockRepresentation{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #1e1f24;
    margin-left: 10px;
}

.removeStock{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background-color: white;
    letter-spacing: 0.6px;
    padding: 8px 16px 8px 16px ;
    color: #FA274A;
    margin-top: 10px;
    cursor: pointer;
}

.searchList {
    height: 220px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}
.confirmTransferButton{
    color: white;
    border-radius: 8px;
    background-color: #005dd6;
    border: none;
    height: 40px;
    width: 250px;
    cursor: pointer;
}
.helperText{
    font-size: 10px;
    font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
    margin-bottom: 0px;
}
.cashDollar{
    color: black;
    position: absolute;
    top: 16px;
    left: 7px;
}
.Error{
    font-size: 12px;
    font-family: 'geomanistbook';
    color: red;
}
.reviewStocksBorder{
    border-bottom: 1px solid #767C8F;
    padding-bottom: 10px;
}
.noDataFound{
    font-size: 16px;
    font-family: 'geomanistbook';
    text-align: center;
    color: #1e1f24;
    margin-top: 100px;
}
.remittanceCard{
	border: 1px solid #D3D4DA;
	border-radius: 12px;
	margin-bottom: 16px;
	overflow: hidden;
}
.remittanceDetails{
	padding: 24px 20px;
	font-family: 'geomanistBook';
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 24px;
}
.remittanceDetails .main{
	font-size: 16px;
	color: #3C404A;
	margin-bottom: 4px;
}
.remittanceDetails .sub{
	font-size: 14px;
	color: #767C8F;
}
.spinnerColor{
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
}
.SuccessMessage{
    font-size: 12px;
    font-family: 'geomanistbook';
    color: green;
}
.errorText{
    padding-left: 8px;
    font-family: 'geomanistregular';
    color: #e61446;
    padding-top: 4px;
    font-size: 14px;
}
.containerSuccess{
   text-align: center;
}
.lottieCenter{
   display: flex;
   justify-content: center;
   margin-top: 50px;
   margin-bottom: 50px;
}
.suceessText{
    font-size: 20px;
    font-family: 'geomanistbook';
    color:#1e1f24
}
.marginCheckBox {
	margin-bottom: 20px;
}