@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';
.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}

.toastMessage{
  padding: 14px;
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: white;
display: flex;
}

.toast{
  background-color:#9096A8 ;
}


.emptyPageParent {
align-items: center;
  position: relative;
  top: -60px;
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.emptyPageImage {
  display: flex;
  align-self: center;
  width: 264px;
  height: 193.53px;
}

.emptyPageHeadText {
  display: flex;
  align-self: center;
  font-family: 'geomanistmedium';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.6000000238418579px;
  text-align: center;
  color: #000000;
}

.emptyPageSubText {
  display: flex;
  align-self: center;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #505562;
}


.verifyRow {
  margin-top: 200px;
  /* display: flex;
  align-items: center;
  height: calc(100vh - 40vh); */
	justify-content: center;
}
.verifyInput {
	width: 450px;
	height: 62px;
}

.otpButton {
	margin-left: 30px;
	width: 150px;
	height: 62px;
}
.SigninButton{
  margin-top: 20px;
  width: 150px;
	height: 62px;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40vh);
}

.otpSuccess {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #d81331;
  justify-content: center;
  margin-top: 15px;
}
.resendOTP {
	margin-top: 30px;
	justify-content: center;
}
.timer {
	font-family: 'geomanistregular';
	margin-left: 5px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.25);
	margin-right: 10px;
}
.goBackText {
	cursor: pointer;
	font-family: 'geomanistregular';
	margin-left: 5px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	/* padding-bottom: 4px; */
	border-bottom: 1px solid #0061d3;
	color: #0061d3;
}
.crossImageStyling{
	position: absolute;
	right: 28px;
	top: 28px;
	cursor: pointer
}

@media only screen and (max-width: 768px) {
  .verifyInput {
		width: 360px;
		height: 48px;
	}
  .otpButton {
		width: 100px;
		height: 62px;
		margin-left: 20px;
	}
  .SigninButton{
    margin-top: 20px;
    width: 100px;
		height: 62px;
  }
  .goBackText {
		font-size: 12px;
		line-height: 140%;
	}

}

@media only screen and (max-width: 480px) {
  .verifyInput {
		width: 200px;
		height: 48px;
	}
  .timer {
    font-size: 10px;
  }
  .otpButton {
		margin-left: 10px;
		width: 100px;
		height: 48px;
	}
  .SigninButton{
    margin-top: 20px;
    width: 100px;
		height: 48px;
  }
  .verifyRow{
    margin-top: 100px;
  }
  .goBackText {
		font-size: 10px;
		line-height: 140%;
	}
}
