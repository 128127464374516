@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.cardDetail {
	border: none;
}

.title {
	margin: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	white-space: nowrap;
}

.amount {
	margin: 0px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	white-space: nowrap;
}

.rowTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	white-space: nowrap;
}

.rowValue {
	/* font-family: 'geomanistregular'; */
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	/* text-align: right; */
	letter-spacing: 0.6px;
	color: #1e1f24;
	white-space: nowrap;
font-family: 'geomanistBook';
/* text-transform: capitalize; */

}





@media (max-width: 770px) {
  .shareAlignment{
    text-align: end;
  }
  .shareRow{
    margin-top: 16px;
  }
  .title{
    font-size: 12px;
    line-height: 16px;
    margin-top: 16px;
  }
  .amount{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
letter-spacing: 0.6px;
color: #1E1F24;
margin-top: 8px;
  }
  .own{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    padding-top: 8px;
    }
    .rowValue {
      text-align: right;
      border-bottom: 1px solid #EFEFF1;
      padding-bottom: 16px;
    }

.rowTitle {
  border-bottom: 1px solid #EFEFF1;
  padding-bottom: 16px;
}
}

