.customSelectBox{
  /* max-height: 250px;
  overflow-y: scroll; */
  width: 20px;
    outline: unset;
    border: unset;
    /* font-size: 20px; */
    font-family: 'geomanistRegular';
  }

.customSelectBox:focus, .customSelectBox:focus-visible{
  outline: unset;
  border: unset;

}

.selectDropDown{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
}

.poweredText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767C8F;
	margin-right:8px
}

.flagImg{
  border: 1px solid lightgray;
}
.arrowRight{
  display: inline-flex;
  cursor: pointer;
}
.arrowRight::after {
    content: '\25bc';
    padding-left: 0.5em;
  }

.LoginText {
	font-family: 'geomanistmedium';
	margin-top: 20px;
	font-style: normal;
	/* font-weight: 500; */
	font-size: 32px;
	line-height: 110%;
	text-align: center;
	color: #000000;
	justify-content: center;
}

.stockalImage {
	height: 50px;
}
.toggle {
	cursor: pointer;
	position: absolute;
  top: 37px;
  right: 19px;
}
.noteText {
	margin-top: 10px;
	padding-left: 190px;
	padding-right: 190px;
	justify-content: center;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}

.showEmail {
	font-family: 'geomanistbook';
}

.buttonRow {
	justify-content: center;
	margin-top: 30px;
}
.buttonCol {
	text-align: -webkit-right;
	text-align: right;
}
.googleButton {
	width: 300px;
	height: 65px;
	padding-left: 40px;
	background: #4285f4;
	font-family: Noticia Text;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 140%;
	display: flex;
	align-items: center;
	color: #ffffff;
}
.buttonImage {
	margin-right: 16px;
}
.line {
	margin-top: 40px;
	width: 225px;
	border-top: 1px solid rgba(22, 37, 66, 0.3);
}
.noColWith {
	padding-left: 0px;
	padding-right: 0px;
	max-width: 0px;
}

.lineText {
	margin-top: 23px;
	padding-left: 28px;
	padding-right: 28px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	display: flex;
	align-items: center;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}
.inputRow {
	width: 660px;
	margin: auto;
	justify-content: center;
	margin-top: 30px;
}
.emailInput {
	/* width: 630px; */
	height: 62px;
}
.nextButton {
	width: 86px;
	height: 62px;
}

.inputs {
  width: 100%;
	margin-top: 20px;
  margin-left:0px;
}
.right {
	text-align: -webkit-right;
  text-align: end;
}

.passwordLeft {
	/* left: 10px; */
}

.signUpEmailInput {
	width: 100%;
	height: 62px;
}
.buttonSignUP {
	width: 189px;
	height: 62px;
	margin-right: 0px;
}

.passwordValidation {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: rgba(22, 37, 66, 0.75);
	margin-left: 8px;
}
.passwordCircle {
	padding-left: 3px;
}
.rememberRow {
	margin-top: 75px;
	justify-content: center;
}
.resendOTP {
	display: flex;
	align-items: baseline;
	margin-top: 16px;
  margin-left:0px;
}
.link:hover {
	text-decoration: none;
	color: #0061d3;
}
.remember {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}
.goBackText {
	cursor: pointer;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #0061d3;
}
.timer {
	font-family: 'geomanistregular';
	margin-left: 5px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.25);
	margin-right: 10px;
}
.verifyNote {
	display: block;
	padding-left: 190px;
	padding-right: 190px;
	margin-top: 15px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	color: #162542;
}
.verifyInput {
	width: 450px;
	height: 62px;
}
.verifyRow {
	margin-top: 30px;
	justify-content: center;
}
.otpButton {
	margin-left: 30px;
	width: 150px;
	height: 62px;
}

.phoneerror {
	margin-top: 10px;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #d81331;
}

.container {
	display: block;
	margin: 0px;
	/* width: 100%; */
	position: relative;
}
.container :global(.PhoneInput) {
	align-items: unset;
}

.container :global(.PhoneInputCountry) {
	margin: 0px;
	height: 62px;
	padding-right: 13px;
	padding-left: 13px;
	align-self: auto;
	border: 2px solid rgba(22, 37, 66, 0.25);
	border-right: 0px;
}

.container input {
	outline: 0px;
	font-family: 'Inter';
	padding: 20px;
	background: #ffffff;
	border: 2px solid rgba(22, 37, 66, 0.25);
	box-sizing: border-box;
	border-radius: 0px;
}

.container input:active,
.container input:hover,
.container input:focus {
	border: 2px solid #0061d3;
	box-shadow: none;
}
.container label {
	visibility: hidden;
	left: 8px;
	position: absolute;
	top: 0;
	opacity: 0;
	transition: 250ms;
}

.container input:placeholder-shown {
	/* font-family: 'Inter'; */
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::placeholder{
  font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::-webkit-input-placeholder {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::-moz-placeholder {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.phoneLabel {
	visibility: visible !important;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	/* color: #ffffff; */
	height: 25px;
	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	/* background: #0061d3; */
	color: rgba(22, 37, 66, 0.75);
	background-color: #c5c8d0;
	border-radius: 12px;
	transform: translate(0, -50%);
	opacity: 1 !important;
}

.container input:not(:placeholder-shown) + label {
	visibility: visible;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	/* color: #ffffff; */
	height: 25px;
	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	/* background: #0061d3; */
	color: rgba(22, 37, 66, 0.75);
	background-color: #c5c8d0;
	border-radius: 12px;
	transform: translate(0, -50%);
	opacity: 1;
}

.otpSuccess {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #d81331;
}

.firstNamePadding {
	padding-right: 10px;
}
.lastNamePadding {
	padding-left: 10px;
}

@media only screen and (max-width: 768px) {
	.stockalImage {
		height: 40px;
	}
	.container :global(.PhoneInputCountry) {
		/* height: 48px; */
	}
	.LoginText {
		margin-top: 10px;
		font-size: 26px;
	}
	.noteText {
		/* padding-left: 119px;
		padding-right: 143px; */
		font-size: 14px;
	}
	.googleButton {
		padding-left: 16px;
		width: 225px;
		height: 50px;
		font-size: 14px;
		line-height: 140%;
	}
	.line {
		margin-top: 40px;
		width: 170px;
	}
	.lineText {
		padding-left: 15px;
		padding-right: 15px;
		font-size: 12px;
		line-height: 150%;
	}
	.emailInput {
		height: 48px;
	}
	.nextButton {
		/* width: 70px; */
		height: 62px;
		margin-right: 4px;
	}
	.inputRow {
		width: 514px;
	}
	.signUpEmailInput {
		/* width: 480px; */
		/* height: 48px; */
	}
	.passwordLeft {
		left: 0px;
	}
	.passwordValidation {
		font-size: 10px;
	}
	.rememberRow {
		margin-top: 50px;
	}
	.remember {
		font-size: 12px;
		line-height: 140%;
	}
	/* .goBackText {
		font-size: 12px;
		line-height: 140%;
	} */
	.verifyNote {
		margin-top: 10px;
		font-size: 14px;
		padding-left: 120px;
		padding-right: 120px;
	}
	.verifyInput {
		width: 360px;
		height: 48px;
	}
	.otpButton {
		width: 100px;
		height: 62px;
		margin-left: 20px;
	}
	.firstNamePadding {
		padding-right: 15px;
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 480px) {
	.stockalImage {
		height: 30px;
	}
	.mobileInputs {
		margin-top: 0px !important;
	}
	.container :global(.PhoneInputCountry) {
		height: 48px;
	}
	.lastNamePadding {
		padding-left: 10px;
	}
	/* .inputs{
		margin-top: 0px;
	} */
  .resendOTP {
    margin-bottom: 20px;
  }
	.LoginText {
		margin-top: 10px;
		font-size: 22px;
	}
	.noteText {
		padding-left: 45px;
		padding-right: 45px;
		font-size: 12px;
	}
	.buttonCol {
		/* text-align: center; */
		text-align: -webkit-center;
		margin-bottom: 15px;
	}
	.faceBookCol {
		/* text-align: center; */
		text-align: -webkit-center;
	}
	.googleButton {
		width: 310px;
		height: 48px;
		font-size: 12px;
		line-height: 150%;
		justify-content: center;
	}
  .timer {
    font-size: 10px;
  }
	.lineText {
		margin-top: 10px;
		font-size: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.inputRow {
		width: 345px;
		flex-wrap: nowrap;
		padding-right: 13px;
	}

	.line {
		margin-top: 27px;
		width: 100px;
	}
	.emailInput {
		height: 48px;
	}
	.mobilePadding {
		padding-right: 0px;
	}
	.signUpEmailInput {
		width: 100%;
		height: 48px;
	}
	.passwordLeft {
		text-align: -webkit-center;
		margin-bottom: 30px;
		left: 0px;
	}
	.mobilePassword {
		text-align: -webkit-center;
		/* margin-bottom: 30px; */
		left: 0px;
	}
	.buttonSignUP {
		width: 126px;
		height: 48px;
		font-size: 12px;
		margin-right: 0px;
	}
	.rememberRow {
		margin-top: 30px;
    margin-bottom: 20px;
	}
	.remember {
		font-size: 10px;
		line-height: 140%;
	}
	/* .goBackText {
		font-size: 10px;
		line-height: 140%;
	} */
	.verifyNote {
		margin-top: 5px;
		font-size: 12px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.verifyInput {
		width: 200px;
		height: 48px;
	}
	.otpButton {
		margin-left: 10px;
		width: 100px;
		height: 48px;
	}
	.nextButton {
		height: 48px;
		width: 68px;
	}
}

@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) {
	.passwordLeft {
		/* left:10px */
	}
	.mobilePassword {
		/* right:10px */
	}
	.inputRow {
		width: 100%;
	}
	.noteText {
		padding-left: 100px;
		padding-right: 100px;
	}
	.verifyNote {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media only screen and (min-device-width: 300px) and (max-device-width: 400px) {
	.passwordLeft {
		/* padding-left: 0px; */
	}
	.mobilePassword {
		/* padding-left: 0px; */
	}
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1440px) {
	.inputRow {
		width: 70%;
	}
	.passwordLeft {
		padding-left: 6px;
		left: 0px;
	}
	.mobilePassword {
		padding-right: 6px;
		/* right: -17px; */
	}
	.buttonSignUP {
		margin-right: -10px;
	}
}

@media (min-height: 200px) and (max-height: 500px) {
  .rememberRow{
    margin-bottom: 30px;
  }
  .right {
    margin-bottom: 30px;
  }
}

@media (min-height: 200px) and (max-height: 700px) {
  .rememberRow{
    margin-top: 25px;
  }
}

@media (min-width: 481px) and (max-width: 768px){
	.passwordLeft{
		margin-bottom: 25px;
	}
	.removingextra{
		margin-bottom: 0px;
	}
}

@media (min-width: 767px) and (max-width: 910px) {
   .rememberRow{
     margin-top: 30px;
   }
}



/* new signup css */


/* Email component */
.title{
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 36px;
color: #3C404A;
margin-left:0px;
margin-bottom: 4px;
}

.subTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
margin-left:0px;
}
.inputs :global(.form-focus){
  width:100%;
}

.cardDiv{
  margin: auto;
  display: table;
  margin-top: 80px;
  min-height: calc(100vh - 285px);
}

.cardSignUp{
width: 412px;
height: auto;
background: #FFFFFF;
border: 1px solid #F7F7F8;
box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
border-radius: 16px;
}
.cardBodySignup{
  padding:32px 24px 32px 24px
}

.emailStyle{
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #3C404A;
margin-left:0px;
}
.resendText{
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
margin-right:4px;
}
.otpInput{
  margin-top:24px
}
.passwordInput :global(.form-control.is-invalid){
   background:none
}

.buttonMargin{
  margin: 32px 0px 0px 0px;
}
.assignUser{
  margin: 20px 0 0 0;
  padding: 16px;
  background: #F7F7F8;
  border-radius: 8px;
}
.assignUserText{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #3C404A;
}
.passwordValidationText{
  margin-top:6px;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
  padding-left:4px;
}
.countryLabel{
  padding-left:12px;
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
}
.label {
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3C404A;
  margin-bottom:5px;
  padding-left:4px;
}
.iconEdit{
  width:20px;
  padding-top:8px;
  cursor:pointer;
}

@media only screen and (max-width: 480px) {
  .cardSignUp{
    width:100%;
    border:0px;
    box-shadow:none;
  }
  .cardBodySignup{
    padding:24px;
  }
  .cardDiv{
    margin-top:12px;
    display:block;
  }
}



/* verify identity css */
.verifyCard{
margin: 28px 0 0 0;
padding: 20px;
background: #FFFFFF;
border: 1.5px solid #D3D4DA;
border-radius: 6px;
display:block;
}
.verifyTitle{
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #3C404A;
}
.infoLogoImg{
  padding-left:6px;
  cursor:pointer;
}

.verifySubTitle{
font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
}

.verifyCheckBox{
  padding-top:8px
}

.checkMark {
	padding-top: 8px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #162542;
}

.checkBoxInput:disabled ~ .checkMark::before {
	background-color: #e9ecef;
	border: none;
}

.checkBox .checkBoxInput:checked:focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
	/* background-color: #6097a1; */
	transition: none;
}

.checkBox .checkBoxInput:not(:checked):focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
}

.checkMark::before {
  background: #F7F7F8;
  border: 1.5px solid #D3D4DA;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.checkBox .checkBoxInput:checked ~ .checkMark::after {
	background-image: url('../../public/images/newOnboarding/check.svg');
}

.checkMark::after {
  width: 1.7rem;
  height: 1.5rem;
  top: 1px;
  left: -27px;
}

.checkBox .checkBoxInput:checked ~ .checkMark::before {
  background: #F7F7F8;
  border: 1.5px solid #0061D3;
}

.errorState{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FA274A;
  }

.phoneContainer{
  width:80%;
  border:0px !important;
}
.phoneButton{
  width:20% !important;
  border:0px !important;
}
.phoneInputBorder{
   border-top:0px !important;
   border-bottom:0px !important;
   border-radius:0px !important;
   border-right:0px !important;
   border-left:1.5px solid #D3D4DA;
   padding: 10px 12px 10px 12px !important;
}
.phoneContainer :global(.form-control:focus){
  border-top:0px !important;
  border-bottom:0px !important;
  border-radius:0px !important;
  border-right:0px !important;
  border-left:1.5px solid #D3D4DA;
}
.phoneNumberDropdown{
  position:relative;
  display:flex;
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
}
.flagImgCode{
  height: 18px;
  vertical-align: sub;
}

/* username css */

.userNameCard{
  margin: 24px 0 0 0;
  background: #FFFFFF;
  border: 1px solid #EFEFF1;
  border-radius: 8px;
  max-height: 252px;
  overflow-y: scroll;
}

.userIcon{
  width:18px;
  vertical-align: baseline;
}

.userNameTitle{
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #3C404A;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.loginLink{
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: right;
color: #0061D3;
cursor:pointer;
}
.userSigleRow{
  margin:0px;
  width:100%;
  padding:12px 0px 12px 0;
  border-bottom:1px solid #EFEFF1;
  height:50px;
}

.userNamebutton{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    height: 40px;
    box-shadow: none;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1.5px solid #0061D3;
    border-radius: 8px;
    color: #0061D3;
}
.phoneNumberLabel{
  position:absolute;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  left: 34%;
  top: 22%;
}

.phoneNumberList{
  width:100% !important;
  top:40px;
}
.passwordInvalid :global(.form-control.is-invalid) {
  background-image: none !important;
  background-position: right 12px center;
  border: 1.5px solid #FA274A;
  background-repeat: no-repeat;
}

/* ----------------------------------------- */
.tooltipLabel {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #3c404a;
}

.tooltipText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
}

.tooltipCopy :global(.tooltip-inner) {
	background-color: #fff;
	color: #0061d3;
	/* box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03); */
	box-shadow: 0 0 15px -7px rgb(0 0 0 / 65%);
	border-radius: 8px;
	font-size: 12px;
}
.tooltipCopyFixed {
	position: fixed;
	bottom: 110px;
	left: 0;
	width: 100vw;
	text-align: center;
	z-index: 1;
}
.tooltipCopyFixed span {
	padding: 8px 12px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	background-color: #fff;
	color: #0061d3;
	border-radius: 8px;
	font-size: 12px;
}
.tooltipCopy :global(.arrow::before) {
	border-bottom-color: #fff;
}

/* .tooltipCopy  :global(.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before ){
	border-bottom-color: white !important;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  } */


@media (max-width:768px) {
	.userSigleRow{
		height:70px;
	  }

	.userNameCard{
		max-height: 352px;
	}
}

.userNameCard::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.userNameCard::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.cardDivWithBanner{
  margin: auto;
  display: table;
  margin-top: 135px;
  min-height: calc(100vh - 335px);
}
