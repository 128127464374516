.input-range {
	width: 224px;
	height: 12px;
}

.input-range__label--min {
	display: none;
}

.input-range__label--max {
	display: none;
}

.input-range__label--value {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
	top: 15px;
}

.input-range__track {
	height: 12px;
}

.input-range__slider-container {
	top: 7px;
}

.input-range__track--active {
	background: #005dd6;
}

.input-range__slider {
	width: 24px;
	height: 24px;
	background: #ffffff;
	border: 1px solid #94bcf0;
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgba(2, 2, 65, 0.15);
}

.input-range__label-container{
  display: none;
}

