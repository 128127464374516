@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.heading{
  font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 0px;
	margin-bottom: 5px;
	text-transform: capitalize;
}

.semiHeading{
  font-family: 'geomanistRegular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #9096A8;
  margin-left: 0px;
  margin-bottom: 15px;
}

@media (min-width:576px) and  (max-width:992px) {
  .semiHeading {
    margin: 15px 0px 18px 0px;
  }
}
.originalPos {
  position: relative;
  /* margin-left: 0px; */
  margin-top: 16px;
  transition: 0s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top:48px;
}

.shiftPos{
  margin-top:48px;
  margin-left: 0px;
}

.webDesignSeeAllStack{
  display: flex;
}

.mobileStackSeeAllCard{
  display: none;
}

.dropdownHeading{
  font-family: 'geomanistBook';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
  margin-top: 10px;
  padding-right:0px;
  text-align:right;
  padding-left: 0px;
}

.fixedBottom{
  display: none;
}

.mobileSort{
  display: none;
}

.noOrdersFound{
	height:60vh;
  text-align: center;
  padding-top: 60px;
  display: block;
}

.noOrdersText{
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
  letter-spacing: 0.6px;
  color: #000000;
  margin-bottom: 8px;
}

.noOrdersFoundMobile{
  display: none;
 }

@media (min-width: 320px) and (max-width: 577px) {
  .displayBlockSeeAllCard{
    display: block;
  }
  .mobileStackSeeAllCard{
    display: inline-flex;
  }
  .webDesignSeeAllStack{
    display: none;
  }
  .trendingCard {
    border: 0px;
    /* border-bottom: 1px solid #eef2fb; */
    border-radius: 8px;
  }

  .cardBody {
    padding: 20px;
  }

  .trendingCard:hover {
    border: 1px solid #efeff1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
    border-radius: 16px;
  }

  .stockImage {
    width: 48px;
    height: 48px;
    border-radius: 3px;
  }

  .stockTitle {
    font-family: 'geomanistmedium';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.56px;
    color: #1e1f24;
    text-transform: uppercase;
  }

  .stockName {
    font-family: 'geomanistregular';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #626777;
    text-align: left;
    height: 40px;
    margin-bottom: 16px;
    /* padding-top: 5px; */
  }

  .cagrValue {
    font-family: 'geomanistbook';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #0DC014;
    text-align: left;
  }

  .stockIncrement {
    font-family: 'geomanistregular';
    float: right;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #626777;
  }
  .creator {
    font-family: 'geomanistregular';
    padding-top: 4px;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.56px;
    color: #626777;
  }
  .cagr {
    font-family: 'geomanistregular';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #626777;
    text-align: left;
  }
  .minHead {
    font-family: 'geomanistregular';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #626777;
    text-align: left;
  }

  .mininv {
    padding-left: 15px;
    padding-right: 7px;
  }
  
  .minValue {
    font-family: 'geomanistbook';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #1e1f24;
    text-align: left;
  }

  .navLink:hover {
    color: inherit;
    text-decoration: none;
  }

  .borderBottom{
    border: 1px solid #EFEFF1;
    width: 90%;
  }
  .DropdownWeb{
    display: none;
  }
  .fixedBottom{
    display: block;
    position: fixed;
    z-index: 100;
    margin-left: 0px;
    margin-right: 0px;
    height: 48px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px -15px 10px -15px #E5E5E5;
    display: inline-flex;
    width: 100%;
  }
  .sortTitle{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #3C404A;
  }
  .sortColumn{
    text-align: center;
    padding-top: 11px;
  }
  .responsivePinContainerBackground{
		display: block;
		position: fixed;
		top:0px;
		height:80%;
		width: 100%;
		background: black;
		left: 0px;
		opacity: 0.5;
    z-index: 100;
	}
  .ResponsiveChangePinOtpContainer{
		display: block;
		padding:20px;
    padding-bottom: 40px;
		position: fixed;
		width: 100%;
		height: 52vh !important;
		background: white;
		/* top: 0px; */
		left: 0px;
		position: fixed;
		bottom: 0;
		width: 100%;
		border-radius: 40px;
    z-index: 100;
	}
  .ResponsiveFilterContainer{
    display: block;
		padding:20px;
    padding-bottom: 40px;
		position: fixed;
		width: 100%;
		height: 40vh !important;
		background: white;
		/* top: 0px; */
		left: 0px;
		position: fixed;
		bottom: 0;
		width: 100%;
		border-radius: 40px;
    z-index: 100;
  }
  .mobileHead{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 32px;
    margin-top:12px;
    /* identical to box height, or 140% */

    letter-spacing: 0.6px;

    /* Neutral/900 */

    color: #1E1F24;
  }
  .radioButtonMobile{
    display: block;
  }
  .radioButtonText{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    text-transform: lowercase;
    color: #1E1F24;
  }
  .mobileSort{
    display: flex;
  }

  /* radio button */
  .radioItem {
    display: block;
    position: relative;
    padding: 0px;
    margin: 10px 0 0;
  }

  .radioItem input[type='radio'] {
    display: none;
  }

  .radioItem label {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    font-weight: normal;
    font-family: 'geomanistbook';
    color: #1E1F24;
    margin-bottom: 15px;
  }

  .radioItem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 8px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #DBDBDB;
    background-color: transparent;
  }

  .radioItem input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 4px;
    content: " ";
    display: block;
    background: black;
  }
  .filterbox{
    display: inline-block;
    border: 1px solid #AAACB3;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #505562;
    font-family: 'geomanistregular';
    text-transform: lowercase;
  }
  .selectedFilterBox{
    border: 1px solid #1E1F24;
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: black;
    font-family: 'geomanistregular';
    text-transform: lowercase;
  }
  .CrossImage{
    position: absolute;
    right: 30px;
    width: 6%;
  }
  .noOrdersFoundMobile{
    height:60vh;
    text-align: center;
    padding-top: 60px;
    display: block;
    /* position: absolute; */
    right:0;
    left:0;
  }
}


@media (min-width: 1000px) and (max-width: 1540px) {
	.trendingSlider {
		height: 250px;
	}
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}
