@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.modalPadding {
	padding: 48px !important;
}

.financialTitle {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	flex: none;
	order: 0;
	align-self: center;
	flex-grow: 0;
	margin: 4px 0px;
	cursor: pointer;
  margin-right: 10px;
  /* checked */
}

.modalTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.modalsubTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
	margin-left: 8px;
}

.timeTitleActive {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	text-align: end;
}

.timeTitle {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #9096a8;
	text-align: end;
}

.navItem {
	/* padding: 0px; */
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #9096a8;
	cursor: pointer;
}

.navItemActive {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	border-bottom: 2px solid #005dd6;
	padding-bottom: 7px;
	cursor: pointer;
}
.markerRemove{
  list-style-type:none;
  padding-left:0px;
  margin-bottom: 0px;
}




@media (max-width: 768px) {
	.financialTitle {
  font-family: 'geomanistbook';
  font-size: 14px;
  line-height: 20px;
  }
  .finDiv{
    margin-top: 8px;
  }
}
