.minSliderValue{
  color: #005DD6;
}

.maxSliderValue{
  color: #005DD6;;
  float: right;
}

.widthSlider{
  margin-bottom: 10px;
}

