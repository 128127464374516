.directHeader {
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFF1;
}

.directBody, .uploadContainer {
    font-family: 'geomanistBook';
    margin-bottom: 96px;
}

.directBody .OTPContainer {
    margin-top: 24px;
    background: #F7F7F8;
    border-radius: 8px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
}

.OTPContainer .otpNote {
    color: #767C8F;
    font-size: 16px;
    margin-bottom: 16px;
}

.OTPContainer .otpInput {
    border: 1.5px solid #D3D4DA;
    background: #FFFFFF;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 10px 12px;
}
.OTPContainer .errorInput:hover, .OTPContainer .errorInput:focus-within{
    border: 1.5px solid #FA274A;
}
.OTPContainer .otpInput input {
    border: none;
    width: 100%;
}

.OTPContainer .otpInput input::placeholder {
    font-family: 'geomanistRegular';
    color: #767C8F;
    font-size: 14px;
}

.OTPContainer .otpCTA {
    font-size: 14px;
    color: #767C8F;
    margin-top: 8px;
}

.textLink {
    color: #0061D3;
    cursor: pointer;
}

.reviewContainer .reviewHeader {
    font-size: 14px;
    color: #3C404A;
    padding: 24px 0px 16px 0px;
    border-bottom: 1px solid #EFEFF1;
}

.reviewBody .reviewSection {
    padding: 16px 4px;
    border-bottom: 1px solid #EFEFF1;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 8px;
}

.reviewSection .row .label {
    color: #767C8F;
}

.reviewSection .row .value {
    color: #3C404A;
}

.reviewSection .row .value .infoIcon {
    width: 18px;
    height: 18px;
    margin-left: 4px;
    cursor: pointer;
}

.reviewSection .option {
    display: flex;
    gap: 16px;
}

.reviewSection .option span {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
}

.reviewSection .option span img {
    width: 20px;
    height: 20px;
}

.tc {
    padding: 16px 4px 0px;
    color: #3C404A;
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
    font-size: 14px;
}

.tc img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 4px;
}

.tooltip {
    background: #FFFFFF;
    font-family: 'geomanistBook';
    box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02) !important;
    opacity: 1 !important;
    border-radius: 8px !important;
}
.tipHeader{
    font-size: 16px;
    color: #3C404A;
}
.tipDesc{
    font-size: 14px;
    color: #767C8F;
}
.tooltip :global(.arrow::before){
    border-top-color: #FFFFFF;
}
.tooltip :global(.tooltip-inner){
    background-color:  #FFFFFF;
    border-radius: 8px;
}
.footerContainer {
    position: fixed;
    display: flex;
    bottom: 0;
    border-top: 1px solid #EFEFF1;
    padding: 24px;
    margin-left: -24px;
    gap: 16px;
    background: #FFFFFF;
    width: 100%;
}

.footerContainer .continueBtn {
    font-family: "geomanistBook";
    background: #0061D3;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    outline-color: #0061D3;
    display: flex;
    align-items: center;
}

.footerContainer .continueBtn:focus,
.footerContainer .continueBtn:hover {
    background: #004EA9;
}

.footerContainer .tertiaryBtn {
    font-family: "geomanistBook";
    background: none;
    border: none;
    color: #0061D3;
    outline: none;
    padding: 0px;
}

.footerContainer .tertiaryBtn:focus,
.footerContainer .tertiaryBtn:hover {
    outline: none;
    color: #004EA9;
}

.spinnerColor {
    border: .25em solid #FFFFFF;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.genericLoading{
    height: calc(100vh - 144px);
    display: flex;
    justify-content: center;
}

@media (max-width:600px) {
    .directBody, .uploadContainer {
        margin-bottom: 126px;
    }
}

/* Upload */
.uploadNote {
    margin-bottom: 16px;
    font-family: 'geomanistBook';
    font-size: 14px;
}

.uploadNote .noteHeader {
    color: #3C404A;
    padding-bottom: 8px;
}

.uploadNote .noteBody {
    color: #767C8F;
    padding-bottom: 16px;
}

.fileUpload {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
}
.errorBook{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #FA274A;
}
.errorRegular{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #FA274A;
}

/* Error */
.errorContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'geomanistBook';
    font-size: 16px;
    color: #3C404A;
    min-height: calc( 100vh - 86px);
}
.errorContainer>img{
    margin-bottom: 24px;
}
.errorContainer .label{
    color: #767C8F;
    font-size: 14px;
    margin-top: 12px;
}
.errorContainer .options{
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
.transferOption{
    font-family: "geomanistBook";
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #D3D4DA;
    border-radius: 8px;
    background: transparent;
    width: 100%;
    align-items: center;
}
.transferOption:focus{
    outline-color: #3C404A;
}
.transferOption .primary{
    font-size: 14px;
    color: #3C404A;
}
.transferOption .secondary{
    font-family: "geomanistRegular";
    font-size: 12px;
    color: #767C8F;
}