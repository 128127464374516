@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.inputLabel {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.input {
	/* width: 288px; */
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	font-family: 'geomanistregular';
	border: 0px;
	height: 48px;
	background: #EFEFF1;
	border-radius: 8px !important;
	border:none !important;
}

/* .input:active,
.input:hover {
	background: #ffffff;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
} */

.input:focus {
	background: #EFEFF1 !important;
	box-sizing: border-box;
	/* box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1); */
}

input::placeholder {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	/* color: #767C8F; */
}
