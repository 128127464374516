@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

@media (max-width:380px) {
    .modalContainer {
        width:100%;
        top:0px;
        left:0px;
    }
}

@media (min-width:380px) {
    .modalContainer {
        width:380px;
    }
}

.modalContainer {
    font-family: 'geomanistBook';
    font-weight: 400;
    height: auto;
    border-radius: 16px;
    overflow: hidden;
    background-color: white;
}

.transactionStatus {
    display: flex;
    flex-direction: column;
    gap:2px;
    align-items: flex-start;
}

.freezeCard {
    display: flex;
    flex-direction: row;
    gap:9.5px;
    align-items: center;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0DC014;
    padding: 24px 24px 20px 24px;
}

.modalContainer img {
    cursor: pointer;
}

.modalContainer p {
    margin: 0px;
}

.modalContainer button:focus {
    outline:none;
}

.divider {
    margin: 0px;
    height: 2px;
    border-top: 2px solid #F7F7F8;
    width: 100%;
}

.modalName {
    font-size: 20px;
    line-height: 28px;
}

.status {
    font-size: 16px;
    line-height: 24px;
}

.modalTextSmall {
    font-size: 12px;
    line-height: 16px;
}

.modalText {
    font-size: 14px;
    line-height: 20px;
    word-break: break-all;
}

.amount {
    font-size: 36px;
    line-height: 44px;
    padding: 12px 0px 0px 0px;
}

.textWhite {
    color: white;
}

.textBlack {
    color:#3C404A
}

.textGrey {
    color:#767C8F
}

.textGreen {
    color:#0DC014
}

.textRed {
    color:#FA274A
}

.textBlue {
    color: #0061D3;
}

.textCenter {
    text-align: center;
}

.transactionInfo, .accountInfo {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.transactionPurpose {
    padding: 8px 12px;
    background-color: #F7F7F8;
    border-radius: 8px;
    min-width: 170px;
    min-height: 36px;
    margin-top: 16px;
}

.transactionDetails, .accountDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:20px;
    padding: 0px 24px 24px 24px;
}

.modalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalRowInfo {
    gap:9px;
    margin-right:3px
}

.modalButtonContainer a {
    cursor: pointer;
    text-decoration: none;
}

.modalButton {
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding-top: 10.75px;
    padding-bottom: 10.75px;
}

.inputBox {
    border: 1px solid #D3D4DA;
    border-radius: 6px;
    background-color: white;
    padding: 10.75px 12px;
    height: 40px;
}

.inputContainer {
    padding: 24px;
}

.pinContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:8px;
}

.otpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 20px;
    margin: 0px 24px 24px 24px;
    background-color: #F7F7F8;
    border-radius: 8px;
}

@media (max-width:345px) {
    .modalButton {
        min-width: 147px;
        gap:5px;
    }

    .modalButton p {
        font-size: 13px;
    }

    .modalButtonContainer {
        gap:8px;
        padding: 0px 0px 24px 0px;
        justify-content: center;
    }

    .inputBox {
        width: 146px;
    }

    .inputContainer {
        gap:10px;
        justify-content: center;
    }
}

@media (min-width:345px) {
    .modalButton {
        min-width: 158px;
    }

    .modalButtonContainer {
        gap:16px;
        padding: 0px 24px 24px 24px;
        justify-content: center;
    }

    .inputBox {
        width: 158px;
    }

    .inputContainer {
        gap:16px;
        justify-content: center;
    }
}

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50px;
}

.modalButtonBlue {
    color:#0061D3;
    border:2px solid #0061D3;
}

.modalButtonRed {
    color:#FA274A;
    border: 2px solid #FA274A;
}

.modalButtonGrey {
    color:#767C8F;
    border: 2px solid #767C8F;
}

.modalButtonSolidRed {
    background-color:#FA274A ;
}

.modalButtonSolidBlue {
    background-color: #0061D3;
}

.modalButtonContainer :global(.btn-danger:disabled) {
    background-color:#FA274A;
    border-color: #FA274A;
}

.modalButtonContainer :global(.btn-primary:disabled) {
    background-color:#0061D3;
    border-color: #0061D3;
}

.modalButtonContainer :global(.btn) {
    height: 45.5px;
}

.modalBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:9px;
    background-color: #FFF2EB;
    color:#FA5D05;
    padding: 8px 0px;
    margin: 0px 24px 32px 24px;
    border-radius: 8px;
}

form {
    font-family: 'geomanistBook';
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:15px;
}

.formfield {
    width: 100%;
    height: 100%;
    position: relative;
    background: white;
}

.modalFormInput iframe {
    height: 100%;
    width: 100%;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.modalFormInput {
    max-width:87px;
    height: 40px;
    padding: 10.75px 12px 10.75px 12px;
    margin-top: 8px;
    border: 1px solid #D3D4DA;
    border-radius: 6px;
    background-color: white;
}

.cardExpiryDate {
    display: flex;
    align-items: center;
}
