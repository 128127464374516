@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.defaultbar {
	width: 100%;
	height: 8px;
	border-radius: 32px;
}
.exclusive{
  background: linear-gradient(90deg, #E0980E 0%, #F4C66C 56.12%, #F2B133 100%);
border-radius: 4px;
font-family: 'geomanistMedium';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 130%;
letter-spacing: 0.6px;
color: #302001;
text-align: center;
padding:12px;
padding-top:6px;
padding-bottom: 6px;
}
.icon {
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
  display: inline-block;
}

.icon:after {

  animation: shine 2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 1;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
    transition-duration: 10s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.insider{
  font-family: 'geomanistMedium';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
margin-top: 3px;
letter-spacing: 0.6px;
cursor: pointer;
white-space: nowrap;
color: #005DD6;
}


.normal{
  width: 100%;
	height: 8px;
	border-radius: 32px;
}
.normal :global(.progress-bar) {
  background-color: #9096A8;
}

.defaultbar :global(.progress-bar) {
  background-color: #0DC014;
}



.title {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
  white-space: nowrap;
}
.zack {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
	white-space: nowrap;
  text-align: end;
}
.colorValue {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #0dc014;
	white-space: nowrap;
}
.defaultValue {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #010113;
	white-space: nowrap;
}
.buy {
	/* background: #dbf6dc; */
	width: 128px;
	height: 128px;
	border-radius: 50%;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
  padding-top: 33%;
  text-align: center;
}
.sell {
  /* background: #dbf6dc; */
	width: 128px;
	height: 128px;
	border-radius: 50%;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
  padding-top: 25%;
  text-align: center;
}

.active{
  white-space:nowrap;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #005DD6;
  padding-bottom: 7px;
  cursor: pointer;
  border-bottom: 1px solid #005DD6;
  display: inline-block;

}

.Inactive{
  white-space:nowrap;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #9096A8;
  padding-bottom: 7px;
  cursor: pointer;
  display: inline-block
}

@media (min-width:992px) and (max-width: 1199px) {
  .buy {
    width: 100px;
    height: 100px;
  }
  .sell{
    width: 100px;
    height: 100px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width:425px){
  .zack {
    text-align: start;
  }
  .buy {
    background: #dbf6dc;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    padding-top: 33%;
    text-align: center;
  }
  .title{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    padding-top: 10px;
  }
  .insider{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #005DD6;
  }
  .defaultValue {
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    white-space: nowrap;
  }
  .sell {
    background: #dbf6dc;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.6px;
    padding-top: 30%;
    text-align: center;
  }
}

@media (min-width:426px) and (max-width:767px){
  .zack {
    text-align: start;
  }
  .buy {
    background: #dbf6dc;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    padding-top: 33%;
    text-align: center;
  }
  .title{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    padding-top: 10px;
  }
  .insider{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #005DD6;
  }
  .defaultValue {
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    white-space: nowrap;
  }
  .sell {
    background: #dbf6dc;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.6px;
    padding-top: 30%;
    text-align: center;
  }
}



