.cardWrapper {
	width: 412px;
	height: auto;
	background: #ffffff;
	border: 1px solid #f7f7f8;
	box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
}

.cardWrapperBody {
	padding: 32px 24px 32px 24px;
}

.title {
	font-family: 'geomanistmedium';
	font-size: 28px;
	line-height: 36px;
	font-weight: 500px;
	color: #3c404a;
}

.subTitle {
	margin-top: 4px;
	margin-bottom: 12px;
	font-family: 'geomanistregular';
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
}

.card {
	width: 100%;
	border: 1.5px solid #d3d4da;
	border-radius: 6px;
	margin: 16px 0px;
	cursor: pointer;
}

.cardBody {
	padding: 12px 20px;
}

.infoLogoImg {
	margin-left: 6px;
}

.cardTitle {
    display: flex;
	font-family: 'geomanistbook';
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
}

.cardSubTitle {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
}

.cardAction {
	display: flex;
	justify-content: end;
	align-items: center;
}

.cardAction img {
	transform: rotate(180deg);
    width: 8px;
    height: 14px;
}

.orLabel {
    width: 100%;
    text-align: center;
	font-family: 'geomanistbook';
	font-size: 12px;
	line-height: 16px;
    color: #3C404A;
}

@media only screen and (max-width: 480px) {
	.cardWrapper {
		border: 0px;
		box-shadow: none;
	}
	.cardWrapperBody {
		padding: 24px;
	}
}
