.cardWrapper {
	margin-top: 80px;
}
.forgotText {
	font-family: 'Noticia Text';
	margin-top: 20px;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 110%;
	text-align: center;
	color: #000000;
	justify-content: center;
}
.noteText {
	justify-content: center;
	margin-top: 10px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}

.poweredText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-right: 8px;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	color: #3c404a;
	margin-left: 0px;
	margin-right: 0px;
}

.subTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 4px;
}

.passwordSpecification {
	margin-top: 5px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-left: 0px;
}

.buttonSignUP {
	background: #0061d3;
	width: 100%;
	height: 62px;
	margin-right: 0px;
}

.cardSignUp {
	width: 412px;
	height: auto;
	background: #ffffff;
	border: 1px solid #f7f7f8;
	box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
}
.cardBodySignup {
	padding: 32px 24px 32px 24px;
}

.inputs {
	width: 100%;
	margin-top: 24px;
	margin-left: 0px;
	margin-right: 0px;
}

.buttonMargin {
	margin: 24px 0px 0px 0px;
}

.verifyOtp {
	width: 100%;
}

.otpInput {
	margin-top: 32px;
}

.resendOtp {
	margin-top: 16px;
	display: flex;
	align-items: baseline;
}

.inputRow {
	justify-content: center;
	margin-top: 30px;
}

.emailInput {
	width: 467px;
	height: 62px;
}

.buttonPassword {
	/* margin-left: 30px; */
	/* padding: 20px 30px; */
	width: 133px;
	height: 62px;
}

.rememberRow {
	margin-top: 75px;
	justify-content: center;
}

.remember {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}

.goBackText {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #0061d3;
}

.otpError {
	margin-top: 8px;
	display: block;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #fa274a;
}

.navLink:hover {
	color: inherit;
	text-decoration: none;
}
.timer {
	margin-top: 1px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-right: 10px;
}

.otpSuccess {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #d81331;
}
.stockalImage {
	height: 50px;
}

.goBackUpdate {
	font-family: 'Inter';
	margin-left: 5px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	/* padding-bottom: 4px; */
	border-bottom: 1px solid #0061d3;
	color: #0061d3;
	cursor: pointer;
}

.userNameCard {
	margin: 24px 0 0 0;
	background: #ffffff;
	border: 1px solid #efeff1;
	border-radius: 8px;
	max-height: 252px;
	overflow-y: scroll;
}

.userSigleRow {
	margin: 0px;
	width: 100%;
	padding: 12px 0px 12px 0;
	border-bottom: 1px solid #efeff1;
	height:50px;
}

.userIcon {
	width: 18px;
	vertical-align: baseline;
}

.userNameTitle {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
}

.loginLink {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	color: #0061d3;
	cursor: pointer;
}

.userError {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #fa274a;
}

.forgotUserNameContainer {
	min-height: calc(100vh - 240px);
}

/* @media only screen and (max-width: 930px) {
	.forgotUserNameContainer {
		height: calc(100vh - 40%);
	}
} */

@media only screen and (max-width: 768px) {
	.stockalImage {
		height: 40px;
	}
	.forgotText {
		margin-top: 10px;
		font-size: 26px;
	}
	.noteText {
		font-size: 14px;
		line-height: 140%;
	}
	.inputRow {
		margin-top: 20px;
	}
	.emailInput {
		width: 382px;
		height: 50px;
	}
	.buttonPassword {
		font-size: 14px;
	}
	.rememberRow {
		margin-top: 50px;
	}
	.remember {
		font-size: 12px;
		line-height: 140%;
	}
}

@media only screen and (max-width: 480px) {
	.cardWrapper {
		margin-top: 12px;
	}
	.cardSignUp {
		border: 0px;
		box-shadow: none;
	}
	.cardBodySignup {
		padding: 24px;
	}
}

@media only screen and (max-width: 480px) {
	.stockalImage {
		height: 30px;
	}
	.forgotText {
		margin-top: 10px;
		font-size: 22px;
	}
	.noteText {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 12px;
		line-height: 140%;
	}
	.inputRow {
		justify-content: unset;
		margin-top: 30px;
	}
	.emailInput {
		width: 211px;
		height: 48px;
	}
	.buttonPassword {
		margin-left: 0px;
		font-size: 12px;
		width: 100%;
		height: 48px;
	}
	.mobileButton {
		text-align: end;
	}
	.rememberRow {
		margin-top: 30px;
	}
	.remember {
		font-size: 10px;
		line-height: 140%;
	}
}

/* password validation style */

/* .inputs {
	justify-content: center;
	margin-top: 30px;
} */
.passwordCircle {
	padding-left: 3px;
}
.passwordValidation {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: rgba(22, 37, 66, 0.75);
	margin-left: 8px;
}
.mobilePassword {
	/* text-align: -webkit-center; */
	/* margin-bottom: 30px; */
	left: 0px;
}
@media only screen and (max-width: 768px) {
	.passwordLeft {
		left: 0px;
	}
	.passwordValidation {
		font-size: 10px;
	}
}

@media only screen and (max-width: 480px) {
	.passwordLeft {
		/* text-align: -webkit-center; */
		/* margin-bottom: 30px; */
		left: 0px;
	}
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1440px) {
	.passwordLeft {
		/* padding-left: 6px; */
		left: 0px;
	}
	.mobilePassword {
		padding-right: 6px;
	}
}
/* ends  */

@media (min-height: 200px) and (max-height: 700px) {
	.rememberRow {
		margin-top: 25px;
	}
}
@media (max-width:768px) {
	.userSigleRow{
		height:70px;
	  }

	.userNameCard{
		max-height: 352px;
	}
}


.userNameCard::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.userNameCard::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}