@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

/* .cardBorder {
	border: none;
}
.bodyPad {
	padding: 0.5rem;
} */
.title {
font-family: 'geomanistmedium';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
letter-spacing: 0.6px;
color: #1E1F24;
}
.text {
  margin-top:8px;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.6px;
  color: #626777;
}
.newsdiv{
  padding-top:20px;
  padding-bottom:20px;
  padding-left:24px;
  padding-right:24px;
  border-radius:8px;
  cursor: pointer;
}
.imageResponsive{
  background-repeat: no-repeat;
  background-size: 100px;
  width:160px;
  height:109px;
  background: white;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #F7F7F8;
}
.imageAdjust{
  border-radius:8px;
  /* height: 100px; */
max-width: 100%;
height :100%;
}
@media (max-width: 992px){
	.title {
	  font-family: 'geomanistBook';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 14px;
	  line-height: 20px;
	  letter-spacing: 0.6px;
	  color: #1E1F24;
	  }
	  .text {
		margin-top:8px;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.6px;
		color: #626777;
	  }
	  .imageResponsive{
		width: 72px;
		height: 80px;
		background-repeat: no-repeat;
		background-size: 50px;
		background: white;
		text-align: center;
		border-radius: 8px;
		border: 1px solid #F7F7F8;
	  }
	  .newsdiv{
		padding-top:24px;
		padding-bottom:16px;
		padding-left:0px;
		padding-right:0px;
		border-radius:8px;
		border-bottom: 1px solid #EFEFF1;
		cursor: auto;
	  }
  }
@media (min-width:992px) and (max-width:1199px){
  .imageResponsive{
    background: white;
    text-align: center;
    width:130px;
    height:100px;
  }
}

/* .imageNews {
	max-width: 100%;
	height: 100%;
	border-radius: 8px;
} */

/* Modal Css */

/* .modalClose {
	position: absolute;
	width: 56px;
	height: 56px;
	left: 385px;
	top: 32px;
	border-radius: 50%;
	background: #ffffff;
	text-align: center;
} */

/* .modalCloseIcon {
	padding-top: 11px;
} */

/* .modalTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 0.6px;
	color: #1e1f24;
} */

/* .newsDate {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #767c8f;
} */

/* .modalImage {
	border-radius: 8px;
	width: 768px;
	height: 296px;
} */

/* .modalDesc {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
} */

/* .openSource {
	text-align: end;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1644c3;
	cursor: pointer;
} */

/* .openSourceIcon {
	vertical-align: sub;
	margin-right: 8px;
} */

/* @media (min-width: 320px) and (max-width: 480px) {
	.cardBorder {
		height: 130px;
	}

	.imageNews {
		height: 80px;
		width: 80px;

		border-radius: 4px;
	}
	.mobilePadding {
		padding: 10px;
		padding-left: 20px;
	}
	.mobileText {
		padding-left: 0px;
	}

	.title {
		font-family: 'geomanistbook';
		font-weight: normal;
		font-size: 10px;
		line-height: 15px;
	}
	.text {
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 0.1px;
	}
	.bodyPad {
		padding-left: 0px;
		padding-bottom: 0px;
	}
} */
