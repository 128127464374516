.instructionContainer{
  padding: 24px 24px 42px 24px;
  /* fixed footer size */
  margin-bottom:86px;
  width: 400px;
}
.instructionHeader{
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 60px 4px 16px;
  border-bottom: 1px solid #EFEFF1;
}
.instructionHeader .bankName{
  font-family: "geomanistBook";
  color: #3C404A;
  margin-bottom: 8px;
}
.instructionHeader .helper{
  font-family: "geomanistRegular";
  color: #767C8F;
}
.instructionContainerGrey .downloadPDF{
  font-family: "geomanistBook";
  background: #0061D3;
  padding: 8.75px 16px 8.75px 12px;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  width: 100%;
  outline-color: #0061D3;
  border-radius: 6px;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.instructionHeader .downloadPDF:focus, .instructionHeader .downloadPDF:hover{
  background:#004EA9;
}
.instructionBody{
  margin-top: 24px;
}
.limitNote{
  font-family: 'geomanistRegular';
  font-size: 14px;
  color: #767C8F;
  gap: 12px;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #D3D4DA;
  padding-top: 20px;
}
.header{
  font-family: 'geomanistBook';
  padding: 24px;
  padding-left: 0px;
  border-bottom: 1px solid #EFEFF1;
  height: 90px;
  display: flex;
  gap: 8px;
  position: fixed;
  top: 0;
  background: #FFFFFF;
  width: 100%;
  z-index: 10;
}
.header .primary{
  font-size: 16px;
  color: #3C404A;
}
.header .secondary{
  font-family: 'geomanistRegular';
  font-size: 14px;
  color: #767C8F;
}
.inputContainer{
  font-family: 'geomanistBook';
  font-size: 14px;
  color: #3C404A;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.inputContainer .input{
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}
.inputContainer input{
  width: 100%;
  border: none;
}
.inputContainer input::placeholder{
  font-family: 'geomanistBook';
  font-size: 14px;
  color: #767C8F;
}
.instructionContainerGrey{
  padding: 20px 24px 20px 24px;
  display: block;
  background: #F7F7F8;
  border-radius: 8px;
  margin-top: 70px;
  margin-bottom: 20px;
}
.spinnerColor{
  border: .25em solid #FFFFFF;
  border-right-color: #CCDFF6;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.loadingContainer{
  text-align: center;
}

@media (max-width:600px){
  .instructionContainer{
    width: 100%;
  }
  .header{
    font-family: 'geomanistBook';
    padding: 24px;
    padding-left: 0px;
    display: flex;
    gap: 8px;
    position:relative;
    top: 0;
    background: #FFFFFF;
    width: 100%;
    z-index: 10;
  }
  .instructionContainerGrey{
    padding: 20px 24px 20px 24px;
    display: block;
    background: #F7F7F8;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
