.dropImageCenter{
  width:100%;
}

.uploadButton{
text-align:center;
padding: 6px 12px 8px 12px;
gap: 6px;
height: 40px;
border: 1.5px dashed #0061D3;
border-radius: 8px;
cursor:pointer;
}
.uploadTitle{
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #0061D3;
}
.downloadImage{
  margin-right:7px;
}

.fileBox{
margin:0px;
width:100%;
text-align:center;
padding: 16px 20px 16px 20px;
height: 70px;
border: 1px solid #D3D4DA;
border-radius: 8px;
}

.fileImage{
  margin-right:20px;
}

.bankName{
  /* padding-left:8px; */
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #3C404A;
}

.textEasyFund{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 27px;
text-align: right;
color: #767C8F;
}

.title{
margin:0px;
margin-top:20px;
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #3C404A;
}

.subTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
}
.fileSize{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
}

.divContainer{
  padding:32px;
  width: 400px;
}
.uploadDiv{
  margin:0px;
  margin-top:24px;
  margin-bottom:24px;
}
.deleteBox{
  cursor:pointer;
  height:16px !important;
}
.genericLoading{
  height: calc(100vh - 144px);
  display: flex;
  justify-content: center;
}

.laterBox{
  margin:0px;
  border-top: 1px solid #EFEFF1;
  padding-top:16px;
}

.cancelContainer, .genericErrorContainer{
  display: flex;
  align-items: center;
  min-height: calc( 100vh - 250px);
}
.cancelContainer>div{
  padding: 16px;
  width: 100%;
}
.cancelContainer .cancelHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:24px;
  color: #3C404A;
  font-family: 'geomanistBook';
  font-size: 24px;
  margin-bottom: 8px;
}
.cancelContainer .cancelBody{
  font-family: 'geomanistRegular';
  font-size: 14px;
  color: #767C8F;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}
.cancelContainer .cancelConfirmBtn{
  color: #FFFFFF;
  background: #FA274A;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: 'geomanistBook';
  border: 1.5px solid #FA274A;
  padding: 8px;
  margin-right: 16px;
}
.cancelContainer .cancelConfirmBtn:focus, .cancelContainer .cancelConfirmBtn:hover{
  background: #CF203D;
}
.cancelContainer .cancelBtn{
  border: 1.5px solid #767C8F;
  border-radius: 8px;
  color: #767C8F;
  font-size: 14px;
  font-family: 'geomanistBook';
  width: 100%;
  padding: 10px 20px;
  background-color: transparent;
}
.cancelContainer .cancelBtn:focus, .cancelContainer .cancelBtn:hover{
  border: 1.5px solid #525663;
  color: #525663;
}

.ellipsisText{
  width:200px;
  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.verifyAdhar .title{
  margin:0px;
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 36px;
color: #3C404A;
}

.verifyAdhar .subtitle{
  margin:0px;
  margin-top:6px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #767C8F;
}

.verifyAdhar .adharContainer{
  margin:0px;
  margin-top:24px;
padding: 20px;
background: #F7F7F8;
border-radius: 8px;
}

.verifyAdhar .uploadtext{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #3C404A;
}

.verifyAdhar .uploadBox{
  cursor:pointer;
  text-align:center;
  height: 131px;
  padding: 10px 16px;
  background: #EFEFF1;
border: 1.5px dashed rgba(211, 212, 218, 0.4);
border-radius: 8px;
}

.uploadedImage{
width: 110px !important;
height: 75px !important;
}

.successContainer{
  height: calc(100vh - 146px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.successMsg{
  font-family: 'geomanistBook';
  font-size: 23px;
  line-height: 32px;
  color: #3C404A;
  margin-bottom: 8px;
}
.successDesc{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #767C8F;
}

.secondaryButton{
padding: 8px 12px;
height: 36px;
border: 1.5px solid #0061D3;
border-radius: 6px;
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #0061D3;
background:#ffffff;
}

.secondaryButton:hover {
  color: #0061D3;
  background:#ffffff;
  box-shadow: none;
}

.secondaryButton:focus {
  color: #0061D3;
  background:#ffffff;
  box-shadow: none;
}

.footerContainer{
  position: fixed;
  display: flex;
  bottom: 0;
  border-top: 1px solid #EFEFF1;
  padding: 24px;
  margin-left: -24px;
  gap:16px;
  background: #FFFFFF;
  width: 100%;
  z-index: 1000;
}
.footerContainer .cancelBtn{
  font-family: "geomanistBook";
  padding: 10px 20px;
  border-radius: 8px;
  color: #0061D3;
  border: 1.5px solid #0061D3;
  background: #FFFFFF;
  outline-color: #0061D3;
}
.footerContainer .cancelBtn:focus, .footerContainer .cancelBtn:hover{
  border: 1.5px solid #004EA9;
  color: #004EA9;
}
.footerContainer .continueBtn{
  font-family: "geomanistBook";
  background: #0061D3;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1.5px solid #0061D3;
  color: #FFFFFF;
  outline-color: #0061D3;
  display: flex;
  align-items: center;
}
.footerContainer .continueBtn:focus, .footerContainer .continueBtn:hover{
  background: #004EA9;
}
.footerContainer .tertiaryBtn{
  font-family: "geomanistBook";
  background: none;
  border: none;
  color: #0061D3;
  outline:none;
  padding: 0px;
}
.footerContainer .tertiaryBtn:focus,  .footerContainer .tertiaryBtn:hover{
  outline: none;
  color: #004EA9;
}



.seamHeading {
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #3c404a;
  margin-bottom: 24px;
}

.splashItemHeading {
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3c404a;
}

.splashItemSubHeading {
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c404a;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activeButton {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: #0061d3;
  border-radius: 8px;
  border: 0;
}

.activateText {
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.renderArray {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.content {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lowerSection {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

.lowerText {
  /* Base/Book */
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767c8f;
  cursor:pointer;
}

.easyFundLogo{
  width: 24px !important;
  height: 24px !important;
}


.reviewDetailsBankRow{
  margin-bottom: 42.5px;
}

.bottomBorderReviewEasyFund{
  border-bottom: 1px solid #EFEFF1;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.reviewEasyFund .type{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-bottom: 20px;
}

.reviewEasyFund .value{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #3C404A;
}

.topBorderReviewEasyFund{
  border-top: 1px solid #EFEFF1;
  padding-top: 20px;
}

.reviewEasyFundGreyBox{
  padding:20px 24px 20px 24px;
  background: #F7F7F8;
  border-radius: 8px;
}

.reviewEasyFundGreyBox .text{
  display: block;
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  margin-left: -15px;
}

.radioButtonEasyFund{
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 16px;
}

.radioButtonEasyFund .text{
  font-family: 'geomanistbook';
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  display: block;
  margin-left: 6px;
}

.radioButtonEasyFund .easyFundTermsCondition{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-top: 4px;
}

.financialYearHead{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  margin-top: 28px;
}

.easyFundLrsTransaction{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  margin-top: 20px;
}

.inputContainer .input{
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}
.inputLeft{
  display: flex;
  gap:12px;
  align-items: center;
  width: 100%;
}
.inputContainer input{
  width: 100%;
  border: none;
}
.inputContainer input::placeholder{
  font-family: 'geomanistBook';
  font-size: 14px;
  color: #767C8F;
}
.inputContainer>label{
  font-family: 'geomanistBook';
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #3C404A;
  margin-bottom: 5px;
}
.addNewTransaction{
  border-top: 1px solid #EFEFF1;
  padding-top: 20px;
  margin-top: 20px;
}
.addNewTransaction .text{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0061D3;
  cursor: pointer;
}

.easyfundFrame{
  height: 400px;
  border: 0px;
  width: 100%;
}

.errorMessage{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #FA274A;
}
.errorInput{
  border: 1.5px solid #FA274A !important;
}

.errorMsg{
  color:#FA274A !important;
  font-family: 'geomanistRegular';
  font-size: 12px;
}
.otpContainer{
  margin-top:24px;
padding: 24px 20px;
align-items: center;
text-align:center;
background: #F7F7F8;
border-radius: 8px;
}

.otpInput{
  justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
.instructBox{
  margin-top:24px;
padding: 24px;
border: 1px solid #EFEFF1;
border-radius: 8px;
}

.secondaryBtn{
  width:100%;
  font-family: "geomanistBook";
  color: #0061D3;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1.5px solid #0061D3;
  background: #FFFFFF;
  outline-color: #0061D3;
  display: flex;
  justify-content:center;
}

.secondaryBtn:focus,.secondaryBtn:hover{
  background: #FFFFFF;
}
.spinnerColor{
  border: .25em solid #0061D3;
  border-right-color: #CCDFF6;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.spinnerColorDelete{
  border: .25em solid #FFFFFF;
  border-right-color: #D3D4DA;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.splashKycBox{
padding: 16px 20px;
background: #F7F7F8;
border-radius: 8px;
}

.digiRecom{
padding: 2px 6px 0px;
background: #FA5B05;
border-radius: 4px;
color: #FFFFFF;
font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
}
.statusScreenContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px;
  font-family: 'geomanistbook';
  font-weight: 600px;
  color:#3C404A;
}
.statusMessage{
font-size:32px;
}
.statusTimer{
color: #0061D3;
font-weight: 900;
font-size: 50px;
}
.statusTimerMessage{
font-size: 20px;
}

@media (max-width: 580px) {
  .splashItemHeading{
    font-size:14px;
  }
}


.buttonContainer{
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
.genericErrorLoading{
  display: flex;
  gap: 6px;
  color: #0061D3;
  align-items: center;
}
