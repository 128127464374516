@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 512px;
	height: 100px;
}

.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
}

.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.title {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.description {
	margin-top: 16px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #767c8f;
}
.otp {
	margin-top: 24px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
}
.inputAnkit {
	margin-top: 8px;
	background: #efeff1;
	height: 48px;
	width: 416px;
	border-radius: 8px;
	border: none;
}
.inputAnkit::placeholder {
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767c8f;
}
.sec {
	margin-top: 24px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #3c404a;
}
.resend {
	margin-top: 24px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #767c8f;
}
.verify {
	margin-top: 500px;
	background: #005dd6;
	border-radius: 8px;
	padding: 10px;
	padding-left: 150px;
	padding-right: 150px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #ffffff;
	border: none;
}
.inputAnkit,
option {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	padding-left: 10px;
}
.business {
	padding-left: 5px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
