@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.uppderDiv {
	/* margin-left: 50%; */
  width: 100%;
	/* margin-right: 6%; */
}
.innerDiv {
	border-radius: 50%;
	height: 32px;
	width: 32px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.6px;
}

.insideUpdderDiv{
  padding-left: 0px;
  justify-content: center;
}
