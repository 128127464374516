.modalPadding {
	padding: 50px;
	padding-top: 10px;
}

.etfPadding{
  padding-top: 0px;
  margin-top: 25px;
}

.cashMangementPadding{
  padding-top: 0px;
  margin-top: 0px;
}

.modalDialog {
	position: fixed;
	margin: auto;
	max-width: fit-content;
	max-height: fit-content;
	/* width: 880px;
	max-width: 880px; */
	/* height: 900px; */
	right: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modalRight:global(.fade .modal-dialog) {
	top: 0px;
	bottom: 0px;
	right: -320px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modalRight:global(.fade.show .modal-dialog) {
	right: 0;
}

.modalDialog :global(.modal-content) {
	height: 100vh;
	max-height: 100vh !important;
}

.modalHeader {
	border-bottom: 0px;
	padding: 0px;
	padding-top: 23px;
	padding-right: 23px;
	color: black;
}

.crossImageStyling{
	position: absolute;
	right: 28px;
	top: 28px;
	cursor: pointer
}

@media (min-width:580px) and (max-width:768px){
  .modalPadding {
    padding: 0px;
    padding-top: 0px;
  }
}

@media (max-width: 600px) {
	.modalRight:global(.fade .modal-dialog) {
	top: 0px;
	bottom: 0px;
	right: -320px;
	-webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
	-o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
	transition: opacity 0.3s linear, bottom 0.3s ease-out;
	}

	.modalRight:global(.fade .modal-dialog) {
	top: 0px;
	bottom: -320px;
	right: 0px;
	-webkit-transition: opacity 0.3s linear, bottom 0.5s ease-out;
	-moz-transition: opacity 0.3s linear, bottom 0.5s ease-out;
	-o-transition: opacity 0.3s linear, bottom 0.5s ease-out;
	transition: opacity 0.3s linear, bottom 0.5s ease-out;
	}

	.modalDialog {
	position: fixed;
	margin: auto;
	max-width: fit-content;
	height:100px;
	right: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
	}

	.modalDialog {
        position: fixed;
        margin: auto;
        max-width: 100%;
        height:100vh !important;
        right: 0;
		top: 50px !important;
        margin:0px !important;
        width: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
	}

	.modal{
		top:50px;
		overflow-x: hidden !important;
	}

	.modalDialog :global(.modal-content) {
		border-radius: 30px 30px 0px 0px !important;
	}

	.modalPadding {
		padding: 16px;
		padding-top: 30px;
	}

	.modal-body{
		overflow-x: hidden !important;
	}

	.crossImageStyling{
		position: absolute;
		right: 28px;
		top: 25px;
		cursor: pointer
	}

}


@media (min-width: 993px) and (max-width: 1000px){
  .modalDialog :global(.modal-content) {
    height: calc(100vh + 15px);
    max-height: calc(100vh + 15px) !important;
  }
}
