@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.CashManagementProfileCard {
  float: right;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #efeff1;
  box-sizing: border-box;
  position: absolute;
  right:0px;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  top:60px;
  overflow: hidden;
}

.amount {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.amountText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

.amountSection {
	margin: 0px;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 24px;
	border-bottom: 1px solid #efeff1;
}

.profileList {
	margin: 0px;
	cursor: pointer;
}

.textDisabled {
	padding: 12px 17px 12px 16px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	cursor: default;
	color: #767C8F;
}

.listItem {
	padding: 12px 17px 12px 16px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	cursor: pointer;
}

.profileList:hover {
	background: #f7f7f8;
}

.link:hover {
	text-decoration: none !important;
}

.dropDownImages{
  padding: 13.5px 0px 14px 20px;
}

.borderDropDownCashManagement{
  border-bottom: 1px solid #D3D4DA;
}
.cashManagementMobileCross{
  display:none;
}

@media (max-width: 992px) {
  .CashManagementProfileCard{
    display: block;
		padding:20px;
		position: fixed;
		width: 50;
		width: 100%;
		height: 30vh;
		background: white;
		bottom: 0px;
		left: 0px;
    z-index: 100;
    padding-top: 30px;
    top:auto;
  }
  .cashManagementMobileCross{
    position: absolute;
    right:20px;
    top:10px;
    display: block;
  }
}
