@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.newsTitle {
	font-family: 'geomanistMedium';
	font-style: normal;
  font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.newsDesc {
	margin-top: 8px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	letter-spacing: 0.6px;
	color: #626777;
	/* margin-bottom: 24px; */
}

.newsBorder {
	margin-bottom: 10px;
  border-radius: 8px;
}
.newsBorder:hover {
	background: #f7f7f8;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 36px;
	padding-left: 15px;
  /* checked */
}

.loadNews {
	margin-top: 24px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	cursor: pointer;
	padding-left: 15px;
}
.imageCss {
  width:160px;
  height:109px;
  background: white;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #F7F7F8;
}

.newsSlider :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;

}
.newsSlider :global(.slick-slide){
  margin-left: 0px;
  padding-left: 0px;
}
.newsSlider :global(.slick-next) {
	display: none !important;
}

@media (min-width:781px) and (max-width: 1024px) {
  .imageCss{
    height: 109px;
    width: 110px;
  border-radius: 4px;
  }
}
@media (min-width:450px) and (max-width: 780px) {

.title {
  font-size: 20px;
  line-height: 28px;
	margin-bottom: 24px;
  margin-top:40px;
}
.imageCss{
  width: 72px;
height: 80px;
border-radius: 4px;
background-repeat: no-repeat;
background-size: 50px;
background: white;
text-align: center;
border: 1px solid #F7F7F8;
}
.newsTitle {
font-size: 14px;
line-height: 20px;
font-family: 'geomanistbook';
font-weight: normal;

}
.newsDesc{
font-size: 12px;
line-height: 16px;
}
/* .newsSlider{
  height: 231px;
} */
.newsMargin{
  border-bottom: 1px solid #EFEFF1;
  border-radius: 0px;
  padding-top: 24px;
  padding-bottom: 16px;
}
}

@media (max-width:425px){
  .newsTitle {
    font-size: 14px;
    line-height: 20px;
    }
    .newsDesc{
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    }
    .newsMargin{
      border-bottom: 1px solid #EFEFF1;
      border-radius: 0px;
      padding-top: 24px;
      padding-bottom: 16px;
    }
    .imageCss {
      /* height: 72px;
      width:100%; */
      height: 72px;
      width: 80px;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-size: 50px;
      background: white;
      text-align: center;
      border: 1px solid #F7F7F8;
    }
    .title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0px;
      margin-top:40px;
    }
}
