.skeletonContainer {
	padding-top: 20px;
	padding-right: 20px;
}
.btnContainer {
	text-align: -webkit-right;
	padding-top: 12px;
}
.withdraw {
	border: 1.5px solid #0061d3 !important;
	height: 40px;
	color: #0061d3;
	background: #ffffff;
	border-radius: 6px;
	padding: 8px 12px;
	margin-right: 16px;
}
.withdraw:hover {
	border: 1.5px solid #004ea9 !important;
	color: #004ea9;
}
.withdraw:focus {
	box-shadow: none;
	outline: none;
}
.withdrawText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.addfunds {
	background: #0061d3 !important;
	height: 40px;
	border-radius: 6px;
	padding: 9.5px 12px;
	border: none;
	margin-right: 15px;
}
.addfunds:hover {
	background: #004ea9 !important;
}
.addfunds:focus {
	box-shadow: none;
	outline: none;
}
.addfunds img {
	width: 18px;
	height: 18px;
}
.addfundstext {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	margin-left: 4px;
}
.menuContainer {
	border: 1.5px solid #0061d3 !important;
	color: #0061d3;
	background: #ffffff;
	border-radius: 6px;
	width: 40px;
	height: 40px;
	padding: 0;
}
.menuIcon {
	position: relative;
	width: 3px;
	height: 3px;
	background-color: #0061d3;
	border-radius: 50%;
	left: 16px;
	top: -6px;
}

.menuIcon:before,
.menuIcon:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	background-color: inherit;
	border-radius: inherit;
}

.menuIcon:before {
	top: 6px;
}

.menuIcon:after {
	top: 12px;
}

@media (max-width: 576px) {
	.btnContainer {
		text-align: -webkit-left;
	}
	.skeletonContainer {
		margin-top: -7px;
		padding: 0px 20px 20px 20px;
	}
}
@media (max-width: 375px) {
	.btnContainer{
		display: flex;
		flex-wrap: wrap;
	}
	.addfunds{
		flex-grow: 1;
	}	
}
@media (max-width: 341px) {
	.menuContainer {
		margin-top: 18px;
	}
}
