.reviewCard {
  height: calc(100vh - 130px);
	border: 0px;
	border-left: 1px solid #efeff1;
}

.reviewCardBody {
	padding: 0px 32px 40px 32px;
}

.reviewOrderTitle {
	padding: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
}

.closeCard {
	cursor: pointer;
	/* top: -15px; */
  padding-top: 17px;
}

.stackName {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.stackImage {
	width: 100%;
	border-radius: 8px;
}

.stackCreator {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #9096a8;
}

.feeNote {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
}

.enterPinText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
}

.spinInput {
	border: none !important;
	text-align: center;
	width: 40px !important;
	height: 48px;
	background: #efeff1 !important;
	border-radius: 8px !important;
	padding-top: 7px;
}

.spinInput::placeholder {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #d3d4da;
}

.termsConditions {
	margin-top: 150px !important;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #505562;
}

.terms {
	display: contents;
	color: #005dd6;
}

.orderPlacedText {
	width: 256px;
	justify-content: center;
	margin-top: 38px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.cardBorder {
	border: 0px;
}

.investing {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #505562;
}

.footer {
  /* width: 100%; */
  /* position: sticky; */
  /* background: #f7f7f8; */
  height: 64px;
  position: fixed;
  width: 901px;
  margin-left: -32px;
  /* left: 225px; */
  bottom: 0px;
}

.footerStack {
  background: #f7f7f8;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: left;
	padding-top: 20px;
	padding-left: 25px;
	padding-right: 25px;
}

.modalWidth {
	width: 1160px;
}
.detail{
  padding-right: 0px;
  padding-left: 20px;
  padding-top: 5px;
}
.confirmFixed{
  position: fixed;
bottom: 24px;
width: 250px;
}

.scrollDiv {
  margin-left: 0px;
  margin-top: 24px;
  padding-right: 30px;
	overflow: scroll;
	height: calc(100vh - 180px);
}
.amountInput {
	font-family: 'geomanistbook' !important;
	padding-left: 25px;
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out !important;
	border: 0px !important;
	background: #efeff1;
	border-radius: 8px !important;
	width: 288px;
	height: 48px;
}

.amountInput:read-only {
	background: #efeff1 !important;
}

.amountInput::placeholder {
	padding-left: 10px;
	font-family: 'geomanistregular' !important;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #9096a8;
}

.amountInput:active,
.amountInput:hover {
	background: #efeff1;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.amountInput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
}

.amountInput:focus {
	background: #efeff1;
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}
.dollarIcon {
	top: -2px;
	visibility: visible;
	position: absolute;
	padding: 13px;
	cursor: pointer;
	color: #9096a8;
	cursor: pointer;
}
.buyButton {
	background: #FA5D05;
}

.buyButton:hover,
.buyButton:focus,
.buyButton:active {
	background: #FA5D05;
	border: 0px;
	box-shadow: none;
}

.buyButton:disabled {
	background-color: #9096a8;
	border-color: #9096a8;
}

.buyButton:not(:disabled):not(.disabled):active,
.buyButton:not(:disabled):not(.disabled):active:focus {
	color: #fff;
	background-color: #FA5D05;
	border-color: #FA5D05;
	box-shadow: none;
}

.sellAll {
	text-align: end;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
}
.otpInput{
  padding: 16px;
}

@media (min-width: 700px) and (max-width: 1100px) {
  .modalWidth {
    width: 100%;
  }
  .reviewOrderTitle {
    font-size: 20px;
  }
  .stackName{
    font-size: 16px;
  }
  .otpInput{
   padding: 10px;
  }
  .termsConditions {
    margin-top: 80px !important;
  }
  /* .scrollDiv {
    width: 70%;
  } */
  .footer{
    width: 724px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
    .amountInput{
      width: 100%;
    }
    .reviewCard{
      height: fit-content;
    }
    .confirmFixed{
      position: static;
      width: 100%;
    }
    /* .buyButton{
      width: 97%;
      height: 48px;
    } */
    .stackImage{
      top: -15px;
      left:20%;
    position: absolute;
      width: 80px;
    }
    .mobileFlex{
      flex-direction: row-reverse;
    }
    .modalWidth{
      width: 100% !important;
      margin: 0px !important;
    }
    .reviewCardBody{
      padding:15px !important;
    }
    .reviewCard{
      border-left: 0px !important;
    }
    .reviewOrderTitle{
      margin-top: 15px;
      margin-bottom: 30px;
      text-align: center;
      font-family: 'geomanistregular';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.6px;
      color: #1E1F24;
    }
    .buttonRow{
      left: 1px;
      justify-content: center;
      margin-top: 0px;
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .termsConditions{
      margin-top: 24px !important;
      margin-bottom: 70px !important;
    }
    .stocksWeightMobile{
      margin-top: 5px;
      font-family: 'geomanistmedium';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.6px;
        color: #1E1F24;
    }
    .modalDialog{
      width: 100%;
      margin: 0px;
    }
    .detail{
      padding-left: 0px;
      padding-top: 0px;
    }
}

@media (min-width: 500px ) and (max-width:1000px){
  .stackCard{
    margin:40px;
    box-shadow: none;
  }
  .modalWidth{
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer{
    margin-left: 0px;
    width: 65%;
  }
  .reviewCardBody{
    padding-left: 20px;
  }
  .pinPadding{
    padding:8px
  }
  .confirmFixed{
    width: 230px;
  }
}

@media (min-width: 500px ) and (max-width:1000px){
  .stackCard{
    margin:40px;
    box-shadow: none;
  }
  .modalWidth{
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer{
    margin-left: 0px;
    width: 65%;
  }
  .reviewCardBody{
    padding-left: 20px;
  }
  .pinPadding{
    padding:8px
  }
  .confirmFixed{
    width: 230px;
  }
}
