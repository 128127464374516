@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.stackName {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.stackTicker {
	margin-bottom: 4px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;

	letter-spacing: 0.6px;
	text-transform: uppercase;

	color: #1e1f24;
}

.stackCreator {
  margin-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
}

.cacrPercentage {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #34b220;
}

.image {
	width: 96px;
	border-radius: 10px;
}
.imageMargin {
	top: -15px;
	margin-right: 24px;
}

.risk {
	margin-top: 8px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #eb8105;
}
.starDesign {
	margin-bottom: 7px;
}

.toopTipImage{
  height: 12px;
  margin-left: 5px;
}

@media (min-width: 500px) and (max-width: 1000px) {
  .imageMargin{
    text-align: end;
    top:0px;
   margin-top: 8px;
 }
 .cardDetail{
  /* width: 335px; */
  margin-top: 24px;
  height: 88px;
  background: #FFFFFF;
  border: 1px solid #EFEFF1;
  box-sizing: border-box;
  border-radius: 8px;
}
}

@media (min-width: 320px) and (max-width: 480px) {
  .imageMargin{
    top:0px;
   margin-top: 8px;
 }
  .craeterProfile{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #1E1F24;
  }
  .stackTicker {
    font-size: 20px;
  }
  .cardDetail{
    /* width: 335px; */
    margin-top: 24px;
    height: 88px;
    background: #FFFFFF;
    border: 1px solid #EFEFF1;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .stackCreator {
    font-size: 12px;
    color: #9096A8;
  }
  .stackName {
    font-size: 16px;
  }
  .starDesign{
    height: 25px;
  }
  .cacrPercentage {
    font-size: 16px;
  }
}
