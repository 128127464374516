/* Absolute Center Spinner */
.overlayLoading {
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.overlayLoading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

/* :not(:required) hides these rules from IE9 and below */
/*.overlayLoading:not(:required) {*/
/*!* hide "loading..." text *!*/
/*font: 0/0 a;*/
/*color: transparent;*/
/*text-shadow: none;*/
/*background-color: transparent;*/
/*border: 0;*/
/*}*/

.overlayLoading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid rgb(27, 189, 163);
  width: 50px;
  height: 50px;
  top: 45%;
  left: 45%;
  position: absolute;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}




/* button loader */
.btnLoader {
  /* border: 4px solid #f3f3f3; */
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 4px solid #1bbda3; */
  border-top: 4px solid rgb(27, 189, 163);

  width: 28px;
  height: 28px;
  /* -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite; */


  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;

  position: absolute;
  top: 25%;
  left: 55%;
  z-index: 1
}

#zsiq_float {
  bottom: 50px;
}
