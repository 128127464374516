@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.loadingContainer {
    font-family: 'geomanistBook';
    font-weight: 400;
    background-color: #F7F7F8;
    border-radius: 8px;
    width: 100%;
    height: 418px;
    margin-top: 32px;
}

.loadingContainer :global(.text-primary) {
    color:#0061D3 !important;
}

.loadingRow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
}

.loadingColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadingContainer p {
    margin: 0px;
}

.textBig {
    font-size: 20px;
    line-height: 28px;
}

.textSmall {
    font-size: 14px;
    line-height: 20px;
}

.textGrey {
    color:#767C8F;
}

.textBlue {
    color:#0061D3;
}

.textBlack {
    color:#3C404A;
}

.loadingButton {
    padding: 8.75px 16px;
    border: 1.5px solid #0061D3;
    border-radius:8px;
    min-width: 97px;
    text-align: center;
    background-color: #F7F7F8;
    cursor: pointer;
}

button:focus {outline:none;}