.container{
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background-color: rgba(220, 104, 3, 0.12);
  border-radius: 8px;
  font-family: 'geomanistMedium';
  width: 100%;
}
.row1{
  display: flex;
  justify-content: center;
  width: 100%;
}
.suspenseTag{
  background-color: #DC6803;
  padding: 6px 12px;
  margin-right: 20px;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 12px;
  font-family: 'geomanistMedium';
}
.responsiveRow{
  width: 100%;
  /* margin-top: 16px; */
}
.tranxNum{
  display: flex;
  align-items: center;
  color: #DC6803;
  margin-right: 12px;
  font-size: 14px;
  justify-content: center;
}
.question{
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #0061D3;
  cursor: pointer;
  font-size: 14px;
  margin-top: 4px;
}
.suspenseModal :global(.modal-dialog){
  width: 400px;
  margin: auto;
}
.suspenseModal :global(.modal-content){
  border-radius: 16px !important;
  padding: 0px 20px 20px 20px;
}
.modalHeader{
  font-family: 'geomanistMedium';
  font-size: 20px;
  margin-bottom: 0px;
}
.modalDesc{
  font-family: 'geomanistRegular';
  margin-top: 8px;
}
@media (min-width:992px){
  .container{
      padding: 16px;
  }
  .question{
      margin-top: 0px;
  }
}
@media (max-width: 425px){
  .suspenseModal :global(.modal-dialog){
      width: 90%;
  }
}
@media (max-width:580px){
  .container{
    margin-right: 15px;
    margin-left: 15px;
  }
  .tranxNum{
    margin-right: 0px;
  }
}

