@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';


.card{
    background:#FFFFFF;
    box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
    border-radius: 16px;
    text-align: center;
    padding: 20px;
    width: 352px;
    height: 316px;
    margin: 20px auto 0;
}

.success{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
}
.pieceText{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #626777;
}
.circle{
  position: relative;
    width:78px;
    height: 78px;
    border: 2.42424px solid #EBEBEB;
    border-radius: 50%;
  margin: auto;
}
.tick{
    z-index: 100;
    position: absolute;
    top: 0px;
    right: -10px;
}
.logoSymbol{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
  font-size: 30px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.footer{
  height: calc(100% - 330px);
  min-height: 140px;
  padding-bottom: 20px;
}
.button{
  font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #ffffff;
    width: 288px;
    height: 64px;
    background: #212529;
    border-radius: 8px;
    box-shadow: none;
    margin-top: 25px;
}