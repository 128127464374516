@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	/* background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
	text-align: center;
	padding: 30px;
	width: 352px; */
	width: 370px;
	/* height: 479px; */
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
  padding:32px;
}
/* .increaseHeightCard{
    background:#FFFFFF;
    box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
    border-radius: 16px;
    height: 550px;
    text-align: center;
    padding: 30px;
    width: 352px;
} */

.buy {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	text-transform: capitalize;
	text-align: left;
	cursor: pointer;
}
.sell {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	text-transform: capitalize;
	text-align: left;
	cursor: pointer;
}
.buyPrices {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #13b948;
	text-align: right;
}
.sellPrices {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #e61446;
	text-align: right;
}
.typeText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: left;
	padding-top: 10px;
}
.DropdownButton {
	background: #efeff1;
	border-radius: 8px;
	width: 120px;
	height: 40px;
	border: none;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	text-align: left;
	padding-left: 15px;
	outline: none !important;
	text-transform: capitalize;
}
.Dropdown {
	background-color: #efeff1 !important;
	width: 120px;
	height: 40px;
	border-radius: 8px;
	border: none;
	color: #1e1f24;
	font-family: 'geomanistbook';
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
}
.arrowDown {
	border: solid black;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 2px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	text-align: right;
	margin: 0 0 2px 12px;
	/* float: right;
	margin-top: 6px;
	margin-right: 40px; */
}
.arrowDownMarket{
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    text-align: right;
    float: right;
    margin-top: 6px;
    margin-right: 10px;
}
.dropdownMenu {
	z-index: 1;
	position: absolute !important;
	top: 0px !important;
	left: 30px !important;
	margin: 0px !important;
	transform: translate(-130px, 45px) !important;
	right: auto !important;
	bottom: auto !important;
	background: #ffffff;
	border: 1px solid #ececec;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	padding-top: 17px;
	padding-right: 20px;
	padding-left: 20px;
	width: 250px;
}
.menuOption {
	padding: 0px !important;
}
.dropHead {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: left;
}
.subhead {
	text-align: left;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
}
.costBreakdownText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	text-align: left;
}
.costBreakdown {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.buyingPower {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #1e1f24;
}
.orderValue {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.4px;
	text-transform: capitalize;
	color: #1e1f24;
	text-align: left;
}
.Value {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.4px;
	text-transform: lowercase;
	color: #1e1f24;
	text-align: right;
}
.reviewButton {
	width: 100%;
	height: 60px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	background: #13b948;
	border-radius: 8px;
	border: none;
}
.reviewButton:focus{
  box-shadow: none;
  outline: none;
}
.rectangle {
	font-family: 'geomanistbook';
	font-size: 14px;
	line-height: 20px;
	font-style: normal;
	text-align: right;
	width: 120px;
	height: 40px;
	left: 200px;
	top: 150px;
	background: #efeff1;
	border-radius: 8px;
	padding-top: 0px;
	padding-right: 10px;
	border: none;
	float: right;
	outline: none !important;
}
.fade {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #767c8f;
	text-align: left;
	cursor: pointer;
}
.investDropdown {
	border: none;
	background-color: white;
	width: 100%;
}
.error {
	background: #f7f7f8;
	border-radius: 6px;
	margin-left: 15px;
	margin-right: 15px;
	width: 100%;
	min-height: 48px;
  padding-bottom: 6px;
}
.errorText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #505562;
	text-align: left;
}
.greenBar {
	width: 30px;
	height: 2px;
	background: #13b948;
	margin-top: 4px;
}
.redBar {
	width: 30px;
	height: 2px;
	background: #fa5d05;
	margin-top: 4px;
}
.dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	width: 300px;
	z-index: 2;
	border: 1px solid rgba(0, 0, 0, 0.04);
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}
.dropUl {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.dropUl li {
	cursor: pointer;
}
/* .dropUl li:hover{
    background: #EFEFF1;
} */
.dollar {
	display: inline-block;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	position: absolute;
	top: 9px;
	left: 42px;
	font-size: 14px;
	line-height: 20px;
}
.info {
	padding-left: 25px;
}
.investDropdownButton {
	background: white;
	border-radius: 8px;
	width: 100%;
	height: 40px;
	border: none;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	text-align: left;
	margin-left: -5px;
	outline: none !important;
	letter-spacing: 0.6px;
	white-space: nowrap;
}
.investDropdownMenu {
	z-index: 1;
	position: absolute !important;
	top: 0px !important;
	left: 140px !important;
	margin: 0px !important;
	transform: translate(-130px, 35px) !important;
	right: auto !important;
	bottom: auto !important;
	background: #ffffff;
	border: 1px solid #ececec;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	padding-top: 12px;
	width: 150px;
	height: 92px;
}
.investDropHead {
	text-align: left;
	height: 40px;
	margin: 0px;
  padding-left: 16px;
}
.investDropHead:hover {
	background: #efeff1;
}
.investOption {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	padding-top: 10px;
}
.reviewDiv{
  margin: 0px;
  padding-top: 24px;
}
.buyTitle{
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1E1F24;
	white-space: nowrap;
}
@media (max-width:576px){
  .card {
    /* background:pink; */
    width:100%;
   height: calc(100vh + 200px);
    background: #ffffff;
    box-shadow: 0px;
    border-radius: 0px;
  }
  .reviewDiv{
   margin-top: 355px;
   padding-top: 0px;
  }
  .fixedDiv{
    background: white;
    z-index: 1000;
    position: fixed;
    height: auto;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding:11px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 576px){
	.fixedDiv{
		margin: 0px;
		padding: 24px 0 0 0;
		position: initial;
	}
}
@media (min-width:576px) and (max-width:1024px ){
  .card{
    /* width: 300px;
    height: 520px; */
	width: 100%;
	height: 100%;
    padding:22px;
    /* margin-left: 30px; */
  }
}


.sellReviewButton {
	width: 100%;
	height: 60px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	background: #fa5d05;
	border-radius: 8px;
	border: none;
}

.see {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #005dd6;
	cursor: pointer;
  display: block;
  margin-top: -11px;
  margin-left: 3px;
}

.seeGreyed{
  font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #767c8f;
	cursor: pointer;
  display: block;
  margin-top: -11px;
  margin-left: 3px;
}

.noteMessage {
	background: #f7f7f8;
	border-radius: 6px;
	margin-left: 15px;
	margin-right: 15px;
	width: 100%;
	height: 48px;
}
.noteMessageText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
  color: #505562;
	text-align: left;
}

.disable{
  opacity: .6;
}

.extendedmessage{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.6px;
color: #505562;
padding: 0px;
}

.otcAlertMessageContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap:12px;
	padding: 13px 12px;
	border-radius: 8px;
	background-color: #FFF2EB;
	color: #FA5B05;
	font-family: 'geomanistRegular';
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	margin: 16px 0px 0px 0px;
}

.otcAlertMessageContainer p {
	margin: 0px;
}

@media (max-width:375px) {
	.otcAlertMessageContainer {
		font-size: 11px;
	}
}

@media (min-width:375px){
	.otcAlertMessageContainer {
		font-size: 12px;
	}
}
