.card {
	width: 840px;
  height: max-content;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	border-bottom: 1px solid #efeff1;
	padding-bottom: 22px;
}
.submit {
	border: 1px solid #efeff1;
	box-sizing: border-box;
	border-radius: 8px;
	padding-top: 16px;
	padding-bottom: 16px;
	/* margin-right: 12px; */
  width: 100%;
	/* width: 360px; */
}
.schedule {
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	border-radius: 8px;
	padding-top: 16px;
	padding-bottom: 16px;
	/* margin-left: 12px; */
  width: 100%;
	/* width: 360px; */
}
.first {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	white-space: nowrap;
}
.second {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
	margin-top: 4px;
}
.pan {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-bottom: 4px;
}
.panInput {
	background: #efeff1;
	border-radius: 8px;
	height: 48px;
	width: 100%;
	border: none;
	padding-left: 10px;
	margin-top: 4px;
}
.panInput::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}
@-moz-document url-prefix() {
	.panInput::placeholder {
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 45px;
		letter-spacing: 0.6px;
		color: #9096a8;
	}
}
.lrs {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
	margin-top: 40px;
}
.please {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.us {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-bottom: 4px;
}
.adName {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-bottom: 4px;
}

.input4 {
	padding-right: 41px;
	padding-left: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	background: #efeff1;
	border-radius: 8px;
	height: 96px;
	width: 100%;
	border: none;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}
.input4::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
@-moz-document url-prefix() {
	.input4::placeholder {
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 100px;
		letter-spacing: 0.6px;
		color: #1e1f24;
	}
}
.Forex {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-top: 4px;
}
.transaction {
	padding-left: 4px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
}
.what {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.pickup {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #3c404a;
}
.date {
	margin-bottom: 4px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
}
.selectDate {
	padding: 10px;
	background: #efeff1;
	border-radius: 8px;
	height: 48px;
  width: 100%;
	/* width: 362.18182373046875px; */
	border: none;
	text-align: start;
  position: relative;
}
.selectDate::after {
	content: none;
	display: none;
}
.arrowCss{
  position: absolute;
  right: 12px;
  top:20px;
}

.selectDate:focus {
	outline: none !important;
	background: #efeff1 !important;
	color: #1e1f24 !important;
	border: none !important;
	box-shadow: none !important;
}
.dropElements {
	cursor: pointer;
	/* width: 362.18182373046875px; */
  width: 93%;
	padding-left: 8px;
	padding-right: 8px;
	background: #ffffff;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	box-shadow: 0px 2px 16px rgba(30, 31, 36, 0.12);
	border-radius: 8px;
  position: absolute;
  z-index: 100;
  margin-top: 5px;
}
.mainElement {
	background: #ffffff;
	margin-top: 8px;
	padding-left: 12px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 4px;
}
.mainElement:hover {
	background: #efeff1;
}

.dateBox {
	box-shadow: 0px 4px 40px rgba(30, 31, 36, 0.12);
}

.selectDate,
option {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.address {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #3c404a;
}
.proof {
	text-align: end;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-decoration-line: underline;
	color: #005dd6;
}
.input2 {
	background: #efeff1;
	border-radius: 8px;
	height: 48px;
	width: 100%;
	border: none;
	padding-left: 10px;
}
.input2::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
@-moz-document url-prefix() {
	.input2::placeholder {
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 45px;
		letter-spacing: 0.6px;
		color: #1e1f24;
	}
}
.input3 {
	height: 48px;
	width: 100%;
	border-radius: 8px;
	background: #efeff1;
	border-radius: 8px;
	border: none;
}
.input3::placeholder {
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.input3:disabled{
  background: #F8F9FA;
  border: 1px solid #F7F7F8;
  color: #9DA2B0;
}
.input4:disabled{
  background: #F8F9FA;
  border: 1px solid #F7F7F8;
  color: #9DA2B0;
}
.selectDate:disabled{
  background: #F8F9FA;
  border: 1px solid #F7F7F8;
  color: #9DA2B0;
}
@-moz-document url-prefix() {
	.input3::placeholder {
		padding-left: 10px;
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 45px;
		letter-spacing: 0.6px;
		color: #1e1f24;
	}
}
.input3,
option {
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px 30px 12px 30px;
	margin-right: 40px;
}
.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.fixedDiv {
  border-top: 1px solid #efeff1;
  padding-top: 20px;
	/* background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	);
	text-align: end;
	position: fixed;
	height: 60px;
	bottom: 0px;
	right: 0px;
	margin-right: 0%; */
	/* padding-top: 50px; */
	/* width: 940px; */
}
.paddingState{
  margin-top: 34px;
}
.erorrMessage{
  margin-bottom: 70px;
  text-align: center;
  margin-top: 10px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #FA274A;
}
.lrsstyle{
  margin-left: 0px;
  margin-right: 0px;
}

.circle {
	padding-left: 3px;
}
.validation {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: rgba(22, 37, 66, 0.75);
	margin-left: 8px;
}

@media (max-width: 768px) {
	.card {
		width: 100%;
	}
  .paddingState{
    margin-top: 20px;
  }
  .pan{
    font-size: 12px;
  }
}

@media (min-width:768px) and (max-width: 990px) {
  .card {
		width: 100%;
	}
}

@media (max-width:1300px){
  .fixedDiv {
    background: none;
    /* text-align: end; */
    position: static;
    width: 100%;
  }
  .addfunds{
    margin-right: 0px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .marginCard{
    margin-bottom: 16px;
  }
  .card{
    height: max-content;
  }
  .lrsstyle{
    margin-left: 0px;
    margin-right: 0px;
  }
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
  .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 0px;
    margin-right: 0px;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 400px) and (max-width: 480px) {
  .card{
    width: 100%;
  }
}
@media (min-width: 300px) and (max-width: 348px) {
  .card{
    width: 290px;
  }
}

@media (min-width: 350px) and (max-width: 390px) {
  .card{
    width: 337px;
  }
}

@media (min-height: 200px) and (max-height: 500px) {
  .marginLast{
    margin-bottom: 120px;
  }
  .fixedDiv{
    background:none;
    position:static;
    margin-top: 15px;
  }
}

.marginLast{
  margin-bottom: 80px;
}
