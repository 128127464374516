@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
	border-bottom: 1px solid #efeff1;
	padding-bottom: 24px;
}
.card {
	width: 840px;
}
.contain {
	cursor: pointer;
	text-align: center;
	width: 240px;
	height: 179px;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	border-radius: 8px;
}
.name {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}
.howDiv{
  padding-left: 20px;
  white-space: nowrap;
}
.responsiveBoth{
  margin-top: 40px;
  padding-left:0px;
}
.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}
@media (max-width: 768px) {
	.card {
    height: calc(100vh + 100px);
		width: 540px;
	}
  .mobileTitle{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 28px;
text-align: center;
letter-spacing: 0.6px;
color: #1E1F24;
  }

}

@media (min-width:768px) and (max-width:1024px){
  .card {
		width: 540px;
	}
  .howResponsive{
    margin-top: 0px;
  }
}

@media (min-width:380px) and (max-width:576px){
  .responsiveBoth{
    padding-left:30px;
  }
}

@media (min-width:576px) and (max-width:768px){
  .howResponsive{
    margin-top: 32px;
  }
}

@media (max-width: 590px) {
  .howDiv{
    padding-left: 0px;
    white-space: nowrap;
  }
	.card {
    height: calc(100vh + 100px);
    width: 100%;
	}
  .spinner{
    width: 500px;
    padding-left: 35%;
  }
  .title{
    font-family: 'geomanistBook';
    font-size: 20px;
    line-height: 28px;
	  border-bottom: 0px;
  }
  .howResponsive{
    margin-top: 32px;
  }
  .contain{
    width: 335px;
    height: 179px;
    border: 1px solid #D3D4DA;
    box-sizing: border-box;
    border-radius: 8px;
  }
}

@media (max-width:330px){
  .card {
		width: 300px;
	}
  .contain{
    width: 300px;
    height: 179px;
  }
  .title{
    font-family: 'geomanistBook';
    font-size: 18px;
    line-height: 25px;
	  border-bottom: 0px;
  }
}


