.cardrowMargin {
	/* margin-top: 64px; */
	margin-left: 0px;
  margin-top: 64px;
}
.responsiveDetail{
  margin-top: 48px;
}
/* .trendingSlider{
  height: 250px;
} */
.trendingSlider :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;

}
.trendingSlider :global(.slick-slide) {
  padding-right: 16px !important;
}

.trendingSlider :global(.slick-next) {
	display: none !important;
}
.themeDiv{
  margin-top:140px;
}
.heading {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 0px;
	margin-bottom: 50px;
}

.headingCss{
  margin-top: 24px;
}
.tableResponsive {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;
  position: static;
}

.headingCss th{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
  border-top: none;
  padding-bottom: 16px;
  white-space: nowrap;
}
.instrument{
  padding-left: 0px;
}

/* .allocation{
  text-align: end;
} */

.holding{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.code{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.rest{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  /* white-space: nowrap; */
}

.rest2{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  margin-bottom: 33px;
  margin-top: 40px;
}

.seeAll{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #005DD6;
  margin-top: 32px;
  cursor: pointer;
}

.fixingTable{
  width: 100%;
  height: 100vh;
}
.sector{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 32px;
}
/* 
.fixingTable th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
} */


.cardtopMargin {
	margin-top: 40px;
	padding-right: 15px;
	padding-left: 15px;
}
.watchlist{
  border: 1px solid #D9D9D9;
box-sizing: border-box;
border-radius: 8px;
display: inline-block;
padding:6px;
padding-left:10px;
padding-right:10px;
cursor:pointer;
white-space: nowrap;
}
.watchlistText{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #1E1F24;
margin-left: 6px;
padding-top: 1px;
white-space: nowrap;
}
.watchlistDiv{
  margin-bottom:32px;
  text-align: end;
}
.heading {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 0px;
  padding-left:15px;
  /* checked */
}
.buttonresponsive{
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align:center;
  z-index: 999;
}
.horizantalLine{
  height: 8px;
  background: #F7F7F8;
  margin-bottom: 0px;
}
.marginLine{
  margin-top: 32px;
  /* margin-left: -15px;
  margin-right: -15px; */
}
.subHeading{
font-family: 'geomanistRegular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.4px;
color: #626777;
}

.reviewMargin{
  margin-top: 40px;
}
.responsiveEtf{
  margin-top: 40px;
}
.responsiveRow{
  margin-bottom:30px;
  margin-top: 48px;
  padding-left: 15px;
}
.desktopLine{
  height: 1px;
  background: #EFEFF1;
  margin-top: 56px;
}
.shareLine{
  margin-top: 40px;
}
.extendedCard{
  width: 352px;
height: 90px;
background: #FFFAF7;
border-radius: 16px;
margin-top:48px;
margin-bottom: 32px;
box-shadow: 0px 4px 24px rgb(2 2 65 / 11%);
padding:24px;
}
.extendedTitle{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #1E1F24;
}
.extendedSubTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #505562;

}


/* extended disclouser */
.extendedModalTitle{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.6px;
color: #1E1F24;
padding-left: 0px;
}

.extendedModalSubTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #626777;
padding-right: 19px;
}
.extendedModalSubValue{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #1E1F24;
}
.extendedModalTnC{
  background: #EFEFF1;
border-radius: 8px;
padding: 20px;
height: 228px;
overflow:scroll;
-webkit-overflow-scrolling: touch;
scrollbar-width: 2px;
margin-top: 12px;
}
.extendedModalBody{
  padding:48px;
}

.extendedModalButton{
  width: 156px;
  height: 48px;
  text-align: center;
  justify-content: center;
  background: #005DD6;
  border-radius: 8px;
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;
}

@media (max-width: 1024px){
  .watchlistDiv{
    text-align:end;
    padding-right: 60px;
  }
}


.rotate {
  -webkit-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 770px) {
  .cardrowMargin {
    /* margin-top: 64px; */
    margin-left: 0px;
    margin-top: 0px;
  }
  .heading {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    margin-bottom: 0px;
    padding-left: 0px;
    margin-top: 40px;
    }
  .responsiveDetail{
    margin-top: 16px;
  }
  .reviewMargin{
    margin-top: 28px;
  }

  .responsiveEtf{
    margin-top: 32px;
  }
  .subHeading{
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #505562;
}
.themeDiv{
  margin-top:40px;
}
}

@media (min-width:426px) and (max-width:768px){
  .marginLine{
    margin-top: 32px;
    /* margin-left: -35px;
    margin-right: -35px; */
  }
  .buttonresponsive{
    right: 0px;
    left: 0px;
    margin-left: 0px;
    z-index: 999;
  }
  .allocation{
    padding-right: 0px;
  }
}

@media (min-width:426px) and (max-width:1000px){
  .buttonresponsive{
    right: 0px;
    left: 0px;
    margin-left: 0px;
    z-index: 999;
  }
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}

.statsModal :global(.modal-body){
  padding: 0px 40px;
  width: 670px;
  /* overflow-y: auto; */
}
.statsModal :global(#crossImage){
  top: 25px;
}

.statsHeader{
  position: sticky;
  top: 0px;
  z-index: 50;
  background: white;
  height: 52px;
}

.statsNavbar{
  position: sticky;
  top: 52px;
  z-index: 1;
  background: white;
}

.statsNavbar :global(.nav-link){
  margin-right: 40px;
}

.statsProfile{
  border: 2px solid rgba(30, 31, 36, 0.12);
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
}
.statsTable th{
  position: sticky;
  left: 0;
  border-top: none;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  background: #EFEFF1;
  color: #505562
}
.statsTable tr td:first-child{
  position: sticky;
  left: 0;
  background: inherit;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.statsTable tr td:first-child, .statsTable tr th:first-child{
  width: 20%;
  border-right: 2px solid rgba(30, 31, 36, 0.12);
  padding-right: 0;
}
.statsTable tr td:nth-child(2), .statsTable tr th:nth-child(2){
 padding-left: 60px;
}

.statsTable td{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  white-space: nowrap;
}
.cardValue{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}
.statSeeAll{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #005DD6;
  cursor: pointer;
  margin-top: 0px;
}
.noData{
  font-family: 'geomanistregular';
  font-size: 16px;
  color: black;
}
@media (min-width:770px) and (max-width:992px){
  .statSeeAll{
    margin-top: 22px;
  }
}
@media (min-width: 992px){
  .statSeeAll{
    margin-top: 0px;
  }
}
.statsCard{
  background: #EFEFF1;
  border-radius: 12px;
  padding: 16px;
  margin: 0px 0px 20px 0px;
  /* width: 180px; */
}

@media (min-width: 600px) and (max-width: 768px){
  .statsModal :global(.modal-body){
    width: 475px;
  }
  .statsTable tr td:nth-child(2), .statsTable tr th:nth-child(2){
    padding-left: 40px;
   }
}

@media (min-width: 768px){
  .statsModal :global(.modal-body){
    padding: 0px 40px;
    width: 685px;
    overflow-y: auto;
  }
}

@media (max-width: 600px){
  .statsModal :global(.modal-body){
    width: 100%;
  }
  .statsCard{
    width: auto;
    height: 80%;
  }
  .statsTable tr td:nth-child(2), .statsTable tr th:nth-child(2){
    padding-left: 20px;
  }
  /* Due to custom modal and holding table restriction giving below bottom margin, 
  can be removed if needed and make sure able to scroll till end in responsive*/
  .responsiveMargin{
    margin-bottom: 42px;
  }
}
@media (max-width: 580px){
  .statsHeader{
    top: -1px;
  }
  .statsNavbar{
    top: 51px;
  }
  .statsTable tr td:first-child, .statsTable tr th:first-child{
    padding-right: 10px;
  }
}
@media (max-width: 425px){
  .statsModal :global(.modal-body){
    padding: 0px 20px;
  }
  .statsTable tr td:nth-child(2), .statsTable tr th:nth-child(2){
    padding-left: 20px;
   }
}