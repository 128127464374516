/* .mobile-modal.fade .mobile-modal-dialog {
  transform: translate3d(0, 20vh, 0);
}

.mobile-modal.in .mobile-modal-dialog {
  transform: translate3d(0, 0, 0);
}

.mobile-modal-dialog {

  width: auto;
  margin: 0rem;
  pointer-events: none;
}

.mobile-modal-content {
  border-radius: 30px 30px 0px 0px;
}

.mobile-modal-body {
  padding: 0px;
}

.fin {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  letter-spacing: 0.6px;

  color: #1e1f24;
}

.billion {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.6px;

  color: #626777;
}

.mobile-row {
  margin-right: 0px;
  margin-left: 0px;
}

.mobile-col {
  padding-right: 0px;
  padding-left: 0px;
}

.mobileTableWidth {
  min-width: 190px;
  width: 190px;
}

.overflowTag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  max-width: 150px;
}

.mobile-mobileNav a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 30px;
}

.mobile-table th {
  padding: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.6px;

  color: #505562;

  top: 0;
}

.mobile-table td {

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #030f23;
}

.mobile-btn {
  padding: 0rem 0rem !important;
}

.mobile-table-header {
  background: #f7f7f8;

  position: sticky;
  top: 0;
}

.stickyHeader {
  position: sticky;
  left: 0;
  background: #F7F7F8
} */



.fin{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;

letter-spacing: 0.6px;

color: #1E1F24;
}

.mobileCss{
  white-space: nowrap;
font-family:'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: right;
letter-spacing: 0.6px;
color: #005DD6;
}

.mobileCss:focus{
	box-shadow: none;
	outline: none;
}

.billion{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #626777;
white-space: nowrap;
}

.finLink{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
}

.finTableHeader{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.6px;
color: #505562;
}
.overflowTag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  max-width: 150px;
}
.finTableData{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #030F23;
}

.mobileTableWidth {
  min-width: 190px;
  width: 190px;
}

.active{
  font-family:'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #005DD6;
}

.inActive{
  font-family:'geomanistbook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #161D28;
  opacity: 0.5;
}
