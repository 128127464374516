.inputContainer{
   width:100%;
   margin-bottom:0px;
}
.inputContainer :global(.form-control){
  background: #FFFFFF;
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
  padding: 10px 12px 10px 40px;
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  height: 40px;
}


.label {
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3C404A;
  margin-bottom:5px;
  padding-left:4px;
}

.inputContainer :global(.form-control:focus){
	box-shadow: none;
	border:1.5px solid #3C404A;
}

.inputContainer :global(.form-control::-webkit-input-placeholder){
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  text-transform:none;
}

.inputContainer input::-moz-placeholder {
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  text-transform:none;
}


/* .inputs.is-invalid:focus {
	box-shadow: none;
}

.inputs.is-valid:focus {
	box-shadow: none;
} */

.inputContainer :global(.form-control.is-invalid) {
	background-image: url('../../../public/images/newOnboarding/errorState.svg');
	background-position: right 12px center;
  border: 1.5px solid #FA274A;
  background-repeat: no-repeat;
}

.inputContainer :global(.form-control.is-valid) {
	background-image: url('../../../public/images/newOnboarding/clearState.svg');
	/* border: 2px solid rgba(22, 37, 66, 0.3); */
  border: 1.5px solid #D3D4DA;
	background-position: right 12px center;
  background-repeat: no-repeat;
}

/* @media only screen and (max-width: 768px) {
	.form-focus .form-control {
		height: 62px;
	}
}

@media only screen and (max-width: 480px) {
	.form-focus .form-control {
		height: 48px;
	}
	.form-focus .focus-label {
		top: -11px;
		padding-top: 0px;
	}
} */

.errorState{
font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #FA274A;
}


.icon{
  width:16px;
  position:absolute;
  top:12px;
  left:12px;
}
