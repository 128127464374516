@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.sidebar {
  z-index: 100;
	top: 60px;
	right: 0%;
	position: fixed;
	width: 320px;
	height: 100vh;
	background-color: white;
	transition: 0.8s ease;
	border-left: 1px solid #f0efef;
	position: fixed;
}

@media(min-width:992px) {
	.sidebar {
		top:80px;
	}
}

.noSideBar {
	position: fixed;
	right: -320px;
	height: 100%;
	background-color: white;
	transition: 0.5s ease;
}

.sidebar ul a {
	display: block;
	height: 100%;
	width: 100%;
	line-height: 65px;
	font-size: 20px;
	color: black;
	padding-left: 40px;
	box-sizing: border-box;
	border-top: 1px solid red;
}

.noLinkStyle{
  height: 100%;
	width: 100%;
	line-height: 0px !important;
	font-size: 0px !important;
	color: black !important;
	padding-left:0px !important;
	box-sizing: border-box;
	border-top: none !important;
}

#check {
	position: absolute;
	right: 300px;
	display: none;
}
.close {
	position: absolute;
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
	z-index: 1111;
	right: 195px;
	top: 100px;
	cursor: pointer;
}
.close:hover {
	opacity: 1;
}
.close:before,
.close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}
.close:before {
	transform: rotate(45deg);
}
.close:after {
	transform: rotate(-45deg);
}
.dot {
	height: 25px;
	width: 25px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	cursor: pointer;
	right: 300px;
}

#check:checked ~ .sidebar {
	right: 100;
}

.sideBarHeader {
	background: #f7f7f8;
}

.head {
	color: #1e1f24;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	letter-spacing: 0.6px;
}
.newhead {
	color: #1e1f24;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	cursor: pointer;
	color: #505562;
}

.closeButton {
	right: 300px;
	position: absolute;
	background: #ffffff;
	border: 1px solid #f0efef;
	width: 40px;
	height: 40px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	top: 35px;
	border-radius: 50%;
	z-index: 1111;
	outline: none !important;
	transition: 0.8s;
}
.hideCloseButton {
	display: none;
}
.unclicked {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	cursor: pointer;
}
.plus {
	font-weight: 300;
	font-size: 12px;
}
.mobileWatchList {
	display: none;
}

.PlusImage{
  margin-right:7px;
}

.addWatchlistTitleText{
  font-family:"geomanistMedium";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #1E1F24;
}
.addWatchlistSubTitleText{
  font-family: "geomanistRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #505562;
}

@media (max-width: 700px) {
	.desktopWatchlist {
		display: none;
	}
	.mobileWatchList {
		display: block;
	}
	.mobileAddButton {
		text-align: center;
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		height: 200px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 75%);
	}
	.AddButtonMobile {
		background: #efeff1;
		border-radius: 6px;
		padding: 15px;
		/* height: 36px; */
		border: none;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.6px;
		color: #1e1f24;
		position: relative;
		font-family: 'geomanistbook';
		left: 0px;
		right: 0px;
		top: 100px;
	}
	.AddButtonMobile:focus {
		outline: none;
	}
	.AddPlus {
		margin-right: 10px;
	}
	.backarrowRow {
		/* display: inline-block; */
		padding-left: 30px;
		padding-top: 25px;
	}
	.WatchlistHeading {
		text-align: center;
		display: inline-block;
		position: absolute;
		left: 20%;
		right: 20%;
		font-family: 'geomanistBook';
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		letter-spacing: 0.6px;
		color: #1e1f24;
    padding-bottom: 10px;
	}
	.addFundsSearchBar {
		padding-left: 20px;
    padding-right: 20px;
    padding-top:5px;
	}
  .mobileWatchListBody{
    height: 600px;
  }
}

@media(min-width:700px) {
	.desktopWatchlist {
		display: block;
	}
}

@media (min-width: 470px) and (max-width:580px) {
  .mobileAddButton {
		text-align: center;
		position: absolute;
		bottom: -20px;
		left: 0px;
		right: 0px;
		height: 200px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 75%);
	}
}

@media (min-width: 320px) and (max-width:400px) {
  .mobileAddButton {
		text-align: center;
		position: absolute;
		bottom: 20px;
		left: 0px;
		right: 0px;
		height: 250px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 75%);
	}
}
