@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 412px;
}

.title {
  margin-top: 20px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
  margin-left: 60px;
}
.subTitle{
  margin-top: 8px;
  padding-left: 45px;
  padding-right: 35px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: center;
letter-spacing: 0.6px;
color: #505562;
}

@media (max-width: 768px) {
	.card {
		width: 350px;
	}
  .title {
    margin-left:10px;
  }
}

@media (max-width:576px){
  .card{
    width: 100%;
  }
  .title{
    margin-left: 30px;
  }
}

@media (max-width:375px){
  .title{
    margin-left: 20px;
  }
}

@media (max-width:320px){
  .title{
    font-size: 20px;
	line-height: 30px;
    margin-left: 20px;
  }
}
