@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.headerDropdown {
    font-family: 'geomanistBook';
    font-weight: 400;
}

.headerDropdown p {
    margin: 0px;
}

.headerDropdown :global(.dropdown-toggle::after) {
    display: none !important; 
}

.headerDropdown :global(.dropdown-menu) {
    overflow: hidden !important;
    transform: translate3d(0px, 45px, 0px) !important;
    padding: 0!important;
    border-radius: 6px !important;
    font-size: 14px;
    border: 1px solid #F7F7F8 !important;
    box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02)!important;
}

.headerDropdown :global(.dropdown-menu-right) {
    transform: translate3d(0px, 55px, 0px) !important;
}

.headerDropdown :global(a:active) {
    background-color: white
}

.headerDropdown :global(.dropdown-item) {
    padding: 0px !important;
}

.headerDropdown :global(.dropdown-item:hover) {
    text-transform: none !important;
}

.headerDropdown :global(.dropdown-divider){
    padding: 0px;
    margin: 0px;
}

.menuItemContainer {
    padding: 6px 0px;
    display: flex;
    flex-direction: column;
}

.menuItemContainer p {
    margin: 0px;
}

.tabContainer p {
    margin: 0px;
}

.dropdownButton {
    padding: 8.75px 14.25px 7.25px 12px;
    display: flex;
    gap: 9.75px;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    color:#767C8F !important;
    background-color: white !important;
    border: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    margin: 0px;
}

.buttonBackground {
    padding: 8.75px 14.25px 7.25px 12px;
    background-color: #F7F7F8 !important;
    border-radius: 6px;
    display: flex;
    gap: 9.75px;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    color:#767C8F !important;
    border: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    margin: 0px;
}

.dropdownHeader {
    font-size: 16px;
    line-height: 24px;
}

.dropdownText {
    font-size: 14px;
    line-height: 20px;
}

.textBlack {
    color:#3C404A;
}

.textGrey {
    color:#767C8F;
}

.textBlue {
    color:#0061D3;
}

.textGreen {
    color:#0DC014;
}

.textOrange {
    color:#FA5D05;
}

.textRed {
    color:#FA274A;
}

.menuContainer {
    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: center;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    padding: 10px 32px 10px 12px;
}

.tabContainer {
    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: center;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    padding: 16px 0px 16px 12px;
}

.menuImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F7F7F8;
    border-radius: 4px;
    padding: 11.67px;
}

.menuContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.dropdownRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 32px 12px 12px;
}

.dropdownRow p {
    margin: 0px;
}

.profileName {
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #EFEFF1;
	color:#767C8F;
	font-size: 14px;
	font-weight: 400;
	border-radius: 50px;
	border: transparent;
	font-family: 'geomanistBook';
}

.profileHeader {
    padding: 16.75px 24px 16px 16px;
    cursor: default !important;
}

.profileHeader:hover {
    background-color: white !important;
}

.navbarTabs {
    width: 100%;
    margin-top: 24px;
    display: flex;
    background-color: #F7F7F8;
    justify-content: center;
    gap:8px;
    align-items: center;
    border-radius: 8px;
    padding:6px;
    height: 40px;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: none !important;
}


.navbarTabs:global(.nav-tabs .nav-link.active ) {
    background-color: white;
    border-radius: 4px;
    border: none !important;
    padding: 4.75px 5.42px;
    color:#0061D3;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    flex-grow: 1;
}

.navbarTabs:global(.nav-tabs .nav-link) {
    border: none !important;
    color:#3C404A;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    flex-grow: 1;
}

.navbarTabs:global(.nav-tabs) {
    border: none !important;
}

.tabMenuItem {
    margin-top: 16px;
}

.tabMenuItem a {
    text-decoration: none !important;
}

.tabDivider {
	width: 100%;
	height: 1px;
    margin-top: 8px;
	background-color: #EFEFF1;
}

.menuDisabled {
    cursor: default;
    opacity: 0.65;
    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: center;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    padding: 10px 32px 10px 12px;
}

.tabDisabled {
    cursor: default;
    opacity: 0.65;
    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: center;
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 14px;
    padding: 16px 12px;
}

.tabDisabled p {
    margin: 0px;
}