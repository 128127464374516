@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.reviewCard {
	position: absolute;
	width: 352px;
	/* height: 606px; */
	/* left: 928px;
	top: 175px; */
	border: 0px;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
}

.backText {
	font-family: 'geomanistbook';
	padding-left: 12px;
	padding-top: 1px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #1e1f24;
}

.reviewOrder {
	margin: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.stockName {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.stockPrice {
	text-align: end;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #13b948;
}

.orderInfoTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.borderGrey {
	padding-bottom: 13px;
	/* padding-right: 15px;
	padding-left: 15px; */
	border-bottom: 1px solid #efeff1;
}

.orderInfo {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.enterSpinText {
	justify-content: center;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
}

.spinInput {
	border: none;
	text-align: center;
	width: 40px;
	height: 48px;
	background: #efeff1;
	border-radius: 8px;
	padding-top: 7px;
}

.spinInput::placeholder {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #d3d4da;
}

.buyButton {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	border-color: #13b948;
	width: 288px;
	height: 64px;
	background: #13b948;
	border-radius: 8px;
	box-shadow: none;
}

.buyButton:hover {
	color: #ffffff;
	background: #13b948;
	border-color: #13b948;
	box-shadow: none;
}

.buyButton:enabled {
	background: #13b948;
	border-color: #13b948;
	box-shadow: none;
}

.sellButton {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	border-color: #fa5d05;
	width: 288px;
	height: 64px;
	background: #fa5d05;
	border-radius: 8px;
	box-shadow: none;
}

.sellButton:hover {
	color: #ffffff;
	background: #fa5d05;
	border-color: #fa5d05;
	box-shadow: none;
}

.sellButton:enabled {
	background: #fa5d05;
	border-color: #fa5d05;
	box-shadow: none;
}

.note {
	padding-left: 5px;
	padding-top: 3px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #505562;
}

.noteDiv {
	margin: 0px;
	display: flex;
	flex-wrap: unset;
}

.error {
	background: #f7f7f8;
	border-radius: 6px;
	margin-left: 15px;
	margin-right: 15px;
	width: 100%;
	height: 48px;
}
.errorText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #505562;
	text-align: left;
}
@media (max-width:992px){
	.reviewCard {
	  position: relative ;
	  width: 100%;
	  height: 100%;
	  /* left: 928px;
	  top: 175px; */
	  border: 0px;
	  background: #ffffff;
	  box-shadow: 0px;
	  border-radius: 0px;
	  margin-left: 0px;
	}
	.reviewDiv{
	  margin-top: 200px;
	}
  }
@media (min-width:992px) and (max-width:1024px ){
.reviewCard {
	width: 310px;
  margin-left: 30px;
}
}