
@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.title{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;

letter-spacing: 0.6px;
color: #1E1F24;
}

.subTitle{
  margin-top: 4px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #9096A8;
}

.marginBottom{
  margin-bottom: 80px;
}

.marginConatiner{
  margin-top: 48px;
}

.seeMore{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #005DD6;
cursor: pointer;
}

.rowSeeMore{
  justify-content: center;
  margin-top: 32px;
}

.trendingSlider :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;
}

.trendingSlider :global(.slick-slide) {
  padding-right: 16px !important;
}

.trendingSlider :global(.slick-next) {
	display: none !important;
}
.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .title{
    font-size: 20px;
  }
  /* .trendingSlider {
		height: 250px;
	} */
  .stockLength{
    text-align: end;
  }
  .fixedBottom{
    position: sticky;
    margin-left: 0px;
    margin-right: 0px;
    height: 48px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px -15px 10px -15px #E5E5E5
  }
  .sortTitle{
    font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #3C404A;
  }
  .sortColumn{
    text-align: center;
    padding-top: 11px;
  }
  .cardtopMargin {
    margin-top: 25px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .mobileMargin{
    margin-bottom: 80px;
  }
  .ResponsiveModalContainer{
		display: block;
		padding:20px;
		position: fixed;
		width: 50;
		width: 100%;
		height: 100vh;
		background: white;
		top: 0px;
		left: 0px;
	}
  .mobileScroll{
    overflow-y: scroll;
  }
}

@media (min-width: 700px) and (max-width: 900px) {
	/* .trendingSlider {
		height: 250px;
	} */
  .trendingSlider :global(.slick-slide) {
		/* width: 290px !important; */
    padding-right: 16px !important;
	}
}

@media (min-width: 700px) and (max-width: 1200px) {
  .showExplore{
    display: none;
  }
}
@media (min-width: 1200px) {
  .showExplore{
    display: block;
  }
}
@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}
