.selectionBox{
  padding:24px 32px 24px 32px;

  background: #FFFFFF;
  border: 1px solid #F7F7F8;
  box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.3), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
  border-radius: 16px;
}

.title{
  text-align:center;
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3C404A;
}

.subTitle{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
}

.buttonWidth{
  width:80%;
}
.buttonFlex{
  display:flex;
  justify-content: space-evenly;
}

.uploadedImage{
  height:200px;
  width:200px;
}

.cardDiv{
  margin: auto;
  margin-top: 80px;
  min-height: calc(100vh - 285px);
}


