@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.profileCard {
	width: 280px;
	height: 329px;
	background: #ffffff;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
}

.amount {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.amountText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

.amountSection {
	margin: 0px;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 24px;
	border-bottom: 1px solid #efeff1;
}

.profileList {
	margin: 0px;
	padding-top: 10px;
	padding-bottom: 8px;
	padding-left: 32px;
	padding-right: 32px;
	cursor: pointer;
}

.listItem {
	padding-left: 10px;
	padding-top: 2px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	cursor: pointer;
}

.profileList:hover {
	background: #f7f7f8;
}

.link:hover {
	text-decoration: none !important;
}