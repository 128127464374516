@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.marginTop {
	margin: 0px;
	margin-top: 40px;
}

.title {
	margin: 0px;
	margin-top: 64px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	display: flex;
	justify-content: space-between;
}

.lrsId {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.statusTime {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #505562;
}

.end {
	text-align: end;
}

.status {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.statusText {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.transactionDetail {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	cursor: pointer;
}

.expandIcon {
	margin-left: 6px;
	width: 8.75px;
	height: 4.58px;
	cursor: pointer;
}

.detailTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.detailValue {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}

.history {
	width: 100%;
	height: 12px;
	background: #efeff1;
	border-radius: 16px;
	position: relative;
}

.completed {
	/* width: 100px; */
	height: 12px;
	background: #13b948;
	border-radius: 16px;
}

.progressBarImage {
	position: absolute;
	top: -19px;
	left: -2%;
}

.progressBarComplete {
	position: absolute;
	left: 97%;
	top: -16px;
}

.lrsIdBox{
	display: inline-block;
    margin-left: 10px;
	background: #D3D4DA;
	border-radius: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	padding-right:10px;
	padding-left: 10px;
}

.remittanceContainer{
	padding-left: 150px;
    padding-right: 150px;
}
.noHistory{
  display: flex;
  justify-content: center;
}
.historyImage{
  margin-top: 64px;
  height: 264px;
  width: 264px;
}
.fundText{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6px;
  color: #FFFFFF;
}
.fundsDiv{
  background: #1E1F24;
  border-radius: 8px;
  display:inline-block;
  padding:14px;
  padding-left:24px;
  padding-right:24px;
  cursor:pointer;
  margin-top:24px;
}
.disabeldFundsDiv{
	cursor: default;
	opacity: 0.6;
}
.nothing{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #505562;
  margin-top: 8px;
}

.timeToFund{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #000000;
  margin-top: 16px;
}

.distance{
  margin-top: 24px;
}

.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}

@media (max-width: 576px) {
	.remittanceContainer{
		padding-left: 0px;
		padding-right: 0px;
	}
	.title{
		margin-bottom: 20px;
	}
}

@media (max-width: 990px) {
  .remittanceContainer{
    padding-left: 0px;
      padding-right: 0px;
  }
}

@media (max-width: 768px) {
  .remittanceContainer{
    padding-left: 0px;
      padding-right: 0px;
  }
  .historyImage{
    margin-top: 48px;
    height: 280px;
    width: 280px;
  }
  .fundsDiv{
    margin-top: 16px;
  }
  .nothingspan{
    display: block;
  }
  .distance{
    margin-top: 32px;
  }
  .title {
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
	.sideBarMobileHide {
	  display: block;
	}
  }
