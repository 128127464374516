@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.notificationCard {
	position: relative;
	top:20px;
	z-index: 1000;
	width: 280px;
	background: #ffffff;
	border: 1px solid #F7F7F8;
	box-sizing: border-box;
	box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 6px;
}

.seeMoreNotificationCard{
	position: relative;
	z-index: 1000;
	width: 500px;
	background: #ffffff;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
	height: 100vh;
    top: -65px;
    left: 20px;
}

.markAllRead{
	font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* line-height: 20px; */
    letter-spacing: 1.4px;
    color: #005DD6;
    font-weight: 600;
	text-transform: none;
	text-align: center;
	margin-top:4px;
	cursor: pointer;
}

.cardPadding {
	padding: 8px 0px 12px 0px;
}

.title {
	font-family: 'geomanistBook';
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.3px;
	padding: 0px;
  color: #3C404A;
}

.time {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	text-align: end;
	padding: 0px;
}

.desc {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	padding: 0px;
}

.border {
	margin: 0px;
	padding: 14px 18px 14px 16px;
	border-bottom: 1px solid #efeff1;
	width: 100%;
  min-height: 110px;
}

.seeMoreButton {
	width: 89px;
	height: 28px;
	background: #eef4fb;
	border-radius: 32px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
	padding: 4px 12px 4px 12px;
	border: none;
	box-shadow: none;
}

.seeMoreButton:hover {
	background: #eef4fb;
	color: #005dd6;
	border: none;
	box-shadow: none;
}

.seeMoreButton:focus {
	background: #eef4fb;
	color: #005dd6;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
}

.seeMoreButton:not(:disabled):not(.disabled):active {
	background: #eef4fb;
	color: #005dd6;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
}

.seeMoreButton:not(:disabled):not(.disabled):active:focus {
	background: #eef4fb;
	color: #005dd6;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
}

.notificationTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	padding: 0px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.closeImage {
	padding: 0px;
	text-align: end;
}

.backarrowRow{
  display: none;
}

.webSeeMoreButton{
  display: flex;
}

.notificationModalBody{
  width:400px;
  overflow-y: scroll;
  height: 90vh;
}

.notificationHeader{
	margin: 0px;
  padding: 0px 16px 24px 16px;
}

.header{
  font-family: 'geomanistBook';
  color: #3C404A;
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
}

.AllReadButton{
  font-size: 14px;
  line-height: 20px;
  margin-top:6px;
  cursor: pointer;
  letter-spacing: 0.6px;
  color: #005DD6;
  font-family: 'geomanistmedium';
}

.NoNotificationRow{
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  width: 100%;
  display: inline-block;
  margin-top: 60px;
}

.noNotificationImage{
  width: 200px;
  height: 131px;
}

.upToDateText{
  margin: 0px;
	padding-bottom: 20px;
	padding-left: 24px;
	padding-right: 24px;
	width: 100%;
  padding-top:16px;
  text-align: center;
  display: inline-block;
  margin-bottom: 115px;
}

.firstText{
  font-family: 'geomanistBook';
  color: #3C404A;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 4px;
}

.secondText{
  font-family: 'geomanistRegular';
  color: #767C8F;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.greyBackground{
  background: #F7F7F8;
  margin: 0px;
	padding-bottom: 20px;
	padding-left: 24px;
	padding-right: 24px;
	border-bottom: 1px solid #efeff1;
	width: 100%;
  padding-top:17px;
  height:110px;
}

.seeMoreText {
	font-family: 'geomanistBook';
	color:#0061D3;
	font-size: 14px;
	line-height: 20px;
	padding: 14px 0px 0px 16px;
}

@media (max-width: 700px) {


  .backarrowRow{
    display: block;
    padding-top:50px;
    padding-left:30px;
  }

  .webSeeMoreButton{
    display: none;
  }

  .webNotification{
    display: none;
  }






}

@media(max-width:992px) {
  .notificationDropdown {
    display: none;
  }

    
  .mobileNotificationHeader{
    align-items: center;
    padding: 20px 16px;
    margin:0px;
  }

}

@media(min-width:992px) {
  .notificationDropdown {
    display: block;
  }
}

.notificationDropdown :global(.dropdown-toggle::after) {
    display: none !important; 
}

.notificationDropdown :global(.dropdown-menu) {
    width: 280px;
    overflow: hidden !important;
    transform: translate3d(0px, 45px, 0px) !important;
    padding: 0!important;
    border-radius: 6px !important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'geomanistBook';
    border: 1px solid #F7F7F8 !important;
    box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02)!important;
}

.notificationDropdown :global(.dropdown-menu-right) {
    transform: translate3d(0px, 40px, 0px) !important;
}

.notificationDropdown :global(a:active) {
    background-color: white
}

.notificationDropdown :global(.dropdown-item) {
   background-color: white;
    padding: 0px !important;
    white-space: pre-wrap;
}

.notificationDropdown :global(.dropdown-item:hover) {
    text-transform: none !important;
	  background-color: white;
}

.notificationDropdown :global(.dropdown-divider){
    padding: 0px;
    margin: 0px;
}

.notificationButton {
	background-color: white !important;
  border: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  margin: 0px;
	padding:0px 14.25px 0px 0px;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 16px;
  gap:6px;
  border-bottom: 1px solid #EFEFF1;
  height: auto;
}

.notificationContainer p {
  margin: 0px;
}

.titleAndTime {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.textBlack {
  font-family: 'geomanistBook';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color:#3C404A;
}

.textGrey {
  font-family: 'geomanistRegular';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color:#767C8F;
}

.allNotifications {
  padding:14px 0px 12px 16px;
  color:#0061D3;
}

.allNotifications:hover {
  background-color: #F7F7F8;
}


@media(max-width:992px) {
  .notificationCard {
    display: block;
    position: relative;
    z-index: 1000;
    width: 100%;
    background: #ffffff;
    border: 1px solid #efeff1;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
    border-radius: 0px;
    height: 100vh;
    top: 0px;
    left: 0px;
  }  
  
  .crossImage{
    text-align: right;
  }

  .crossImage img {
    vertical-align: baseline;
  }

  .mobileCardPadding{
    padding: 8px 0px 12px 0px;
    height: 90vh;
    overflow-y: scroll;
  }
}

@media(min-width:992px) {
  .notificationCard {
    display: none;
  }
}

.bellIconCircle{
  position: absolute;
  top:1px;
  float: right;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #E81D1D;
  margin-left: 11px;
}


