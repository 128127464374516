@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.toolTipText {
    font-family: 'geomanistBook';
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
}

.cashMgmtToolTip :global(.tooltip-inner){
    padding: 10px;
    background-color:  #FFFFFF;
    color: #3C404A;
    border-radius: 8px;
    opacity: 1 !important;
    box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
}

.cashMgmtToolTip :global(.arrow::before){
    border-top-color: #FFFFFF;
}

.toolTipHead{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3C404A;
  margin: 0px;
}
