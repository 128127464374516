@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.buttonDiv{
  background: #FFFFFF;
  box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 16px 16px 0px 0px;
  text-align: center;
}
.buyButton{
padding: 14px;
padding-left: 40%;
padding-right: 40%;
font-family: 'geomanistMedium';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
text-align: center;
letter-spacing: 0.6px;
color: #FFFFFF;
background: #0DC014;
border-radius: 4px;
border: none;
white-space: nowrap;
}
.buyButton:focus {
	box-shadow: none;
	outline: none;
}
.buysellDiv{
  /* width: 375px; */
  width: auto;
  height: 440px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 56.77%);
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 210px;
}
.innerDiv{
  width: auto;
  height: 200px;
}
.circularDiv{
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: #EFEFF1;
  display: flex;
  justify-content: center;
  margin: auto;
}
.buyPermannet{
white-space: nowrap;
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
align-items: center;
text-align: center;
letter-spacing: 0.6px;
color: #1E1F24;
background: #EFEFF1;
border-radius: 8px;
border: none;
cursor: pointer;
height: 48px;
width: auto;
padding-left: 20%;
padding-right: 20%;
margin: 10px;
}
.buyPermannet:focus {
	box-shadow: none;
	outline: none;
}
.cancelResponsive{
  margin-top: 20px;
}
.buysellModal{
  z-index:1040;
}

.buysellModal :global(.modal-dialog) {
  width:100%;
  margin:0px;
}
.buysellModal :global(.modal-content) {
  height: 100vh;
}

@media (min-width: 320px ) and (max-width:425px){
  .cancelResponsive{
    margin-top: 20px;
  }
  .buyButton{
    padding: 14px;
    padding-left:30%;
    padding-right: 30%;
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    background: #0DC014;
    border-radius: 4px;
    border: none;
    white-space: nowrap;
    }
  /* .bodyResponsive :global(.modal-dialog) {
    margin: 0px !important;
  } */
}


