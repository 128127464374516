@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.themeTitle {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
  /* checked */
}
.themeColumn {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 1%;
	padding-top: 10px;
	padding-bottom: 10px;
	background: #f6f7f7;
	border-radius: 60px;
	margin-top: 16px;
}
.themeText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #021241;
  white-space: nowrap;
  cursor: pointer;
  /* Checked */
}
.themeDiv{
  margin-top: 8px;
}

@media (max-width:780px){
  .themeTitle {
    font-size: 20px;
    line-height: 28px;
  }
  .themeDiv{
    padding-left:15px;
  }
  .themeColumn {
    margin-top: 20px;
  }
  .themeDiv{
    margin-top: 4px;
    padding-left: 10px;
  }
  .themeText {
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.6px;
    text-transform: capitalize;
    color: #021241;
    white-space: pre-wrap;
    cursor: pointer;
  }
}
