@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.descriptiontitle {
  margin-bottom: 16px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	letter-spacing: 0.6px;

	/* Neutral/900 */

	color: #1e1f24;
}


.description {
  height: 113px;
	background: linear-gradient(#626777, #ffffff);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	width: 95%;
}

.descriptionTotal {
  min-height: 113px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.title {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #626777;
}

.marginBottom {
	border-bottom: 1px solid #efeff1;
}

.value {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	padding-bottom: 24px;
}

.seeMore {
	margin-top: 20px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	cursor: pointer;
}

.methodology {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.methodologyDetail {
	margin-top: 16px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
}
.themeCss {
	margin-top: 40px;
}
.sell {
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid #efeff1;
}
.sellValue {
  margin-top: 4px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.sellChange {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #0dc014;
}
.sellStack {
  cursor: pointer;
	width: 130px;
	height: 40px;
	justify-content: center;
	align-items: center;
	padding: 10px 30px;
	background: #ffffff;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09);
	border-radius: 8px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.4px;
	color: #1e1f24;
}
.modalWidth {
	width: 512px;
}

.tooltipImage {
	padding-left: 10px;
	padding-bottom: 2px;
	cursor: pointer;
}

.ticker {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
}
.snp {
	margin-left: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #626777;
}

.graphLegend{
  /* margin-bottom: 4px; */
  right: 23px;
  bottom: 17px;
  position: absolute;
}
.legendTitle{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #1E1F24;
}
.legendCircle{
  margin-right: 8px;
  display: inline-block;
  width: 8px;
height: 8px;
background: #1E1F24;
border-radius: 50%;
}
.disclaimer{
  margin-top: 40px;
}
.disclaimerTitle{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.4px;
color: #1E1F24;
}
.disclaimerText{
  margin-top: 8px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 160%;
letter-spacing: 0.6px;

color: #626777;
}

.activeValue {
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.4px;
color: #1E1F24;
margin-right: 39px;
border-bottom: 2px solid #1E1F24;
padding-bottom: 4px;
cursor: pointer;
}

.nonactive{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #9096A8;
margin-right: 39px;
cursor: pointer;
}

.graphRow{
  margin-top: 32px;
}

@media (min-width: 320px) and (max-width: 576px) {
  .graphLegend{
    bottom: 352px;
    right: 115px;
  }
  .descriptiontitle {
    margin-bottom: 16px;
  }
  .value{
    font-size: 16px;
    color: #1E1F24;
    font-family: 'geomanistbook';
  }
  .downloadFactsheet{
    margin-left: 10px;
    font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #505562;
  }

  .modalWidth {
    width: 100%;
  }
  .methodology {
    margin-left: 0px;
    margin-right: 0px;
  }
  .methodologyDetail{
    margin-left: 0px;
    margin-right: 0px;
  }
  .themeCss {
    margin-top: 32px;
  }
  .sellStack{
    width: 100%;
    box-shadow: none;
    border: 1px solid #FA5D05;
    color:#FA5D05
  }
  .modalDialog{
    width: 100%;
    margin: 0px;
	max-width: 100%;
  }
  .modalDialog :global(.modal-content){
    height: 100vh;
  }
}

@media (min-width: 500px ) and (max-width:1000px){
  .modalWidth {
    padding-right: 40px;
    padding-left: 40px;
  }
}


.sellDisableMessage {
  display: inline !important;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.6px;
  color: #3C404A;
}
@media (min-width: 576px){
	.modalDialog {
		margin: 0 0 0 auto;
	}
}