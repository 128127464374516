@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.shariyaComplianceContainer {
    font-family: 'geomanistBook';
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    padding: 6px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shariyaComplianceContainer p {
    margin:0px;
}

.ratingContainer>span>span {
    margin-right:6px;
}

@media(min-width:768px) and (max-width:992px) {
    .ratingContainer img {
        width: 9px;
    }
}

@media(min-width:992px) {
    .ratingContainer img {
        width: 13.9px;
    }
}
  