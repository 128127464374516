@import '../../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../../public/geomanist/geomanistBook/stylesheet.css';

.noNomineeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}
.noNomineeContainer > div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}
.info{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #626777;
    text-align: center;
    margin-bottom: 20px;
}
.noNomineeButton {
    height: 50px;
}
.subHeading{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #152441;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
}
.nomineeInput  {
    margin-bottom: 30px !important;
}

.textInput, .textInput:focus{
    background: #efeff1;
    border-radius: 8px !important;
    height: 52px !important;
}
.textBorder{
    border: 0 !important;
}
.errorText{
    padding-left: 8px;
    font-family: 'geomanistregular';
    color: #e61446;
    padding-top: 4px;
    font-size: 14px;
}
.addButton{
    background: #005dd6 !important;
    border-radius: 8px;
    font-family: 'geomanistmedium';
    font-weight: 500;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.6px;
    border: none;
    box-shadow: none;
    color: #ffffff;
    height: 48px;
}
.plusSymbol::before{
    content: '+';
    font-size: 18px;
    margin-right: 4px;
}
.btn{
    margin-left: 12px;
    height: 48px;
    width: 120px;
}
.borderBottom {
	border-top: 2px solid rgba(22, 37, 66, 0.3);
    margin: 40px 0;
}
.section{
    margin-top:40px
}
.clickable{
    cursor: pointer;
}
.saveDisabled{
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
       ),
       #0061d3 !important;
}
.saveDisabled:hover{
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
       ),
       #0061d3;
}
.cancelButton, .cancelButton:hover{
    background: #efeff1 !important;
    border-radius: 6px;
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.6px;
    border: none;
    box-shadow: none;
    color: #1e1f24 !important;
}
@media (max-width:480px){
    .nomineeInput :global(.form-focus .focus-label){
        top: -4px;
    }
}
.popOverImage{
    margin-left: 5px;
    margin-top: 5px;
    height: 12px;
    vertical-align: top;
    cursor: pointer;
  }
.downloadLink{
    display: flex;
    align-items: center;
    color: rgb(0, 93, 214);
    cursor: pointer;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.saving{
    width: 60px;
    text-align: left;
    margin: auto;
}

/* DateInput css */
.dropdown{
    height: 52px !important;
    border-radius: 8px !important;
    border: none !important;
    background-color: rgb(239, 239, 241) !important;
}
.calendaricon {
	position: absolute;
	right: 43px;
	top: 18px;
	cursor: pointer;
}
.calendar {
	position: absolute;
	z-index: 105;
	top: 45px;
	width: 368px;
}
/* PhoneInput css */
.dropdownButton{
    width: 20px;
    outline: unset;
    border: none;
    background: inherit;
    font-family: 'geomanistRegular';
}
.phoneInput{
    display: flex;
    align-items: center;
    background: #efeff1;
    border-radius: 8px;
    padding-left: 8px;
}
.phoneInputText, .phoneInputText:focus{
    background: #efeff1;
    border: none;
    height: 52px !important;
    width: 88%;
    border-radius: 8px !important;
    border: none !important;
    padding-left: 8px !important;
}
.phoneLabel{
    padding-left: 35px;
}
