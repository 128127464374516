@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.navbar {
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
	padding-left:32px;
	padding-right: 32px;
	padding-top: 12px;
	padding-bottom: 12px;
}

.bigscreenHeader{
  background-color:white ;
  position: sticky;
  z-index: 1000;
  top: 0px;
  box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
}


.siteLogo {
	height: 32px;
}

.topLayer {
	/* margin-top: 35px; */
	position: absolute;
	z-index: 1000;
	top:64px;
}

.logoCol {
	margin-left: 1%;
}

.link:hover {
	text-decoration: none !important;
}

.link{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.logo {
	padding-left: 15px;
	/* position: absolute; */
	/* left: 2.22%;
	right: 88.89%;
	top: 25%;
	bottom: 25%; */
	/* background-image: url('../../public/images/group.png');
	background-repeat: no-repeat;
	background-size: contain; */
}

.marginHead{
  margin-left: 40px;
}

.head {
	/* display: flex; */
	margin-right: auto;
	font-family: 'geomanistregular';
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	/* text-transform: lowercase; */
	color: #505562 !important;
	cursor: pointer;
  width: 60px;
}

.head a {
	color: #505562 !important;
}

.head a:hover {
	color: #1e1f24;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
}

.pointers {
	padding-left: 140px;
}

.unhoveredDiv {
	margin-top: 5.22%;
	/* margin-bottom: 5.22%; */
	margin-left: 20%;
	font-family: 'geomanistregular';
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
}
.unhoveredDiv div {
	color: #505562;
}

.bellMargin{
  margin-left:40px;
}

.unhoveredDiv div:hover {
	/* margin-top: 5.22%;
	margin-bottom: 5.22%;
	margin-left: 20%;
	text-decoration: none;
	color: #1e1f24;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px; */
}

.ashwad {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-transform: none;
	letter-spacing: 0.6px;
	/* padding-left: 20px; */
	color: #1e1f24;
  cursor: pointer;
  margin-left: 34px;
}
.ashwadndarrow {
	margin-top: 1.5%;

	margin-right: 1%;
}

.arrow{
  margin-left:10px;
  cursor: pointer;
}

.notification {
	/* margin-top: 110%; */
	padding-right: 0px;
	padding-left: 0px;
}
.bellIconBottomFix{
  margin-bottom: 3px;
  cursor: pointer;
}
.search {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 95px;
}
.bellIcon {
	display: none;
}
.horizontalLine {
	display: none;
}
.NavbarRowMargin{
  margin-left: 141px;
}
.mobileHeader {
	display: none;
}

/* .navigationIcon{
	padding-left:0px;
	padding-right: 0px;
} */



.SearchbarMargin{
  margin-left:100px;
}

.bellIconCircle{
  position: absolute;
  float: right;
  top:1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #E81D1D;
  margin-left: 11px;
}

.navDropdownHeader p {
	margin:0px;
}
.navDropdownHeader:focus {
	outline: none;
	border: none;
}

.profileData {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.backArrowImage{
	position: relative;
	text-align: center;
	display: none;
}

@media (max-width: 700px) {
	.navbar {
		box-shadow: 0px 0px 0px;
		/* padding-right: 0px;
		padding-left: 0px; */
	}
	.search {
		margin-left: 30px;
	}
	.collapsibleBody {
		z-index: 1000;
		background-color: white;
		border-left: 20px solid rgba(30, 31, 36, 0.5) !important;
		margin-top: -50px;
    justify-content:end ;
	}
	.logo {
		padding-left: 15pxpx;
	}
	.navIcon {
		padding-right: 20px !important;
		border-color: rgba(0, 0, 0, 0) !important;
		float: right;
	}
	.bellIcon {
		padding-left: 100px;
		display: block !important;
	}
	.notification {
		display: none;
	}
	.pointers {
		padding-left: 0%;
		padding-top: 8px;
	}
	.horizontalLine {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		/* margin: 1em 0; */
		padding: 0;
		margin-left: 30px;
		margin-right: 30px;
	}
	.collapseCross {
		color: black;
	}
	.ashwad {
		display: none;
	}
	.arrow {
		display: none;
	}

	/* mobile screen updated css ishan 6/07/2021 */

	.mobileHeader {
		display: block;
		padding: 15px;
	}


	.offcanvas {
		min-height: 100%;
		-webkit-transition: all 0.25s ease-out;
		-o-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;
	}

	.offcanvasRight {
		right: 0;
	}

	.offcanvasRight:active {
		right: 300px;
	}

	.offcanvasRight .sidebarOffcanvas {
		right: -100%;
		-webkit-transition: all 0.25s ease-out;
		-o-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;
	}

	.offcanvasRight.active .sidebarOffcanvas {
		right: 0;
		border-left: 1px solid #e7e7e7;
	}

	.mobileHeaderNav {
		align-items: flex-start !important;
	}

	.iconMargin {
		margin-right: 10px;
	}

	.buttonBalance {
		padding-left: 0px;
		cursor: pointer;
		font-size: 13px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.itemDivider {
		border-bottom: 1px solid #f4f4f4;
		width: 100%;
		padding: 5px;
	}

	.mobileHeaderNav li {
		margin: 0px !important;
		text-align: start !important;
	}

	.sidebarOffcanvas {
		position: fixed;
		top: 56px;
		bottom: 0;
		width: 100%;
		padding: 0px 15px 15px;
		background-color: white;
		border-radius: 0;
		overflow-y: auto;
		z-index: 1000;
	}


	.crossImage {
    position: absolute;
    right:30px;
    top:30px;
    z-index: 1000;
	}
	.sideGreyLine {
		width: 10px;
		position: absolute;
		/* color:Red; */
		left: 0px;
		width: 20px;
		height: 120vh;
		background: #1e1f24;
		opacity: 0.5;
	}
	.marginHeaderFunctionality {
		margin-top: 60px;
    height: 100vh;
    overflow: auto;
	}
	.mobileLink {
		color: #505562;
	}
	.marginLinkMobile {
		margin-top: 20px;
	}
	.profileCardLink {
		margin-top: 15px;
		margin-left: 10px;
		color: #1e1f24;
	}
	.mobileSearchBar{
		display: block;
	}
	.backarrowRow{
		/* display: inline-block; */
		padding-left: 30px;
		padding-top:25px;
	}
	.WatchlistHeading{
		text-align: center;
		display: inline-block;
		position: absolute;
		left: 40%;
		right: 40%;
		font-family: 'geomanistBook';
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		letter-spacing: 0.6px;
		color: #1E1F24;
	}
	.searchInput {
		padding: 10.25px 13.25px 9.25px 35px !important;
		transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
		border-radius: 6px !important;
		width: 100%;
		background: #FFFFFF;
		border: 1.5px solid #D3D4DA !important;
	}
	.searchInput::placeholder {
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.6px;
		color: #767C8F;
		vertical-align: middle;
	}

	.searchInput:active,
	.searchInput:focus {
		background: #f7f7f8;
		border: 1px solid #d3d4da;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
		border-radius: 8px;
	}
	.backArrowImage{
		position: relative;
		text-align: center;
		display: flex;
	}
}

/* // Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... } */

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1250px) {
	/* .head {
		padding-left: 20px;
		width: 70px;
	} */
  .NavbarRowMargin{
    margin-left: 30px;
  }
  .SearchbarMargin{
    margin-left:50px;
  }
  .marginHead{
    margin-left: 20px;
  }
  /* .navbar{
    justify-content: flex-end;
  } */
  .collapsibleBody {
    justify-content:flex-end ;
	}
}

@media (min-width: 1300px) and (max-width: 1455px) {
  .NavbarRowMargin{
    margin-left: 80px;
  }
  .SearchbarMargin{
    margin-left:50px;
  }
  /* .navbar{
    justify-content: flex-end !important;
  } */
  .collapsibleBody {
    justify-content:flex-end ;
	}
}


/* @media (min-width: 1550px) {
	.navbar {
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
	padding-left:100px;
	}
}

@media (min-width: 1800px) {
  .navbar {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    padding-left:250px;
    }
} */

/* @media (min-width: 1700px) {
	.navbar {
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
	padding-left:200px;
	padding-right: 200px;
	}
} */

@media (min-width: 700px) and (max-width: 992px) {
	.mobileHeader{
	display: block;
	padding:15px;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 1000;
	}

	.crossImage{
		position: absolute;
		right:40px;
		top:30px;
		z-index: 1;
	}
	.sideGreyLine{
		width:10px;
		position: absolute;
		/* color:Red; */
		left:0px;
		width: 20px;
		height: 120vh;
		background: #1E1F24;
		opacity: 0.5;
	}
	.marginHeaderFunctionality{
		margin-top:40px;
    height: 100vh;
    overflow: auto;
	}
	.mobileLink{
		color: #505562;
	}
	.marginLinkMobile{
		margin-top:20px;
	}
	.profileCardLink{
		margin-top:15px;
		margin-left:10px;
		color:#1E1F24;
	}
	.mobileSearchBar{
		display: none;
	}

}
 @media (min-width: 1500px) {
   .navbar{
     max-width: 1500px;
     margin:auto;
     box-shadow: none;
   }
}

.navigationBar {
	margin: auto;
	max-width: 1150px;
	background-color: white;
	height: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0px 15px;
}

@media(min-width:992px) and (max-width:1200px) {

	.navigationBar {
		gap:30px;
	}

	.logoSearchContainer {
		gap:20px;
	}
}

@media(min-width:1200px) {

	.navigationBar {
		gap:88px;
	}

	.logoSearchContainer {
		gap:46px
	}

}

.logoSearchContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.navigationItemsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.horizontalDivider {
	background-color: #EFEFF1;
	width: 2px;
	height: 40px;
	margin: 0px 27px 0px 12.75px;
}

.navModal :global(.modal-dialog) {
	right: 0px !important;
	height: 100vh !important;
}

@media(max-width:375px) {
	.navModal :global(.modal-content) {
		overflow-y: scroll;
		overflow-x: hidden;
		height: 104vh !important;
	}
}

@media(min-width:375px) {
	.navModal :global(.modal-content) {
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100vh !important;
	}
}

.navModal :global(.modal-backdrop.show) {
	height: 100vh !important;
	width: 100% !important;
}

.navModal :global(.modal.show) {
	padding: 0px !important;
	height: 100vh !important;
}


@media(max-width:600px) {
	.navModal :global(.modal-dialog) {
		width: 100% !important;
		margin: 0px !important;
		padding: 0px !important;
	}

	.navModal:global(.fade.show .modal-dialog) {
		right: 0px !important;
		min-width: 100% !important;
		padding: 0px !important;
	}

}

@media(min-width:600px) {
	.navModal :global(.modal-dialog) {
		width: 400px !important;
		margin-right: 0px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding: 0px !important;
	}
}

.mobileNavbar {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.mobileNavigation {
	background-color: white;
    position: sticky;
    top: 0px;
    z-index: 1000;
	box-shadow: 0px 2px 16px rgba(2, 10, 19, 0.08);
}

.mobileNavigation img {
	cursor: pointer;
}

.mobileNavigationBar {
	display: flex;
	justify-content: flex-start;
	gap: 60px;
	align-items: center;
	padding: 0px 15px;
	height: 60px;
}

@media(max-width:992px) {
	.mobileNavigation {
		display:block;
	}

	.bigscreenHeader {
		display: none;
	}
}

@media(min-width:992px) {
	.mobileNavigation {
		display: none;
	}

	.bigscreenHeader{
		display: block;
	}
}

@media(max-width:600px) {
	.ResponsiveModalContainer {
		display: block;
		position: fixed;
		width: 100%;
		height: 115vh;
		background: white;
		top: 0px;
		right: 0px;
		z-index: 1000;
	}
}

@media(min-width:600px) and (max-width:992px) {
	.ResponsiveModalContainer {
		display: block;
		position: fixed;
		width: 400px;
		height: 100vh;
		background: white;
		top: 0px;
		right: 0px;
		z-index: 1000;
	}
}

@media(min-width:992px) {
	.ResponsiveModalContainer {
		display: none;
	}
}

.navbarButton{
	border:none;
	background-color: white;
	position: absolute;
	right: 15px;
}

.bellIconMobile {
	border:none;
	background-color: white;
	position: absolute;
	right:56px;
}

.watchListStar{
	display: block;
	right:100px;
	position: absolute;
}

.watchListSearch{
	display: block;
	right:135px;
	position: absolute;
}

@media(max-width:700px) {
	.tabletSearchBar {
		display: none;
	}

	.modalSearchBar {
		display: block;
	}
}

@media(min-width:700px) {
	.watchListStar{
		display: none;
	}

	.tabletSearchBar {
		display: block;
		padding-left: 30px;
	}

	.modalSearchBar {
		display: none;
	}
}

.searchIcon {
	visibility: visible;
	position: absolute;
	left: 25px;
	z-index: 1;
	padding: 6px 0px 0px 5px;
}

.profileMenu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	font-family: 'geomanistBook';
	font-size: 14px;
	font-weight: 400;
	background: #F7F7F8;
	border-radius: 12px;
	outline: none;
	border: none;
	color: #3C404A;
}

.navDropdownHeader {
	padding: 16.75px 20.83px 15.25px 16px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropdownRow {
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0px;
}

.dropdownRow p {
    margin: 0px;
}

.resultDivider {
	width: 100%;
	height: 1px;
	background-color: #EFEFF1;
}

.profileOptionsDropdown {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.profileOptionsDropdown a {
	text-decoration: none;
	color:#3C404A;
}
