@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 512px;
	height: 100px;
}

.title {
	margin-top: 200px;
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
