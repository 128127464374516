.navbar {
	width: 100%;
	background: #ffffff;
	padding-left: 160px;
	padding-right: 162px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.containerNav {
	background: #ffffff;
	border-bottom: 1px solid #efeff1;
	box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	position: sticky;
	top: 0px;
	z-index: 1000;
	margin: 0;
}

.subTitle {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-left: 0px;
}

@media (max-width: 768px) {
	.navbar {
		padding-left: 24px;
		padding-right: 17px;
	}
	.navbarImage {
		max-width: 80px;
	}
}

@media (min-width: 1440px) {
	.navbar {
		margin-left: auto;
		margin-right: auto;
		max-width: 1440px;
		/* padding-left: 0px;
		padding-right: 0px; */
	}
}

@media (max-width: 374px) {
	.navbar {
		flex-direction: column;
		align-items: flex-start;
	}
}

@media (min-width: 500px) and (max-width: 700px) {
	.navbar {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (min-width: 700px) and (max-width: 930px) {
	.navbar {
		padding-left: 160px;
		padding-right: 160px;
	}
}

@media (min-width: 1090px) {
	.navbar {
		padding-left: 160px;
		padding-right: 160px;
	}
}
