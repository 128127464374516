@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 840px;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
	border-bottom: 1px solid #efeff1;
	padding-bottom: 24px;
}
.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
}
.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.review {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.header {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #626777;
}
.data {
	margin-top: 4px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}
.pickup {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}
.description {
	margin-top: 8px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.change {
	margin-top: 16px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
}
.marginPickUp{
  margin-left: 285px;
}
.fixedDiv {
  border-top: 1px solid #efeff1;
  padding-top: 20px;
  margin-top: 25px;
}
@media (max-width: 768px) {
	.card {
		width: 95%;
	}
  .marginPickUp{
    margin-left: 0px;
  }
}

@media (max-width: 1024px) {
	.card {
		width: 95%;
	}
}

@media (min-width: 300px) and (max-width: 480px) {
  .card{
    width: 100%;
  }
  .marginPickUp{
    margin-left: 0px;
    margin-top: 16px;
  }
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
}
