.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.stockTitle{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #1E1F24;
}

.value {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #505562;
}

.notfound {
  padding-left: 15px;
	white-space: nowrap;
	margin-top: 10px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #505562;
}


.exploreCard {
	width: 100%;
	border: 0px;
	border-bottom: 1px solid #f0efef;
}

.cardBody {
  padding: 15px;
}
.imageDiv {
  width: 40px;
  height: 40px;
}
.stockImage {
  width: 39px;
  height: 39px;
  padding: 10px;
}
.imagePaddingRemove {
  padding-top: 0px;
}

.investTitle{
 white-space: nowrap;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.6px;
color: #626777;
white-space: nowrap;
}
.investValue{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;

letter-spacing: 0.6px;

color: #1E1F24;
}


.buttonSell{
  height: 44px;
  background: #FA274A;
border-radius: 8px;
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: 0.6px;
border: none;
box-shadow: none;
color: #FFFFFF;
width: 100%;
}
.shares{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.6px;
color: #626777;
}
.buttonSell:hover {
	background: #FA274A;
	color: #ffffff;
	border: none;
	box-shadow: none;
}

.buttonSell:focus {
	background: #FA274A;
	color: #ffffff;
	box-sizing: border-box;
	border: none;
}

.buttonSell:not(:disabled):not(.disabled):active {
	background: #FA274A;
	color: #ffffff;
	box-sizing: border-box;
	border: none;
}

.buttonSell:not(:disabled):not(.disabled):active:focus {
	background: #FA274A;
	color: #ffffff;
	box-sizing: border-box;
	border: none;
}

.buttonSell:disabled{
  background-color: #D3D4DA;
  border-color: #D3D4DA;
  color: #ffffff;
}

.buttonBuy:disabled{
  background-color: #D3D4DA;
  border-color: #D3D4DA;
  color: #ffffff;
}
.modalDialog :global(.modal-content){
  height: 100vh;
}

.buttonBuy{
  height: 44px;
  background: #0DC014;
border-radius: 8px;
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: 0.6px;
color: #FFFFFF;
border: none;
box-shadow: none;
width: 100%;
}

.buttonBuy:hover {
	background: #0DC014;
	color: #ffffff;
	border: none;
	box-shadow: none;
}

.buttonBuy:focus {
	background: #0DC014;
	color: #ffffff;
	box-sizing: border-box;
	border: none;
}

.buttonBuy:not(:disabled):not(.disabled):active {
	background: #0DC014;
	color: #ffffff;
	box-sizing: border-box;
	border: none;
}

.buttonBuy:not(:disabled):not(.disabled):active:focus {
	background: #0DC014;
	color: #ffffff;
	box-sizing: border-box;
	border: none;
}

.view{
  padding-top:2px;
  margin-right: 5px;
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #005DD6;
}

.viewImage{
 height: 8px;
}

.totalSum{
  padding-top: 5px;
  padding-left: 6px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: right;
letter-spacing: 0.4px;
color: #626777;
}

.mobilePrice{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: right;
letter-spacing: 0.6px;
color: #1E1F24;
}
.changeColor{
  white-space: nowrap;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
text-align: right;
letter-spacing: 0.6px;
}

.sellDisableMessage{
  display: inline !important;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #3C404A;
}


.modalDialog{
  width: 100%;
  margin: 0 0 0 auto;
}
.modalDialog :global(.modal-content){
  height: 100vh;
}
@media (max-width:576px){
	.modalDialog{
		max-width: 100%;
	  }
}
@media (max-width:768px){
	.modalDialog{
		margin-left: auto;
    max-width: 100%;
	  }
}
@media (min-width: 767px) and (max-width: 991px){
  .modalDialog{
    max-width: 100%;
  }
}
