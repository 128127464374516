.cardDiv{
  margin: auto;
  display: table;
  margin-top: 80px;
  min-height: calc(100vh - 285px);
}

.cardSignUp{
width: 412px;
height: auto;
background: #FFFFFF;
border: 1px solid #F7F7F8;
box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
border-radius: 16px;
}
.cardBodySignup{
  padding:32px 24px 32px 24px
}
.title{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #3C404A;
  margin-left:0px;
  margin-bottom: 4px;
  }

  .subTitle{
    font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-left:0px;
  }

  .splashKycBox{
    padding: 16px 20px;
    background: #F7F7F8;
    border-radius: 8px;
    }

    .digiRecom{
    padding: 2px 6px 3px;
    margin-left:8px;
    background: #FA5B05;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    }
    .splashItemHeading {
      font-family: 'geomanistBook';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #3c404a;
    }
    .activeButton {
      width: 100% !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      background: #0061d3;
      border-radius: 8px;
      border: 0;
    }

    .activateText {
      font-family: 'geomanistBook';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
    .secondaryBtn{
      width:100%;
      font-family: "geomanistBook";
      color: #0061D3;
      padding: 10px 20px;
      border-radius: 8px;
      border: 1.5px solid #0061D3;
      background: #FFFFFF;
      outline-color: #0061D3;
      display: flex;
      justify-content:center;
    }

    .secondaryBtn:focus,.secondaryBtn:hover{
      background: #FFFFFF;
    }
    .calendaricon {
      position: absolute;
      right: 43px;
      top: 22px;
      cursor: pointer;
    }
    .calendarImg{
      position: absolute;
      width:15px;
      right: 7%;
      top: 40px;
      cursor: pointer;
    }

    .calendar {
      position: absolute;
      z-index: 105;
      top: 49px;
      right: -2px;
      width: 275px;
      /* -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22);
      box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22); */
    }
    .panCardCapital{
      text-transform: uppercase;
    }
    .cancelContainer>div{
      padding: 16px;
      width: 100%;
    }
    .cancelContainer .cancelHeader{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap:24px;
      color: #3C404A;
      font-family: 'geomanistBook';
      font-size: 24px;
      margin-bottom: 8px;
    }
    .cancelContainer .cancelBody{
      font-family: 'geomanistRegular';
      font-size: 14px;
      color: #767C8F;
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
    }
  .genericErrorLoading{
      display: flex;
      gap: 6px;
      color: #0061D3;
      align-items: center;
  }
  .spinnerColor{
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    }
  .contactUs{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0061D3;
    cursor:pointer;
  }
  .passwordInvalid :global(.form-control.is-invalid) {
    background-image: none !important;
    background-position: right 12px center;
    border: 1.5px solid #FA274A;
    background-repeat: no-repeat;
  }
  .goBackCenter{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0061D3;
    margin-top:15px;
    cursor:pointer;
  }

    @media (max-width: 580px) {
      .splashItemHeading{
        font-size:14px;
      }
    }

    @media only screen and (max-width: 480px) {
      .cardSignUp {
        border: 0px;
        box-shadow: none;
        width: 100%;
      }
      .cardBodySignup {
        padding: 24px;
      }
      .cardDiv {
        margin-top: 12px;
        display: block;
      }
    }

