.circle{
  display: inline-block;
  width: 8px;
height: 8px;
border-radius: 50%;
background: #3C404A;
}

.qvalue{
  font-family: 'geomainstregular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
margin-left: 8px;
letter-spacing: 0.6px;
color: #1E1F24;
}

.value{
  /* text-align: center; */
  padding-left: 15px;
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;

letter-spacing: 0.6px;

color: #1E1F24;
}

.seeMore{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #005DD6;
margin: 0px 4px;
cursor: pointer;
}



/* stocks See more css */
.modalWidth{
  width:850px;
  padding-top:35px;
}
.chartCard{
  height: 325px;
  width: 399px;
  border: 1px solid #D3D4DA;
box-sizing: border-box;
border-radius: 12px;
}
.chartCardBody{
  padding:32px
}
.chartTitle{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
letter-spacing: 0.6px;
color: #1E1F24;
}

.rowMargin{
  margin-top: 40px;
}

@media (min-width:300px) and (max-width: 480px) {
  .modalWidth{
    width: 100%;
    height: calc(100vh + 100px);
  }
  .mobileMargin{
    margin-top: 20px;
  }
  .chartCard{
    width: 100%;
  }
}

@media (min-width:500px) and (max-width: 991px) {
  .modalWidth{
    width: 600px;
    height: calc(100vh + 100px);
    padding-left: 60px;
  }
  .mobileMargin{
    margin-top: 20px;
  }
}

