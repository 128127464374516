@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.emptyPageParent {
	position: relative;
	top: -60px;
	height: calc(100vh - 300px);
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.emptyPageImage {
	display: flex;
	align-self: center;
	width: 264px;
	height: 193.53px;
	margin-top: 10px;
}

.emptyPageHeadText {
	display: flex;
	align-self: center;
	font-family: 'geomanistmedium';
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0.6000000238418579px;
	text-align: center;
	color: #000000;
}

.emptyPageSubText {
	display: flex;
	align-self: center;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #505562;
}
