/* MODAL */
.imageCompanyModal {
	height: 56px;
	width: 56px;
	border-radius: 8px;
}
.paddingRemove {
	padding-right: 0px;
}
.stockTitleModal {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	text-transform: uppercase;
	color: #1e1f24;
}
.stockDescriptionModal {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-top: 8px;
}

.buttonModal {
	border: 0px;
	height: 40px;
	padding: 5px 40px 5px 40px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* text-align: center; */
	letter-spacing: 0.4px;
	color: #ffffff;
	border-radius: 8px;
	background: none;
}
.topRow {
  margin-top: 50px;
	width: 800px;
}
.buttonsDiv {
	margin-top: 10px;
}

.buyBtn {
	margin-left: 15px;
}

.buyButton {
  border: 0px;
	height: 40px;
	padding: 5px 40px 5px 40px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* text-align: center; */
	letter-spacing: 0.4px;
	color: #ffffff;
	border-radius: 8px;
	background: #0DC014;
	border-radius: 8px;
	box-shadow: none;
}

.buyButton:hover,
.buyButton:focus,
.buyButton:active {
	background: #0DC014;
	border: 0px;
	box-shadow: none;
}

.buyButton:disabled {
	background-color: #D3D4DA;
	border-color: #D3D4DA;
}

.buyButton:not(:disabled):not(.disabled):active,
.buyButton:not(:disabled):not(.disabled):active:focus {
	color: #fff;
	background-color: #0DC014;
	border-color:#0DC014;
	box-shadow: none;
}

.sellButton {
  border: 0px;
	height: 40px;
	padding: 5px 40px 5px 40px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* text-align: center; */
	letter-spacing: 0.4px;
	color: #ffffff;
	border-radius: 8px;
	border-radius: 8px;
	box-shadow: none;
	background: #FA274A;
}

.sellButton:hover,
.sellButton:focus,
.sellButton:active {
	background:#FA274A;
	border: 0px;
	box-shadow: none;
}

.sellButton:disabled {
	background-color: #D3D4DA;
	border-color:#D3D4DA;
}

.sellButton:not(:disabled):not(.disabled):active,
.sellButton:not(:disabled):not(.disabled):active:focus {
	color: #fff;
	background-color: #FA274A;
	border-color: #FA274A;
	box-shadow: none;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .topRow {
    width: 850px;
  }
}

@media (min-width: 700px) and (max-width: 890px) {
  .topRow {
    width: 715px;
  }
}
