.portfolioFooter {
	margin-top: 100px;
}

.summaryRow {
	margin-top: 5.77%;
	/* margin-left: 1%; */
}
.export{
  text-align: end;
  margin-right: 25px;
}
.exportDiv{
  cursor: pointer;
  display: inline;
  background: #EFEFF1;
border-radius: 8px;
height: 36px;
width: 96px;
padding:2px 12px 8px 12px;
}
.exportText{
  font-family: 'geomanistmedium';
  margin-left: 10px;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.4px;
color: #3C404A;
}
.portfolioTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
	text-transform: capitalize;
}

.tableTitle {
	margin-top: 5.77%;
	padding-left: 3%;
	font-family: 'geomanistmedium';
	margin-left: 0px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
	opacity: 0.8;
	padding-left: 0px;
}

.totalSum{
  padding-top: 5px;
  padding-left: 6px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: right;
letter-spacing: 0.4px;
color: #626777;
}

@media (min-width: 320px) and (max-width: 480px) {
	.summaryRow {
		margin-right: -15px;
		margin-left: -15px;
	}
  .tableTitle{
    font-size: 20px;
  }
  .totalSum{
    font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #505562;
  }
  .mobilePadding{
    padding-left: 0px;
    /* padding-right: 0px; */
  }
  .portfolioTitle{
    font-size: 20px;
  }
  .exportDiv{
    display: contents;
    width: 102px;
  }
  .export{
    margin-right: 0px;
    text-align: start;
  }
}
/* security lending */
.secLending{
	display: flex;
	justify-content: space-between;
	padding: 20px 32px 20px 24px;
	gap: 20px;
	background: #F7F7F8;
	border-radius: 8px;
	align-items: center;
	margin-top: 46px;
	margin-bottom: 70px;
}
.secLending .info{
	display: flex;
	align-items: center;
	gap: 20px;
}
.secLending .info img{
	width: 48px;
	height: 48px;
}
.lendingHeader{
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 20px;
	color: #3C404A;
}
.lendingDescription{
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	color: #767C8F;
}
.lendingDescription span{
	color: #0061D3;
	cursor: pointer;
}
.lendingDescription span:hover{
	text-decoration: underline;
}
.lendingDownload{
	border: 1.5px solid #0061D3;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 10px 16px 10px 12px;
	white-space: nowrap;
	color: #0061D3;
	font-family: 'geomanistBook';
	font-weight: 400;
	font-size: 14px;
	height: max-content;
	display: flex;
	align-items: center;
	justify-content: center
}
.lendingDownload img{
	margin-right: 6px;
	width: 18px;
	height: 18px;
}
.lendingDownload:hover,.lendingDownload:focus{
	border: 1.5px solid #004EA9;
	color: #004EA9;
}
.tryAgain{
	color: #0061D3;
	margin-left: 6px;
}
.lendingDownload span{
	display: flex;
	align-items: center;
}
.disableBtn{
	cursor: default !important;
}
.lendingDownloadError{
	border: 1.5px solid #FA5B05 !important;
	color: #FA5B05 !important;
}
.spinnerColor{
	margin-right: 6px;
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
}
@media (min-width: 700px) {
	.summaryRow {
		margin-right: -15px;
		margin-left: -15px;
		padding-left: 2%;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
	.sideBarMobileHide {
	  display: block;
	}
  }


/* @media (min-width: 1600px) {
	.bigScreen {
		max-width: 1400px;
	}
} */

@media (min-width: 1400px) {
	.summaryRow {
		margin-right: -15px;
		margin-left: -15px;
		padding-left: 1%;
	}
}

@media (min-width: 1500px) {
	.summaryRow {
		margin-right: -15px;
		margin-left: -15px;
		padding-left: 1%;
	}
}

@media (min-width: 2500px) {
	.summaryRow {
		margin-right: -15px;
		margin-left: -15px;
		padding-left: 10%;
	}
}

/* for sidebar */
@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
  /* .bigScreen {
		max-width: 1400px;
	} */
}
/* Security lending responsive */
@media (max-width:768px){
	.secLending{
		flex-direction: column;
	}
	.lendingDownload{
		width: 100%;
	}
}
@media (min-width:768px){
	.secLending{
		flex-direction: row;
	}
	.lendingDownload{
		width: auto;
	}
}
