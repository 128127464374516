@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';


.marginAdjust{
  margin-left:170px;
  margin-right: 170px;
  margin-top:15px;
}

.heading{
  font-family: 'geomanistMedium';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #000000;
}

.inputbox {
	position: relative;
	width: 100%;
	border: 0px;
}

.searchInput {
	padding-left: 40px;
  padding-top:24px;
  padding-bottom: 24px;
	border-radius: 8px !important;
	width: 100%;
	border: 0px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(2, 2, 65, 0.08);
}

.searchInput::placeholder {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #9096a8;
	vertical-align: middle;
}

.searchInput:active,
.searchInput:focus {
	box-sizing: border-box;
  padding-left: 40px;
  padding-top:24px;
  padding-bottom: 24px;
	border-radius: 8px !important;
	width: 100%;
	border: 0px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(2, 2, 65, 0.08) !important;
}

.searchList {
	height: 400px;
	overflow-y: scroll;
	width: 100%;
	margin-top: 20px;
  overflow-x: hidden;
}

.searchItem{
  padding-top:20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #EFEFF1;
}

.stockTitle{
  font-family: 'geomanistBook';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  color: #1E1F24;
}

.stockName{
  font-family: 'geomanistRegular';
  color: #1E1F24;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.watchListStar{
  font-family: 'geomanistBook';
  font-size: 14px;
  color: #005DD6;
  cursor: pointer;
  text-align: right;
}

.addedStar{
  font-family: 'geomanistBook';
  font-size: 14px;
  color: #1E1F24;
  cursor: pointer;
  text-align: right;
}

.stockType{
  font-family: 'geomanistRegular';
  color: #626777;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-align: right;
}

.blueStar{
  margin-bottom:3px;
}

.crossImage{
  margin-top:35px;
  text-align: right;
  margin-right: 60px;
}

.watchlistsearchbarCross{
  position: absolute;
  top:15px;
  right:15px;
}

.watchListSearchBarSearchSign{
  position: absolute;
  top:15px;
  left:10px;
}

@media (max-width: 990px) {
  .marginAdjust{
    margin-left:60px;
    margin-right: 60px;
    margin-top:15px;
  }
}

@media (max-width: 770px) {
  .marginAdjust{
    margin-left:0px;
    margin-right: 0px;
    margin-top:15px;
  }
}

@media (max-width: 700px) {
  .heading{
    display: none;
  }
  .crossImage{
    display: none;
  }
}


