@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.nav-tabs {
	border-bottom: 1px solid #d3d4da;
}

.nav-tabs .nav-link.active {
  padding-left: 0px;
  padding-right: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	border: 0px;
	border-bottom: 2px solid #005dd6;
}

.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
	margin-right: 24px;
	border: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

/* .profileTabHide{
	display: block;
} */

/* .profileTabResponsive{
	display: none !important;
}

.basicDetail{
	display: none !important;
} */

@media (max-width: 576px) {
	.nav-tabs .nav-link {
		margin-right: 15px;
		/* padding-right:10px; */
	}

  .nav-tabs .nav-link.active {
    /* padding-right: 10px; */
    margin-right: 15px;
  }
	/* .profileTabHide{
		display: none;
	}

	.profileTabHide:global(.tab-content) {
		display: none;
	}
	.profileTabHide{
		display: none !important;
	} */
	/* .profileTabResponsive{
		display: inline-block;
	} */
}
