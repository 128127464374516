@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.overText {
	position: absolute;
	bottom: 15px;
	left: 35px;
	font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #FFFFFF;
  font-family: 'geomanistMedium';
}

/* .overlayImage{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%)
} */

.overlayImage{
  position:relative;
  display:inline-block;
  width: 100%;
}


/*
#002f4b,#dc4225
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.overlayImage:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%;
  height:100%;
  display:inline-block;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 8px;
}

.overlayImage img{
  display:block;
}

.image {
	width: 100%;
	height: 320px;
	border-radius: 8px;
  /* filter: brightness(0.9); */
}
.image:hover {
	border-radius: 8px;
	/* -webkit-transform: scale(1.3);
	transform: scale(1.3); */
}

@media (min-width: 320px) and (max-width: 480px) {
	.image {
		width: 100%;
		height: 200px;
	}
  .overText {
    position: absolute;
    bottom: 10px;
    left: 20px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    font-family: 'geomanistMedium';
    /* background: linear-gradient(
      180deg, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%); */
  }
}

/* @media (max-width: 1024px) {
	.image {
		width: 160px;
		height: 200px;
	}
} */
