@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.tableTitle {
	margin-top: 5.77%;
	padding-left: 3%;
	font-family: 'geomanistmedium';
	margin-left: 0px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
	opacity: 0.8;
}

.dataTable td,
.dataTable th {
  padding-top: 24px;
  padding-bottom: 24px;
	vertical-align: middle !important;
}

.tableHeader {
	font-family: 'geomanistbook';
	font-style: normal;
	border-bottom: 1px solid #eaeaea !important;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	white-space: nowrap;
	color: #767c8f;
}

.tableborder {
	border-bottom: 1px solid #eaeaea !important;
}
.imageCompany {
	height: 40px;
	width: 40px;
	border-radius: 8px;
}
.imageCompany:hover {
  cursor: pointer;
}

.creator {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	border-bottom: 1px solid #eaeaea !important;
}

.tableData {
	font-family: 'geomanistregular';
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	/* border-bottom: 1px solid #eaeaea !important; */
	font-weight: normal;
	text-transform: lowercase;
}
.tableData:first-letter {
	text-transform: uppercase;
}

.stacktableData {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #1e1f24;
	/* border-bottom: 1px solid #eaeaea !important; */
}

.stacktableData:first-letter {
	text-transform: uppercase;
}

.incrementImage {
	margin-bottom: 3px;
	margin-right: 5px;
}
.stocktableData {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;

	letter-spacing: 0.6px;
	text-transform: uppercase;
	/* border-bottom: 1px solid #eaeaea !important; */
	color: #1e1f24;
}
.allocation {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #005dd6;
}
.allocation:hover {
	text-decoration: underline;
}

.buttonMargin{
  margin-left: 8%;
}
.sellDisableMessage{
  display: inline !important;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #3C404A;
}

.buyDisabledMessage{
	display: inline !important;
	font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.6px;
  color: #3C404A;
  }


@media (min-width: 700px) and (max-width: 1100px) {
  .allocation{
     font-size: 10px;
  }
  .buttonMargin{
    margin-left: 1px;
  }
  .buyButtons{
    font-size: 10px;
  }
}
