@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.portfolioCard {
  border: 0px;
  background: transparent !important;
}

.portfolioTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #000000;
	text-transform: capitalize;
	margin-bottom: 32px;
}

.stockValue {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 4px;
}

.stockTitle {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

.incrementPercentage {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	margin-left: 4px;
	padding-top: 6px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .marginSlide{
    margin-left: 0;
  }
  .portfolio{
    margin:0px
  }
	.portfolioTitle {
		font-size: 20px;
		line-height: 28px;
		color: #1e1f24;
	}
	.stockValue {
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 28px;
	}
	.incrementPercentage {
		margin-left: 0px;
		padding-top: 0px;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.6px;
	}
	.stockTitle {
		margin-top: 0px;
		font-size: 12px;
		line-height: 16px;
		color: #626777;
		white-space: nowrap;
	}
	.paddingNone {
		padding: 0px;
	}
  .portfolioSlider{
    height: 60px;
  }
  .portfolioSlider :global(.slick-list){
    height: 60px !important;
    min-height: 60px !important;
  }
}
