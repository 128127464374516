@import '../../public/geomanist/geomanistRegular/stylesheet.css';

.footer1 {
	/* Regular/Label - 12px */
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
   margin-bottom: 0px;
	/* identical to box height, or 133% */
	letter-spacing: 0.6px;

	/* Neutral/700 */
	color: #505562;
	text-transform: none;
	text-align: justify;
  	display: inline-flex;
	width: 100%;
}

.footer {
	/* Regular/Label - 12px */
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
   margin-bottom: 20px;
	/* identical to box height, or 133% */
	letter-spacing: 0.6px;

	/* Neutral/700 */
	color: #505562;
	text-transform: none;
	text-align: justify;

}
/* .hotline {
	text-align: end;
  white-space: nowrap;
} */
.footer_desc {
	margin: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 160%;

	/* or 16px */
	letter-spacing: 0.6px;

	/* Neutral/600 */
	color: #626777;
	text-align: justify;
	text-transform: none;
}
.line {
	height: 1px;
	border-radius: 0px;
	background: #efeff1;
	padding: 0px;
}
.hotlineImage {
	/* padding-right: 1.3%; */
  margin-right: 10px;
}
.stockalpowered {
  margin-bottom: 5%;
	width: 100%;
	justify-content: flex-end;
}

.onBoardMobileBorderless,.inBoardMobileBorderless {
	white-space: nowrap;
	padding-left: 0;
  /* margin-left: 2%; */
}
.onBoardDisclaimer{
  text-decoration: underline;
  text-align: start !important;
}
.onBoardDisclaimer,.onBoardEmail,.onBoardPhone,.inBoardDisclaimer,.inBoardEmail,.inBoardPhone {
	white-space: nowrap;
	padding-left: 0;
	text-align: center;
}
@media (max-width: 320px){
	.onBoardDisclaimer,.onBoardEmail,.onBoardPhone,.inBoardDisclaimer,.inBoardEmail,.inBoardPhone {
		text-align: end;
	}
}
@media (min-width: 320px) and (max-width: 767px) {
	.onBoardMobileBorderless, .inBoardMobileBorderless {
		text-align: center;
	}
	/* .hotline {
		text-align: center;
    margin-top: 16px;
	} */
	.onBoardDisclaimer,.onBoardEmail,.onBoardPhone,.inBoardDisclaimer,.inBoardEmail,.inBoardPhone,.onBoardMobileBorderless {
		text-align: center;
    	margin-top: 16px;
	}
  .footer{
    margin-bottom: 0px;
  }
  .footer1{
    font-size: 10px;
    margin-bottom: 0px;
    line-height: 14px;
    letter-spacing: 0.6px;
  }
  .onBoardDisclaimer{
    padding-left: 15px;
    margin-top:14px !important ;
  }
  .inBoardDisclaimer{
    padding-left: 15px;
  }
}
@media (min-width: 768px) and (max-width: 1200px){
	.onBoardDisclaimer,.onBoardPhone {
		text-align: end;
		margin-top: 0px;
	}
	.onBoardEmail{
		text-align: left;
		margin-top: 0px;
	}
  .onBoardDisclaimer{
    /* text-align: center !important; */
  }
}
@media (min-width: 768px) and (max-width: 992px){
	.inBoardPhone {
		text-align: end;
		margin-top: 0px;
	}
	.inBoardMobileBorderless,.inBoardEmail{
		text-align: left;
		margin-top: 0px;
	}
  .inBoardDisclaimer{
    text-align: start;
    padding-left: 30px;
  }
}

@media (min-width: 1000px){
   .onBoardMobileBorderless{
     /* margin-left: 3%; */
   }
}


.poweredText{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.4px;
color: rgba(80, 85, 98, 0.6);
margin-right: 8px;
}


@media (min-width: 1200px) and (max-width: 1300px) {
.onBoardDisclaimer{
  text-align: start !important;
margin-left: 4%;
}
.poweredSpacing{
  margin-right: 1% !important;
}
}

@media (min-width: 1301px) and (max-width: 1350px) {
  .onBoardDisclaimer{
    margin-left: 2%;
    }
}

.onBoardEmail{
  text-align: end !important;
}

.onBoardPhone{
  text-align: start !important;
}

@media (min-width: 300px) and (max-width: 900px) {
  .onBoardEmail{
    text-align: center !important;
    padding-left: 15px;
  }

  .onBoardPhone{
    padding-left: 15px;
    text-align: center !important;
  }
  .footerMargin{
    margin-bottom: 0px !important;
  }
  .onBoardMobileBorderless{
    padding-left: 10px;
  }
  .onBoardDisclaimer{
    padding-right: 10px;
    text-align: center !important;
    /* margin-top: 0px !important; */
  }
  .showPowered{
    /* display: none !important; */
    text-align: start !important;
    padding-left: 0px;
  }
}

.footerMargin{
  margin-bottom: 14px;
}
.onBoardMobileBorderless{
  text-align: center  ;
}

.poweredSpacing{
  margin-right: 25px;
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .poweredSpacing{
    margin-right: 5% !important;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .poweredSpacing{
    max-width: 12%;
    margin-right: 0px;
  }
  .onBoardDisclaimer{
    margin-left: 45px;
  }
  .onBoardEmail{
    text-align: end !important;
  }
  .onBoardPhone{
    text-align: start !important;
  }
}
@media (min-width: 1550px) and (max-width: 3000px) {
  .poweredSpacing{
    margin-right: 20px;
  }
}

/* @media (min-width: 1550px) and (max-width: 3000px) {
  .onBoardMobileBorderless{
    max-width: 60%;
    flex:0 0 60%;
  }
} */

.showPowered{
  display: block;
  text-align: end;
}

.inBoardDisclaimer{
 text-decoration: underline;
 text-align: start;
}


.boardNotDisclaimer{
  text-align: end;
  margin-left: 1%;
}
.boardLandingDisclaimer{
  text-align: end;
  margin-left: 2%;
}
@media (min-width: 900px) and (max-width: 120px) {
.boardNotDisclaimer{
  margin-left: 3%;
}
}
@media (min-width: 300px) and (max-width: 768px) {
.boardNotDisclaimer{
  text-align: center;
}
.inBoardDisclaimer{
  text-align: center;
}
}

@media (min-width: 1200px) and (max-width: 1245px) {
.inBoardDisclaimer{
  margin-left: 3%;
}
}
