
.homeFooter{
  margin-top: 100px;
}
.accountsetupFooter{
  margin-bottom: 8%;
  padding-bottom: 10px;
}
.fundFooter{
  margin-top: 100px;
}
.signinFooter{
  position: absolute;
  bottom: 0;
  right: 0;
}
.colResponsive{
  text-align: end;
}
.accountSetupFooter{
  margin-top: 0px;
  margin-bottom: 100px;
}

/* @media (max-width: 800px) {
  .signinFooter{
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 20px;
  }
} */
@media (max-width: 992px) {
  .accountsetupFooter{
    padding-bottom: 72px;
  }
}

/* test for smriti */

@media (min-height: 200px) and (max-height: 500px) {
  .signinFooter{
    position:  static;
    bottom: 0;
    right: 0;
  }
}

/* start */

.colResponsive{
  text-align: end;
}
@media (min-width: 769px) and (max-width: 1024px){
  .signinFooter{
    width: 700px;
    right: 0;
  }
  .colResponsive{
    text-align: start;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: start !important;
  }
}
@media (min-width: 1025px){
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
}
@media (min-width: 1440px){
  .signinFooter{
    width: 900px;
    right: 60px;
  }
  .colResponsive{
    text-align: center;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
}
@media (min-width: 1700px) and (max-width: 2559px){
  .signinFooter{
    width: 1100px;
  }
  .colResponsive{
    text-align: center;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
}
@media (min-width: 2560px){
  .signinFooter{
    right: 300px;
    width: 1500px;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
}
/* end */

@media (min-width: 769px) and (max-width: 1024px){
  .signinFooter{
    width: 700px;
    right: 0;
  }
  .colResponsive{
    text-align: start;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: start !important;
  }
  .fundFooter{
    margin-top: 120px;
  }
  .orderFooter{
    margin-top: 250px;
  }
}
@media (min-width: 1025px){
  .portfolioFooter{
    margin-top: 130px;
  }
  .orderFooter{
    margin-top: 170px;
  }
  .fundFooter{
    margin-top: 100px;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
}
@media (min-width: 1440px){
  .portfolioFooter{
    margin-top: 190px;
  }
  .orderFooter{
    margin-top: 250px;
  }
  .fundFooter{
    margin-top: 180px;
  }
  .colResponsive{
    text-align: center;
  }
  .signinFooter{
    width: 900px;
    right: 60px;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
}
@media (min-width: 1700px) and (max-width: 2559px){
  .colResponsive{
    text-align: center;
  }
  .signinFooter{
    width: 1100px;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
  .orderFooter{
    margin-top: 500px;
  }
}
@media (min-width: 2560px){
  .orderFooter{
    margin-top: 530px;
  }
  .fundFooter{
    margin-top: 900px;
  }
  .signinFooter{
    right: 300px;
    width: 1500px;
  }
  .colResponsive :global(.Footer_hotline__1Pqw-) {
    text-align: end !important;
  }
  .accountSetupFooter{
    margin-top: 300px;
  }
}

.parent{
  min-height: 100vh;
}

.content{
  min-height: 92vh;
}

.FooterParent{
  position: relative;
  margin-top: 0px;
  margin-bottom: 5px;
}

.Footer{
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) and (max-width: 992px) {
  .content{
    min-height: 87vh;
  }
  .FooterParent{
    position: relative;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .content{
    min-height: 80vh;
  }
  .FooterParent{
    margin-bottom: 70px;
  }

}
@media (max-width: 380px) {
  .accountsetupFooter{
    padding-bottom: 92px;
  }
}

/* @media (min-width: 481px) and (max-width: 991px) {
  .bottomResponsive{
    padding-bottom: 80px;
  }
} */
