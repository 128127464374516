@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.themeRowMargin{
  margin-bottom: 32px;
  cursor: pointer;
}

.heading{
  margin-bottom: 24px;
  color: #1E1F24;
  font-family: 'geomanistMedium';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  margin-top: 48px;
}

.detailsPadding{
  margin-left:170px;
  margin-right:170px;
}

.image{
  width:100%;
  height:200px;
  border-radius: 8px;
}

.bannerImage{
  width: 128px;
  border-radius: 8px;
  height: 160px;
  float: right;
}

.themesBackButton{
  position: absolute;
  top:32px;
	/* left: 35px; */
	font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;;
  font-family: 'geomanistBook';
}

.overText {
	position: absolute;
	top:74px;
	/* left: 35px; */
	font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;;
  font-family: 'geomanistMedium';
}

.stockCount{
  position: absolute;
	top: 110px;
	/* left: 35px; */
	font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #505562;
  font-family: 'geomanistRegular';
}

.stockCountHeightIncrease{
  top:140px;
  position: absolute;
	font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #505562;
  font-family: 'geomanistRegular';
}

.selectedheading{
  font-family: 'geomanistRegular';
  color: #1E1F24;
  letter-spacing: 0.6px;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 65px;
  margin-top:59px
}

.HeadingColor{
  color: #505562;
}

.HeadingColor:hover{
  color: #505562;
  text-decoration: none;
}

.backArrowImage{
  height: 14px;
  margin-right:8px;
  margin-bottom: 2px;
}

.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}

.mobileThemeTable{
  display: none;
}

.webThemeTable{
  display: flex;
}

.mobileBanner{
  display: none;
}

.tabletShimmer{
  display: none;
}

.stackHeadingDesktop{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1e1f24;
  padding-left: 0px;
  margin-top: 56px;
  margin-bottom: 24px;
  padding-left:15px;
}

.webBanner{
  margin-top:40px;
}

.noOrdersFound{
	height:60vh;
  text-align: center;
  padding-top: 60px;
}

.noOrdersText{
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
  letter-spacing: 0.6px;
  color: #000000;
  margin-bottom: 8px;
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}

/* @media (min-width: 481px) {
  .mobileBanner{
    display: none;
  }
} */

@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width:992px) {
  .detailsPadding{
    margin-left:0px;
    margin-right:0px;
  }
  .webShimmerTheme{
    display: none;
  }
  .tabletShimmer{
    display: block;
  }

}

@media (max-width:1200px) and (min-width:993px) {
  .detailsPadding{
    margin-left:100px;
    margin-right:100px;
  }
}

@media (min-width:320px) and (max-width:480px) {
  .themeRowMargin{
    padding-right: 10px;
    padding-left:10px;
    margin-bottom: 10px;
  }
  .mobileThemeTable{
    display: block;
  }
  .webThemeTable{
    display: none;
  }
  .TableMarginTop{
    margin-top: 56px;
    margin-left: 0px;
  }
  .stocksEtf {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    padding-left: 0px;
  }
  .mobileImageShow{
    width:100%;
    height:440px;
  }
  .mobileImage{
    width: 100%;
    height: 100%;
  }
  .image{
    display: none;
  }
  .webBackButton{
    display: none;
  }
  .backArrowImageMobile{
    position: absolute;
    top: 80px;
    left: 20px;
    filter: brightness(0);
  }
  .overText {
    position: absolute;
    bottom: 44px;
    left: 20px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    font-family: 'geomanistMedium';
  }
  .stockCount{
    left:20px;
  }
  .mobileBanner{
    display: block;
  }
  .webBanner{
    display: none;
  }
  .mobileStockCount{
    position: absolute;
    top: 460px;
    left: 20px;
    line-height: 20px;
    font-size: 15px;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    font-family: 'geomanistRegular';
  }
  .mobileOverText{
    position: absolute;
    top: 430px;
    left: 20px;
    line-height: 20px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    font-family: 'geomanistMedium';
  }
  .mobileOverTextHeightIncrease{
    position: absolute;
    top: 410px;
    left: 20px;
    line-height: 20px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    font-family: 'geomanistMedium';
  }

  /* stack card */
  .trendingCard {
    border: 0px;
    /* border-bottom: 1px solid #eef2fb; */
    border-radius: 8px;
  }

  .cardBody {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .trendingCard:hover {
    border: 1px solid #efeff1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
    border-radius: 16px;
  }

  .stockImage {
    width: 48px;
    height: 48px;
    border-radius: 3px;
  }

  .stockTitle {
    font-family: 'geomanistmedium';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.56px;
    color: #1e1f24;
    text-transform: uppercase;
  }

  .stockName {
    font-family: 'geomanistregular';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #626777;
    text-align: left;
    height: 40px;
    margin-bottom: 16px;
    /* padding-top: 5px; */
  }

  .cagrValue {
    font-family: 'geomanistbook';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #0DC014;
    text-align: left;
  }

  .stockIncrement {
    font-family: 'geomanistregular';
    float: right;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #626777;
  }
  .creator {
    font-family: 'geomanistregular';
    padding-top: 4px;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.56px;
    color: #626777;
  }
  .cagr {
    font-family: 'geomanistregular';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #626777;
    text-align: left;
  }
  .minHead {
    font-family: 'geomanistregular';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #626777;
    text-align: left;
  }
  .minValue {
    font-family: 'geomanistbook';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.56px;
    color: #1e1f24;
    text-align: left;
  }

  .navLink:hover {
    color: inherit;
    text-decoration: none;
  }

  .stackCardHeading{
    padding-left: 0px;
  }
  .borderBottom{
    border: 1px solid #EFEFF1;
    width: 90%;
  }
}
