/* extended disclouser */
.extendedModalTitle{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.6px;
color: #1E1F24;
padding-left: 0px;
}

.extendedModalSubTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #626777;
padding-right: 10px;
}
.extendedModalSubValue{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #1E1F24;
}
.extendedModalTnC{
  background: #EFEFF1;
border-radius: 8px;
padding: 20px;
height: 228px;
overflow:scroll;
-webkit-overflow-scrolling: touch;
scrollbar-width: 2px;
margin-top: 12px;
font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
letter-spacing: 0.4px;
color: #626777;
}
.extendedModalBody{
  padding:48px;
}
.paddingSetting{
  padding-top: 10px;
  padding-left: 0px;
}

.extendedModalButton{
  width: 156px;
  height: 48px;
  text-align: center;
  justify-content: center;
  background: #005DD6;
  border-radius: 8px;
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;
}


.checkMark {
  padding-top: 4px;
  padding-left: 12px;
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.checkBoxInput:disabled ~ .checkMark::before {
	background-color: #e9ecef;
	border: none;
}

.checkBox .checkBoxInput:checked:focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
	/* background-color: #6097a1; */
	transition: none;
}

.checkBox .checkBoxInput:not(:checked):focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
}

.checkMark::before {
	background-color: white;
	border: 2px solid rgba(22, 37, 66, 0.25);
	border-radius: 4px !important;
	height: 20px;
	width: 20px;
}

.checkMark::after {
	width: 20px;
	height: 20px;
}

.checkBox .checkBoxInput:checked ~ .checkMark::before {
	background-color:  #1E1F24;
	border: 2px solid #1E1F24;
}

.cardSpace{
  margin-top:48px;
}

.extendedCard{
  width: 370px;
height: 90px;
background: #FFFAF7;
border-radius: 16px;
margin-top:48px;
margin-bottom: 32px;
box-shadow: 0px 4px 24px rgb(2 2 65 / 11%);
padding:24px;
}
.extendedTitle{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #1E1F24;
}
.extendedSubTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #505562;

}
.otpSuccess {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #d81331;
}

.modalDialog :global(.modal-content) {
  border-radius: 16px !important;
}

.marginRow{
  margin-top: 15px;
}
.preMarketCircle{
  width: 8px;
height: 8px;
background: #005DD6;
border-radius: 50%;
margin-right: 8px;
margin-top: 2px;
}
.spinnerColor{
  width:1.5rem;
  height:1.5rem;
  border: .25em solid #ffffff;
  border-right-color: transparent;
}

@media (min-width: 300px) and (max-width:700px){
  .extendedTitle{
    font-size: 14px;
  }
  .extendedSubTitle{
    font-size: 12px;
  }
  .extendedModalTitle{
    font-size:18px;
  }
  .extendedModalSubTitle{
    font-size: 12px;
  }
  .extendedModalSubValue{
    font-size: 12px;
  }
  .extendedModalTnC{
    font-size: 12px;
    padding: 15px;
    height: calc(100vh - 480px);
  }
  .modalDialog :global(.modal-content) {
   height: 90vh;
  }
  .modalDialog{
    margin: 0px;
  }
  .paddingSetting{
    margin-bottom: 15px;
    text-align: end;
    padding-right: 0px;
  }
  .marginRow{
    margin-top: 15px;
  }
  .extendedModalBody{
    padding: 30px 30px 20px 30px;
  }
  .cardSpace{
    margin-top: 0px;
  }
}

@media (min-width: 300px) and (max-width:1000px){
  .extendedCard{
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
  }
}


