.highcharts-range-selector-buttons > text:first-child {
	display: none;
}

.compare{
  display: contents;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #1E1F24;
text-align: end;
}


.activeValue {
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.4px;
color: #1E1F24;
margin-right: 39px;
border-bottom: 2px solid #1E1F24;
padding-bottom: 4px;
cursor: pointer;
}

.nonactive{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #9096A8;
margin-right: 39px;
cursor: pointer;
}
.marginTop{
  margin-top:30px
}
.legendGraph{
  margin-top: 2px;
  background: #0DC014;
border-radius: 4px;
width: 16px;
height: 16px;
}


@media (min-width: 320px) and (max-width: 480px) {
  .compare{
    display: none;
  }
  .marginTop{
    margin-top:0px
  }
}
