.heading{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 64px;
  }
  .navbar {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    padding-left:32px;
    padding-right: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .bigscreenHeader{
    position: sticky;
    z-index: 1000;
    top: 0px;
  }

  /* @media (max-width: 700px) {
    .bigscreenHeader {
      display: none;
    }
    .navbar {
      box-shadow: 0px 0px 0px;
    }
  }

  @media (min-width: 701px) and (max-width: 992px) {
    .bigscreenHeader {
      display: none;
    }
  }

  @media (min-width: 1500px) {
    .bigscreenHeader{
      position: sticky;
      z-index: 1000;
      top: 0px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    }
    .navbar{
      max-width: 1500px;
      margin:auto;
      box-shadow: none;
    }
  } */
