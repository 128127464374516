.profileCheckBox .ProfileCheckMark {
	padding-left: 20px;
	padding-top: 2px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #162542;
	/* transform: scale(1.5); */
}

.checkBoxInput:disabled ~ .ProfileCheckMark::before {
	background-color: #e9ecef;
	border: none;
}

.checkBox .checkBoxInput:checked:focus ~ .ProfileCheckMark::before {
	box-shadow: 0 0 0 1px #fff;
	/* background-color: #6097a1; */
	transition: none;
}

.checkBox .checkBoxInput:not(:checked):focus ~ .ProfileCheckMark::before {
	box-shadow: 0 0 0 1px #fff;
}

.profileCheckBox .ProfileCheckMark::before {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px !important;
	height: 20px !important;
	width: 20px !important;
}

.profileCheckBox .checkBox .checkBoxInput:checked ~ .ProfileCheckMark::after {
	background-image: url('../../../public/images/checkbox.svg');
}

.ProfileCheckMark::after {
	width: 24px;
    height: 2rem;
    top: -3px;
    left: -26px;
}

.checkBox .checkBoxInput:checked ~ .ProfileCheckMark::before {
	background-color: black;
	border: 2px solid black;
}

.ProfileCheckMark::before{
	width:20px !important;
	height: 20px !important;
}

.ProfileCheckMark {
	padding-left: 8px;
}

.ProfileCheckMark:focus {
	outline: none;
}