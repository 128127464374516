.dropdownButton{
  display: flex;
  justify-content: space-between;
  width:100%;
  padding: 10px 12px;
  height: 40px;
  background: #FFFFFF;
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  box-shadow:none;
}

.dropdownButton:hover {
  background: #FFFFFF;
  border: 1.5px solid #3C404A;
  border-radius: 6px;
  box-shadow:none;
}

.dropdownButton:focus {
  background: #FFFFFF;
  border: 1.5px solid #3C404A;
  border-radius: 6px;
  box-shadow:none;
}

.dropdownButton:not(:disabled):not(.disabled):active {
  background: #FFFFFF;
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
  box-shadow:none;
}

.dropdownButton:not(:disabled):not(.disabled):active:focus {
  background: #FFFFFF;
  border: 1.5px solid #D3D4DA;
  border-radius: 6px;
  box-shadow:none;
}

.childrenText{
  text-align:start;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
}
