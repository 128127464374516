.addBankUserInfo{
    background: #F7F7F8;
    border: 1px solid #EFEFF1;
    border-radius: 8px;
    padding: 0px 16px;
    font-family: 'geomanistBook';
}
.userContainer{
    display: flex;
    gap:8px;
    align-items: center;
    padding: 16px 0px;
}
.dividerSecondary{
    border-bottom: 1px solid #D3D4DA;
}
.userContainer .user{
    display: flex;
    flex-direction: column;
    gap:4px;
}
.userContainer .user .name{
    font-size: 16px;
    color: #3C404A;
}
.userContainer .user .residence{
    font-size: 12px;
    color: #767C8F;
}
.flag{
    width: 32px !important;
    height: 32px !important;
    margin-right: 6px;
}
.sourceContainer{
    font-size: 14px;
    color: #767C8F;
    display: flex;
    justify-content: space-between;
    gap:8px;
    align-items: center;
    padding: 16px 0px;
}
.sourceContainer .value{
    color: #3C404A;
    display: flex;
    gap: 8px;
    align-items: center;
}
.sourceContainer .value img{
    cursor: pointer;
}
.panContainer{
    padding: 16px 0px;
    font-size: 14px;
    color: #767C8F;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid #D3D4DA;
}
.panContainer .input{
    padding: 10px 12px;
    font-family: 'geomanistBook';
    border: 1.5px solid #D3D4DA;
    border-radius: 6px;
    font-size: 14px;
    color: #3C404A;
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
}
.panContainer input::placeholder{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
}

.panError{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #FA274A;
    margin-top: 6px;
}
.panInfo{
    color: #767C8F;
    font-family: 'geomanistRegular';
    font-size: 12px;
    margin-top: 6px;
}
.panVerified{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 14px;
    color: #767C8F;
}
.panVerified .value{
    color: #3C404A;
    margin-right: 8px;
}
.textLink{
    font-family: 'geomanistBook';
    color: #0061D3;
    cursor: pointer;
}
.addBankForm{
    padding-top: 24px;
    margin-bottom: 86px;
}
.addBankForm .inputContainer{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}
.row{
    display: flex;
    gap:16px;
}
.inputContainer .input{
    border: 1.5px solid #D3D4DA;
    border-radius: 6px;
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
}
.errorInput:hover, .errorInput:focus-within{
    border: 1.5px solid #FA274A !important;
}
.errorMsg{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color:#FA274A
}
.error{
    color:#FA274A
}
.inputLeft{
    display: flex;
    gap:12px;
    align-items: center;
    width: 100%;
}
label{
    margin-bottom: 0px;
}
.inputContainer input{
    width: 100%;
    border: none;
}
.inputContainer input::placeholder{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #767C8F;
}
.inputContainer .panInput{
    padding: 10px 12px;
    border: 1.5px solid #FA274A;
    border-radius: 6px;
}
.addressDefault{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #767C8F;
}
.addressDefault span{
    margin-left: 6px;
    color: #0061D3;
    cursor: pointer;
}
.divider{
    margin-bottom: 20px;
    border-bottom: 1px solid #EFEFF1;
}
.alertNote{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    align-items: flex-start;
}
.checkInputContainer{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    display: flex;
    gap: 12px;
    align-items: center;
}
.checkInputContainer img{
    cursor: pointer;
}
.footerContainer{
    position: fixed;
    display: flex;
    bottom: 0;
    border-top: 1px solid #EFEFF1;
    padding: 24px;
    margin-left: -24px;
    gap:24px;
    background: #FFFFFF;
    width: 100%;
}
.continueBtn{
    font-family: "geomanistBook";
    background: #0061D3;
    padding: 10px 20px;    
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    outline-color: #0061D3;
    display: flex;
    align-items: center;
}
.continueBtn:focus, .continueBtn:hover{
    background: #004EA9;
}
.tertiaryBtn{
    font-family: "geomanistBook";
    background: none;
    border: none;
    color: #0061D3;
    outline:none;
    padding: 0px;
}
.tertiaryBtn:focus, .tertiaryBtn:hover{
    outline: none;
    color: #004EA9;
}
.genericLoading{
    height: calc(100vh - 144px);
    display: flex;
    justify-content: center;
}

.spinnerColor{
    border: .25em solid #FFFFFF;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.input:hover, .input:focus-within{
    border: 1.5px solid #3C404A;
  }
@media (max-width: 600px){
    .row{
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
}
.custIDContainer{
    border: 1px solid #D3D4DA;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.taxContainer{
    display: flex;
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #767C8F;
    justify-content: space-between;
    align-items: center;
}
.taxContainer .option{
    display: flex;
    align-items: center;
    gap: 6px;
    margin-right: 16px;
    cursor: pointer;
}
.taxContainer .option label{
    cursor: pointer;
}
.sectionHeader{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
}
.sectionInfo{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
    margin-bottom: 20px;
}
/* Verify */
.verifyContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 86px);
    font-family: 'geomanistBook';
    padding: 0 24px;
    gap: 6px;
}
.verifyContainer .header{
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #3C404A;
    margin-top: 20px;
}
.verifyContainer .headerError{
    color: #FA274A;
}
.verifyContainer .description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #767C8F;
}
.verifyContainer .tryAgain{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0061D3;
    gap: 6px;
    margin-top: 6px;
    cursor: pointer;
}
.verifyContainer .noLimitCTA{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}
.verifyContainer .limitCTA{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
}
.verifyContainer .tryAgain:hover, .verifyContainer.tryAgain:focus, .textLink:hover, .textLink:focus{
    color: #004EA9;
}
.limitCTA .linkContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 24px;
}
.verifyContainer .continueBtn{
    width: fit-content;
}
@media (max-width:600px){
    .addBankForm{
         margin-bottom:126px;
     }
 }