.dropDown {
	margin: 0px;
}

.bankDropdown {
	max-height: 231px;
	overflow: auto;
	z-index: 100;
	position: absolute !important;
	/* left: 133px; */
	/* margin-top: 6px; */
	transform: translate(0px, 62px) !important;
	background: #ffffff;
	border: 2px solid rgba(22, 37, 66, 0.25);
	box-sizing: border-box;
	top: -2px;
	width: inherit;

	/* width: 337px; */
	/* width: 96%; */
}

.flag {
	position: absolute;
	padding-top: 18px;
	padding-left: 15px;
}
.inputFlag {
	padding-left: 40px;
}

.dropdownCard {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.dropDownItem {
	padding-left: 16px;
	padding-right: 16px;
	cursor: pointer;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #162542;
	padding-bottom: 15px;
  padding-top: 15px;
  letter-spacing: 0.4px;
  overflow-wrap: break-word;
}
.dropDownAlt {
  font-size: 10px;
  display: inherit;
}
.dropDownItem:hover {
	background-color: #f7f7f8;
}

.container {
	width: 100%;
	position: relative;
}

.container input {
	font-family: 'geomanistregular';
	padding: 20px;
	padding-right: 55px;
	background: #ffffff;
	border: 2px solid rgba(22, 37, 66, 0.25);
	box-sizing: border-box;
  border-radius: 0px;
  letter-spacing: 0.6px;
}

.container input:active,
.container input:hover,
.container input:focus {
	border: 2px solid #0061d3;
	box-shadow: none;
}

.container label {
	visibility: hidden;
	left: 8px;
	position: absolute;
	top: 0;
	opacity: 0;
	transition: 250ms;
}

.container input:placeholder-shown {
	/* font-family: 'Inter'; */
	padding-right: 55px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}
.container input::placeholder{
  padding-right: 55px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::-webkit-input-placeholder {
	font-family: 'geomanistregular';
	font-style: normal;
	padding-right: 55px;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::-moz-placeholder {
	font-family: 'geomanistregular';
	padding-right: 55px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input:not(:placeholder-shown) + label {
	visibility: visible;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	/* color: #ffffff; */
	height: 25px;
	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	/* background: #0061d3; */
	color: rgba(22, 37, 66, 0.75);
	background-color: #c5c8d0;
	border-radius: 12px;
	transform: translate(0, -50%);
	opacity: 1;
}

.container input:disabled {
	background-color: white;
}

.dropDownImage {
	cursor: pointer;
	position: absolute;
	right: 33px;
	top: 2px;
	padding-top: 18px;
	opacity: 0.4;
}

@media only screen and (max-width: 780px) {
	.dropDownImage {
		right: 22px;
		top: -6px;
	}
	.bankDropdown {
		top: -16px;
	}
}
