@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.ratingContainer>span>span {
    margin:0px 11px
}
  
.ratingContainer img {
    width: 13.9px;
}

.ratingContainer {
    text-align: center;
    padding:9px 0px 6px 0px;
    margin-top: 9px;
    border-top: 1.5px solid #D3D4DA;
}

.shariahRatingFilter p {
    margin: 0px;
    cursor: pointer;
}

.shariahRatingFilter {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    margin-top: 16px;
    border: 1.5px solid #D3D4DA;
    box-shadow: 0px 2px 16px rgba(60, 64, 74, 0.08);
    color: #666666;
}

.shariahDropDown {
    background-color: white;
    border-radius: 8px;
    position: relative;
    top:8px;
    border: 1.5px solid #D3D4DA;
    box-shadow: 0px 2px 16px rgba(60, 64, 74, 0.08);
    padding: 4px 0px;
    color: #666666;
}

.shariahText {
    padding:6px 10px;
}

.shariahText:hover {
    background-color: #EFEFF1;
}

.shariahDropDown p {
    margin: 0px;
    cursor: pointer;
}

.shariahHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}