.stockList {
	margin-right: 4.39%;
	margin-left: 4.39%;
	border-bottom: 1px solid #eaeaea;
	/* border-bottom: 1px solid black; */
	/* padding: 16px; */
	padding-top: 2.5%;
	width: 289px;
	height: 62px;
	/* left: 1135px;
	top: 125px; */
}
.stockImage {
	height: 45px;
	width: 45px;
	/* position: absolute; */
	/* left: 0%;
	right: 85.81%;
	top: 3.23%;
	bottom: 32.26%; */
	border-radius: 50%;
	border: 1px solid #f0efef;
	box-sizing: border-box;
}
.stockTitle {
	/* position: absolute; */
	/* left: 18.34%;
	right: 71.97%; */
	/* top: 0%;
	bottom: 61.29%; */
	font-family: 'geomanistmedium';
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: 0.56px;
	color: #1e1f24;
}

.stockName {
	/* position: absolute; */
	/* left: 18.34%; */
	/* right: 57.09%; */
	/* top: 38.71%; */
	/* bottom: 29.03%; */
	font-family: 'geomanistregular';
	/* padding-top: 5px; */
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.56px;
	color: #626777;
	/* overflow: hidden; */
	white-space: nowrap;
}
.stockRemove {
	padding-top: 10%;

	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;

	text-align: right;
	letter-spacing: 0.6px;

	color: #e61446;
}
.stockValue {
	font-family: 'geomanistbook';
	font-size: 16px;
	line-height: 24px;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	/* line-height: 1.09; */
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: right;
}
.stockIncrement {
	font-family: 'geomanistbook';
	text-align: right;
	font-size: 14px;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.6px;
	color: #13b948;
}

.redPercentChange{
  font-family: 'geomanistbook';
	text-align: right;
	font-size: 14px;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.6px;
	color: #FA274A;
}

.link{
  text-decoration: none;
}

@media (max-width: 700px) {
  .stockList{
    width: auto;
    height: 80px;
    margin-left:15px;
    margin-right:15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top:16px;
    padding-bottom: 16px;
  }
}
