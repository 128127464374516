@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';


.virtualCardContainer p {
    margin: 0px;
}

.virtualCardContainer {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    height: 198px;
    cursor: pointer;
}

.fetchData {
    position: relative;
    overflow: hidden;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    height: 198px;
    cursor: pointer;
}

.fetchData p {
    margin: 0px;
}

.shadowFrozen {
    background: #F7F7F8;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border: 1px solid #D3D4DACC;
}

.cardInfo {
    padding: 20px 20px 24px 20px;
}

.cardHolder {
    height: 56px;
    padding: 22px 20px;
}

.cardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardStatus {
    display: flex;
    align-items: center;
    gap:6px;
}

.cardHeader {
    font-size: 16px;
    line-height: 24px;
}

.cardText {
    font-size: 14px;
    line-height: 20px;
}

.cardName {
    font-size: 12px;
    line-height: 16px;
}

.textWhite {
    color: #FFFFFF;
}

.textBlack {
    color: #3C404A;
}

.textGrey {
    color: #767C8F;
}

.textBlue {
    color: #0061D3;
}

.fontWeight {
    font-weight: 500;
}

.cardVideo {
    width:100%;
    height: 198px;
    border-radius: 16px;
}

.loadingVideo {
    mix-blend-mode: multiply;
}

video {
    object-fit: cover !important;
}

.filterFrozen {
    filter: grayscale(100%);
}

.cardContent {
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    z-index: 100;
}
