@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.value {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.title {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.percentage {
	margin-left: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
}

.name {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.marketActive {
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin-left: 4px;
	margin-bottom: 1px;
	background: #0dc014;
}
.marketInActive {
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin-left: 4px;
	margin-bottom: 1px;
	background: #FA274A;
}

@media (min-width: 320px) and (max-width: 480px) {
	.marginBottom {
		margin-bottom: 8px;
	}
	.name {
		margin-bottom: 11px;
	}
	.value {
		font-size: 20px;
	}
	.percentage {
		font-size: 12px;
		color: #0dc014;
	}
	.title {
		font-size: 12px;
		margin-bottom: 4px;
	}
  .increaseMargin{
    margin-bottom: 20px;
  }
  .value{
    font-family:'geomanistBook';
  }
}

@media (min-width: 481px) and (max-width: 990px) {
  .increaseMargin{
    margin-bottom: 20px;
  }
  .value{
    font-family:'geomanistBook';
  }
}
