.alert{
    height: 48px;
    border-radius: 0px;
    font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #FFFFFF;
margin-bottom: 0px;
text-align: center;
width: 100%;
position: fixed;
z-index: 1000;
}

/* @media only screen and (max-width: 480px) {
	.alert{
    position: relative;
  }
} */
