.emptyPageParent {
  position: relative;
  top: -60px;
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.emptyPageHeadText {
  display: flex;
  align-self: center;
  font-family: 'geomanistmedium';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.6000000238418579px;
  text-align: center;
  color: #000000;
}
