@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';


.title{
font-family: 'geomanistMedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.6px;
color: #1E1F24;
}

.head{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #626777;
white-space: nowrap;
}

.val{
font-family: 'geomanistRegular';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
letter-spacing: 0.6px;
color: #1E1F24;

}

@media (max-width: 768px){

  .title{
    font-size: 20px;
    line-height: 28px;
  }

  .head{
    font-family: 'geomanistRegular';
    }

    .val{
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      }
}



