@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.textAreaStartBanner{
    padding-left:63px;
    /* padding-bottom:53px; */
    padding-top:56px;
}

.headingStartBanner{
    font-family: 'geomanistregular';
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.6px;
    color: #1E1F24;
}

.ErrorMessageStartBanner{
    font-family: 'geomanistRegular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #E61446;
}

.blueTextStartBanner{
    font-family: 'geomanistRegular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #005DD6;
}

.bannerTextStartBanner{
    font-family: 'geomanistRegular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1E1F24;
}

.buttonStartBanner{
    width: 173px;
    height: 40px;
    left: 208px;
    top: 310px;
    background: #005DD6;
    border-radius: 8px;
    border: none;
    color: white;
    font-family: 'geomanistMedium';
    letter-spacing: 0.4px;
    font-size: 14px;
    line-height: 20px;
}

.buttonStartBanner:focus{
    outline: none;
}

.helpTextStartBanner{
    font-family: 'geomanistBook';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    padding-top:16px
}

.imageStartBanner{
    /* height: 300px; */
    /* width: 300px; */
    display: flex;
    align-items: flex-end;
    width:75%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.shortBannerTextWidth{
    font-family: 'geomanistRegular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1E1F24;
    width: 65%;
    /* padding-right:200px; */
}

@media (min-width: 990px) and (max-width:1200px) {
  .shortBannerTextWidth{
    font-family: 'geomanistRegular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1E1F24;
    width: 100%;
    /* padding-right:200px; */
  }
}

@media only screen and (max-width: 990px) {
    .shortBannerTextWidth{
        width: 100%;
    }
    .textAreaStartBanner{
        padding-left: 40px;
        padding-top: 40px;
        padding-bottom:40px;
        padding-right: 40px;
    }
    .ImageCol{
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .textAreaStartBanner{
      padding-left: 44px;
      padding-top: 40px;
      padding-bottom:40px;
      padding-right: 40px;
    }
}
