.labelTitle{
font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #626777;
padding-left: 4px;
}
.error{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: #162542;
  text-align: center;
  margin-top: 30px;
}
.spinner{
  padding-top: 150px;
  text-align: center;
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}
.rectangle {
	font-size: 16px;
  width: 100%;
	font-style: normal;
	height: 40px;
  color: #666666;
	background: #efeff1;
	border-radius: 8px;
	padding-top: 0px;
  padding-left: 8px;
	padding-right: 0px;
	border: none;
	outline: none !important;
}


@media (max-width: 580px) {
  .margin{
    margin-bottom: 20px;
  }
}
