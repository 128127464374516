@import '../../public/geomanist/geomanistBold/stylesheet.css';
@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  /* checked */
}
.desc {
	margin-top: 8px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
  /* checked */
}
.head {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #626777;
  white-space: nowrap;
  margin-bottom: 4px;
}
.val {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  white-space: nowrap;
}

.know {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 180%;
	letter-spacing: 0.6px;
	color: #005dd6;
	cursor: pointer;
  margin-left: 10px;
  white-space: nowrap;
}

.about{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.aboutDiv{
  margin-top: 48px;
}


.aboutSlider  :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;
}

.aboutSlider  :global(.slick-next) {
	display: none !important;
}

@media (max-width: 770px) {
.title {
  font-size: 20px;
  line-height: 28px;
  }
.desc{
font-size: 14px;
line-height: 20px;
}
.aboutDiv{
  margin-top: 40px;
}
.aboutSlider{
  height: 150px;
  width: auto;
}
.head{
font-family: 'geomanistregular';
font-size: 12px;
line-height: 16px;
}
.val {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;

}
.aboutDiv{
  margin-top: 32px;
}
}
