.reportMargin{
  margin-bottom: 40px;
}
.reportBlock {
  padding: 25px;
  /* width: 352px; */
  height: 212px;
  /* width: 96.53%; */
  /* height: 151.44%; */
  background: #f7f7f8;

  border-radius: 12px;
}
.reportBlock h6{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.6px;
  color: #021241;
}

.reportBlock p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.4px;
  height: 48px;
  color: #505562;
}

.reportBlock button {
  /* width: 100%; */
  /* height: 40px; */

  /* Primary/500 */
  position: absolute;
    bottom: 19px;
    width: 300px;

  border: 1px solid #005dd6;
  box-sizing: border-box;
  border-radius: 8px;
}
.quickoReport{
  height: 315px !important;
  padding:40px !important
}

.quickoButtonImage{
  margin-right: 13px;
  margin-bottom: 2px;
}
.quickoButton{
  bottom: 6px !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .reportBlock button {
    width: 255px;
  }
  .reportBlock{
    padding:15px;
    height: 194px;
  }
  .reportBlock h6{
    font-size:16px
  }
  .reportBlock p {
    font-size:14px
  }
}
@media (min-width: 500px) and (max-width: 992px) {
  .reportBlock button {
    width: 187px;
  }
  .reportBlock{
    padding:10px;
  }
  .reportBlock h6{
    font-size:14px
  }
  .reportBlock p {
    font-size:10px
  }
  .quickoImageCol{
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .reportBlock h6 {
    font-size: 16px;
  }
  .coloumMargin{
    margin-bottom: 20px;
  }
  .reportBlock{
      height: 192px;
  }

  .reportBlock button {
    position:static;
    width: 100%;
  }
  .reportBlock p {
    font-size: 14px;
    letter-spacing: 0px;
  }
  .reportMargin{
    margin-bottom: 20px;
  }
  .quickoReport{
    margin: 0px;
    height: 300px !important;
    padding: 25px !important;
  }
  .quickoImageCol{
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .reportBlock h6 {
    font-size: 16px;
  }
  .coloumMargin{
    margin-bottom: 20px;
  }
  .reportBlock{
      height: 192px;
  }

  .reportBlock button {
    position:static;
    width: 100%;
  }
  .reportBlock p {
    font-size: 14px;
    letter-spacing: 0px;
  }
  .reportMargin{
    margin-bottom: 20px;
  }
  .quickoReport{
    margin: 0px;
    height: 300px !important;
    padding: 25px !important;
  }
  .quickoImageCol{
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .reportBlock button {
    width: 120px;
    position: absolute;
    bottom: 19px;
  }
}

.quickoBanner {
  background-color: #D7E3F1;
  border-radius: 12px;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.bannerContent p {
  margin: 0px;
}

.bannerTitle {
  font-family: 'Inter';
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #0061D3;
  max-width: 529px;
}

.terms {
  font-family: 'geomanistregular';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #616770;
}

.bannerButton {
  font-family: 'geomanistregular';
  border: 2px solid #0061D3;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #D7E3F1;
  color: #0061D3;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

@media (max-width:768px) {
  .quickoBanner {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .bannerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:16px;
  }

  .bannerButton {
    margin-top: 20px;
    padding: 8px 12px;
  }

  .bannerTitle {
    font-size: 14px;
    line-height: 22px;
  }

  .terms {
    font-size: 12px;
    line-height: 17px;
  }

  .invoiceTableHead{
    font-size: 12px;
  }

  .inovicesTableValue{
    font-size: 10px;
  }
}

@media (min-width:768px) {
  .quickoBanner {
    margin-top: 40px;
  }

  .quickoBanner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .bannerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;
  }

  .bannerTitle {
    font-size: 16px;
    line-height: 24px;
  }

  .terms {
    font-size: 14px;
    line-height: 20px;
  }

  .bannerButton {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
  }
}

@media (min-width:768px) and (max-width:992px) {
  .bannerTitle {
    font-size: 14px;
    line-height: 22px;
  }

  .terms {
    font-size: 12px;
    line-height: 17px;
  }

  .bannerButton {
    padding: 8px 12px;
  }
  .invoiceTableHead{
    font-size: 12px;
  }

  .inovicesTableValue{
    font-size: 10px;
  }
}
