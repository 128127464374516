.subHeader{
    font-family: "geomanistBook";
    font-size: 14px;
    color: #3C404A;
    padding: 0px 4px 16px;
    border-bottom: 1px solid #EFEFF1;
}
.body{
    padding: 16px 0px 42px 0px;
    /* fixed footer size */
    margin-bottom:86px;
}
.panContainer{
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFF1;
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #767C8F;
    gap: 8px;
}
.panContainer .input{
    padding: 10px 12px;
    font-family: 'geomanistBook';
    border: 1.5px solid #D3D4DA;
    border-radius: 6px;
    font-size: 14px;
    color: #3C404A;
    display: flex;
    justify-content: space-between;
}
.panContainer input::placeholder{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
}
.input:hover, .input:focus-within{
    border: 1.5px solid #3C404A;
  }
.panInputError:hover, .panInputError:focus-within{
    border: 1.5px solid #FA274A !important;
}
.panError{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #FA274A;
    margin-top: 6px;
}
.panError span{
    color:#0061D3;
    cursor: pointer;
    margin-left: 6px;
}
.panError span:hover{
    text-decoration: underline;
}
.panVerifying{
    color: #767C8F;
    font-family: 'geomanistRegular';
    font-size: 12px;
    margin-top: 6px;
}
.panVerified{
    display: flex;
    justify-content: space-between;
    color: #3C404A;
    margin-top: 8px;
}

.lrsQuestionContainer{
    border-bottom: 1px solid #EFEFF1;
    display: flex;
    justify-content:space-between;
    padding: 16px 0px;
}
.lrsQuestionContainer .label{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #767C8F;
    margin-right: 24px;
}
.lrsQuestionContainer .option{
    display: flex;
    cursor: pointer;
}
.lrsQuestionContainer .option img{
    width: 20px;
    height: 20px;
}
.lrsQuestionContainer .option .label{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    margin: 0px 16px 0px 6px;
    cursor: pointer;
}
.transactionContainer{
    padding-top: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFF1;
}
.transactionNote{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    margin-top: 20px;
}
.transaction > div{
    padding-top: 20px;
}
.transaction label{
    font-family: 'geomanistBook';
    font-size: 12px;
    color: #3C404A;
}
.transaction .inputContainer{
    padding: 10px 12px;
    width: 100%;
    border: 1.5px solid #D3D4DA;
    border-radius: 6px;
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    display: flex;
}
.transaction input::placeholder{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
}
.inputContainer:hover, .inputContainer:focus-within{
    border: 1.5px solid #3C404A;
  }
.transaction input{
    border: none;
    width: 100%;
}
.transaction .helper{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #767C8F;
}
.footerContainer{
    position: fixed;
    display: flex;
    bottom: 0;
    border-top: 1px solid #EFEFF1;
    padding: 24px;
    margin-left: -24px;
    gap:16px;
    background: #FFFFFF;
    width: 100%;
  }
.footerContainer .cancelBtn{
    font-family: "geomanistBook";
    padding: 10px 20px;    
    border-radius: 8px;
    color: #0061D3;
    border: 1.5px solid #0061D3;
    background: #FFFFFF;
    outline-color: #0061D3;
}
.footerContainer .cancelBtn:focus, .footerContainer .cancelBtn:hover{
    border: 1.5px solid #004EA9;
    color: #004EA9;
}
.footerContainer .continueBtn{
    font-family: "geomanistBook";
    background: #0061D3;
    padding: 10px 20px;    
    border-radius: 8px;
    border: 1.5px solid #0061D3;
    color: #FFFFFF;
    outline-color: #0061D3;
    display: flex;
    align-items: center;
}
.footerContainer .continueBtn:focus, .footerContainer .continueBtn:hover{
    background: #004EA9;
}
.footerContainer .tertiaryBtn{
    font-family: "geomanistBook";
    background: none;
    border: none;
    color: #0061D3;
    outline:none;
    padding: 0px;
  }
.footerContainer .tertiaryBtn:focus,  .footerContainer .tertiaryBtn:hover{
    outline: none;
    color: #004EA9;
}
.step, .instruction li::marker{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
}
.description{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
}
.downloadPDF{
    font-family: "geomanistmedium";
    margin-top: 16px;
    background: #0061D3;
    padding: 10px 16px 10px 12px;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
}
.downloadPDF:focus, .downloadPDF:hover{
    outline-color: #0061D3;
    background: #004EA9;
}
.downloadPDF img{
    margin-right: 6px;
}
.instruction{
    padding-left: 24px;
    margin: 0px;
}
.instruction li{
    margin-bottom: 24px;
}
.lrsIDContainer{
    display: flex;
    flex-direction: column;
    gap:24px;
    margin: 32px 0px;
    align-items: center;
}
.lrsIDContainer img{
    width: 140px;
}
.lrsIDContainer .lrsID{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #626777;
}
.addTransaction{
    display: flex;
    gap: 4px;
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #0061D3;
    margin-top: 24px;
    cursor: pointer;
    padding-bottom: 24px;
    border-bottom: 1px solid #EFEFF1;
}
.transactionCount{
    display: flex;
    gap:4px;
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
}
.deleteTransaction{
    cursor: pointer;
}
.reviewContainer>div{
    margin-bottom: 20px;
}
.reviewContainer .label{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #767C8F;
    width: 50%;
}
.reviewContainer .value{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    width: 50%;
    text-align: right;
}
.errorInput{
    display: flex;
    justify-content: space-between;
}
.errorInput:hover, .errorInput:focus-within{
    border: 1.5px solid #FA274A !important;
}
.errorMsg{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #FA274A;
}
.spinnerColor{
    border: .25em solid #FFFFFF;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.genericLoading{
    height: calc(100vh - 144px);
    display: flex;
    justify-content: center;
}
.panVerified{
    display: flex;
    justify-content: space-between;
    color: #767C8F;
}
.panVerified .panNumber{
    color: #3C404A;
    display: flex;
    gap: 8px;
}
@media (max-width:600px){
   .body{
        margin-bottom:126px;
    }
}