.card {
	width: 840px;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
}

.bankName {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.download {
	padding-left: 10px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #005dd6;
}

.transactionCard {
	background: #f7f7f8;
	border-radius: 8px;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}
.transaction {
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.step {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  white-space: nowrap;
}
.netbanking {
	margin-top: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.login {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
	margin: 10px 0 0 10px;
	cursor: pointer;
}
.key {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.value {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.subtopic {
	padding-top: 20px;
	padding-left: 30px;
	width: 400px;
	color: black;
	font-family: 'geomanistBook';
}
.responsiveDiv{
  padding-bottom: 24px;
  border-bottom: 1px solid #efeff1;
}
.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
}
.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.fixedDiv {
	background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	);
	/* text-align: end; */
	position: fixed;
	height: 120px;
	bottom: 0px;
	/* right: 0px; */
	/* margin-right: 0%; */
	padding-top: 50px;
	width: 940px;
}
.responsiveInstructions{
  width: 100%;
}
.fixedAccount{
  position: fixed;
  width: 880px;
  /* width: 60%; */
  height: 61px;
  top: 51px;
  z-index: 1000;
  background: white;
}
.accountNumber{
  justify-content: flex-end;
  padding-right: 30px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 28px;
display: flex;
align-items: center;
letter-spacing: 0.6px;
color: #1E1F24;
opacity: 0.6;
}
.circularPointer{
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  border: 1.5px solid #162542;
  border-radius: 50%;
  background-color: #162542;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}
.body{
  padding: 0 15px;
}
@media (max-width: 768px) {
	.card {
		width: 540px;
	}
  .fixedDiv {
    width: 640px;
  }
  .fixedAccount{
    width: 540px;
  }
  .accountNumber{
    justify-content:flex-start;
  }
}

@media (min-width: 769px) and (max-width:1024px){
  .card{
    width: 640px;
  }
  .fixedDiv {
    width: 740px;
  }
  .responsiveInstructions{
    width: 100%;
  }
  .responsiveImage{
    width: 100%;
  }
  .fixedAccount{
    width: 740px;
  }
  .accountNumber{
    justify-content:flex-start;
  }
}


@media (max-width:576px){
  .accountNumber{
    font-size: 12px;
    justify-content: start;
    padding-left: 45px;
  }
  .card{
    min-width: 300px;
    max-width: 576px;
    width: 100%;
  }
  .responsiveDiv{
    padding-bottom: 0px;
    border-bottom: none;
  }
  .title {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 8px;
    text-align: center;
    margin-left: 0px;
  }

  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
  .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    height: 100%;
  }
  .pdf{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #005DD6;
    padding-left: 5px;
      }
    .responsiveInstructions{
      width: 100%;
    }
    .responsiveImage{
      width: 100%;
    }
    .download{
      font-size: 10px;
    }
    .body{
      padding: 0 8px;
    }
}

@media (max-width:375px){
  .card{
    width:100%;
  }
  /* .title {
    margin-left: 100px;
  } */
}

@media (max-width:320px){
  .card{
    width:100%;
  }
  /* .title {
    margin-left: 90px;
  } */
}
