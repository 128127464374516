.timelineWrap {
	position: relative;
}

.timeline {
	height: 1px;
	width: 100%;
	background-color: #6097a1;
	position: relative;
}

.marker {
	border: 1px solid #6097a1;
	z-index: 1000;
	color: #6097a1;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 1em;
	text-align: center;
	position: absolute;
	/* margin-left: -25px; */
	background-color: white;
	border-radius: 50%;
	top: -20px;
}

.marker:hover {
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);

	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.step{
  font-family: 'geomanistregular';
  font-style: normal;
	font-weight: normal;
	font-size: 14px;
  color: #1e1f24;
}

.stepActive{
  font-family: 'geomanistregular';
  font-style: normal;
	font-weight: normal;
	font-size: 14px;
  color: rgb(0, 93, 214);

}

.timlineWidth{
  width:785px
}

@media (max-width: 500px) {
  .timelineWrap{
    width: 88%;
  }
}


@media (max-width: 800px) {
  .timlineWidth{
    width:112%;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .timlineWidth{
    width:100%;
    margin-left:0px;
    text-align: center;
  }
}
