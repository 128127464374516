.container {
	display: inline-block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: relative;
    display: block;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #f7f7f8;
	border: 2px solid #d3d4da;
	border-radius: 4px;
}
.error{
	border: 2px solid #FA274A;
}
.checkmark:focus-within, .checkmark:hover{
	border: 2px solid #004EA9 !important;
}
/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
	background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #FFFFFF;
    border: 2px solid #0061D3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5.5px;
    top: 1.5px;
    width: 5px;
    height: 10px;
    border: solid #0061D3;
    border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
