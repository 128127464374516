.subHeader{
  font-family: "geomanistBook";
  font-size: 14px;
  color: #3C404A;
  padding: 0px 4px 16px;
  border-bottom: 1px solid #EFEFF1;
}

.beneficiaryTable{
  border: 1px solid #D3D4DA;
  border-radius: 8px;
  background: #EFEFF1;
}
.beneficiaryTableRow{
  border-bottom: 1px solid #D3D4DA;
  display: flex;
  align-items: flex-start;
  padding: 12px;
}
.beneficiaryTable .beneficiaryTableRow:last-child{
  border: none;
}
.beneficiaryTableRow > img{
  margin-right:8px;
}
.beneficiaryTableContent{
  width: 100%;
}
.beneficiaryTableKey{
  font-family: 'geomanistRegular';
  font-size: 14px;
  color: #162542;
  font-weight: 600;
}
.beneficiaryTableValue{
  font-family: 'geomanistRegular';
  font-size: 14px;
  color: #162542;
  display: flex;
  justify-content: space-between;
  margin-top:12px;
  flex-grow: 1;
  gap:12px;
}
.beneficiaryTableValue > span{
  padding: 6px 11px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
}
.beneficiaryAccNum{
  background: #DEEFF2;
}
.beneficiaryAccInfo{
  font-family: 'geomanistRegular';
  font-size: 12px;
  color: #0061D3;
  font-weight: 600;
}
.divider{
  width: 100%;
  height: 1px;
  background-color: #CAD0D7;
  margin: 15px 0px 5px 0px;
}
.copyIcon{
  width: 20px;
  cursor: pointer;
}
.primaryCopyIcon{
  width: 16px;
  cursor: pointer;
}
.tooltipCopy :global(.tooltip-inner){
  background-color:  #E0ECFA;
  color: #0061D3;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  font-size: 12px;
}
.tooltipCopyFixed{
  position: fixed;
  bottom: 110px;
  left: 0;
  width: 100vw;
  text-align: center;
  z-index: 1;
}
.tooltipCopyFixed span{
  padding: 8px 12px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background-color: #E0ECFA;
  color: #0061D3;
  border-radius: 8px;
  font-size: 12px;
}
.tooltipCopy :global(.arrow::before){
  border-top-color: #E0ECFA;
}
.footerContainer{
  position: fixed;
  display: flex;
  bottom: 0;
  border-top: 1px solid #EFEFF1;
  padding: 24px;
  margin-left: -24px;
  gap:16px;
  background: #FFFFFF;
  width: 100%;
}
.footerContainer .cancelBtn{
  font-family: "geomanistBook";
  padding: 10px 20px;    
  border-radius: 8px;
  color: #0061D3;
  border: 1.5px solid #0061D3;
  background: #FFFFFF;
  outline-color: #0061D3;
}
.footerContainer .cancelBtn:focus, .footerContainer .cancelBtn:hover{
  border: 1.5px solid #004EA9;
  outline-color: #004EA9;
  color: #004EA9;
}
.footerContainer .continueBtn{
  font-family: "geomanistBook";
  background: #0061D3;
  padding: 10px 20px;    
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  outline-color: #0061D3;
  display: flex;
  align-items: center;
}
.footerContainer .continueBtn:focus, .footerContainer .continueBtn:hover{
  background: #004EA9;
}
.footerContainer .tertiaryBtn{
  font-family: "geomanistBook";
  background: none;
  border: none;
  color: #0061D3;
  outline:none;
  padding: 0px;
}
.footerContainer .tertiaryBtn:focus,  .footerContainer .tertiaryBtn:hover{
  outline: none;
  color: #004EA9;
}
.spinnerColor{
  border: .25em solid #FFFFFF;
  border-right-color: #CCDFF6;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.link{
  cursor: pointer;
}
.link:hover{
  text-decoration: underline;
}
.beneficiaryTableError{
  background: rgba(220, 104, 3, 0.12) !important;
  border: 1px solid rgba(220, 104, 3, 0.2);
  border-radius: 8px;
  font-family: 'geomanistBook';
  font-size: 14px;
  color: #3C404A;
}
.beneficiaryTableError .spinnerColor{
  border: .25em solid #0061D3;
  border-right-color: #CCDFF6;
}
