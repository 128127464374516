.fullScreen {
	background-color: white;
	margin: 0px;
	height: 100vh;
}

.selectionContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.selectionBox{
  padding:24px 32px 24px 32px;

  background: #FFFFFF;
  border: 1px solid #F7F7F8;
  box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
  border-radius: 16px;
}

.title{
  display: flex;
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #3C404A;
}

.subTitle{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
}
.orLabel {
  width: 100%;
  text-align: center;
font-family: 'geomanistbook';
font-size: 12px;
line-height: 16px;
  color: #3C404A;
}

.card {
	width: 100%;
	border: 1.5px solid #d3d4da;
	border-radius: 6px;
	margin: 16px 0px;
	cursor: pointer;
}

.cardBody {
	padding: 12px 20px;
}

.infoLogoImg {
	margin-left: 6px;
}

.cardTitle {
    display: flex;
	font-family: 'geomanistbook';
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
}

.cardSubTitle {
	margin-top: 4px;
	font-family: 'geomanistregular';
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
}

.cardAction {
	display: flex;
	justify-content: end;
	align-items: center;
}

.cardAction img {
	transform: rotate(180deg);
    width: 8px;
    height: 14px;
}

.noSupprted{
  margin: 50px 20px;
  font-weight: 600;
}

.stockalImage {
	height: 50px;
  margin-top: 10px;
}
