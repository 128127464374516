.responsiveInline{
  display: flex;
}

.imageResponsive{
  width: 32px;
  height: 32px;
}

.raingMargin{
  margin-top: 40px;
}

.buttonCss{
  margin-left: 5px;
  margin-top: 1px;
  white-space: nowrap;
  border: none;
  color: #005DD6;
  background: #FFFFFF;
  padding: 0px;
}

.blurrImage{
  display: flex;
  width: 100%;
  margin-top: 24px;
  height: 395px;
  align-items: center;
  padding: 70px 66px 77px 0px;
}

.morningText{
  margin-top: 8px;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #9096A8;
  white-space: nowrap;
}

.holdingResponsive{
  margin-top: 48px;
  margin-bottom: 24px;
}

.dateCss{
  display: flex;
  cursor: pointer;
  margin-top: 6px;
}

.morningStarCss{
  margin-top: 8px;
  height: 32.00056457519531px;
  width: 152.54913330078125px;
}

.holding{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}
.net{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #9096A8;
  white-space: nowrap;
  margin-top: 8px;
}

.netResponsive{
  text-align: end;
}

.provided{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #9096A8;
}

/* .responsiveInstrument{
  padding-left: 24px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
} */

/* .responsiveAllocation{
  padding-right: 24px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
} */

.responsiveAsset{
  /* padding-right: 24px; */
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
}

.responsiveColumn{
  margin: 0px;
  padding: 14px 24px 14px 24px;
}

/* .responsiveRow{
  margin:0px;
  margin-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EFEFF1;
} */

.assetRow{
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EFEFF1;
}

.code{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.rest{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  /* white-space: nowrap; */
}

.rest2{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.gradientBox{
  background: linear-gradient(360deg, red 10%, rgba(255, 255, 255, 0) 100%);
  /* background: linear-gradient(354.66deg, #FFFFFF 53.72%, rgba(255, 255, 255, 0) 94.88%); */
  position: absolute;
  /* width: 736px; */
  width: 96%;
  height: 96px;
}
.sector{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 48px;
}
.asset{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 72px;
  margin-bottom: 8px;
}

.seeAll{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #005DD6;
  margin-top: 32px;
  cursor: pointer;
}

.allRights{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.6px;
  color: #626777;
  margin-top: 56px;
  margin-bottom: 32px;
}

.industry{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 89px;
  margin-bottom: 24px;
}

.horizontalLine{
  margin-top: 40px;
  margin-bottom: 0px;
}
.assetTitle{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1E1F24;
}

.barTitle{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #3C404A;
  /* margin-left:16px; */
  margin-top:6px;
}

/* .fixedHolding{
  top: 47px;
  position: fixed;
  background: #FFFFFF;
  z-index: 1000;
  width: 50%;
} */

/* .tableResponsive {
  overflow-y: scroll;
  height: 90vh;
} */

.tableResponsive {
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.fixingMorningStar{
  width: calc(50vw);
}

.fixingMorningStar th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

.outerDiv{
  border: 1px solid #EFEFF1;
  box-sizing: border-box;
  box-shadow: 0px 2px 16px rgba(30, 31, 36, 0.12);
  border-radius: 8px;
  position:absolute;
  z-index: 1000;
  background: #FFFFFF;
  padding:8px;
  /* right:80px;
  margin-top: 32px; */
}

.innerDiv{
  margin:8px;
  cursor: pointer;
}

.innermostDiv{
  padding:8px;
  padding-left: 12px;
  border-radius: 4px;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  white-space: nowrap;
}

.buttonCss:focus{
  outline: none;
}

.headingCss{
  margin-top: 24px;
}

.headingCss th{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #767C8F;
  border-top: none;
  padding-bottom: 16px;
  white-space: nowrap;
}

.absoluteCover{
  position: absolute;
  left:0px;
  bottom: 0px;
  padding-left: 31px;
  padding-right: 31px;
  height: 570px;
}

.cardBorder{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(30, 31, 36, 0.07);
  border-radius: 16px;
}

.imagePadding{
  margin-left: 32px;
  margin-top: 23px;
  margin-bottom: 29px;
  width: 261px;
  height: 196px;
}

.cardContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #626777;
  margin-top: 8px;
}

.header{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}

.buttonDist{
  margin-top: 16px;
}

.icon {
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
  display: inline-block;
}

.icon:after {

  animation: shine 2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 1;
}

.toopTipImage{
  margin-left: 5px;
  margin-top: 5px;
  height: 12px;
  vertical-align: top;
}

.ratingStar{
  margin-top: 20px;
}

.morningStarLogoMarginUnrated{
  margin-top: 5px;
}

.noRating{
  display: inline-block;
  margin-left:5px;
  font-family: 'geomanistRegular';
  position: absolute;
  font-size: 12px;
  color: #9096A8;
  display: inline-table;
  margin-left: 5px;
  top: 3px;
}

.noRatingText{
  display: inline-block;
  margin-left:5px;
  font-family: 'geomanistRegular';
  font-size: 12px;
  color: #9096A8;
  margin-left: 5px;
  top: 3px;
}

.unratedText{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
  color: #9096A8;
}

.ratingOption{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: #F7F7F8;
  border-radius: 4px;
}
.ratingOption button{
  outline: 0;
}

.ratingOption button:last-child{
  border: none !important;
}

.yearButton{
  border: none;
  border-right: 1px solid #EFEFF1;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #9096A8;
  background: inherit;
  margin: 7px 0px;
  padding: 0px 12px;
}

.activeOption{
  color: #005DD6 !important;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
    transition-duration: 10s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.plan {
	background: linear-gradient(90deg, #e0980e 0%, #f4c66c 56.12%, #f2b133 100%);
	border-radius: 8px;
	border: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 130%;
	letter-spacing: 0.6px;
	color: #302001;
	padding: 13px;
	padding-left: 20px;
	padding-right: 20px;
}

:global(.table thead th){
  border-bottom: 1px solid #EFEFF1 !important;
}

:global(.table td){
  border-top: none !important;
}

.allocation{
  text-align: end;
}

.customTooltip{
  width: 200px;
  height: 130px;
  z-index: 4000;
  position:absolute;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(2, 2, 65, 0.11);
  border-radius: 8px;
  top: 75px;
  left: 0px;
}

.customTooltipArrow{
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  /* background: chartreuse; */
  position:absolute;
  border:none;
  transform:rotate(-135deg);
  border-radius:8px;
  top:-20px;
  left:50px;
  /* box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.05);
}


.customTooltipText{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #1E1F24;
  }

  .customTooltipButtonText{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.6px;
  color: #302001;
  background: linear-gradient(90deg, #E0980E 0%, #F4C66C 56.12%, #F2B133 100%);
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  margin: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  }

  .customTooltipButtonText:focus{
    outline: none;
    border:none;
    box-shadow: none;
  }


@media (min-width:992px) and (max-width: 1200px){
  .imagePadding{
    width: 200px;
    height: 150px;
  }
  .blurrImage{
    padding: 0;
  }
  .header{
    font-size: 16px;
  }
  .description{
    font-size: 12px;
  }
  .buttonDist{
    margin-top: 10px;
  }
  .plan {
    font-size: 12px;
    line-height: 130%;
    padding: 8px;
  }
}

@media (max-width:992px){
  .blurrImage{
    padding: 0;
    margin-top: 0;
  }
}

@media (max-width: 768px){
  .raingMargin{
    margin-top: 24px;
  }

  .dateCss{
    margin-top: 3px;
  }

  .imageResponsive{
    width: 24px;
    height: 24px;
  }

  .morningText{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    color: #9096A8;
    white-space: nowrap;
  }

  .morningStarCss{
    margin-top: 8px;
    height: 20.00035285949707px;
    width: 95.34320831298828px;
  }

  .holdingResponsive{
    margin-top: 42px;
    margin-bottom: 16px;
  }

  .holding{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
  }
  .net{
    margin-top: 0px;
  }
  .fixingMorningStar{
    width: calc(90vw) !important;
  }
}

@media (max-width: 767px){
  .cardBorder{
    text-align: center;
  }
  .imagePadding{
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 200px;
    height: 150px;
  }
  .header{
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
  .description{
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
  }
}

@media (max-width:425px){
  .netResponsive{
    text-align: start;
    margin-top: 8px;
  }
  /* .responsiveInstrument{
    padding-left: 0px;
  } */

  /* .responsiveAllocation{
    padding-right: 0px;
  } */
  /* .responsiveCol{
    padding-right: 0px;
  } */
  /* .responsiveColumn{
    margin: 0px;
    padding: 10px 16px 10px 16px;
  } */
  /* .responsiveRow{
    margin-bottom: 16px;
  } */
  .rest2{
    padding-right: 0px;
  }

  .gradientBox{
    width: 93%;

  }
  .seeAll{
    font-style: normal;
    font-weight: normal;
    font-family: 'geomanistBook';
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
  }
  .horizontalLine{
    margin-top: 32px;
    margin-bottom: 0px;
  }
  .sector{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    margin-top: 32px;
  }
  .asset{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .assetRow{
    padding-top: 10px;
    padding-bottom: 9px;
    border-bottom: 1px solid #EFEFF1;
  }
  .assetTitle{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #1E1F24;
  }
  .industry{
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    margin-top: 42px;
    margin-bottom: 16px;
  }
  .allRights{
    margin-top: 42px;
    margin-bottom: 40px;
  }
  .barTitle{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #3C404A;
    margin-top: 8px;
  }
  .buttonCss{
    margin-left: 0px;
    margin-top: 0px;
  }
  /* .fixedHolding{
    position: fixed;
    background: #FFFFFF;
    z-index: 1000;
    width: 92%;
  } */
  .instrument{
    padding-left: 0px;
  }
  .allocation{
    padding-right: 0px;
  }
  .headingCss th{
    padding-left: 0px;
    padding-right: 0px;
  }
  .noRating{
    margin-left:2px;
    font-size: 6px;
  }
  .noRatingText{
    display: block;
    margin-left:5px;
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #9096A8;
    margin-left: 5px;
    top: 3px;
  }
  .morningStarTableBody td{
    font-size: 13px;
    padding: 5px;
  }
  .fixingMorningStar{
    width: calc(90vw) !important;
  }
}
@media (max-width: 420px){
  .responsiveInline{
    display: block;
  }
}

