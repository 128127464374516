@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 840px;
}
.responsiveDiv{
  padding-bottom: 24px;
  border-bottom: 1px solid #efeff1;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
}

.transfer {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #1e1f24;
}

.bankCard {
	cursor: pointer;
	margin-top: 12px;
	margin-right: 40px;
	height: 56px;
	width: 200px;
	background: #ffffff;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);
	border-radius: 8px;
}
.bankName {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.betaTitle{
  font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	color: #005dd6;
  margin-left: 4px;
  vertical-align: super;
}
/* @media (max-width: 768px) {
	.card {
		width: 540px;
	}
} */


@media (max-width:768px){
  .card{
    width: 100%;
  }

  .responsiveDiv{
    padding-bottom: 0px;
    border-bottom: none;
  }
  .title {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 8px;
    text-align: center;
    margin-right:50px;
  }
  .transfer {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
  }
  .bankCard {
    cursor: pointer;
    margin-top: 16px;
    margin-right: 0px;
    height: 56px;
    width: 56px;
    background: #ffffff;
    border: 1px solid #efeff1;
    box-sizing: border-box;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding-top: 11px;
  }
  .bankName {
    font-family: 'geomanistRegular';
    font-size: 10px;
    line-height: 16px;
    text-align: start;
    letter-spacing: 0.6px;
    color: #626777;
    white-space: nowrap;
    /* text-align: center; */
    width: 75%;
    margin-top: 8px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
	.card {
		width: 500px;
	}

  .responsiveDiv{
    padding-bottom: 24px;
    border-bottom: 1px solid #efeff1;
  }
  .title {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 8px;
    margin-left: 0px;
  }

  .transfer {
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #1e1f24;
  }

  .bankCard {
    cursor: pointer;
    margin-top: 12px;
    margin-right: 40px;
    height: 56px;
    width: 200px;
    background: #ffffff;
    border: 1px solid #efeff1;
    box-sizing: border-box;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding-top: 0px;
  }
  .bankName {
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-top: 0px;
    white-space: initial;
    width: 100%;
  }
  .betaTitle{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #005dd6;
    margin-left: 4px;
    vertical-align: super;
  }
}
@media (max-width:375px){
  /* .card{
    width: 345px;
  } */
  .title {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 8px;
    text-align: center;
    margin-left:5px;
    margin-right: 40px;
  }
  .bankName {
    font-family: 'geomanistRegular';
    font-size: 10px;
    line-height: 16px;
    text-align: start;
    letter-spacing: 0.6px;
    color: #626777;
    white-space: nowrap;
    /* text-align: center; */
    width: 85%;
    margin-top: 8px;
  }
}

@media (max-width:325px){
  /* .card{
    width: 295px;
  } */
  .title {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    margin-bottom: 8px;
    text-align: center;
    margin-left:20px;
  }
  .bankName {
    font-family: 'geomanistRegular';
    font-size: 10px;
    line-height: 16px;
    text-align: start;
    letter-spacing: 0.6px;
    color: #626777;
    white-space: nowrap;
    /* text-align: center; */
    width: 100%;
    margin-top: 8px;
  }
}


.okbutton{
  padding:10px;
  width: 100px;
  height: 40px;
  margin-top: 1px;
  white-space: nowrap;
  border: none;
  color: #FFFFFF;
  background:  #005DD6;
  padding: 0px;
}
