@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 256px;
	height: 266px;
	background: #f5f7fa;
	border-radius: 12px;
	border: none;
}

.cardPadding {
	padding: 40px 32px 46px 32px;
}

.didKnowText {
	margin: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #021241;
}

.note {
	margin: 0px;
	margin-top: 16px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #0e2a78;
}

.upgradeButton {
	background: #005dd6;
	border-radius: 8px;
	width: 191px;
	height: 40px;
	font-family: 'geomanistbook';
	font-weight: 500;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	border: none;
	box-shadow: none;
	color: #ffffff;
}
.upgradeButton:hover {
	background: #1644c3;
	border-radius: 6px;
	color: #ffffff;
	border: none;
	box-shadow: none;
}

.upgradeButton:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.upgradeButton:not(:disabled):not(.disabled):active {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.upgradeButton:not(:disabled):not(.disabled):active:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}
