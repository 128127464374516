@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';
.coloredLegend {
	width: 16px;
	height: 16px;
	left: 1136px;
	top: 490px;
	background: #005dd6;
	border-radius: 4px;
	display: inline-block;
	margin-right: 9px;
	vertical-align: middle;
}

.legendPercentage {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-right: 5px;
}
.value {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.portfolio {
  margin-top: 50px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;

	color: #1e1f24;
}

@-moz-document url-prefix(){
  .portfolio{
    margin-top: 30px;
  }
}
