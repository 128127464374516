.create.alignment .my-dialog {
  position: absolute;
  top: 0px;
  width: 880px;
  bottom: 0;

  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.create.alignment .my-content {
  height: 100%;
  overflow-y: auto;
}

.my-lg {
  max-width: 60%;
}

.my-close-button {
  height: 27px;
  width: 17px;
  position: absolute;
  top: 10px;
  right: 24px;
}

.scrollDiv {
  overflow: auto;
}

.scrollDiv::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.overflowTag {
  width: 150px;
  max-width: 210px;
}

.tableHeaderText{
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.6px;
color: #505562;
}
.tableColumn{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #030F23;
}

.stickyHeader {
  position: sticky;
  left: 0;
  background: #F7F7F8
}

.tableWidth {
  min-width: 190px
}


@media (min-width: 900px) and (max-width: 1440px) {
  .my-lg {
    max-width: 80%;
  }
}

@media only screen and (max-width: 834px) {
  .my-lg {
    max-width: 90%;
  }

  .my-close-button {


  }
}

.create.alignment.fade .my-dialog {
  right: 0%;

  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.my-content {
  border-radius: 0;
  border: none;
  padding-top: 4.9%;
  padding-left: 5.9%;
  padding-right: 5.5%;
  padding-bottom: 4.9%;
}

.my-header {
  padding: 0px;
  display: block;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9096a8;
  letter-spacing: 0.4px;
}

.my-header .close {
  padding: 0px;
  margin: 0px;
}

.financials {
  font-family: 'geomanistmedium';
  font-size: 32px;
  font-weight: 500;
  color: #1e1f24;
  letter-spacing: 0.6px;
  font-style: normal;
  line-height: 40px;
}
.tabs{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #9096A8;
}

.annuallyActive{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.6px;
color: #005DD6;
}

.my-col {
  padding: 0px;
  margin: 0px;
}

.my-row {
  padding: 0px;
  margin: 0px;
}



.my-table th {
  width: 197px;
  padding: 12px;
  background-color: #f7f7f8;
}


/* .financials {
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  } */
