@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.dataTable td,
.dataTable th {
	vertical-align: middle !important;
	padding: 20px;
}

.tableborder {
	border-top: 0px;
	border-bottom: 1px solid #eaeaea !important;
}

.tableHead {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #767c8f;
	border-bottom: 1px solid #eaeaea !important;
}

.name {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #000000;
}

.creator {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.6px;
	color: #000000;
	opacity: 0.6;
}

.price {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #4dbc3c;
}

.pricePercentage {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	text-align: right;
	line-height: 21px;
	letter-spacing: 0.6px;
	color: #000000;
	opacity: 0.6;
}

.tableData {
	text-align: right;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #000000;
	opacity: 0.6;
	border-bottom: 1px solid #eaeaea !important;
}

@media (min-width: 1600px) {
	.bigScreen {
		max-width: 1400px;
	}
}
