@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.dataTable td {
	vertical-align: middle !important;
	padding-top: 20px;
	padding-bottom: 20px;
}
.dataTable th {
	vertical-align: middle !important;
	padding-top: 10px;
	padding-bottom: 10px;
}


.dataTable tr:nth-of-type(even) {
  background-color: #F7F7F8;
 }

.tableborder {
	border-top: 0px;
	border-bottom: 1px solid #eaeaea !important;
}

.stocksEtfRow{
  text-align: start;
  max-width: 200px;
  width: 20%;
}

.priceRow{
  max-width: 200px;
  width: 18%;
}

.tableHead {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #767c8f;
	border-bottom: 1px solid #eaeaea !important;
	font-size: 12px;
	line-height: 16px;
}

.name {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #000000;
}

.creator {
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.6px;
	color: #626777;
}

.price {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.pricePercentage {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	text-align: right;
	line-height: 21px;
	letter-spacing: 0.6px;
	color: #0dc014;
}

.tableData {
	text-align: right;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #626777;
}
.imageCompany {
	width: 48px;
	height: 48px;
	padding-left: 0px;
}

@media (min-width: 320px) and (max-width: 768px){
	.imageCompany {
		width: 32px;
		height: 32px;
		padding-left: 0px;
	  }
	  .name {
		font-size: 14px;
		font-family: 'geomanistbook';
	  }
	  .creator {
		font-size: 10px;
		width: 67px;
	  }
	  .price {
		font-size: 12px;
	  }
	  .pricePercentage {
		font-size: 10px;
	  }
	  .tableData {
		font-size: 14px;
	  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .mobileSpacing{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .mobileTable{
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .creator {
    font-size: 10px;
    width: 40px;
  }
}


@media (min-width: 700px) and (max-width: 1100px) {
.creator{
  width: 120px;
}

}
