@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.marginTop {
	margin: 0px;
	margin-top: 40px;
}

.title {
	margin: 0px;
	margin-top: 64px;
  margin-bottom: 40px;
	font-family: 'geomanistmedium';
	letter-spacing: 0.6px;
	color: #1e1f24;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.transactionDetail {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	cursor: pointer;
}

.expandIcon {
	margin-left: 6px;
	width: 8.75px;
	height: 4.58px;
	cursor: pointer;
}

.detailTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.detailValue {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}

.history {
	width: 100%;
	height: 12px;
	background: #efeff1;
	border-radius: 16px;
	position: relative;
}

.completed {
	/* width: 100px; */
	height: 12px;
	background: #13b948;
	border-radius: 16px;
}

.lrsIdBox{
	display: inline-block;
    margin-left: 10px;
	background: #D3D4DA;
	border-radius: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	padding-right:10px;
	padding-left: 10px;
}

.orderContainer{
	padding-left: 170px;
    padding-right: 170px;
}

.noOrdersFound{
	height:60vh;
  text-align: center;
  padding-top: 60px;
}

.noOrdersText{
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
  letter-spacing: 0.6px;
  color: #000000;
  margin-bottom: 8px;
}

.noOrdersTextBottom{
  font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
  letter-spacing: 0.6px;
  color: #505562;
}

.originalPos {
	transition: 1s;
}

.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}

@media (max-width: 576px) {
	.orderContainer{
		padding-left: 5px;
		padding-right: 5px;
	}
	.title{
		font-size: 20px;
	}
}


@media (max-width: 990px) {
  .orderContainer{
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media (min-width: 991px) and (max-width: 1250px) {
  .orderContainer{
		padding-left: 100px;
		padding-right: 100px;
	}
}


@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
	.sideBarMobileHide {
	  display: block;
	}
  }