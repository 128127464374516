.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top-width: 6px;
  border-top-style:solid;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  -ms-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 40%;
  left: 48%;
  z-index: 105;
}
