.fullPage {
	width: 100vw;
	display: flex;
	justify-content: center;
}

.cardSignUp {
	width: 412px;
	height: auto;
	background: #ffffff;
	border: 1px solid #f7f7f8;
	box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
}
.cardBodySignup {
	padding: 32px 24px 32px 24px;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	color: #3c404a;
	margin-left: 0px;
}

.subTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-left: 0px;
}

.content {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-left: 0px;
	display: flex;
	align-items: center;
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5000;
	/* transform: translateY(-100vh);
	opacity: 0;
	transition: 0.4s ease-in; */
}

.popupHeading {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #3c404a;
}

.popup {
	background: #ffffff;
	border: 1.5px solid #efeff1;
	box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
	width: 380px;
	position: absolute;
	top: calc((100vh - 380px) / 2);
	left: calc((100vw - 380px) / 2);
	z-index: 999;
	overflow: hidden;

	animation: move 0.5s;
}

@keyframes move {
	from {
		top: 100vh;
	}
	to {
		top: calc((100vh - 380px) / 2);
	}
}

.popupHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 24px 28px 20px 24px;
	gap: 32px;
	border: 16px;
	background: #f7f7f8;
	border-bottom: 1.5px solid #efeff1;
}

.popupBody {
	background: #ffffff;
	box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
}

.submission {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	color: #3c404a;
}

.checkMark {
	padding-top: 8px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #162542;
}

.checkBoxInput:disabled ~ .checkMark::before {
	background-color: #e9ecef;
	border: none;
}

.checkBox .checkBoxInput:checked:focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
	/* background-color: #6097a1; */
	transition: none;
}

.checkBox .checkBoxInput:not(:checked):focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
}

.checkMark::before {
	background: #f7f7f8;
	border: 1.5px solid #d3d4da;
	border-radius: 4px;
	width: 20px;
	height: 20px;
}
.checkBox .checkBoxInput:checked ~ .checkMark::after {
	background-image: url('../../public/images/newOnboarding/check.svg');
}

.checkMark::after {
	width: 1.7rem;
	height: 1.5rem;
	top: 1px;
	left: -27px;
}

.checkBox .checkBoxInput:checked ~ .checkMark::before {
	background: #f7f7f8;
	border: 1.5px solid #0061d3;
}

.errorState {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #fa274a;
}

@media only screen and (max-width: 480px) {
	.cardSignUp {
		border: 0px;
		box-shadow: none;
	}
	.cardBodySignup {
		padding: 24px;
	}
}
