@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.trendingCard {
	border: 0px;
	border-bottom: 1px solid #eef2fb;
  border-radius: 8px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent ;
  border-top: 1px solid transparent ;
}

@media (max-width:991px) {
	.trendingCard {
		margin-bottom: 16px;
	}
}

.cardBody {
  padding: 24px;
}

.trendingCard:hover {
	border: 1px solid #efeff1;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
	border-radius: 16px;
}

.stockImage {
	width: 52px;
	height: 52px;
	border-radius: 10px;
}

.stockTitle {
	font-family: 'geomanistmedium';
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: 0.56px;
	color: #1e1f24;
	text-transform: uppercase;
}

@media (min-width:576px) and (max-width:768px) {
	.stockTitle {
		font-size: 17px;
	}
}

.stockName {
	font-family: 'geomanistregular';
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	text-align: left;
	height: 50px;
  margin-bottom: 16px;
	/* padding-top: 5px; */
}

.cagrValue {
	font-family: 'geomanistbook';
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.56px;
	color: #0DC014;
	text-align: left;
}

.stockIncrement {
	font-family: 'geomanistregular';
	float: right;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.56px;
	color: #626777;
}
.creator {
	font-family: 'geomanistregular';
	padding-top: 4px;
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.56px;
	color: #626777;
}
.cagr {
	font-family: 'geomanistregular';
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.56px;
	color: #626777;
	text-align: left;
}
.minHead {
	font-family: 'geomanistregular';
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.56px;
	color: #626777;
	text-align: left;
}
.minValue {
	font-family: 'geomanistbook';
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.56px;
	color: #1e1f24;
	text-align: left;
}

.navLink:hover {
	color: inherit;
	text-decoration: none;
}

.stackCardHeading{
  padding-left: 0px;
  padding-right: 0px;
}


/* @media (min-width: 320px) {
	.trendingCard {
		border-bottom: 1px solid #efeff1;
		border-radius: 8px;
		height: 233px;
		width: 256px;
	}
	.cardBody {
		padding: 1rem;
	}
} */


@media (max-width:991px) {
  .trendingCard{
    border: 1px solid #efeff1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
    border-radius: 16px;
    margin-right:20px;
  }
  .cardBody{
    padding: 24px;
  }
}
  .trendingCard{
    width: 100%;
  }


  @media (max-width:480px){
	.cardBody {
	  padding: 18px;
	}
	.trendingCard{
	  border: 1px solid #efeff1;
	  box-sizing: border-box;
	  box-shadow:none !important;
	  border-radius: 16px;
	  margin-right:20px;
	}
  }


/* @media (min-width: 767px) and (max-width: 1023px) {
	.stockImage {
		width: 30px;
		height: 30px;
	}
	.stockTitle {
		font-size: 12px;
		line-height: 0;
		letter-spacing: 0px;
	}
	.creator {
		padding-top: 5px;
		font-size: 8px;
		line-height: 0;
		letter-spacing: 0.3px;
	}
	.stockName {
		font-size: 1px;
		line-height: 0;
		letter-spacing: 0px;
		padding-top: 1px;
	}
	.cagr {
		font-size: 8px;
		line-height: 0;
		letter-spacing: 0.33px;
	}
	.cagrValue {
		font-size: 10px;
		line-height: 0;
		letter-spacing: 0.33px;
	}
	.minHead {
		font-size: 10px;
		line-height: 0;
		letter-spacing: 0.33px;
	}
	.minValue {
		font-size: 10px;
		line-height: 0;
		letter-spacing: 0.33px;
	}
	.trendingCard {
		border: 1px solid #efeff1;
		border-radius: 8px;
		width: 170px;
		height: 170px;
	}
	.cardBody {
		padding: 15px;
	}
} */

/* @media (max-width: 1024px) {
	.stockImage {
		width: 36px;
		height: 36px;
	}
	.stockTitle {
		font-size: 16px;
		line-height: 1;
		letter-spacing: 0px;
	}
	.creator {
		padding-top: 5px;
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.3px;
	}
	.stockName {
		font-size: 10px;
		line-height: 1;
		letter-spacing: 0.3px;
		padding-top: 1px;
	}
	.cagr {
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.33px;
	}
	.cagrValue {
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.33px;
	}
	.minHead {
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.33px;
	}
	.minValue {
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.33px;
	}
} */
