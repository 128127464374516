@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.ReportCard{
    background: #F7F7F8;
    border-radius: 12px;
    padding:32px;
    height: 210px;
    width: 352px;
}

.ReportHeading{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.6px;
    color: #021241;
    margin-top: 10px;
}

.ReportSubHeading{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #505562;
}

.ReportRow{
    margin-top:80px;
}

.SecondReportRow{
    margin-top:40px;
    margin-bottom: 100px;
}

.ReportButton{
    border: 1px solid #005DD6;
    box-sizing: border-box;
    border-radius: 8px;
    width: 288px;
    height: 40px;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.6px;
    /* text-transform: capitalize; */
    color: #005DD6;
    position: absolute;
    top:150px;
}

.ReportButton:focus {
    outline: none;
    box-shadow: none;
  }
  .marginConatiner{
    margin-top: 80px;
  }

  /* .footerMargin{
    margin-top: 160px;
  } */

  @media (min-width: 300px) and (max-width: 480px) {
    .footerMargin{
      margin-top: 80px;
    }
    .marginConatiner{
      margin-top: 60px;
    }
  }
