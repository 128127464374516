/****Carosuel Css*********/
/************************/
.gradientImage1 {
	width: 100%;
	height: 100vh;
	/* background-image: -webkit-linear-gradient(
			240deg,
			rgba(0, 0, 0, 0) 48.19%,
			#000000 100%
		),
		url('../../public/images/vijentha.png'); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.gradientImage2 {
	width: 100%;
	height: 100vh;
	/* background-image: -webkit-linear-gradient(
			240deg,
			rgba(0, 0, 0, 0) 48.19%,
			#000000 100%
		),
		url('../../public/images/shreenidhi.png'); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.gradientImage3 {
	width: 100%;
	height: 100vh;
	/* background-image: -webkit-linear-gradient(
			240deg,
			rgba(0, 0, 0, 0) 48.19%,
			#000000 100%
		),
		url('../../public/images/pratap.png'); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.carouselCaption {
	width: 100%;
	bottom: 45px;
	text-align: justify;
	padding-left: 30px;
	padding-right: 30px;
	left: 0px;
	right: 0px;
}
.carouselText {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #ffffff;
}
.carouselName {
  text-align: center;
	margin-top: 20px;
	font-family: 'geomanistmedium';
	font-style: normal;
	/* font-weight: bold; */
	font-size: 18px;
	line-height: 110%;
	color: #1E1F24;
}
.carouselItem {
  background-color: #F0F0F4;
	transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

/*****Boarding Screen ******/
/***************************/
.fullScreen {
	background-color: white;
	margin: 0px;
	min-height: 100vh;
}
.onlyInput{
  margin-left: 15%;
  margin-right: 15%;
}

.slideShow {
	padding: 0px;
	padding-right: 20px;
  z-index: 999;
}
.formCol {
	margin: auto;
}

/* @media (min-height: 600px) and (max-height: 770px) {
.fullScreen{
  height: calc(100vh + 100px);
}
} */
@media (min-width: 850px) and (max-width: 1000px) {
  .onlyInput{
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (min-width: 750px) and (max-width: 851px) {
  .onlyInput{
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media only screen and (max-width: 751px){
  .onlyInput{
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
	.slideShow {
		display: none;
	}
  .formCol {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
	.slideShow {
		display: none;
	}
  .formCol {
    margin-top: 20px;
  }
}
@media (min-width:768px) {
	.slideShow {
		display: block;
		padding: 0px;
		padding-right: 20px;
	  	z-index: 999;
	}
	.formCol {
		margin: auto;
	}
}
/* .signinFooter{
    position: relative;
    bottom:75px;
    right: 0;
}

@media  (max-width: 768px){
	.signinFooter{
		position: relative;
		bottom: 20px;
		right: 0;
	}
}

@media  (min-width: 768px) and (max-width: 1200px){
	.signinFooter{
		bottom: 70px;
	}
} */

.colResponsive{
	text-align: center;
	padding: 0px;
}
.myvideo{
	height: 100vh;
	width: 100%;
	min-height: 680px;
}
@media (min-width:768px) and (max-width: 812px){
	.onfidoResponsive :global(.onfido-sdk-ui-Modal-inner){
		max-width: 62vw;
	}
}
.body{
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	margin: 60px 0;
}