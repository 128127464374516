@import '../../../public/geomanist/geomanistRegular/stylesheet.css';

.dropDown {
	margin: 0px;
}

.profileBankDropdown {
	z-index: 100;
	position: absolute !important;
	/* left: 133px; */
	margin-top: 15px;
	transform: translate(0px, 62px) !important;
	background: #ffffff;
	/* border: 2px solid rgba(22, 37, 66, 0.25); */
	box-sizing: border-box;
	width: calc(100% - 30px);
	/* width: 96%; */
	border: 1px solid #EFEFF1;
	box-shadow: 0px 2px 16px rgba(30, 31, 36, 0.12);
	border-radius: 8px;
	max-height: 300px;
    overflow-y: scroll;
}

.dropdownCard {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.dropDownItem {
	padding-left: 16px;
	padding-right: 16px;
	cursor: pointer;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #162542;
	padding-bottom: 15px;
	padding-top: 15px;
	overflow-wrap: break-word;
}
.dropDownItem:hover {
	background-color: #f7f7f8;
}

.ProfileDropDownContainer {
	width: 100%;
	position: relative;
}

.ProfileDropDownContainer input {
	font-family: 'Inter';
	/* padding: 20px; */
	background: #ffffff;
	border: 2px solid rgba(22, 37, 66, 0.25);
	box-sizing: border-box;
	border-radius: 0px;
}

.ProfileDropDownContainer input:active,
.ProfileDropDownContainer input:hover,
.ProfileDropDownContainer input:focus {
	border: 2px solid #0061d3;
	box-shadow: none;
}

/* .ProfileDropDownContainer label {
	visibility: hidden;
	left: 8px;
	position: absolute;
	top: 0;
	opacity: 0;
	transition: 250ms;
} */

.ProfileDropDownContainer input:placeholder-shown {
	/* font-family: 'Inter'; */
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.ProfileDropDownContainer input::placeholder{
  font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.ProfileDropDownContainer input::-webkit-input-placeholder {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.ProfileDropDownContainer input::-moz-placeholder-placeholder {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

/* .ProfileDropDownContainer input:not(:placeholder-shown) + label {
	visibility: visible;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #ffffff;
	height: 25px;
	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	background: #0061d3;
	border-radius: 12px;
	transform: translate(0, -50%);
	opacity: 1;
} */

.ProfileDropDownContainer input:disabled {
	background-color: white;
}

.ProfielDropDownImage {
	cursor: pointer;
	position: absolute;
	right: 33px;
	top: 25px;
	padding-top: 10px;
	/* opacity: 0.4; */
    color:black
}

.editProfileDropDown{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1e1f24;
    font-family: 'geomanistregular' !important;
    border: 0px;
    height: 48px;
    background: #EFEFF1 !important;
    border-radius: 8px !important;
    border: none !important;
	padding-right:50px;
}

.ProfileDropdownLabel{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.6px;
    color: #626777;
}
.ProfileDropDownAlt {
	font-size: 10px;
	display: inherit;
  }