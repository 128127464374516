@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.originalPos {
  position: relative;
  margin-top: 16px;
  transition: 0s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top:48px;
}
.cashMgmtRow {
  display: flex;
  justify-content: end;
  align-items: center;
  gap:11px;
  cursor: pointer;
}
.shiftPos{
  margin-top:48px;
  margin-left: 0px;
}
.savingsTitle{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* or 150% */
  color: #767C8F;
}
.savingsUsFlag{
  width: 28px;
  height: 100%;
  margin-right:16px;
  margin-bottom: 15px;
}
.savingsAccountBalance{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #3C404A;
}
.savingsMargin{
  margin-top: 14px;
}
.savingsPillsMargin{
  margin-top: 44px;
}
.savingsPills{
  display: inline-block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #767C8F;
  background: #EFEFF1;
  border-radius: 6px;
  padding:8.5px 16px 8.5px 16px;
  margin-right: 8px;
  cursor: pointer;
}
.savingSelectedPills{
  color: #0061D3;
  background: #EEF4FB;
  display: inline-block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 6px;
  padding:8.5px 16px 8.5px 16px;
  margin-right: 8px;
  cursor: pointer;
}
.savingsTableHeader{
  background: #F7F7F8;
  border-radius: 8px 8px 0px 0px;
  margin-left: 0px;
  margin-right:0px;
  margin-top: 24px;
}
.savingsTableHeading{
  padding:12.75px 0px 12.75px 24px;
  font-size: 14px;
  font-family: 'geomanistBook';
  line-height: 20px;
  color: #767C8F;
}
.savingTableType{
  padding: 12.75px 0px 12.75px 24px;
}
.savingsTablePoints{
  margin-left: 0px;
  margin-right:0px;
  /* border-radius: 8px 8px 0px 0px; */
  border-left:1px solid #F7F7F8;
  border-right:1px solid #F7F7F8;
  border-bottom:1px solid #F7F7F8;
  cursor: pointer;
}
.savingsTablePoints:hover{
  background: #F5F7FA;
}
.savingTableTransactionTypeValue{
  display: block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
}
.savingTableId{
  display: block;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
  margin-top: 4px;
}
.savingTableAmountGreenValue{
  display: block;
  font-family: 'geomanistBook';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0DC014;
}
.savingTableAmountRedValue{
  display: block;
  font-family: 'geomanistBook';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FA274A;;
}
.savingThreeVerticalDots{
  height: 16px;
  cursor: pointer;
}
.savingsCardDatailsDiv{
  position: sticky;
  top:120px;
}
.savingsLeftCardPad{
  padding-right: 30px;
}
.savingsRightCardPad{
  padding-left: 30px;
  border-left: 1px solid;
  line-height: 10px;
  border-color:#F7F7F8;
}
.savingsRightCardHeading{
  font-family: "geomanistBook";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #767C8F;
  padding-left: 20px;
}
.savingsRightCardDetails{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  padding-left: 20px;
}
.savingsRightCardDetailsValue{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  text-align: right;
  padding-left: 0px;
}
.savingsRightCardMarginBottom{
  margin-bottom: 16px;
}
.savingsRightCardDetailsAddButton{
  padding: 10.75px 20px 10.75px 20px;
  background: #0061D3;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}
.savingsRightCardDetailsTransferButton{
  color: #0061D3;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #0061D3;
  padding: 10.75px 20px 10.75px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.cashManagementVirtualCardOuter{
  background: #FFFFFF;
  box-shadow: 4px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  min-height: 200px;
}
.cashManagementVirtualCard{
  /* background: #FFFFFF;
  box-shadow: 4px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px; */
  padding: 20px 26px 0px 20px;
}
.cashManagementVirtualCardHeading{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #767C8F;
}
.cashManagementVirtualCardEncodedNumber{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3C404A;
}
.cashManagementVirtualCardLock{
  text-align: right;
  cursor: pointer;
}
.cashManagementVirtualCardAmount{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #3C404A;
}
.cashManagementVirtualCardExipiry{
  font-family: 'geomanistBook';
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #0061D3;
}
.cashManagementVirtualCardIssuedTo{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 23.25px 0px 21.25px 20px;
  position: absolute;
  left: 0px;
  top: 229px;
}
.cashManagementVirtualCardVisa{
  text-align: right;
  padding: 23.25px 22px 21.25px 35px;
  position: absolute;
  right: 0px;
  top: 229px;
}
.cashManagementVirtualCardBackgroundVideo{
  background-color:#0061D3;;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.savingsRightCardDetailsButtonRow{
  justify-content: space-evenly;
}
.savingsRightCardThreeDots{
  line-height: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  display: block;
  padding: 9px 15px 10px 15px;
  cursor: pointer;
  border: 1.5px solid #0061D3;
  border-radius: 7.41667px;
}
.savingRightCardThreeVerticalDots{
  height: 16px;
}
.fraudPopupContainer{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  /* padding: 12px 30px 12px 20px; */
  right:30px;
  /* margin-top: 50px; */
  z-index: 100;
  display: none;
  cursor: pointer;
  width: 190px;
}
.fraudPopupContainer .text{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'geomanistBook';
  color: #3C404A;
  display: block;
  padding:12px 25px 12px 20px;
}
.fraudPopupContainer .text:hover{
  background: #f7f7f8;
}
.fraudPopupContainer .flagImage{
  margin-right:13px;
}
.savingsPagination{
  margin-left: 0px;
  margin-right: 0px;
  border-left: 1px solid #F7F7F8;
  border-right: 1px solid #F7F7F8;
  border-bottom: 1px solid #F7F7F8;
  border-radius: 0px 0px 8px 8px;
}
.savingPaginationButton{
  padding-left: 12.75px;
  padding-right: 12.75px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #767C8F;
  cursor: pointer;
}
.savingPaginationRowPad{
  padding-top: 20.75px;
  padding-bottom: 20.75px;
  line-height: 20px;
}
.savingPaginationRowCount{
  padding-top: 20.75px;
  padding-bottom: 20.75px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #767C8F;
  text-align: center;
}
.cashManagementVirtualCardDetails{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
}
.cashManagementVirtualCardDetailsValue{
  font-family: 'geomanistBook';
  font-weight: 400;
  font-size: 16px;
  color: #3C404A;
  line-height: 28px;
}
.savingTableMobileTimeDate{
  display: none;
}
.cashManagementVirtualCardExpiry{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #767C8F;
}
.cashManagementVirtualCardCVViFrame{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #767C8F;
  width: 30px;
  display: block;
  float: right;
  margin-top: 4px;
  margin-left: 4px;
}
.cashManagementVirtualCardCVV{
  /* margin-left: 6px; */
  font-family:'geomanistBook';
  font-weight: 400;
  font-size: 14px;
  color: #3C404A;
  line-height: 28px;
}
.cashManagementVirtualCardLockImg{
  width:12px;
  height:13.5px;
  margin-right: 2px;
}
.cashManagementVirtualCardBottomRowText{
  z-index: 10;
}
.cashManagemntAnimation{
  z-index: 1;
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}
.cashManagementVirtualCardImageBackground{
  border-radius: 0px 0px 16px 16px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* height: 58px; */
}
.savingsRightCardCopyText{
  margin-left: 5px;
  cursor: pointer;
}
.savingsPillsDropdownSelection{
  margin-right:-15px;
  padding: 8.75px 12.75px 8.75px 14px;
  box-sizing: border-box;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  font-size: 14px;
  color: #767C8F;
  display: block;
  /* width: 90%; */
  float: right;
  font-family: 'geomanistBook';
  cursor: pointer;
  text-align: center;
}
.savingPillsDropDownIcon{
  /* padding-right:16.5px; */
  /* padding-left: 12.5px; */
  /* position: absolute; */
  /* top: 18px;
  right: 20px; */
  display: inline-block;
  margin-left: 5px;
}
.savingsRightCardTopBorder{
  margin-top: 32px;
  margin-bottom: 32px;
}
.savingsTitleTransactions{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #767C8F;
  margin-bottom: 16px;
}
.savingPillCircleColor{
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #767C8F;;
  border-radius: 50%;
  margin-right: 10px;
}
.transactionTypeContainer{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  /* padding: 12px 30px 12px 20px; */
  margin-top: 60px;
  z-index: 100;
  display: block;
  cursor: pointer;
  right: 15px;
}
.transactionTypeValue{
  display: block;
  padding: 12px 32px 12px 18px;
  border-bottom: 1px solid #D3D4DA;
  font-family: 'geomanistBook';
  font-size: 14px;
  white-space: pre;
}
.transactionTypeValue:hover{
  background: #f7f7f8;
}
/* animate modal */
.animateBottom {
  position: relative;
  animation: animatebottom 0.4s;
  margin-top: 100px;
  max-width: 380px;
  border-radius: 16px !important;
}
.mobileVirtualCardShowOnly{
  display: none;
}
.savingsDownloadButton{
  padding: 11.25px 11.25px 11.25px 11.25px;
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  cursor: pointer;
}
.savingsHideMobileFilter{
  display: none;
}
.savingsMobileCross{
  display: none;
}
.savingsDownloadButtonAlighnment{
  text-align: left;
  padding-left: 0px;
  margin-left:-15px;
}
.transferModalBody {
  width: 400px;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.transferModalBody::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.transferModalBody::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
.transferModalBody::-webkit-scrollbar-thumb {
  background: #efeff1;
  border-radius: 10px;
}
.savingsModalPadding{
  padding:0px !important;
}
.transferModalHeading{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3C404A;
}
.transferModalBalance{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-bottom: 24px;
  display: block;
}
.transferModaInputBackground{
  background: #F7F7F8;
  border-radius: 8px;
  padding: 24px 16px 16px;
  width: 100%;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}
.transferModaInputBox{
  background-color: #F7F7F8;
  border: none;
  text-align: left;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  color: #3C404A;
  width: 28%;
  /* padding-left: 40px; */
  padding: 0px;
  margin-left: 40px;
}
.transferModaInputBox::placeholder{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  color: #767C8F;
}
.transferModalcurrencyinput{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  color: #3C404A;
  position: absolute;
  margin-left: 15px;
}
.transferModalCharges{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767C8F;
  display: block;
}
.transferModalDetailsHeading{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-top: 32px;
}
/* .trasnferToManuallyBox{
  border: 1px dashed #4797F1;
  border-radius: 8px;
  padding: 22.5px 19.75px 22.75px 16px;
} */
.trasnferToManuallyBox{
  border: 1px dashed #4797F1;
  border-radius: 8px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 22.5px 19.75px 22.75px 16px;
  cursor: pointer;
}
.trasnferToManuallyBoxMargin{
  margin-top: 20px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
}
.transferManuallyImage{
  margin-right: 16px;
}
.transferManuallyRightArrow{
  float: right;
  margin-top: 10px;
}
.trasnferToContactBox{
  border: 1px solid #D3D4DA;
  border-radius: 8px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 22.5px 19.75px 26.75px 16px;
  cursor: pointer;
}
.transferManuallyBankNumber{
  display: block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3C404A;
}
.transferManuallyBankName{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
  margin-left: 6px;
}
.transferModalTakeInputHeading{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #3C404A;
  display: block;
  width: 100%;
}
.transferModalTakeInputBox{
  background: #FFFFFF;
  margin-top: 8px;
}
.transferModalInputBoxImage{
  text-align: right;
  border-left:1px solid #D3D4DA;
  border-top: 1px solid #D3D4DA;
  border-bottom: 1px solid #D3D4DA;
  border-top-left-radius:6px;
  border-bottom-left-radius: 6px;
  padding: 15.25px 15px 15.25px 15px;
}
.transferModalInputBox{
  border-right:1px solid #D3D4DA;
  border-top: 1px solid #D3D4DA;
  border-bottom: 1px solid #D3D4DA;
  border-top-right-radius:6px;
  border-bottom-right-radius: 6px;
  padding: 11.25px 15px 11.25px 15px;
  width: 100%;
  padding-left: 0px;
  border-left: none;
}
.transferTypeModalInput {
  height:43px;
  margin:0;
  padding-left:40px;
  margin-top: 8px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
}
.transferTypeModalImage {
  position: absolute;
  bottom: 13px;
  width: 13px;
  left: 30px;
}
.transferModalHeadingBorder{
  margin-top: 20px;
  border-top: 1px solid #EFEFF1;
  padding-top: 20px;
}
.transferTypeRow{
  margin-top: 24px;
}
.AccountHolderAddress{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
  margin-top: 8px;
}
.transactionModalTopBorder{
  border: 1px solid #EFEFF1;
  padding-top: 24px;
}
.transferModalCancelButton{
  width: 100%;
  display: block;
  padding-top: 10.75px;
  padding-bottom: 10.75px;
  /* padding:10.75px 20px 10.75px 20px; */
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #0061D3;
  border: 1.5px solid #0061D3;
  border-radius: 8px;
  cursor: pointer;
}
.transferTypeModalBottomButton{
  position: absolute;
  bottom:20px;
  width: 100%;
}
.transferModalContinueButton{
  /* padding:10.75px 20px 10.75px 20px; */
  width: 100%;
  display: block;
  padding-top: 10.75px;
  padding-bottom: 10.75px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: white;
  border: 1.5px solid #0061D3;
  border-radius: 8px;
  cursor: pointer;
  background-color: #0061D3;
}
.transferModalSaveDetails{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  margin-left: 20px;
}
.transferModalCodeSentText{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767C8F;
}
.transferModalCodeSentEmail{
  display: block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: black;
}
.transferModalExpiryText{
  padding-top: 24px;
  padding-bottom: 8px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767C8F;
  display: block;
}
.transferModalSavingsRightCardDetails{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
}
.transferModaAddRemark{
  width: 100%;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767C8F;
  border: none;
  background: #F7F7F8;
}
.transferModaAddRemarkBox{
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  background: #F7F7F8;
  border-radius: 8px;
  padding-top:12px;
  padding-bottom: 12px;
  margin-bottom: 15px;
}
.transferModaAddRemark::placeholder {
  font-size: 14px;
  color: #767C8F;
  font-family: 'geomanistBook';
}
.transactionModalTypeContainer{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
  position: absolute;
  margin-top: 10px;
  z-index: 1000;
  display: block;
  cursor: pointer;
  /* right: 15px; */
}
.transferTypeModalInputCountry{
  height:43px;
  margin:0;
  padding-left:40px;
  margin-top: 8px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  padding: 12px 40px;
  white-space: pre;
}
.selectTransferType{
  background: #F7F7F8;
  border-radius: 8px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 6px 6px 6px 6px;
}
.selectedTransferType{
  background: #FFFFFF;
  border-radius: 4px;
  padding: 6px 12px;
  text-align: center;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #0061D3;
  cursor: pointer;
}
.notSelectedTransferType{
  background: #F7F7F8;
  padding: 6px 12px;
  text-align: center;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3C404A;
  cursor: pointer;
}
.transferTypeModalRightTick{
  position: absolute;
  top:38px;
  right:25px;
}
.transferTypeModaldownIcon{
  position: absolute;
  right: 25px;
  bottom: 15px;
}
.transferTypeModalInputFieldsErrors{
  margin-top: 8px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FA274A;
}
.savingDatePickerWebPadding{
  padding-right: 30px;
  padding-left: 0px;
}
.otpErrorText{
  margin-top: 8px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FA274A;
  display: block;
  text-align: center;
}
.transferTypeContactName{
  margin-left: 15px;
  margin-right: 15px;
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  color: #3C404A;
  font-family:'geomanistBook';
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding-right: 30px;
}
.beneficiaryAddressWithNameCM{
  display: block;
  color: #3C404A;
  font-family:'geomanistBook';
  font-size: 12px;
}
.editNameImage{
  position: absolute;
  right:15px;
  top:16px;
}
.accountTypeDropDownSelectedValue{
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.completedOfTransferCashMangement{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.imageCenterCompletedCM{
  display: block;
  margin: auto;
}
.transferInititatedCM{
  font-family:'geomanistBook';
  font-size: 20px;
  line-height: 28px;
  display: block;
  text-align: center;
  color: #3C404A;
}
.timeOfTransferCM{
  font-family:'geomanistBook';
  display: block;
  text-align: center;
  color: #767C8F;
  font-size: 14px;
  line-height: 20px;
}
.transferManuallyTransferType{
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  color: #767C8F;
}
.forgotPinText{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0061D3;
  display: block;
  margin-top: 2px;
  cursor: pointer;
}
.EnterCardPinText{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
}
#card-number{
  font-family: "geomanistBook";
}
.cardNumberFontFamily{
  font-family: "geomanistBook";
}
.cashManagementVirtualCardEncodedNumber iframe {
  width:100%;
}

.cashManagementVirtualCardCVV iframe {
  width: 100%;
}

.cashManagementVirtualCardExipiry iframe {
  width: 100%;
}

.webDisplayNone{
  display: none;
}
.transferModalDetailsEditDetails{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0061D3;
  margin-top: 32px;
  text-align: right;
}
.sixDigitCodeText{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-bottom: 2px;
}
.emailBlueText{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  margin-bottom: 0px;
}
.laodingData{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3C404A;
  /* margin-bottom: 56px; */
  display: block;
}
.cardholderNameAndVisa{
  font-family: 'geomanistBook';
  position: absolute;
  top:20px;
  width: 100%;
  padding-left: 20px;
}
.visaCardImage{
  display: inline-block;
  position: absolute;
  right: 26px;
}
.cashManagementVirtualCardIssuedToName{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}
video::-webkit-media-controls {
  display: none;
}
.transactionModalTypeContainerBankType{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
  position: absolute;
  margin-top: 10px;
  z-index: 1000;
  display: block;
  cursor: pointer;
  bottom: 50px;
}
video::-webkit-media-controls, video::-moz-media-controls, video::-o-media-controls, video::-ms-media-controls {
  display: none !important;
  opacity: 1 !important;
}
@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}


@media (min-width: 1000px) and (max-width: 1540px) {
  .shiftPos {
    margin-left: 2%;
    transition: 1s;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .visaCardImage{
    top:5px;
  }
  .cardholderNameAndVisa{
    top:15px;
  }
  .transferModalcurrencyinput{
    line-height: 44px;
  }
  .inputBoxMobileInput{
    width:20px;
    height: 20px;
  }
  .savingsLeftCardPad{
    padding-right: 15px;
  }
  .savingtableHideMobile{
    display: none;
  }
  .savingsTableHeadingAmount{
    text-align:right;
    padding-right: 24px;
  }
  .savingTableMobileTimeDate{
    display: block;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 20px;
    text-align: right;
    color: #767C8F;
  }
  .savingTableMobileBalance{
    display: none;
  }
  .cashManagementVirtualCardIssuedTo{
    left: 15px;
    top: 335px !important;
  }
  .cashManagementVirtualCardVisa{
    top: 330px !important;
    right: 5px !important;
  }
  .savingPaginationButton{
    display: none;
  }
  /* .savingsRightCardDetailsButtonRow{
    justify-content: space-around;
  } */
  .savingsRightCardDetailsAddButton{
    padding:10.75px 25px 10.75px 25px;
  }
  .savingsTableHeading{
    padding:12.75px 0px 12.75px 10px;
  }
  .savingsTableHeadingAmount{
    padding-right: 10px;
  }
  .savingTableType{
    padding-left: 10px;
  }
  /* .fraudPopupContainer{
    display: block;
    padding:20px;
    position: fixed;
    width: 50;
    width: 100%;
    height: 40vh;
    background: white;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    padding-top: 30px;
  } */
  .transferModalBody {
		width: 100% !important;
    height: calc(100vh) !important;
		overflow-y: auto;
		overflow-x: hidden;
    margin-bottom: 40px;
	}
  .transferTypeModalBottomButton{
    width: 100%;
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .webDisplayNone{
    display: block;
  }
  .transferModalBody {
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .savingDatePickerWebPadding{
    padding-right: 15px;
    padding-left: 15px
  }
  .savingsPillsDropdownSelection{
    margin-right:0px;
  }
  .savingsDownloadButtonAlighnment{
    text-align: right;
    padding-left: 15px;
    margin-left:0px;
  }
  .WebVirtualCardShowOnly{
    display: none;
  }
  .mobileVirtualCardShowOnly{
    display: block;
    margin-top: 24px;
  }
  .cashManagementVirtualCardVisa{
    top: 340px;
    right:40px;
  }
  .cashManagementVirtualCardIssuedTo{
    left: 15px;
    top: 345px;
  }
  .savingtableHideMobile{
    display: none;
  }
  .savingTableMobileTimeDate{
    display: block;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #767C8F;
  }
  .savingTableMobileBalance{
    display: none;
  }
  .savingsTableHeadingAmount{
    text-align:right;
    padding-right: 24px;
  }
  .savingsWebFilter{
    display: none;
  }
  .savingsHideMobileFilter{
    display: block;
  }
  .savingsMobileFilter{
    border: 1px solid #D3D4DA;
    border-radius: 6px;
    padding-top: 8.75px;
    padding-bottom: 8.75px;
    text-align: center;
    margin-top: 16px;
  }
  .savingsMobileFilterText{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3C404A;
  }
  .transactionTypeContainer{
    display: block;
    padding:20px;
    position: fixed;
    width: 50;
    width: 100%;
    height: 40vh;
    background: white;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    padding-top: 30px;
  }
  .savingsMobileCross{
    position: absolute;
    right:20px;
    top:10px;
    display: block;
  }
}
