@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 412px;
}

.title {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  padding-left: 20px;

}

.erorrMessage{
  text-align: center;
  margin-top: 10px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #FA274A;
}


.reference {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-top: 24px;
}

.inputNew {
	background: #efeff1;
	border-radius: 8px;
	border: none;
	height: 48px;
	width: 416px;
	padding-left: 10px;
}

.inputNew::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

@-moz-document url-prefix() {
	.inputNew::placeholder {
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 45px;
		letter-spacing: 0.6px;
		color: #9096a8;
	}
}
.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
	height: 56px;
	width: 416px;
}

.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
}
.fixedDiv {
	background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	);

	position: fixed;
	height: 120px;
	bottom: 0px;
	margin-right: 0%;
	padding-top: 50px;
  width: 100%;
}
.rowDiv{
  border-bottom: 1px solid #efeff1;
  padding-bottom: 24px;
}
@media (max-width: 768px) {
	.card {
		width: 350px;
	}
	.inputNew {
		width: 100%;
	}
	.addfunds {
		width: 100%;
	}
}

@media (max-width:570px){
  .card{
    width: 100%;
  }
  .title{
font-family: 'geomanistMedium';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
letter-spacing: 0.6px;
color: #1E1F24;
padding-left: 95px;
  }
  .rowDiv{
    border-bottom: none;
    padding-bottom: 0px;
  }
  .inputNew {
		width: 100%;
	}
  .addfunds {
		width: 100%;
	}
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
}
@media (max-width:375px){
  .title{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    padding-left: 70px;
      }
}

@media (max-width:320px){
  .title{
    font-family: 'geomanistMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #1E1F24;
    padding-left: 50px;
      }
}

@media (min-height: 200px) and (max-height: 500px) {
  .fixedDiv{
    background:none;
    position:static;
    margin-top: 15px;
  }
}
