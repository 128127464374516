@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.inputbox {
	position: relative;
	width: 320px;
	border: 0px;
}

.cardBody {
	padding: 0px;
	/* padding: 2rem; */
	border-radius: 6px;
}

.searchInput {
	padding: 10.25px 13.25px 9.25px 40px !important;
	height: 40px !important;
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	background: #FFFFFF;
	border-radius: 6px !important;
	width: 448px;
	border: 1.5px solid #D3D4DA !important;
	padding-left:16px;
	font-family: 'geomanistBook';
}

.searchInput::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	vertical-align: middle;
}

.searchInput:active,
.searchInput:focus {
	background: #FFFFFF;
	border: 1.5px solid #3C404A !important;
	box-sizing: border-box;
	border-radius: 6px;
}

.deleteIcon {
	visibility: visible;
	position: absolute;
	right: 10px;
	z-index: 1;
	cursor: pointer;
	color: #505562;
	cursor: pointer;
	padding-top: 6px;
}

.searchIcon {
	visibility: visible;
	position: absolute;
	padding: 5px;
	left: 10px;
	z-index: 1;
	padding-top: 8px;
}

.link:hover {
	text-decoration: none !important;
}
.searchItem {
	padding: 12px 16px;
	height: 44px;
}

.searchItem:hover {
	background-color: #f7f7f8;
}

.stock {
	padding: 12.75px 0px 12px 16px;
	margin-left: 0px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
}

.stockTitle {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #3C404A;
	padding-right: 0px;
}

.stockName {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	padding-left: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.stockType {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #767C8F;
	text-align: end;
}


::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.searchCard :global(::-webkit-scrollbar-thumb) {
	background-color: #00000033;
	width: 4px;
	height: 50px;
	border-radius: 999px;
}

.stockPadding {
	padding-left: 32px;
	padding-right: 32px;
}

.stackImage {
	width: 80px;
	height: 80px;
	border-radius: 8px;
	margin-bottom: 8px;
}

.stackName {
	width: 52px;
	text-align: center;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.MobileWatchListStart{
  display: none;
}

@media (max-width: 700px) {
	.inputbox {
		position: relative;
		width: 100%;
		border: 0px;
	}
	.searchInput {
		padding-bottom: 9px;
		transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
		border-radius: 6px !important;
		width: 100%;
		background: #FFFFFF;
		border: 1.5px solid #D3D4DA !important;
	}
	.searchCard {
	  width: 100%;
    position: relative;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    overflow-y: scroll;
	}

  .stockTitle {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #1e1f24;
    padding-right: 0px;
  }
  .stockName{
    font-size: 13px;
  }
  .stock{
    display: none;
  }
  .stockPadding{
    display: none;
  }
  .cardBody {
    padding-top: 8px;
  }
  .stockType{
    font-size: 13px;
    padding-left:0px;
  }
  .MobileWatchListStart{
    display: contents;
  }
  .watchListStar{
		display: block;
		top:0px;
	}
}

@media (min-width: 700px) and (max-width:992px) {
  .inputbox {
		width: 320px;
		padding-top: 0px;
	}
  .searchInput {
		width: 380px;
	}
}

.resultDivider {
	width: 100%;
	height: 1px;
	background-color: #EFEFF1;
}

.searchResultDivider {
	width: 100%;
	height: 1px;
	background-color: #EFEFF1;
}

@media(max-width:700px) {
	.searchList{
		height: 104vh;
		overflow-x: hidden;
	}

	.searchResultDivider {
		display: none;
	}
}

@media(min-width:700px) {
	.searchList {
		height: 220px;
		overflow-y: scroll;
		overflow-x: hidden;
		width: 100%;
	}

	.searchCard {
		margin-top: 8px;
		width: 360px;
		height: 268px;
		border: 1px solid  #F7F7F8;
		box-sizing: border-box;
		box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
		border-radius: 6px;
		position: absolute;
		z-index: 1000;
	}

	.searchResultDivider {
		display: block;
	}
	
}