.noteText {
	justify-content: center;
	margin-top: 10px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}

.cardContainer {
	width: 100vw;
	display: flex;
	justify-content: center;
	padding-top: 80px;
	direction: row;
}

@media (max-width: 425px) {
	.cardContainer {
		padding-top: 0px;
	}
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	color: #3c404a;
	margin-left: 0px;
	margin-bottom: 4px;
}

.subTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-left: 0px;
}

.passwordSpecification {
	margin-top: 5px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-left: 0px;
}

.buttonSignUP {
	background: #0061d3;
	width: 100%;
	height: 40px;
	margin-right: 0px;
}

.cardSignUp {
	width: 412px;
	height: auto;
	background: #ffffff;
	border: 1px solid #f7f7f8;
	box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
}
.cardBodySignup {
	padding: 32px 24px 32px 24px;
}

.inputs {
	width: 100%;
	margin-top: 20px;
	margin-left: 0px;
}

.toggle {
	cursor: pointer;
	position: absolute;
	top: 39px;
	right: 15px;
}

.toggle1 {
	cursor: pointer;
	position: absolute;
	top: 38px;
	right: 15px;
}

.buttonMargin {
	margin: 32px 0px 0px 0px;
}

.verifyOtpButton {
	margin: 24px 0px 0px 0px;
}

.verifyOtp {
	margin-top: 8px;
	width: 100%;
}

.otpInput {
	margin-top: 24px;
}

.emailInput {
	width: 467px;
	height: 62px;
}

.buttonPassword {
	width: 133px;
	height: 62px;
}

.rememberRow {
	margin-top: 75px;
	justify-content: center;
}

.remember {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}

.poweredText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-right: 8px;
}

.goBackText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #0061d3;
}

.otpError {
	display: block;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #fa274a;
	margin-top: 8px;
}

.navLink:hover {
	color: inherit;
	text-decoration: none;
}
.timer {
	font-family: 'geomanistregular';
	/* margin-left: 5px; */
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-right: 10px;
}

.passwordInvalid :global(.form-control.is-invalid) {
	background-image: none !important;
	background-position: right 12px center;
	border: 1.5px solid #fa274a;
	background-repeat: no-repeat;
}

.otpSuccess {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #d81331;
}
.stockalImage {
	height: 50px;
}

.goBackUpdate {
	font-family: 'Inter';
	margin-left: 5px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	/* padding-bottom: 4px; */
	border-bottom: 1px solid #0061d3;
	color: #0061d3;
	cursor: pointer;
}

.forgotPasswordContainer {
	min-height: calc(100vh - 155px);
}

@media only screen and (max-width: 768px) {
	.stockalImage {
		height: 40px;
	}
	.forgotText {
		margin-top: 10px;
		font-size: 26px;
	}
	.noteText {
		font-size: 14px;
		line-height: 140%;
	}

	.emailInput {
		width: 382px;
		height: 50px;
	}
	.buttonPassword {
		font-size: 14px;
	}
	.rememberRow {
		margin-top: 50px;
	}
	.remember {
		font-size: 12px;
		line-height: 140%;
	}
	.goBackText {
		font-size: 12px;
		line-height: 140%;
	}
}

@media only screen and (max-width: 480px) {
	.cardSignUp {
		border: 0px;
		box-shadow: none;
	}
	.cardBodySignup {
		padding: 24px;
	}
}

@media only screen and (max-width: 480px) {
	.stockalImage {
		height: 30px;
	}
	.timer {
		font-size: 10px;
	}
	.forgotText {
		margin-top: 10px;
		font-size: 22px;
	}
	.noteText {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 12px;
		line-height: 140%;
	}
	.emailInput {
		width: 211px;
		height: 48px;
	}
	.buttonPassword {
		margin-left: 0px;
		font-size: 12px;
		width: 100%;
		height: 48px;
	}
	.mobileButton {
		text-align: end;
	}
	.rememberRow {
		margin-top: 30px;
	}
	.remember {
		font-size: 10px;
		line-height: 140%;
	}
	.goBackText {
		font-size: 10px;
		line-height: 140%;
	}
}
