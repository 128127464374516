.moneyControl .moneyControlLogo {
	/* padding-bottom: 40px;
  padding-top: 40px; */
	padding: 9% 0;
}

.moneyControl .infoPart {
	padding-left: 60px;
	padding-right: 30px;
	background-color: white;
}

.moneyControl .internalPad {
	padding-right: 8%;
}

.moneyControl .header {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: bold;
	font-size: 2.5rem;
	line-height: 130%;
	color: #020e26;
	margin-bottom: 0;
}

.moneyControl .accountBtn {
	width: 210px;
	height: 64px;
	left: 80px;
	bottom: 256px;
	border: none;
	background: #54a831;
	border-radius: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: bold;
	font-size: 1.125em;
	line-height: 150%;
	text-align: center;
	letter-spacing: 1px;
	color: #ffffff;
	flex: none;
	order: 0;
	align-self: center;
	flex-grow: 0;
	margin: 3% 0px;
}

.moneyControl .trusted {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 0.875em;
	line-height: 150%;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #000000;
}

.moneyControl .partnerType {
	/* padding-top: 50px; */
}

.moneyControl .moneyLoginText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 0.875em;
	line-height: 150%;
	letter-spacing: 0.5px;
	color: #0065a1;
	cursor: pointer;
	/* margin-top: 5px; */
}

.moneyControlStockalLogo {
	/* float: right; */
	/* padding-bottom: 5px; */
	display: inline-block;
	width: 96px;
}

.smallMoneyControltext {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	color: #000000;
	opacity: 0.5;
	/* margin-bottom: 5px; */
}

.moneyControlDisclaimer {
	padding-left: 15px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	color: #000000;
	opacity: 0.5;
}

.moneyControl ul {
	margin: 5% 0;
	padding: 0;
	list-style-type: none;
}

.moneyControl .firstTick li {
	/* margin-left:5%; */
	font-size: 0.9em;
	line-height: 1em;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 1.125em;
	line-height: 130%;
	color: #020e26;
}
.moneyControl .companyCard {
	background-color: #dbf3f6;
	width: 100vw;
	/* height: 100vh; */
}

.moneyControl .thirdTick li {
	/* margin-left:5%; */
	font-size: 0.9em;
	line-height: 1em;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 1.125em;
	line-height: 130%;
	color: #020e26;
}
.moneyControl .whiteCard {
	width: auto !important;
	height: 240px;
	background: #ffffff;
	box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.09);
	border-radius: 13px;
	margin-top: 30px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
}

.moneyControl .insideImage {
	/* width: 70px;
  height: 70px; */
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-slider button {
	display: none !important;
}

.rightColumn .slick-list,
.rightColumn {
	min-height: 800px !important;
	height: 100vh !important;
}

.moneyControlMaxWidth {
	max-width: 380px;
}

@media (min-width: 768px) and (max-width: 992px) {

	.moneyControl .companyCard {
		display: none;
	}

	.moneyControlMaxWidth {
		max-width: none !important;
	}

	/* .smallMoneyControltext {
		width: 139px !important;
	}

	.stockalLogoDiv {
		padding-left: 100px !important;
	} */

	.moneyControl .infoPart {
		padding-left: 60px !important;
		padding-right: 60px !important;
	}

	.moneyControl .header {
		max-width: 480px !important;
	}

	.moneyControl ul {
		margin: 3% 0 !important;
	}
}

@media only screen and (max-width: 480px) {
	.moneyControl .infoPart {
		padding-left: 20px !important;
		padding-right: 20px;
		background-color: white;
	}

	.moneyControl .accountBtn {
		width: 100% !important;
	}

	.moneyControl .internalPad {
		padding-right: 20px !important;
	}

	.moneyControl .trusted {
		text-align: center;
	}

	.moneyControl .moneyLoginText {
		text-align: center;
		padding-top: 20px;
	}

	.moneyControl .companyCard {
		display: none;
	}

	.moneyControl .header {
		font-size: 36px !important;
	}

	.moneyControl .trusted {
		font-size: 12px !important;
	}
}
@media (min-width:480px) and (max-width: 768px){
	.moneyControl .companyCard {
		display: none;
	}
	.moneyControl .infoPart {
		padding-left: 40px !important;
		padding-right: 40px;
	}
}
@media (min-width: 992px){
	.moneyControl .companyCard {
		display: block;
	}
}
