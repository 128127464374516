@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
	.sideBarMobileHide {
	  display: block;
	}
  }
