@import '../../public/geomanist/geomanistBook/stylesheet.css';

/* .card {
  width: 120px;
  background-color: #555;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.myDIV{
    width:150px;
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    text-transform: lowercase;
    color: #1E1F24;
}

.myDIV:hover + .card {
  display: block;
  color: red;
} */

.arrowDown{
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
     transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  text-align: right;
    float: right;
    margin-top: 6px;
    margin-right: 10px;
}

.tooltip {
    position: relative;
    display: inline-block;
    width:150px;
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    text-transform: lowercase;
    color: #1E1F24;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
  width: 352px;
  height: 241px;
  background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ECECEC transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.head{
   font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    color: #626777;
}
.bid{
    font-family:'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #13B948;
}
.offer{
     font-family:'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
   color: #E61446;
}
.info{
    font-family:'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    color: #626777;
    text-align: left;
    padding-top: 10px;
}
.infoDetail{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.6px;
    color: #1E1F24;
    text-align: right;
    padding-top: 10px;
}