@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 840px;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
  padding-left: 20px;
  cursor: pointer;
  white-space:nowrap;
}
.erorrMessage{
  text-align: center;
  margin-top: 10px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #FA274A;
}

.scrollDiv{
  height: calc(100vh - 200px);
  overflow: scroll;
}
.wire {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #1e1f24;
}
.key {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.value {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.responsiveRow{
  padding-bottom: 24px;
  border-bottom: 1px solid #efeff1;
}

.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
	margin-right: 40px;
}
.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.fixedDiv {
	/* background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	); */
	text-align: end;
	position: fixed;
	/* height: 120px; */
	bottom: 10px;
	/* left: 0px;
	right: 0px; */
	/* margin-right: 0%; */
	padding-top: 50px;
}
.otherDiv{
  margin-left: 15px;
}
@media (max-width: 768px) {
	.card {
		width: 520px;
	}
}

@media (min-width:768px) and (max-width:1024px){
  .card {
		width: 540px;
	}
}

@media (max-width: 576px) {
	.card {
		width: 100%;
	}
  .pdf{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #005DD6;
padding-left: 5px;
  }

  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
  .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-right: 0px;
  }
  .otherDiv{
    margin-left: 15px;
    margin-right:15px;
  }
  .title{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 28px;
text-align: center;
padding-left: 95px;
cursor: auto;
  }
  .responsiveRow{
    padding-bottom: 0px;
    border-bottom: none;
  }
  .wire{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.6px;
  }
}

@media (max-width:330px){
  .otherDiv{
    padding-bottom: 50px;
  }
  .title{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    padding-left: 60px;
    cursor: auto;
      }
}
