.checkMark {
  padding-left: 10px;
  padding-top: 2px;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #3C404A;
}

.checkBoxInput:disabled ~ .checkMark::before {
	background-color: #e9ecef;
	border: none;
}

.checkBox .checkBoxInput:checked:focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
	/* background-color: #6097a1; */
	transition: none;
}

.checkBox .checkBoxInput:not(:checked):focus ~ .checkMark::before {
	box-shadow: 0 0 0 1px #fff;
}

.checkMark::before {
  background: #F7F7F8;
  border: 1.5px solid #D3D4DA;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.checkBox .checkBoxInput:checked ~ .checkMark::after {
  background-image: url('../../public/images/newOnboarding/check.svg');
}

.checkMark::after {
  width: 1.7rem;
  height: 1.5rem;
  top: 1px;
  left: -27px;
}

.checkBox .checkBoxInput:checked ~ .checkMark::before {
  background: #F7F7F8;
  border: 1.5px solid #0061D3;
}
