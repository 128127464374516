.cookieCard {
	z-index: 1000;
	position: fixed;
	bottom: 12px;
	bottom: 0px;
	/* left: 54px; */
	background: #ffffff;
	/* width: 345px; */
	/* height: 132px; */
	border: 1px solid rgba(51, 51, 51, 0.25);
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(22, 37, 66, 0.08);
	/* border-radius: 8px; */
	width: 100%;
	padding: 12px;
}

.cookieCardText {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	color: #162542;
}

.cookieCardText {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #162542;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.privacy {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	color: #0061d3;
}

.cookieButton {
	padding-top: 4px !important;
	color: white;
	width: 81px;
	height: 39px;
	background: #0061d3;
	border-radius: 4px;
}

@media (min-width: 300px) and (max-width: 500px) {
	.cookieCard {
		bottom: 0px;
	}
}

@media (max-width: 1300px) {
	.cookieCard {
		padding-right: 80px;
		padding-left: 30px;
		bottom: 0px;
	}
}
