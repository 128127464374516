@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 840px;
}

.responsiveEnter{
  margin-top: 104px;
}

.inputResponsive {
  padding-left: 200px;
  margin-top: 36px;
}

.oneTime {
	display: inline-block;
	white-space: nowrap;
	background: #f7f7f8;
	border-radius: 8px;
	padding: 10px;
	padding-right: 20px;
	padding-left: 20px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #505562;
}
.title {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	border-bottom: 1px solid #efeff1;
	padding-bottom: 24px;
}

.transferred {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}
.dollar {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 72px;
	line-height: 84px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px 35px 12px 30px;
	border: none;
	box-shadow: none;

}
.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;

}
.minimum {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #9096a8;
	margin-top: 38px;
}
.fifty {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.input {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 72px;
	line-height: 84px;
	letter-spacing: 0.6px;
	border: none;
	color: #1e1f24;
  height: 84px;
  /* text-align: center; */
  width: 300px;
}
.fundAmountContainer input::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 72px;
	line-height: 84px;
	letter-spacing: 0.6px;
	border: none;
	color: #d3d4da;
}

.input:focus {
	outline: none !important;
}
.fixedDiv {
	text-align: center;
  margin-top: 20px;
  /* border-top: 1px solid #efeff1; */
  padding-top: 20px;
	/* position: absolute;
	height: 120px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	margin-right: 0%;
	padding-top: 50px; */
}
.erorrMessage{
  text-align: center;
  margin-top: 10px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #FA274A;
}

.MobileErorrMessage{
  display: none;
}

@media (min-width: 769px) and (max-width:992px){
  .card {
		width: 100%;
	}
}


@media (max-width: 768px) {
  .erorrMessage{
    display: none;
  }
	.card {
		width: 540px;
	}
  .mobileTitle{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 28px;
text-align: center;
letter-spacing: 0.6px;
color: #1E1F24;
  }
  .transferred {
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.6px;
    color: #9096A8;
  }
  .responsiveEnter{
    margin-top: 50px;
  }
  .input{
    font-size: 50px;
  }
  .fundAmountContainer input::placeholder {
    font-size: 50px;
  }
  .dollar{
    font-size: 50px;
  }
  .inputResponsive{
    margin-top: 20px;
  }
  .minimum{
    margin-top: 20px;
  }
  .fixedDiv{
    background:none;
    position:static;
    margin-top: 15px;
  }
  .MobileErorrMessage{
    display: block;
    text-align: center;
    margin-top: 10px;
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #FA274A;
  }
}


/* @media (min-width:790px) and (max-width:1024px){
  .card {
		width: 540px;
	}
} */

/* @media (min-width:380px) and (max-width:576px){
  .responsiveBoth{
    padding-left:30px;
  }
} */

@media (max-width: 576px) {
  .card{
    width: 450px;
  }
  .responsiveEnter{
    margin-top: 38px;
    /* height: calc(100vh - 200px);
    overflow: scroll; */
  }
  .inputResponsive {
    padding-left: 100px;
    margin-top: 16px;
  }
  .input {
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 72px;
    letter-spacing: 0.6px;
    border: none;
    color: #1e1f24;
    height: 72px;
    text-align: start;
    width: 200px;
  }
  .fundAmountContainer input::placeholder {
    font-size: 40px;
  }
  .minimum {
    font-size: 14px;
    line-height: 20px;
  }

  .oneTime{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #767C8F;
    margin-left: 15px;
  }

  .fifty {
    font-size: 14px;
    line-height: 20px;
  }

  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }

  .dollar {
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 72px;
    letter-spacing: 0.6px;
    color: #1e1f24;
  }

  .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 0px;
    margin-right: 0px;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 390px) {
  .card{
    width: 350px;
  }
  .responsiveEnter{
    margin-top: 38px;
  }
  .oneTime{
    margin-left: 25px;
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .card{
    width: 300px;
  }
  .responsiveEnter{
    margin-top: 38px;
  }
  .inputResponsive {
    padding-left: 50px;
    margin-top: 16px;
  }
}

/* @media (max-width:330px){
  .card {
		width: 300px;
	}
  .contain{
    width: 300px;
    height: 179px;
  }
  .title{
    font-family: 'geomanistBook';
    font-size: 18px;
    line-height: 25px;
	  border-bottom: 0px;
  }
} */

.addInstWithdraw {
  border: none;
  display: inline-block;
  white-space: nowrap;
  background: #f7f7f8;
  border-radius: 8px;
  /* padding: 10px; */
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #505562;
  width: 200px;
  height: 28px;
  font-size: 14px;
}

.addInstWithdraw::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

/* test for smriti */

@media (min-height: 200px) and (max-height: 500px) {
  .fixedDiv{
    background:none;
    position:static;
    margin-top: 15px;
  }
  .responsiveEnter{
    margin-top: 38px;
  }
}

@media (min-height: 510px) and (max-height: 700px) {
  .fixedDiv{
    /* bottom: 100px; */
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  .dollar {
    font-size: 50px;
  }
  .input{
    font-size: 50px;
  }
  .fundAmountContainer input::placeholder {
    font-size: 50px;
  }
  .fixedDiv{
    bottom: 100px;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .dollar {
    font-size: 50px;
  }
  .input{
    font-size: 50px;
  }
  .fundAmountContainer input::placeholder {
    font-size: 50px;
  }
  .fixedDiv{
    bottom: 100px;
  }
}


.touchCss{
  text-align: end;
	position: static;
	padding-top: 50px;
}

@media (min-width:576px) and (max-width:800px){
  .card{
    width: 540px ;
  }
}
