.container {
	display: flex;
	position: relative;
	cursor: pointer;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.container input {
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
	position: relative;
	display: inline-block;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background: #f7f7f8;
	border: 2px solid #d3d4da;
	border-radius: 999px;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
	background-color: #ccc;
} */

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
	background: #0061d3;
	border: 2px solid #0061d3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container .checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
	left: 5px;
	top: 1.5px;
	width: 5px;
	height: 10px;
	border: solid #FFFFFF;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
