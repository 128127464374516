@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';


.originalPos {
  position: relative;
  margin-top: 16px;
  transition: 0s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top:48px;
}
.shiftPos{
  margin-top:48px;
  margin-left: 0px;
}
.savingsLeftCardPad{
  padding-right: 25px;
}
.savingsTitle{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #767C8F;
}
.savingsAccountBalance{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #3C404A;
}
.savingsPillsMargin{
  margin-top: 44px;
}
.savingsTitleTransactions{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #767C8F;
  margin-bottom: 16px;
}
.savingDatePickerWebPadding{
  padding-right: 30px;
  padding-left: 0px;
}
.savingsDownloadButtonAlighnment{
  text-align: left;
  padding-left: 0px;
  margin-left:-15px;
}
.savingsDownloadButton{
  padding: 11.25px 11.25px 11.25px 11.25px;
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  cursor: pointer;
}
.savingsTableHeader{
  background: #F7F7F8;
  border-radius: 8px 8px 0px 0px;
  margin-left: 0px;
  margin-right:0px;
  margin-top: 24px;
}
.savingsTableHeading{
  padding:12.75px 0px 12.75px 24px;
  font-size: 14px;
  font-family: 'geomanistBook';
  line-height: 20px;
  color: #767C8F;
}
.savingTableType{
  padding: 12.75px 0px 12.75px 24px;
}
.savingsTablePoints{
  margin-left: 0px;
  margin-right:0px;
  /* border-radius: 8px 8px 0px 0px; */
  border-left:1px solid #F7F7F8;
  border-right:1px solid #F7F7F8;
  border-bottom:1px solid #F7F7F8;
  cursor: pointer;
}
.savingsTablePoints:hover{
  background: #F5F7FA;
}
.savingTableTransactionTypeValue{
  display: block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
}
.savingsPagination{
  margin-left: 0px;
  margin-right: 0px;
  border-left: 1px solid #F7F7F8;
  border-right: 1px solid #F7F7F8;
  border-bottom: 1px solid #F7F7F8;
  border-radius: 0px 0px 8px 8px;
}
.savingPaginationButton{
  padding-left: 12.75px;
  padding-right: 12.75px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #767C8F;
  cursor: pointer;
}
.savingPaginationRowPad{
  padding-top: 20.75px;
  padding-bottom: 20.75px;
  line-height: 20px;
}
.savingPaginationRowCount{
  padding-top: 20.75px;
  padding-bottom: 20.75px;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #767C8F;
  text-align: center;
}
.rightPart {
	display: flex;
	flex-wrap: wrap;
}
.addfunds {
	background: #0061d3 !important;
	border-radius: 6px;
	height: 40px;
	padding: 9.5px 35px;
	border: none;
	margin-right: 10px;
}
.addfunds:hover {
	background: #004ea9 !important;
}
.addfunds:focus {
	box-shadow: none;
	outline: none;
}
.addfunds img {
	width: 18px;
	height: 18px;
}
.addfundstext {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	margin-left: 4px;
}
.remittanceHistory {
	border: 1.5px solid #0061d3 !important;
	height: 40px;
	color: #0061d3;
	background: #ffffff;
	border-radius: 6px;
	padding: 8px 20px;
	margin-right: 10px;
}
.remittanceHistory:hover {
	border: 1.5px solid #004ea9 !important;
	color: #004ea9;
}
.remittanceHistory:focus {
	box-shadow: none;
	outline: none;
}
.remittanceHistoryText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.menuContainer {
	border: 1.5px solid #0061d3 !important;
	color: #0061d3;
	background: #ffffff;
	border-radius: 6px;
	width: 40px;
	height: 40px;
	padding: 0;
}
.menuIcon {
	position: relative;
	width: 3px;
	height: 3px;
	background-color: #0061d3;
	border-radius: 50%;
	left: 16px;
	top: -6px;
}
.menuIcon:before,
.menuIcon:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	background-color: inherit;
	border-radius: inherit;
}

.menuIcon:before {
	top: 6px;
}

.menuIcon:after {
	top: 12px;
}
.fundDetailSection {
	padding: 20px;
  padding-left: 0px;
	padding-right: 0px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.fundDetail {
	display: flex;
	align-items: flex-start;
}
.fundImg {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f7f7f8;
	border-radius: 4px;
	margin-right: 12px;
}
.fundImg img {
	width: 20px;
	height: 20px;
}
.fundLabel {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: #767C8F;
	margin-bottom: 2px;
}
.fundDescription {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: #767c8f;
}
.amount {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	color: #3c404a;
  margin-bottom: 2px;
}
.banner {
	margin-top: 32px;
	width: 100%;
	border-radius: 8px;
	padding: 20px;
	padding-right: 24px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
  background:#EFEFF1;
}
.bannerHeader {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #3c404a;
}
.learnMore {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #0061d3;
  margin-top: 8px;
}
.savingTableId{
  display: block;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
  margin-top: 4px;
}
.optionContainer {
	display: none;
	position: absolute;
	top:44px;
	right: 0px;
	background: #ffffff;
	border: 1px solid #d3d4da;
	box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.optionContainer a {
	text-decoration: none;
}
.visible {
	display: block !important;
}
.options {
  color: #3c404a;
	padding: 12px 18px;
	width: max-content;
	display: flex;
  cursor: pointer;
}
.optionsDisabled{
  color: grey;
	padding: 12px 18px;
	width: max-content;
	display: flex;
}
.responsiveThreeButton{
  display: none;
}
.webThreeButtons{
  display: block;
}
.mobileRightSideDetails{
  display: none;
}
.savingPillCircleColor{
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #767C8F;;
  border-radius: 50%;
  margin-right: 10px;
}
.fundStatusHead{
  display: block;
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #767C8F;
  margin-top: 4px;
}
.savingsPillsDropdownSelection{
  margin-right:-15px;
  padding: 8.75px 12.75px 8.75px 14px;
  box-sizing: border-box;
  border: 1px solid #D3D4DA;
  border-radius: 6px;
  font-size: 14px;
  color: #767C8F;
  display: block;
  /* width: 90%; */
  float: right;
  font-family: 'geomanistBook';
  cursor: pointer;
  text-align: center;
}
.savingPillsDropDownIcon{
  display: inline-block;
  margin-left: 5px;
}
.transactionTypeValue{
  display: block;
  padding: 12px 32px 12px 18px;
  border-bottom: 1px solid #D3D4DA;
  font-family: 'geomanistBook';
  font-size: 14px;
  white-space: pre;
}
.transactionTypeValue:hover{
  background: #f7f7f8;
}
.savingsHideMobileFilter{
  display: none;
}
.transactionTypeContainer{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  /* padding: 12px 30px 12px 20px; */
  margin-top: 50px;
  z-index: 100;
  display: block;
  cursor: pointer;
  right: 0px;
}
.savingsMobileCross{
  display: none;
}
.spinnerColor{
  border: .25em solid #0061d3;
  border-right-color: #0061d3;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  color:#0061d3;
}
.spinnerContainer{
  text-align: center;
  height:150px;
  padding-top: 60px;
}
.savingsRightCardPad{
  padding-left: 20px;
  border-left: 1px solid;
  line-height: 10px;
  border-color:#F7F7F8;
}
.fraudPopupContainer{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  /* padding: 12px 30px 12px 20px; */
  right:30px;
  /* margin-top: 50px; */
  z-index: 100;
  display: none;
  cursor: pointer;
  width: 190px;
}
.fraudPopupContainer .text{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'geomanistBook';
  color: #3C404A;
  display: block;
  padding:12px 25px 12px 20px;
}
.fraudPopupContainer .text:hover{
  background: #f7f7f8;
}
.fraudPopupContainer .flagImage{
  margin-right:13px;
}
.uploadDocs{
  background: #F7F7F8;
  border-radius: 8px;
  padding: 16px 15px 16px 20px;
  position: absolute;
  right: 25px;
  color: #FA5B05;
  font-family:'geomanistBook';
  font-size: 14px;
  max-width: 285px;
}
.uploadSeeAll{
  color: #0061D3;
  font-size: 14px;
  line-height: 20px;
}
.noDataFound{
  font-size: 16px;
  font-family:'geomanistBook';
  text-align: center;
  display: block;
  margin-top: 10px;
}
.mobileSavingsTableHeader{
  display: none;
}
.savingShowMobile{
  display: none;
}
.emailSendImage{
  /* height: calc(50vh - 146px); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.successDesc{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767C8F;
  padding: 20px 40px 20px 40px;
}
.successContainer{
  border-radius: 8px;
}
.addFundModal :global(.modal-body){
  padding: 0px !important;
}
.addFundModal :global(#crossImage){
  z-index: 11;
  /* overwriting parent property for easy click */
  padding: 6px;
  right: 22px;
  top: 22px;
}
.uploadDocsMobile{
  display: none;
}
.uploadDocsImage{
  height: 20px;
  margin-top: 5px;
}
@media (max-width: 992px) {
  .optionContainerMobile{
    display: none;
    position: absolute;
    top:44px;
    right: 0px;
    background: #ffffff;
    border: 1px solid #d3d4da;
    box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    z-index: 11;
  }
  .optionContainer{
    z-index:10 !important;
  }
  .uploadDocsImage{
    height: 25px !important;
    margin-top: 5px;
  }
  .uploadDocsMobile{
    display: block;
    background: #F7F7F8;
    border-radius: 8px;
    padding: 16px 15px 16px 20px;
    position: relative;
    /* right: 25px; */
    color: #FA5B05;
    font-family:'geomanistBook';
    font-size: 14px;
    /* max-width: 285px; */
    margin: 15px 0px 0px 0px;
    background: #FFF2EB;
    border-radius: 8px;
    max-width: 400px;
  }
  .mobileRightAlignment{
    text-align:right;
    padding-right:24px;
  }
  .savingsHideMobileFilter{
    display: block;
  }
  .savingShowMobile{
    display: block;
  }
  .savingtableHideMobile{
    display: none;
  }
  .savingPaginationButton{
    display: none;
  }
  .savingsTableHeading{
    display: inline-block;
  }
  .savingsTableHeader{
    display: none;
  }
  .mobileSavingsTableHeader{
    display: block;
    background: #F7F7F8;
    border-radius: 8px 8px 0px 0px;
    margin-left: 0px;
    margin-right:0px;
    margin-top: 24px;
  }
  .optionContainer {
    z-index: 1;
  }
  .savingsPillsMargin{
    justify-content: space-between;
  }
  .savingsDownloadButtonAlighnment{
    text-align: right;
  }
  .savingsLeftCardPad{
    padding-right: 15px;
  }
  .uploadDocs{
    display: none;
  }
  .transactionTypeContainer{
    display: block;
    padding:20px;
    position: fixed;
    width: 50;
    width: 100%;
    height: 60vh;
    background: white;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    padding-top: 30px;
  }
  .savingsMobileCross{
    position: absolute;
    right:20px;
    top:10px;
    display: block;
  }
  .savingsPillsDropdownSelection{
    margin-right:0px;
  }
  .WebVirtualCardShowOnly{
    display: none;
  }
  .responsiveThreeButton{
    display: block !important;
    /* margin-top: -15px; */
  }
  .webThreeButtons{
    display: none;
  }
  .mobileRightSideDetails{
    display: block;
    border: 1px solid #D3D4DA;
    border-radius: 8px;
    margin-top: 20px;
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 20px;
  }
  .banner{
    display: none;
  }
  .addfunds {
    background: #0061d3 !important;
    border-radius: 6px;
    height: 40px;
    padding: 9.5px 35px !important;
    border: none;
    margin-right: 0px;
    width: 45%;
  }
  .remittanceHistory {
    border: 1.5px solid #0061d3 !important;
    height: 40px;
    color: #0061d3;
    background: #ffffff;
    border-radius: 6px;
    padding: 8px 20px;
    margin-right: 0px;
    float: right;
    width: 45%;
  }
  .rightPart {
    display: flex;
    justify-content: space-between;
  }
  .savingsWebFilter{
    display: none;
  }
  .savingsMobileFilter{
    border: 1px solid #D3D4DA;
    border-radius: 6px;
    padding-top: 8.75px;
    padding-bottom: 8.75px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .savingsMobileFilterText{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1200px) {
  .addfunds {
    background: #0061d3 !important;
    border-radius: 6px;
    height: 40px;
    padding: 9.5px 10px;
    border: none;
    margin-right: 18px;
  }
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}

@media (max-width: 435px) {
  .addfunds {
    background: #0061d3 !important;
    border-radius: 6px;
    height: 40px;
    padding: 9.5px 0px !important;
    border: none;
    margin-right: 0px;
    width: 44%;
  }
  .remittanceHistory {
    border: 1.5px solid #0061d3 !important;
    height: 40px;
    color: #0061d3;
    background: #ffffff;
    border-radius: 6px;
    padding: 8px 20px;
    margin-right: 0px;
    float: right;
    width: 44%;
  }
  .optionContainer{
    z-index:10;
  }
}

@media (max-width:600px){
  .addFundModal :global(#crossImage){
      padding: 6px;
      right: 22px;
      top: 19px;
  }
  .addFundModal :global(.modal-dialog){
      height: 100% !important;
      min-height: 100%;
  }
  .addFundModal :global(.modal-content){
      max-height: calc(100% - 50px) !important;
  }
}
