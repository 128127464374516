.step {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #162542;
  white-space: nowrap;
}
.netbanking {
	margin-top: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #162542;
}
.bankName {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #162542;
}
.download {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: capitalize;
	color: #005dd6;
}
.link{
  cursor: pointer;
}
.link:hover{
  text-decoration: underline;
}
.transactionCard {
	background: #f7f7f8;
	border-radius: 8px;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}
.transaction {
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.login {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
	margin: 10px 0 0 0;
	cursor: pointer;
}
.key {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #162542;
  margin-left: 4px;
}
.beneficiaryTableValue {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #162542;
  justify-content: right;
  display: flex;
  flex-grow: 1;
}
.beneficiaryTableValue > span{
  text-align: end;
}
.responsiveInstructions{
  width: 100%;
}
.subtopic {
	padding-top: 20px;
	padding-left: 30px;
	width: 400px;
	color: #162542;
	font-family: 'geomanistBook';
}
.circularPointer{
  margin-right: 4px;
}
.copyIcon{
  margin-left:14px;
  width: 20px;
  cursor: pointer;
}
.beneficiaryTable, .beneficiaryTableBg{
  background: #EFF1F4;
  border: 1px solid #CAD0D7;
  border-radius: 8px;
}
.beneficiaryTable{
  width: calc(100% + 16px);
  position: relative;
  left: -16px;
}
ol li:nth-child(n+10) .beneficiaryTable{
  position: relative;
  left: -25px;
  width: calc(100% + 25px);
}
.beneficiaryTableError{
  background: rgba(220, 104, 3, 0.12) !important;
  border: 1px solid rgba(220, 104, 3, 0.2);
}
.beneficiaryTableRow{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 22px 16px 22px 16px;
  border-bottom: 1px solid #CAD0D7;
}
.beneficiaryTableContent{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.beneficiaryTableKey{
  display: flex;
  align-items: flex-start;
  min-width: 222px;
}
.beneficiaryAccNum{
  background:#DEEFF2
}
.beneficiaryAccInfo{
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #0061D3;
  text-align: end;
  font-weight: 500;
}
.divider{
  width: 100%;
  height: 1px;
  background-color: #CAD0D7;
  margin: 15px 0px 5px 0px;
}
.warning{
  font-size: 12px;
  color: #B35300;
  font-weight: 500;
  margin-top: 4px;
  padding: 0px 15px;
}
.infoCard{
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: flex-start;
  gap:12px;
  border-radius: 8px;
  background-color: #EFF1F4;
}
.leftMargin {
  margin-left: 8px;
}
/* .responsiveImage{
  max-width: 100%;
} */
.list{
  padding: 0 15px;
}
.tooltipCopy :global(.tooltip-inner){
  background-color:  #E0ECFA;
  color: #0061D3;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  font-size: 12px;
}
.tooltipCopyFixed{
  position: fixed;
  bottom: 110px;
  left: 0;
  width: 100vw;
  text-align: center;
  z-index: 1;
}
.tooltipCopyFixed span{
  padding: 8px 12px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background-color: #E0ECFA;
  color: #0061D3;
  border-radius: 8px;
  font-size: 12px;
}
.tooltipCopy :global(.arrow::before){
  border-top-color: #E0ECFA;
}
.marginStepPrimary{
  margin-top:32px;
}
.marginStepSecondary{
  margin-top: 16px;
}
.cardBody{
  padding-right: 0.25rem !important;
}
.beneficiaryAccNumValue{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
}
/* To avoid li flow */
.responsiveImage{
  position: relative;
  left: -16px;
  max-width: calc(100% + 16px);
}
ol li:nth-child(n+10) .responsiveImage{
  position: relative;
  left: -25px;
  max-width: calc(100% + 25px);
}
.spinnerColor{
  border: .25em solid #005DD6;
  border-right-color: transparent;
}
.instructionError{
  display: flex;
  flex-direction: row;
  padding-top: 2px;
}
@media (min-width: 700px) and (max-width:1024px){
  .card{
    width: 640px;
  }
  .fixedDiv {
    width: 740px;
  }
  .responsiveInstructions{
    width: 100%;
  }
}
@media(max-width: 768px){
  .instructionError{
    flex-direction: column;
  }
}
@media (max-width:576px){
  .card{
    width: 400px;
  }
  .responsiveDiv{
    padding-bottom: 0px;
    border-bottom: none;
  }
  .title {
    font-family: 'geomanistmedium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: #162542;
    margin-bottom: 8px;
    text-align: center;
    margin-left: 120px;
  }

  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
  box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
  border-radius: 16px 16px 0px 0px;
  }
  .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    height: 100%;
  }
  .pdf{
    font-family: 'geomanistBook';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #005DD6;
    padding-left: 5px;
      }
    .responsiveInstructions{
      width: 100%;
    }
    .download{
      font-size: 12px;
    }
    .beneficiaryTableContent{
      flex-direction: column;
    }
    .beneficiaryTableValue {
      margin-top: 14px;
      justify-content: space-between;
    }
    .beneficiaryAccNumValue{
      align-items: initial;
    }
    .beneficiaryTableValue >span, .beneficiaryAccInfo{
      text-align: left;
    }
}

@media (max-width:375px){
  .download{
    font-size: 10px;
  }
}
