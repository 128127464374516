@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.absoluteSymbol {
	position: absolute;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #efeff1;
	text-align: center;
}

.modalPadding {
	padding: 50px;
	padding-top: 0px;
}
.crossImageStyling {
	cursor: pointer;
	text-align: end;
	margin-right: 30px;
	margin-top: 28px;
}

.modalDialog {
	position: fixed;
	margin: auto;
	max-width: fit-content;
	max-height: fit-content;
	right: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modalRight:global(.fade .modal-dialog) {
	top: 0px;
	bottom: 0px;
	right: -320px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modalRight:global(.fade.show .modal-dialog) {
	right: 0;
}

.modalDialog :global(.modal-content) {
	height: 101vh;
	max-height: calc(100vh + 1rem) !important;
}
@media (min-width: 425px) and (max-width: 768px) {
	.modalPadding {
		padding: 32px;
		padding-top: 0px;
	}
}

@media (max-width: 576px) {
	.modalDialog :global(.modal-content) {
		width: 100vw;
	}
}

@media (min-width: 320px) and (max-width: 425px) {
	.modalPadding {
		padding: 20px;
		padding-top: 0px;
	}
}

.mobileModal:global(.modal) {
	z-index: 1040;
}

.mobileModal:global(.modal-backdrop.show) {
	width: 105vw;
	height: 104vh;
}

/* new styling  */
.heading {
	margin-top: 80px;
	margin-bottom: 24px;
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
	color: #3c404a;
}
.fundDetailContainer {
	width: 100%;
	border: 1px solid #d3d4da;
	border-radius: 8px;
}
.headerContainer {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: 1px solid #efeff1;
}
.leftPart {
	color: #3c404a;
}
.rightPart {
	display: flex;
	flex-wrap: wrap;
}
.fundAvailableLabel {
	margin-bottom: 4px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}
.fundAvailable {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items:center;
}
.addfunds {
	background: #0061d3 !important;
	border-radius: 6px;
	height: 40px;
	padding: 9.5px 12px;
	border: none;
	margin-right: 15px;
}
.addfunds:hover {
	background: #004ea9 !important;
}
.addfunds:focus {
	box-shadow: none;
	outline: none;
}
.addfunds img {
	width: 18px;
	height: 18px;
}
.addfundstext {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	margin-left: 4px;
}
.remittanceHistory {
	border: 1.5px solid #0061d3 !important;
	height: 40px;
	color: #0061d3;
	background: #ffffff;
	border-radius: 6px;
	padding: 8px 12px;
	margin-right: 15px;
}
.remittanceHistory:hover {
	border: 1.5px solid #004ea9 !important;
	color: #004ea9;
}
.remittanceHistory:focus {
	box-shadow: none;
	outline: none;
}
.remittanceHistoryText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.menuContainer {
	border: 1.5px solid #0061d3 !important;
	color: #0061d3;
	background: #ffffff;
	border-radius: 6px;
	width: 40px;
	height: 40px;
	padding: 0;
}
.menuIcon {
	position: relative;
	width: 3px;
	height: 3px;
	background-color: #0061d3;
	border-radius: 50%;
	left: 16px;
	top: -6px;
}

.menuIcon:before,
.menuIcon:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	background-color: inherit;
	border-radius: inherit;
}

.menuIcon:before {
	top: 6px;
}

.menuIcon:after {
	top: 12px;
}
.optionContainer {
	display: none;
	position: absolute;
	top:44px;
	right: 0px;
	background: #ffffff;
	border: 1px solid #d3d4da;
	box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
}
.optionContainer a {
	color: #3c404a;
	text-decoration: none;
}
.visible {
	display: block !important;
}
.options {
	padding: 12px 18px;
	width: max-content;
	display: flex;
}
.fundDetailSection {
	padding: 20px;
	padding-right: 24px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.amount {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
}
.withdrawable {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.withdrawLink {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #0061d3;
	cursor: pointer;
}
.fundDetail {
	display: flex;
	align-items: flex-start;
}
.fundImg {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f7f7f8;
	border-radius: 4px;
	margin-right: 12px;
}
.fundImg img {
	width: 20px;
	height: 20px;
}
.fundLabel {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
	margin-bottom: 2px;
}
.fundDescription {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
}
.pendingHeader {
	margin-top: 32px;
	padding: 8px;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
}
.pendingDetailContainer {
	width: 100%;
	border: 1px solid #d3d4da;
	border-radius: 8px;
}
.pendingDetailSection:not(:last-child) {
	border-bottom: 1px solid #efeff1;
}
.pendingDetailSection {
	padding: 20px;
	padding-right: 24px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.amountSection {
	display: flex;
	align-items: baseline;
}
.pendingLabel {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
}
.banner {
	margin-top: 32px;
	width: 100%;
	border: 1px solid #d3d4da;
	border-radius: 8px;
	padding: 20px;
	padding-right: 24px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
}
.bannerHeader {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #3c404a;
}
.bannerDescription {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
}
.learnMore {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #0061d3;
}
.bottomMargin {
	padding: 24px;
}
.mobileHide {
	display: block;
}

@media (max-width: 767px) {
	.learnMore {
		margin-top: 16px;
	}
}
@media (max-width: 700px) {
	.mobileHide {
		display: none;
	}
}
@media (max-width: 470px) {
	.heading {
		margin-top: 32px;
		margin-bottom: 16px;
	}
	.headerContainer {
		flex-direction: column;
		align-items: flex-start;
	}
	.rightPart {
		margin-top: 18px;
	}
	.fundDescription {
		width: 90%;
	}
}
@media (max-width: 375px) {
	.rightPart{
		width: 100%;
	}
	.addfunds{
		flex-grow: 1;
	}	
}
@media (max-width: 342px) {
	.menuContainer {
		margin-top: 18px;
	}
	.optionContainer{
		top: 60px;
		left: 0;
		right: auto;
	}
}
