@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.searchCard {
	border: 0px;
	border-left: 1px solid #efeff1;
	/* width: 30%; */
}

.cardBody {
	padding: 0px;
  padding-left: 30px;
  padding-top: 30px;
}

.sortText {
	margin: 0px;
	margin-top: 20px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.dropDownMargin {
	right: -10px;
}

.marketCapActive {
	padding: 8px 12px 8px 12px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
	height: 36px;
	background: #ffffff;
	border: 1px solid #005dd6;
	box-sizing: border-box;
	border-radius: 8px;
	width: fit-content;
  cursor: pointer;
}

.marketCapInactive {
	padding: 8px 12px 8px 12px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
	height: 36px;
	background: #ffffff;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	border-radius: 8px;
	width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.marketBlock {
	max-width: fit-content;
	padding: 0px;
	padding-right: 8px;
}

.theme {
	margin-right: 12px;
	margin-top: 12px;
	font-family: 'geomanistregular';
	padding: 10px 15px 10px 15px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #0f37a3;
	width: fit-content;
	height: 36px;
	background: #eef4fb;
	border-radius: 60px;
}

.rowMargin{
  margin-top: 42px;
}

.showEtf{
  padding-right: 0px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #1E1F24;
}
.exclusive{
  background: linear-gradient(90deg, #E0980E 0%, #F4C66C 56.12%, #F2B133 100%);
border-radius: 4px;
font-family: 'geomanistMedium';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 130%;
letter-spacing: 0.6px;
color: #302001;
text-align: center;
padding:12px;
padding-top:6px;
padding-bottom: 6px;
}
.icon {
  margin-left: 10px;
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
  display: inline-block;
}

.icon:after {

  animation: shine 2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.closeImage{
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 0px;
  top: 0px;
}

.icon:active:after {
  opacity: 1;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
    transition-duration: 10s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
@media (min-width: 1200px){
  .nowrap{
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

@media (max-width: 1200px) {
  .searchCard {
    border: 0px;
    border-left: 0px;
    /* width: 30%; */
  }

  .cardBody {
    padding: 30px;
  }
}

.applyButton{
width: 100%;
height: 48px;
background: #1E1F24;
border-radius: 8px;
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
text-align: center;
letter-spacing: 0.6px;
color: #FFFFFF;
}
.resetButton{
  justify-content: flex-end;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 16px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: 0.6px;
color: #005DD6;
cursor: pointer;
}
@media (min-width: 320px) and (max-width: 480px) {
  .sortText {
    margin-top: 0px;
  }
  .resetButton{
    font-size: 14px;
  }
}

@media (max-width: 330px){
  .marginForStrongBuy{
    width: 320px;
  }
  .sortText {
    width: 320px;
  }
}