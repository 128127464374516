.message{
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 110%;
	color: #000000;
	text-align: center;
}
.header{
	border-bottom: 0;
}
