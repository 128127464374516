@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.activePositive {
font-family: 'geomanistbook';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.4px;
color: #0DC014;
border-bottom: 2px solid #0DC014;
cursor: pointer;
}

.activeNegative {
font-family: 'geomanistbook';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.4px;
color: #FA274A;
border-bottom: 2px solid #FA274A;
cursor: pointer;
}

.nonactive{
font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #767C8F;
cursor: pointer;
}

.durationText {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767C8F;
	cursor: default;
}

.delayed{
    font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: center;
letter-spacing: 0.6px;
color: #626777;
}
.expand{
    font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: right;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #1E1F24;
margin-left: 25px;
}

.isLive{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #767C8F;
margin-left: 8px;
}

.marketActive {
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin-left: 4px;
	margin-bottom: 1px;
	background: #0dc014;
}

.graphDurationContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 32px 0px 0px 0px;
	
}

.durationTextContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap:20px;
}

@media (max-width:1200px) {
	.graphDurationContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap:20px;
	}
}

@media (min-width:1200px) {
	.graphDurationContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

@media (max-width:1200px) {
	.closeTimeContainer {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 4px;
	}
}

@media (min-width:1200px) {
	.closeTimeContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap:12px;
	}
}

.otcToolTipText {
    font-family: 'geomanistRegular';
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
    text-align: left;
    color: #767C8F;
}

.otcToolTip :global(.tooltip-inner){
    max-width: 240px !important;
    padding: 10px;
    background-color:  #FFFFFF;
    color: #767C8F;
    border-radius: 8px;
    opacity: 1.0 !important;
    box-shadow: 0px 2px 16px -4px rgba(60, 64, 74, 0.16), 0px 2px 12px -2px rgba(60, 64, 74, 0.04);
}

.otcToolTip :global(.arrow::before){
    border-top-color: #FFFFFF;
    border-bottom-color:#FFFFFF;
}

.otcToolTipHeader{
  font-family: 'geomanistBook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3C404A;
  margin: 0px;
  text-align: left;
}

.otcDisclaimer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	color: #FA5B05;
	font-family: 'geomanistBook';
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;	
	gap: 6px;
}

.otcDisclaimer p {
	margin: 0px;
}

@media (max-width:375px) {
	.activeNegative, .activeNegative, .nonactive, .durationText, .otcDisclaimer{
		font-size: 12px;
	}
}

@media (min-width:375px) {
	.activeNegative, .activeNegative, .nonactive, .durationText, .otcDisclaimer {
		font-size: 14px;
	}
}