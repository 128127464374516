.erorrMessage{
  margin-top:10px;
	font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-left:10px;
  color: #FA274A;
  }
  .background {
	  background: #efefef;
	  padding: 0px;
	  height: 100vh;
  }
  .dialogClass {
	  max-width: 100%;
	  margin: 0px;
	  max-height: 100%;
  }

  .Title {
	  font-family: 'geomanistmedium';
	  font-style: normal;
	  font-weight: 500;
	  font-size: 24px;
    line-height: 32px;
    color: #3C404A;
	  margin: 0px;
    padding-left:10px;
    padding-right:10px;
  }

  .blackText {
    margin:0px;
    margin-top:10px;
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3C404A;
    padding-left:10px;
    padding-right:10px;
  }
  .detail {
	  margin: 0px;
	  font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #767C8F;
    padding-left:10px;
    padding-right:10px;
  }
  .containerWidth {
	  margin-top: 10px;
	  max-width: 770px;
  }

  .nextSectionButton {
    height: 40px;
    width:max-content;
  }

  .borderBottom {
	  padding-bottom: 50px;
	  border-bottom: 2px solid rgba(22, 37, 66, 0.3);
  }
  .edit {
	  margin: 0px;
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 14px;
	  line-height: 150%;
	  text-decoration-line: underline;
	  color: #0061d3;
	  cursor: pointer;
  }

  .header {
	  height: 70px;
	  background: #ffffff;
	  /* padding-left: 25%;
	  padding-right: 25%; */
	  border-bottom: 2px solid rgba(22, 37, 66, 0.3);
	  margin-right: 0px;
  }
  .investment {
	  padding-top: 10px;
	  font-family: 'geomanistmedium';
	  font-style: normal;
	  font-weight: 500;
	  font-size: 24px;
	  line-height: 110%;
	  color: #162542;
  }
  .brandImg {
	  /* width: 30px; */
	  height: 30px;
	  margin-right: 20px;
  }

  .brand {
	  padding-top: 20px;
  }
  .save {
	  padding-top: 25px;
	  text-align: end;
	  font-family: 'geomanistregular';
	  font-style: normal;
	  font-weight: 400;
	  font-size: 14px;
	  line-height: 140%;
	  text-decoration-line: underline;
	color: #d81331;
	cursor: pointer;
  }
  @media (max-width:425px){
	  .brandImg {
		  width: 24px;
		  height: 24px;
		  margin-right: 10px;
	  }
	  .save {
		  padding-left: 0px;
		  font-size: 12px;
	  }
  }
  .modalFooter {
	  border-top: none;
	  padding: 0px;
  }

  .footer {
	 background: #FFFFFF;;
	position:fixed;
	bottom: 0;
	z-index: 100;
	  /* padding-top: 10px; */
	  padding-bottom: 10px;
	  /* padding-left: 25%;
	  padding-right: 25%; */
	  width: 100%;
  }

  .progressTitle {
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #009C06;
	  white-space: nowrap;
  }

  .progressQuote {
	  font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #162542;
  }

  .progress {
	/* position: fixed;
	bottom: 82px; */
	  margin: 0px;
	  width: 100%;
	  height: 2px;
	  background: rgba(22, 37, 66, 0.3);
	  /* position: relative; */
  }

  .complete {
	  height: 2px;
	  background: #13b948;
  }

  .payButton {
	  min-width: 130px;
	  width: 100%;
	  height: 62px;
  }

  /********PersonalDetail ********/
  /*******************************/
  .personalInputRow {
	  margin: 0px;
	  margin-top: 20px;
  }
  .input {
	  height: 62px;
  }

  .right {
	  text-align: end;
  }
  .dropdowmn {
	  height: 62px;
	  width: 100%;
  }
  .addressdropdown {
	  height: 62px;
	  width: 100%;
	  background-color: #F8F8F8 !important;
  }
  .calendaricon {
	  position: absolute;
	  right: 43px;
	  top: 22px;
	  cursor: pointer;
  }
  .calendarImg{
    position: absolute;
    width:15px;
	  right: 10%;
	  top: 40px;
	  cursor: pointer;
  }

  .calendar {
	  position: absolute;
	  z-index: 105;
    top: 49px;
    right: -2px;
	  width: 275px;
	  /* -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22);
	  -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22);
	  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.22); */
  }

  /*******Financial Detail************/
  /**************************/
  .heading {
	  font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3C404A;
    padding-left:10px;
    padding-right:10px;
  }

  .financialWrap {
	  /* white-space: nowrap; */
	  width: inherit;
	  overflow: hidden;
	  text-overflow: ellipsis;
  }

  .desc {
	  font-family: 'geomanistregular';
	  font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #767C8F;
    padding-left:10px;
    padding-right:10px;
  }

  /*******Legal Detail ***********/
  /*******************************/
  /******************************/
  .checkBoxMargin {
	  margin: 0px;
	  margin-top: 20px;
  }

  .companyInput {
	  margin: 0px;
	  margin-top: 12px;
	  margin-left: 48px;
  }

  .riskTitle {
	  font-family: 'Noticia Text';
	  font-style: normal;
	  font-weight: bold;
	  font-size: 24px;
	  line-height: 150%;
	  color: #162542;
  }

  .riskDetail {
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 18px;
	  line-height: 150%;
	  color: rgba(22, 37, 66, 0.75);
  }

  .riskUnderLine {
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 18px;
	  line-height: 150%;
	  text-decoration-line: underline;
	  color: #0061d3;
	  cursor: pointer;
  }

  /* .modalDialog {
	  max-width: 50% !important;
  } */

  @media (max-width: 575px) {
	  .modalDialog {
		  max-width: 100% !important;
	  }
  }



  .modalBody {
	  padding: 50px !important;
  }

  .errorMessage {
	  font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FA274A;
  }

  .legalNote {
	  font-family: 'geomanistregular';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 14px;
	  line-height: 20px;
	  letter-spacing: 0.6px;

	  color: rgba(22, 37, 66, 0.75);
  }

  /***********************/
  /***Upload Document*****/
  .uploadTitle {
	  margin: 0px;
	  margin-top: 50px;
	  margin-bottom: 30px;
	  font-family: 'Noticia Text';
	  font-style: normal;
	  font-weight: bold;
	  font-size: 24px;
	  line-height: 110%;
	  color: #162542;
  }

  .imageCheck {
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 12px;
	  line-height: 150%;
	  color: rgba(22, 37, 66, 0.75);
  }

  .uploadDropDown {
	  /* margin-top: 30px; */
	  height: 62px;
	  width: 100%;
  }

  .uploadDiv {
	  margin-top: 20px;
	  height: 250px;
	  background: rgba(22, 37, 66, 0.3);
  }

  .dropImageCenter {
	  width: 400px;
	  display: table-cell;
	  vertical-align: middle;
	  height: 250px;
	  text-align: center;
  }

  .uploadButton {
	  width: 146px;
	  height: 62px;
  }

  .dropTitle {
	  margin-top: 5px;
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 12px;
	  line-height: 145%;
	  text-align: center;
	  color: rgba(22, 37, 66, 0.75);
  }

  .uploadedImage {
	  width: 370px;
	  height: 250px;
  }

  .uploadImageSection {
	  margin-top: 20px;
	  position: relative;
	  /* width: 100%; */
	  height: 100%;
  }

  .imageButton {
	  top: 185px;
	  left: 157px;
	  background: #d81331;
	  border-radius: 14px;
	  position: absolute;
	  width: 68px;
	  height: 28px;
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: bold;
	  font-size: 12px;
	  line-height: 150%;
	  color: #ffffff;
	  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		  border-radius 0.3s ease-in-out;
	  border: none;
	  box-shadow: none;
  }

  .imageButton:hover {
	  background: #d81331;
	  border-radius: 14px;
	  box-shadow: none;
	  border-radius: 37px;
  }

  .imageButton:focus {
	  background: #d81331;
	  border-radius: 14px;
	  box-sizing: border-box;
	  border: none;
	  box-shadow: none;
  }

  .imageButton:not(:disabled):not(.disabled):active {
	  background: #d81331;
	  border-radius: 14px;
	  box-sizing: border-box;
	  border: none;
	  box-shadow: none;
  }

  .imageButton:not(:disabled):not(.disabled):active:focus {
	  background: #d81331;
	  border-radius: 14px;
	  box-sizing: border-box;
	  border: none;
	  box-shadow: none;
  }

  .circle {
	  padding-left: 3px;
  }

  .validation {
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: normal;
	  font-size: 12px;
	  line-height: 150%;
	  color: rgba(22, 37, 66, 0.75);
	  margin-left: 8px;
  }

  .FrontTitle {
	  margin: 0px;
	  margin-top: 10px;
	  font-family: 'Inter';
	  font-style: normal;
	  font-weight: bold;
	  font-size: 18px;
	  line-height: 150%;
	  color: rgba(22, 37, 66, 0.75);
  }

  .disclosures {
	  cursor: pointer;
	  font-family: 'geomanistregular';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0061D3;
  }

  .colPaddningMobile {
	  padding-left: 0px;
    padding-right:10px
  }

  .politicalRadio{
    margin-left:0px;
    margin-right:0px;
    padding: 20px;
    height: auto;
    border-bottom: 1px solid #F7F7F8;
  }
  .politicalRadio :global(.form-check-label){
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3C404A;
    padding-left:5px;
  }
  .politicalRadio :global(.form-check){
    line-height:initial;
  }
  .politicalInput{
    margin-top:20px;
    height:40px;
  }

  @media (max-width: 325px){
	  .brand{
		  display: flex;
	  }
	  .investment{
		  padding-top: 0px;
	  }
  }
  @media only screen and (max-width: 768px) {
	  .containerWidth {
		  max-width: 650px;
	  }
	  .investment {
		  font-size: 16px;
	  }
  }

  @media (min-width: 480px) and (max-width: 768px) {
	  .progressTitle, .progressQuote, .payButton{
		  font-size: 2.5vw;
	  }
  }
  @media only screen and (max-width: 480px) {
	  .colPaddningMobile {
		  padding-left: 10px;
	  }
	  .colMarginMobile {
		  margin-top: 20px;
		  padding-left: 10px;
	  }
	  .dropdowmn {
		  height: 48px;
	  }
	  .addressdropdown {
		height: 48px;
		background-color: #F8F8F8 !important;
	}
	  .calendaricon {
		  right: 18px;
		  top: 17px;
	  }
	  .Title {
		  font-size: 22px;
	  }
	  .detail {
		  font-size: 12px;
	  }
	  .input {
		  height: 48px;
	  }
	  .heading {
		  font-size: 14px;
	  }
	  .desc {
		  font-size: 12px;
	  }
	  .edit {
		  font-size: 12px;
	  }
	  .legalNote {
		  font-size: 12px;
	  }
	  .disclosures {
		  font-size: 12px;
	  }
	  .progressTitle, .progressQuote{
		  font-size: 12px
	  }
	  .payButton{
		  height: 48px;
	  }
  }
  @media (min-width: 481px) and (max-width: 768px) {
	  .colMarginMobile {
		  margin-top: 20px;
		  padding-left: 0px;
	  }
  }



  /* new styles */
  .flagImgCode{
    height: 18px;
    vertical-align: sub;
  }
  .countryLabel{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3C404A;
  }
  .errorState{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FA274A;
    }

  .label {
    display:inline-block;
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #3C404A;
    margin-bottom:5px;
    padding-left:4px;
  }

.cardAccount{
  margin-top:40px;
border: 1px solid #F7F7F8;
box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
border-radius: 16px;
}

.cardBodyAccount{
  padding:32px 14px;
}

.accountTitle{
padding-left:10px;
padding-bottom:8px;
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 44px;
color: #3C404A;
margin:0;
padding-top:70px;
}

.subTitle{
padding-left:10px;
font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #767C8F;
margin:0;
}

.paddingCol{
  padding-left:10px;
  padding-right:10px;
}

.mobileRightMargin{
  padding-right:10px;
  padding-left:0px;
}
.mobileLeftMargin{
  padding-left:10px;
  padding-right:0px;
}
.buttonMargin{
  margin:0px;
  margin-top:40px
}
.dropdownWidth{
  width:100%
}
.mobileMargin{
  padding-left:10px;
  padding-right:10px;
}
.politicalDiv{
  display:block;
  padding-left:10px;
  padding-right:10px;
}
.signatureText{
  padding-top:6px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #767C8F;
}
.disclouresDetail{
  margin:0px;
  margin-top:15px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #9BA1A8;
}
.accountSetDropDown{
  width:92%;
}
.accountDropPlaceholder{
font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
}
.DropImage{
  position: absolute;
    top: 44px;
    width: 12px;
    right: 10%;
}
.legalBox{
  padding:20px;
  margin:0px;
  border-bottom:1px solid #F7F7F8;
}
.legalBorderBox{
  padding:20px;
  margin:0px;
  background:#F7F7F8;
  border-radius: 8px;
}
@media only screen and (max-width:768px){
  .paddingCol{
    margin-top:20px;
    padding-left:0px;
    padding-right:0px;
  }
  .mobileMargin{
    margin-top:20px;
  }
  .personalInputRow{
    margin-top:0px;
  }
  .buttonMargin{
    margin:0px;
    margin-top:20px;
  }
  .nextSectionButton{
    width:100%;
  }
}

.editButton{
  padding-left:10px;
  padding-right:10px;
  text-align:end;
}
.signatureDiv{
  margin:0px;
  margin-top:20px;
  padding-bottom:20px;
  border-bottom:1px solid #EFEFF1;
}
.verifypanText{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #767C8F;
  margin-top:6px;
  cursor:pointer;
}
.passwordInvalid :global(.form-control.is-invalid) {
  background-image: none !important;
  background-position: right 12px center;
  border: 1.5px solid #FA274A;
  background-repeat: no-repeat;
}
.panCardCapital{
  text-transform: uppercase;
}
.greyBox{
padding: 16px 24px;
background: #F7F7F8;
border-radius: 12px;
}
.greyBoxTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
}
.greyBoxSubTitle{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #3C404A;
}

@media only screen and (max-width:600px){
  .cardAccount{
    border:0px;
    box-shadow:none;
    border-bottom: 1px solid #EFEFF1;
  }
  .editButton{
    /* text-align:start */
  }
}
