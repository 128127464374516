.btnContainer {
	width: 100%;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1.5px solid #0061d3;
	border-radius: 8px;
	cursor: pointer;
}
.btnWrapper {
	display: flex;
	align-items: flex-start;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #0061d3;
}
.btnWrapper img {
	width: 18px;
	height: 18px;
	margin-right: 6px;
}
.fileContainer {
	width: 100%;
	padding: 10px 15px;
	border: 1.5px solid #d3d4da;
	border-radius: 8px;
}
.fileWrapper {
	display: flex;
	justify-content: space-between;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	/* font-size: 14px;
	line-height: 20px;
	color: #0061d3; */
}
.fileLabelWrapper {
	display: flex;
	align-items: center;
	width: 80%;
}
.fileLabelWrapper img {
	width: 24px;
	height: 24px;
	margin-right: 12px;
}
.fileLabel {
    width: 100%;
}
.fileTitle {
	font-size: 14px;
	line-height: 20px;
	color: #3c404a;
	/* elipsis */
	display: block;
	display: -webkit-box;
	max-width: 100%;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.fileDate {
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
}
.deleteIcon {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.deleteIcon img {
	width: 18px;
	height: 18px;
}
