@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.cardHead {
	background: #fff1ed;
	border-radius: 7.42857px;
	/* height: 400px; */
	/* width: 352px; */
	height: 469px;
	text-align: center;
}

.heading {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #5e382c;
	padding-top: 20px;
}

.note {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #7e5d52;
  display: inline-flex;
}

.marginSecond {
	margin-left: 50px;
}

.slider {
	width: 300px;
	text-align: center;
	overflow: hidden;
}

.slides {
	/* margin-left: 20px; */
  padding-left: 20px;
  display: flex;
	position: relative;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	height: 290px;
	-webkit-overflow-scrolling: touch;
}

.slides > div {
	flex-shrink: 0;
}

.carouselButton {
	background-color: #f0d1c7;
	border-radius: 50%;
	border: 0px;
	transition: none !important;
	width: 32px;
	height: 34px;
	padding-top: 3px;
}

.carouselButton:focus {
	box-shadow: 0 0 0 1px #fff;
}

.slider1 {
	transition-duration: 1s;
	width: 85%;
	position: absolute;
	top: 0;
	z-index: 1;
}

.slider2 {
	position: absolute;
	width: 85%;
	margin: 13px;
}

.slider3 {
	width: 85%;
	margin: 22px;
}

.StockOfTheDaySlider :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;
}

@media (min-width: 769px) and (max-width: 990px) {
	.cardHead {
		height: 400px;
	}
  .StockOfTheDaySlider :global(.slick-slide) {
    width: 290px !important;
  }
  /* .StockOfTheDaySlider{
    width:300px;
  } */
  .heading{
    text-align: left;
    padding-left: 20px;
    padding-top: 40px;
    margin-bottom: 10px;
  }
}

@media  (min-width: 340px) and (max-width: 490px) {
  .note{
    font-size: 14px;
  }
	.cardHead {
		width: auto;
		height: 320px;
	}
  .StockOfTheDaySlider :global(.slick-list) {
    /* width: 290px !important; */
    padding-left: 0px !important;
  }
  /* .StockOfTheDaySlider :global(.slick-slide) {
    width:234px !important;
  } */
  .heading{
    text-align: left;
    padding-left: 15px;
    padding-top: 40px;
    margin-bottom: 0px;
  }
}

/* @media  (min-width: 340px) and (max-width: 400px) {
  .StockOfTheDaySlider :global(.slick-slide) {
    width:200px !important;
  }
} */

@media (min-width: 490px) and (max-width: 768px) {
	.cardHead {
		width: auto;
		height: 390px;
	}
  .heading{
    text-align: left;
    padding-left: 15px;
    padding-top: 40px;
    margin-bottom: 0px;
  }
  .StockOfTheDaySlider :global(.slick-slide) {
    width: 290px !important;
  }
}

/* @media (max-width: 375px) {
	.cardHead {
		width: 215px;
	}
} */
/*
@media (max-width: 980px) {
	.cardHead {
		height: 330px;
		border-radius: 0px;
		text-align: start;
		padding-left: 20px;
	}
	.heading {
		font-size: 18px;
		line-height: 24px;
		opacity: 0.8;
		padding-top: 20px;
		margin-bottom: 0px;
	}
	.buttonRemove :global(.slick-list) {

		padding-left: 0px !important;
	}

	.buttonRemove :global(.slick-next) {
		display: none !important;
	}

	.people {
		font-family: 'geomanistbook';
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: 0.6px;
		color: #7e5d52;
	}
} */
