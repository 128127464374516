@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.link {
	display: inline-block;
	margin-top: 8px;
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	cursor: pointer;
}

.greyedOutLink{
  display: inline-block;
	margin-top: 8px;
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: grey;
	cursor: pointer;
}

.imageDiv {
	width: 48px;
	height: 48px;
	background: #d7f4f8;
	border-radius: 50%;
	text-align: center;
	padding-top: 10px;
}

.dropdownBtn {
	background: #efeff1;
	border-radius: 8px;
	font-family: 'geomanistregular';
	border: none;
	width: 288px;
	height: 48px;
	text-align: initial;
	color: #1e1f24;
}

.dropdownBtn:hover {
	background: #efeff1;
	border-radius: 8px;
	color: #1e1f24;
	border: none;
	box-shadow: none;
}

.dropdownBtn:focus {
	background: #ffffff;
	color: #1e1f24;
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.dropdownBtn:not(:disabled):not(.disabled):active {
	background: #ffffff;
	color: #1e1f24;
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.dropdownBtn:not(:disabled):not(.disabled):active:focus {
	background: #ffffff;
	color: #1e1f24;
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.dropDown {
	margin: 0px;
	margin-top: 24px;
	position: relative;
}

.dropDownIcon {
	visibility: visible;
	position: absolute;
	padding: 11px;
	right: 10px;
	z-index: 1;
	cursor: pointer;
	color: #505562;
	cursor: pointer;
}

.inputControl {
	margin: 0px;
	margin-top: 24px;
}

.amountInput {
	font-family: 'geomanistregular';
	padding-left: 25px;
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	border: 0px;
	background: #efeff1;
	border-radius: 8px;
	width: 288px;
	height: 48px;
}

.amountInput::placeholder {
	padding-left: 10px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #9096a8;
}

.amountInput:active,
.amountInput:hover {
	background: #ffffff;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.amountInput:focus {
	background: #ffffff;
	border: 1px solid #1e1f24;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

/* FUndDetail detail */
.fundDetail {
	margin-top: 40px;
	border-bottom: 1px solid #efeff1;
	min-height: 132px;
}

.fundImage {
	text-align: end;
	padding-top: 15px;
}

.fundTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
}

.fundDescription {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
}

.fundValue {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	text-align: end;
  white-space: nowrap;
}

/* ************************************************* */
/* ************************************************* */
/* ************************************************* */
/* FUnd Detail */
.fundDetailSection {
	max-width: 640px;
	margin-right: 93px;
}

/* ************************************************* */
/* ************************************************* */
/* ************************************************* */
/* Add fund */
.addFundCard {
	width: 352px;
	/* height: 515px; */
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
}

.cardPadding {
	padding: 2rem;
}

.addFundTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	text-transform: lowercase;
	color: #005dd6;
	margin-right: 34px;
	padding-bottom: 4px;
	cursor: pointer;
}

.activeFundTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	text-transform: lowercase;
	color: #005dd6;
	margin-right: 34px;
	padding-bottom: 4px;
	cursor: pointer;
	border-bottom: 2px solid #005dd6;
}

.note {
	padding-top: 3px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-left: 9px;
}

.noteDiv {
	margin: 0px;
	margin-top: 30px;
	display: flex;
	flex-wrap: unset;
}

.checkBox {
	margin: 0px;
	margin-top: 24px;
}

.checkBox label {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
	margin-left: 4px;
}

.checkBoxLabel {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
	margin-left: 8px;
}

.checkBox input[type='checkbox'] {
	height: 20px;
	width: 20px;
	margin-top: 1px;
}
.checkBox input[type='checkbox']:checked + label:after {
	background-color: #13b948;
}

.marginRow {
	margin: 0px;
	margin-top: 24px;
}

.reviewButton {
	font-family: 'geomanistbook';
	position: absolute;
	width: 288px;
	height: 64px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	background: #005dd6;
	border-radius: 8px;
	border: none;
	box-shadow: none;
	color: #ffffff;
}

.reviewButton:hover {
	background: #1644c3;
	border: none;
	box-shadow: none;
}

.reviewButton:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.reviewButton:not(:disabled):not(.disabled):active {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.reviewButton:not(:disabled):not(.disabled):active:focus {
	background: #005dd6;
	color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
}

.secureNoteBLock {
	margin: 0px;
	margin-top: 80px;
	display: flex;
	flex-wrap: unset;
	justify-content: center;
}

.secureNote {
	padding-top: 7px;
	padding-left: 7px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #d3d4da;
}

.dollarIcon {
	visibility: visible;
	position: absolute;
	padding: 13px;
	cursor: pointer;
	color: #9096a8;
	cursor: pointer;
}

.LrsTitle {
	margin: 0px;
	margin-top: 17px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.rupee {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #505562;
}

.conversionNote {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #767c8f;
}

.bankDropdown {
	z-index: 1;
	position: absolute !important;
	left: 133px;
	margin-top: 6px;
	transform: translate(-130px, 45px) !important;
	background: #ffffff;
	border: 1px solid #d3d4da;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 2, 65, 0.1);
	border-radius: 8px;
	padding-top: 5px;
	width: 288px;
}

.dropdownCard {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.dropDownItem {
	padding-left: 16px;
	padding-right: 16px;
	cursor: pointer;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	padding-bottom: 15px;
	padding-top: 15px;
	border-bottom: 1px solid #efeff1;
	/* margin-bottom: 20px; */
}
.dropDownItem:hover {
	background-color: #f7f7f8;
}

/* ************************************************* */
/* ************************************************* */
/* ************************************************* */
/* Review Order Card */
.reviewCard {
	position: absolute;
	width: 352px;
	border: 0px;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
}

.backRow {
	cursor: pointer;
	margin: 0px;
	display: flex;
	flex-wrap: unset;
}

.backText {
	cursor: pointer;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 12px;
}

.reviewTitle {
	margin: 0px;
	margin-top: 16px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.reviewDetailTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.reviewDetailValue {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.disclaimer {
	margin: 0px;
	margin-top: 16px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #767c8f;
}

.importantNote {
	margin: 0px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-transform: lowercase;
	color: #13b948;
}

/**************************************/
/**************************************/
/**************************************/
/* Fund Transfer */
.fundTransferCard {
	position: absolute;
	width: 352px;
	padding-bottom: 37px;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(2, 2, 65, 0.11);
	border-radius: 16px;
}

.FundTransferTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #005dd6;
	margin: 0px;
	margin-top: 21px;
	justify-content: center;
}

.cancelledText {
	margin-top: 8px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-transform: lowercase;
	color: #626777;
}

.LrsId {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
	justify-content: center;
	margin-top: 8px;
	padding-bottom: 24px;
	border-bottom: 1px solid #efeff1;
}

.nextSteps {
	margin: 0px;
	margin-top: 16px;
	margin-left: 32px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
}

.steps {
	margin: 0px;
	padding-left: 32px;
	padding-right: 22px;
}

.stepTitle {
	margin-top: 8px;
	border: none;
	background-color: white;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	text-transform: lowercase;
	color: #1e1f24;
}

.stepTitle:focus {
	outline: #ffffff;
}

.stepActive {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	text-transform: lowercase;
	color: #1e1f24;
	text-align: left;
	padding: 0px;
}

.stepInactive {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
	text-align: left;
	padding: 0px;
}

.srnoActive {
	padding: 0px;
	font-family: 'geomanistmedium';
}

.srnoInactive {
	padding: 0px;
	font-family: 'geomanistregular';
}

.stepDetail {
	margin-top: 8px;
	width: 250px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}

.stepInsideTitle {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #1e1f24;
	margin-top: 16px;
	margin-bottom: 8px;
}

.changeAddressLink {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #005dd6;
	margin-top: 16px;
	margin-bottom: 16px;
}

.modalDialog {
	position: fixed;
	margin: auto;
	max-width: fit-content;
	max-height: fit-content;
	right: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.modalDialog :global(.modal-content) {
	height: 101vh;
	max-height: calc(100vh + 1rem) !important;
}
.crossImageStyling{
  cursor: pointer;
  text-align: end;
  margin-right: 30px;
  margin-top: 28px;
}
.modalPadding {
	padding: 50px;
	padding-top: 0px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .modalPadding{
    padding: 20px;
  }
}
.downloadButton {
	transition: color 0s ease-in-out, background-color 0s ease-in-out,
		border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	margin-top: 8px;
	background: #efeff1;
	border-radius: 8px;
	margin-bottom: 16px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-transform: lowercase;
	color: #1e1f24;
	border: none;
}

.downloadButton:focus,
.downloadButton:hover,
.downloadButton:active,
.downloadButton:not(:disabled):not(.disabled):active {
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	background: #efeff1;
	border-radius: 8px;
	margin-bottom: 16px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-transform: lowercase;
	color: #1e1f24;
	border: none;
	box-shadow: none;
}

.downloadButton:not(:disabled):not(.disabled):active:focus {
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
	box-shadow: none;
}

.stepCard {
	padding: 0px;
	padding-left: 23px;
	border-bottom: 1px solid #efeff1;
}

/**************************/
/**************************/
/**************************/
/*Wire Instruction */
.wireNote {
	margin: 24px 42px 0px 32px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

/**********************/
/**********************/
/**********************/
/***Withdraw Amount*/
.accountName {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #1e1f24;
	margin-left: 10px;
}

.accountNumber {
	font-family: 'geomainstregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #626777;
	margin-left: 10px;
}

.editdelete {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-transform: lowercase;
	color: #005dd6;
	margin-right: 24px;
	margin-left: 10px;
	margin-top: 8px;
}

.addBank {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	margin-left: 10px;
}

.modalClose {
	position: absolute;
	width: 56px;
	height: 56px;
	left: 900px;
	top: 32px;
	border-radius: 50%;
	background: #ffffff;
	text-align: center;
}

.modalCloseIcon {
	padding-top: 11px;
}

.addBankTitle {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.noteCard {
	width: 432px;
	/* height: 140px; */
	background: #eef4fb;
	border: 0px;
	border-radius: 8px;
}

.noteCardPadding {
	padding: 24px !important;
}

.bankDetailText {
	margin: 0px;
	margin-top: 32px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-transform: lowercase;
	color: #767c8f;
}

.bankDetailsBorder {
	padding-bottom: 40px;
	border-bottom: 1px solid #efeff1;
}

.bankAddedText {
	width: 544px;
	padding-top: 290px;
	padding-left: 140px;
	padding-right: 160px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

/* .modalPadding {
	padding: 56px !important;
} */

/*****************/
/****************/
/****************/
/****FUnd Initiated*/
.fundInitiatedImage {
	margin: auto;
	padding-top: 50px;
}

.initiatedText {
  justify-content: center;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #1e1f24;
	padding: 24px 48px 0px 48px;
}

.fundInitiatedNote {
  justify-content: center;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.6px;
	color: #626777;
	padding: 0px 60px 0px 60px;
}

@media (max-width: 750px){
  .fundTitle {
    font-size: 16px;
    line-height: 24px;
  }
  .link{
    font-size: 14px;
    line-height: 20px;
  }
  .fundDescription{
    font-size: 12px;
    line-height: 16px;
  }
  .fundValue {
    font-size: 16px;
    line-height: 24px;
  }
  .cashSettlementButton{
    cursor: pointer;
    color: #005DD6;
    letter-spacing: 0.6px;
    font-size: 12px !important;
    line-height: 20px;
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    margin-top:8px;
    margin-bottom: 8px;
  }
  .cashSettlementData{
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 20px;
    color: #1E1F24;
    letter-spacing: 0.6px;
    font-family: 'geomanistregular';
    margin-bottom: 8px;
  }
}


@media (min-width : 800px) and (max-width: 1050px){
  .fundTitle {
    font-size: 18px;
    line-height: 24px;
  }
  .link{
    font-size: 16px;
    line-height: 20px;
  }
  .fundDescription{
    font-size: 14px;
    line-height: 16px;
  }
  .fundValue {
    font-size: 18px;
    line-height: 24px;
  }
}

/** Pending Orders Amount CSS under Buying Power **/
.pendingComm {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1E1F24;
	margin-top: 8px;
}

.pendingCommAmount {
	display: inline;
	font-family: 'geomanistmedium';
	font-weight: 400;
}

.pendingCommText {
	display: inline;
	font-family: 'geomanistregular';
}

.cashSettlementButton{
  cursor: pointer;
  color: #005DD6;
  letter-spacing: 0.6px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  margin-top:8px;
  margin-bottom: 8px;
}

.cashSettlementData{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1E1F24;
  letter-spacing: 0.6px;
  font-family: 'geomanistregular';
  margin-bottom: 8px;
}

.cashSettlementAmount{
  font-family: 'geomanistMedium';
}

.settlementArrow{
  height: 6px;
  width: 10px;
}
