.cardrowMargin {
	/* margin-top: 64px; */
	margin-left: 0px;
  margin-top: 64px;
}
.buttonresponsive{
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align:center;
}
.subHeading{
font-family: 'geomanistRegular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #626777;
margin-top: 4px;
}
.heading {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 0px;
  /* checked */
}
.heading2 {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 0px;
  padding-left:15px;
  /* checked */
}
.horizantalLine{
  height: 1px;
  background: #EFEFF1;
  margin-bottom: 0px;
}
.cardtopMargin {
	margin-top: 40px;
	/* padding-right: 15px;
	padding-left: 15px; */
}

.priceResponsive{
  white-space:nowrap;
  padding-top:7px;
  /* padding-left:40px; */
}
.investmentLine{
  margin-bottom: 40px;
  margin-top: 40px;
}

.watchlist{
  border: 1.5px solid #0061D3;
box-sizing: border-box;
border-radius: 8px;
display: inline-block;
padding:4px 10px 6px 10px;
cursor:pointer;
white-space: nowrap;
}

.watchlistText{
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #0061D3;
margin-left: 6px;
}

.watchlistDiv{
  /* text-align:right; */
  padding-right:90px;
  margin-bottom:32px;
}

.thunderDiv{
  text-align:start;
  display: inline-block;
    /* margin-right:50px;
    margin-left: 10px; */
    cursor: pointer;
}

.blob {
	/* background: black; */
	border-radius: 50%;
	/* margin: 10px; */
	height: 20px;
	width: 20px;
  /* background: rgba(255, 177, 66, 1); */
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
	transform: scale(1);
	animation: pulse 3s infinite;
}

.marginCost{
  margin-top:40px;
  margin-bottom: 40px;
}

.responsiveRow{
  margin-bottom:30px;
  margin-top: 48px;
  margin-left: 3px;
}

.similarDiv{
  margin-top:48px;
}
.stockDetailsResponsive{
  margin-top: 48px;
}
.similarMargin{
  margin-top: 48px;
}
.responsiveSimilar{
  margin-top: 24px;
}
.originalPos {
	transition: 1s;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
	}

	70% {
		transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
	}

	100% {
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
	}
}

.trendingSlider :global(.slick-list) {
	padding-left: 0px !important;
}

.trendingSlider :global(.slick-next) {
	display: none !important;
}

.trendingSlider :global(.slick-slide) {
  padding-right: 16px !important;
}


.customTooltip{
  width: 280px;
  height: 155px;
  z-index: 4000;
  position:absolute;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(2, 2, 65, 0.11);
  border-radius: 8px;
  top: 110px;
  left: -35px;
}
.active{
  white-space:nowrap;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #005DD6;
  padding-bottom: 7px;
  cursor: pointer;
  border-bottom: 1px solid #005DD6;
  display: inline-block;

}

.Inactive{
  white-space:nowrap;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #9096A8;
  padding-bottom: 7px;
  cursor: pointer;
  display: inline-block
}

.banner {
  width:100%;
  background-color: #D7E3F1;
  z-index:2;
  padding: 15px;
  margin-top: 50px;
  border-radius: 8px;
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

@media (min-width:768px) and (max-width:992px) {
  .finacialstats{
    margin-top: 32px;
  }
}

@media (max-width:768px) {
  .finacialstats{
    margin-top: 8px;
  }
}

@media (min-width:992px) {
  .finacialstats{
    margin-top: 8px;
  }
}

@media (max-width:1199px){
  .responsiveRow{
    margin-bottom:30px;
    margin-top: 48px;
    margin-left: 3px;
    margin-right: 3px;
  }
}

@media (max-width:1024px)
{
  .customTooltip
  {
    left: -15px;
  }


}

.customTooltipArrow{
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  /* background: chartreuse; */
  position:absolute;
  border:none;
  transform:rotate(-135deg);
  border-radius:8px;
  top:-20px;
  left:50px;
  /* box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.05);
}

.customTooltipText{
font-family: 'geomanistRegular';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
text-align: center;
letter-spacing: 0.6px;
color: #1E1F24;
}


.customTooltipButtonText{
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 130%;
letter-spacing: 0.6px;
color: #302001;
background: linear-gradient(90deg, #E0980E 0%, #F4C66C 56.12%, #F2B133 100%);
border-radius: 8px;
cursor: pointer;
text-align: center;
margin: 16px;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 15px;
padding-right: 15px;
}
.customTooltipButtonText:focus{
  outline: none;
  border:none;
  box-shadow: none;
}



@keyframes loading {
	0% {
		transform: translateX(-150%);
	}
	50% {
		transform: translateX(-60%);
	}
	100% {
		transform: translateX(150%);
	}
}



.icon {
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
  display: inline-block;
}

.icon:after {

  animation: shine 2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 1;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
    transition-duration: 10s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}


.marginLine{
  margin-top: 58px;
}


.price{
margin-left: 8px;
font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
color: #1E1F24;
}

.themeDiv{
  margin-top:140px;
}
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media (min-width: 1600px) {
	/* .bigScreen {
		max-width: 1400px;
	} */
  /* .responsiveRow{
    padding-right: 90px;
  } */
}

@media (min-width: 770px) and (max-width:1024px){
  .watchlistText{
  font-family: 'geomanistBook';
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 20px;
letter-spacing: 0.6px;
text-transform: capitalize;
margin-left: 6px;
}
.priceResponsive{
  white-space:nowrap;
  padding-top:7px;
  padding-left:30px;
}
}
@media (min-width:426px) and (max-width:1000px){
  .buttonresponsive{
    right: 0px;
    left: 0px;
    margin-left: 0px;
    z-index: 999;
  }

}

@media (max-width: 768px) {

  .similarMargin{
    margin-top: 29px;
  }
  .heading {
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-bottom: 0px;
  }
  .heading2 {
font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
letter-spacing: 0.6px;
color: #1E1F24;
    /* checked */
  }
  .subHeading{
    font-family: 'geomanistRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #626777;
    margin-top: 4px;
    padding-left: 15px;
    }
  .similarDiv{
    margin-top:40px;
  }
  .themeDiv{
    margin-top:40px;
  }
  .stockDetailsResponsive{
    margin-top: 16px;
  }
  .marginLine{
    margin-top: 38px;
  }
  .marginCost{
    margin-top: 40px;
  }
  .marginCost{
    margin-top:32px;
    margin-bottom: 35px;
  }

  /* .trendingSlider {
		height: 205px;
	} */
  .cardrowMargin {
    margin-left: 0px;
    margin-top: 40px;
  }
  .trendingSlider :global(.slick-slide) {
    padding-right: 16px !important;

  }
}

@media (min-width: 320px) and (max-width: 480px) {
  /* .trendingSlider {
		height: 200px;
	} */
  .similarResponsive{
    /* padding-left: 15px; */
    padding-right: 15px;
  }
  .heading{
    font-size: 20px;
    line-height: 28px;
  }
  .similarDiv{
    margin-top:40px;
  }
  .themeDiv{
    margin-top:40px;
  }
  .trendingSlider :global(.slick-slide) {
    /* width: 290px !important; */
    padding-right: 16px !important;

  }
}

@media (min-width:770px) and (max-width:1024px){
  .responsiveRow{
    margin-bottom:30px;
    margin-left:10px;
  }
}

@media (min-width: 1000px) and (max-width: 1540px) {
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
}

@media(min-width:700px) {
  .sideBarMobileHide {
    display: block;
  }
}

@media (min-width:769px) {
  .subHeading {
    padding-left: 15px;
  }
}





