/***basic styles**/
.skeleton {
	background: #777;
	opacity: 0.7;
	margin: 10px 0;
	border-radius: 4px;
}

.skeleton.circle {
	border-radius: 50%;
}

.skeletonCard {
	background-color: #ddd;
	opacity: 0.3;
	border: 0px;
	margin: 20px;
}

/****animation****/
.shimmer-wrapper {
	position: absolute;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100%;
	animation: loading 1s infinite;
}

.shimmer {
	width: 50%;
	height: 100%;
	background: rgba(255, 255, 255, 0.2);
	transform: skewX(-20deg);
}

@keyframes loading {
	0% {
		transform: translateX(-150%);
	}
	50% {
		transform: translateX(-60%);
	}
	100% {
		transform: translateX(150%);
	}
}
