.selectDropDownContainer{
	position: relative;
}
/* select-label */
.select {
	position: relative;
	width: 100%;
	min-height: 40px;
	border: 1.5px solid #d3d4da;
	padding: 10px 12px;
	border-radius: 6px;
}
.selectWrapper {
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 20px;
}
.selectIcon {
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	margin-right: 12px;
}
.selectValue {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	width: 10px;
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.downArrow {
	position: absolute;
	right: 0px;
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
}
.select img {
	width: 100%;
	height: 100%;
}
/* drop-down */
.dropDown {
	position: absolute;
	z-index: 1;
	width: 100%;
	background: #ffffff;
	border: 1px solid #F7F7F8;
	box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 6px;
	display: none;
}
/* search-box */
.searchBox {
	padding: 16px 12px;
	border-bottom: 1px solid #efeff1;
}
.searchInputWrapper {
	border: 1.5px solid #d3d4da;
	border-radius: 6px;
	padding: 12px;
	display: flex;
	align-items: center;
}
.searchInputWrapper:focus-within {
    border: 1.5px solid #3C404A;
}
.searchIcon {
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	margin-right: 12px;
}
.clearIcon {
    width: 18px;
	height: 18px;
    display: flex;
	align-items: center;
    cursor: pointer;
}
.searchInputWrapper img {
	width: 100%;
	height: 100%;
}
.searchInput {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	border: none;
	outline: none;
	width: 10px;
	flex-grow: 1;
}
/* search-input placeholder  */
.searchInput::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
}
.searchInput::-webkit-input-placeholder {
	/* Edge */
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
}
.searchInput:-ms-input-placeholder {
	/* Internet Explorer */
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
}
/* option-list */
.optionList {
	max-height: 176px;
	overflow-y: scroll;
	padding-bottom: 0px;
}
.optionList::-webkit-scrollbar {
	width: 4px;
  }
/*Track */
.optionList::-webkit-scrollbar-track {
	border-radius: 10px;
}
/* Handle */
.optionList::-webkit-scrollbar-thumb {
background: rgba(0, 0, 0, 0.2);
border-radius: 999px;
}
.option {
	padding: 12px 13px;
	border-bottom: 1px solid #efeff1;
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
    cursor: pointer;
}
.optionList .option:last-child{
	border-bottom: none;
}
.noResult {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
    text-align: center;
    padding-top: 32px;
}
.optIcon {
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	margin-right: 8px;
}
.option img {
	width: 100%;
	height: 100%;
}

/* visibilty */
.visible {
	display: block;
}
.hide {
    display: none;
}
/* rotate-180 */
.rotate {
	transform: rotate(180deg);
}
.placeholder {
    color: #767C8F;
}
.inputBorder, .select:hover{
    border: 1.5px solid #3C404A;
}
.inputBorder, .disabled:hover{
    border: 1.5px solid #d3d4da !important;
}
.errorInput:hover, .errorInput:focus-within{
    border: 1.5px solid #FA274A !important;
}
.errorInputVisible{
    border: 1.5px solid #FA274A !important;
}