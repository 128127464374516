.banner {
    width:100%;
    background-color: #D7E3F1;
    z-index:2;
}

.banner p {
    margin: 0px;
}

.banner a {
    text-decoration: underline;
    color: #0061D3;
}

@media(max-width:700px) {
    .banner {
        position: absolute;
        top:60px;
     }
}

@media(min-width:700px) {
    .banner {
        position: fixed;
        top:60px;
    }
}

@media(min-width:992px) {
    .banner {
        position: fixed;
        top: 80px;
    }
}

.content {
    font-family: 'Inter';
    font-weight: 500;
    color: #0061D3;
}

@media(max-width:768px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:14px;
        font-size: 14px;
        line-height: 20px;
    }

    .banner {
        padding:18px 18px 16px 20px;
    }

    .banner div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .titleDesktop {
        display: none;
    }

    .titleMobile {
        display: block;
    }
}

@media(min-width:768px) {
    .content {
        flex-direction: row;
        gap:18px;
        justify-content: flex-start;
        font-size: 16px;
        line-height: 24px;
    }

    .banner {
        padding:16px;
    }

    .banner div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .titleDesktop {
        display: block;
    }

    .titleMobile {
        display: none;
    }
}