.cardDiv{
  margin: auto;
  display: table;
  margin-top: 80px;
}

.cardSignUp{
width: 412px;
height: auto;
background: #FFFFFF;
border: 1px solid #F7F7F8;
box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
border-radius: 16px;
}
.cardBodySignup{
  padding:32px 24px 32px 24px
}
.title{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #3C404A;
  margin-left:0px;
  }

  .subTitle{
    font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767C8F;
  margin-left:0px;
  }

  .passportDiv{
    margin-top:80px;
  }

  .documentPlaceholder {
    font-family: geomanistregular !important;
  }

  .panDiv{
    display:block;
    margin:0px;
    margin-top:24px;
    /* background: #F7F7F8;
    border: 1px solid #EFEFF1;
    border-radius: 8px;
    padding: 16px; */
  }

  .panLabel{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #767C8F;
  }
  .flexs{
    display:flex;
    justify-content:space-between;
  }
  .panNumber{
    font-family: 'geomanistbook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3C404A;
  }
  .verifyCard{
    margin: 28px 0 0 0;
    padding: 20px;
    background: #FFFFFF;
    border: 1.5px solid #D3D4DA;
    border-radius: 6px;
    display:block;
    }
    .verifyTitle{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3C404A;
    }

    .verifySubTitle{
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #767C8F;
    }

    .verifyCheckBox{
      margin:0px;
      margin-top:24px;
    }
    .content{
      margin-top:16px;
      font-family: 'Geomanist';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #3C404A;
    }

    .checkMark {
      padding-top: 6px;
      padding-left: 8px;
      font-family: 'geomanistregular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #767C8F;
    }

    .checkBoxInput:disabled ~ .checkMark::before {
      background-color: #e9ecef;
      border: none;
    }

    .checkBox .checkBoxInput:checked:focus ~ .checkMark::before {
      box-shadow: 0 0 0 1px #fff;
      /* background-color: #6097a1; */
      transition: none;
    }

    .checkBox .checkBoxInput:not(:checked):focus ~ .checkMark::before {
      box-shadow: 0 0 0 1px #fff;
    }

    .checkMark::before {
      background: #F7F7F8;
      border: 1.5px solid #D3D4DA;
      border-radius: 4px;
      width: 20px;
      height: 20px;
    }
    .checkBoxError::before {
      background: #F7F7F8;
      border: 1.5px solid #FA274A;
      border-radius: 4px;
      width: 20px;
      height: 20px;
    }
    .checkBox .checkBoxInput:checked ~ .checkMark::after {
      background-image: url('../../public/images/newOnboarding/check.svg');
    }

    .checkMark::after {
      width: 1.7rem;
      height: 1.5rem;
      top: 1px;
      left: -27px;
    }

    .checkBox .checkBoxInput:checked ~ .checkMark::before {
      background: #F7F7F8;
      border: 1.5px solid #0061D3;
    }

    .panBackGround{
      padding:16px;
      background: #F7F7F8;
      border: 1px solid #EFEFF1;
      border-radius: 8px;
    }
    .disclosures{
    cursor: pointer;
	  font-family: 'geomanistregular';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0061D3;
    }
    .disclosures:hover{
      color: #0061D3;
      text-decoration:none;
    }
    .errorMessgae{
      font-family: 'geomanistregular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FA274A;
    }
    .panCardCapital{
      text-transform: uppercase;
    }


  @media (max-width:500px){
    .cardSignUp{
      border:0px;
      box-shadow:none;
      width:100%
    }
    .cardBodySignup{
      padding:24px;
    }
    .cardDiv{
      margin-top:12px;
      display:block;
    }
  }

