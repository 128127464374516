@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.card {
	width: 840px;
}

.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-bottom: 8px;
	border-bottom: 1px solid #efeff1;
	padding-bottom: 24px;
}
.description {
	margin-top: 32px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
}
.important {
	font-family: 'geomanistmedium';
}
.holder {
	margin-top: 24px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
}
.name {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.heading {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-bottom: 5px;
}

.bankInput {
	padding-left: 15px;
	height: 48px;
	width: 100%;
	border-radius: 8px;
	background: #efeff1;
	border: none;
}
.bankInput:disabled{
  background: #F8F9FA;
  border: 1px solid #F7F7F8;
  box-sizing: border-box;
  border-radius: 8px;
}

.bankInput::placeholder {
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #9096a8;
}

.errorMsg{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color:#FA274A
}

@-moz-document url-prefix() {
	.bankInput::placeholder {
		font-family: 'geomanistRegular';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 45px;
		letter-spacing: 0.6px;
		color: #9096a8;
	}
}

.agree {
  position: absolute;
	padding-left: 10px;
	font-family: 'geomanistRegular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px 35px 12px 30px;
	margin-right: 40px;
}
.addfundstext {
	font-family: 'geomanistMedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.box {
	height: 16px;
	width: 16px;
}

.erorrMessage{
  text-align: center;
  margin-top: 10px;
  font-family: 'geomanistregular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.6px;
color: #FA274A;
}

.fixedDiv {
  border-top: 1px solid #efeff1;
  padding-top: 20px;
	/* background: linear-gradient(
		354.66deg,
		#ffffff 53.72%,
		rgba(255, 255, 255, 0) 94.88%
	);
	text-align: end;
	position: fixed; */
	/* height: 120px; */
	/* bottom: 24px; */
	/* left: 0px; */
	/* right: 0px;
	margin-right: 0%; */
	/* padding-top: 50px; */
}

.marginBankAccount{
  margin-bottom: 30px;
}
.spinnerColor{
	border: .25em solid #005DD6;
	border-right-color: transparent;
	width: 20px;
	height: 20px;
	margin-left: 0.5rem;
  }
.link{
	cursor: pointer;
}
.link:hover{
	text-decoration: underline;
}
@media (max-width: 768px) {
	.card {
		width: 100%;
    height: fit-content;
    /* padding-bottom: 100px; */
	}
	.bankInput {
		/* width: 250px; */
		padding-top: 5px;
	}
}

@media (min-width:769px) and (max-width: 990px) {
  .card{
    width: 700px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .card{
    width: 100%;
    height: fit-content;
    padding-bottom: 100px;
  }
  .fixedDiv {
    text-align: center;
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin-right: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    background: #FFFFFF;
box-shadow: 0px -8px 32px rgba(17, 17, 17, 0.08);
border-radius: 16px 16px 0px 0px;
  }
   .addfunds {
    background: #005dd6;
    border-radius: 8px;
    padding: 0px;
    margin-right: 0px;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
  .marginBankAccount{
    margin-bottom: 100px;
  }
}

.withdrawErrorText {
	font-family: 'geomanistregular';
	color: #e61446;
	padding-top: 8px;
	margin-bottom: 0px;
	font-size: 14px;
}

.requiredInput:after {
  content:"*";
  color: #e61446;
}


.dropdownInputFont {
  font-family: 'geomanistregular';
}
