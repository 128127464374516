.optionText{
  font-family: 'geomanistbook';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3C404A;
  padding:12px;
  border-bottom: 1px solid #D3D4DA;
  cursor:pointer;
  }

.dropdownBox{
  z-index:100;
  margin-top:1px;
  width:88%;
  max-height: 200px;
  height: auto;
  overflow:auto;
  position:absolute;
  background: #FFFFFF;
  border: 1.5px solid #EFEFF1;
  box-shadow: 0px 12px 16px -4px rgba(60, 64, 74, 0.08), 0px 4px 6px -2px rgba(60, 64, 74, 0.02);
  border-radius: 6px;
}
.noData{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #767C8F;
text-align: center;
padding-top: 30px;
padding-bottom:40px;
}
