.LoginText {
	font-family: 'geomanistmedium';
	/* margin-top: 20px; */
	font-style: normal;
	/* font-size: 32px; */
	line-height: 110%;
	text-align: center;
	color: #000000;
	justify-content: center;
}

.poweredText {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767c8f;
	margin-right: 8px;
}

.buttonRow {
	justify-content: center;
	margin-top: 30px;
}
.toggle {
	cursor: pointer;
	position: absolute;
	top: 37px;
	right: 19px;
}
.buttonImage {
	margin-right: 16px;
}

.buttonCol {
	text-align: -webkit-right;
	text-align: right;
}

.googleButton {
	width: 300px;
	height: 65px;
	padding-left: 40px;
	background: #4285f4;
	font-family: Noticia Text;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 140%;
	display: flex;
	align-items: center;
	color: #ffffff;
}

.line {
	margin-top: 40px;
	width: 231px;
	border-top: 1px solid rgba(22, 37, 66, 0.3);
}

.lineText {
	margin-top: 23px;
	padding-left: 28px;
	padding-right: 28px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	display: flex;
	align-items: center;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}

.link:hover {
	text-decoration: none;
	color: #0061d3;
}

.inputRow {
	justify-content: center;
	margin-top: 30px;
}

.emailInput {
	/* width: 630px; */
	height: 62px;
}
.passwordInput {
	/* width: 492px; */
	height: 62px;
}
.inputPaddingLeft {
	padding-left: 6px;
	text-align: end;
}
.inputPaddingRight {
	padding-right: 0px;
}
.noColWith {
	padding-left: 0px;
	padding-right: 0px;
	max-width: 0px;
}
/* .button {
	margin-left: 30px;
	width: 90%;
	height: 62px;
} */

.forgotRow {
	margin-left: 181px;
	margin-top: 15px;
}

.forgotSeperator {
	margin-left: 10px;
	margin-right: 10px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3);
}

.forgotText {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
	color: #0061d3;
}

.rememberRow {
	margin-top: 75px;
	justify-content: center;
}
.remember {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: rgba(22, 37, 66, 0.75);
}
.goBackText {
	cursor: pointer;
	font-family: 'geomanistregular';
	margin-left: 5px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	/* padding-bottom: 4px; */
	border-bottom: 1px solid #0061d3;
	color: #0061d3;
}
.passwordInput::-ms-reveal,
.passwordInput::-ms-clear {
	display: none;
}

.cardDiv {
	margin: auto;
	display: table;
	margin-top: 80px;
	min-height: calc(100vh - 270px);
}

.cardDivWithBanner{
	margin: auto;
	display: table;
	margin-top: 135px;
	min-height: calc(100vh - 335px);
}

.cardSignUp {
	width: 412px;
	height: auto;
	background: #ffffff;
	border: 1px solid #f7f7f8;
	box-shadow: 0px 0px 16px -4px rgba(60, 64, 74, 0.08),
		0px 4px 6px -2px rgba(60, 64, 74, 0.02);
	border-radius: 16px;
}
.cardBodySignup {
	padding: 32px 24px 32px 24px;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 36px;
	color: #3c404a;
	margin-left: 0px;
}

.subTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #767c8f;
	margin-left: 0px;
}

.forgotRowDiv {
	margin: 20px 0px 0px;
	border-top: 1px solid #efeff1;
	padding: 20px 0px 0px;
}
.userError {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #fa274a;
}
.errorState {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #fa274a;
}
.passwordInvalid :global(.form-control.is-invalid) {
	background-image: none !important;
	background-position: right 12px center;
	border: 1.5px solid #fa274a;
	background-repeat: no-repeat;
}

/* banner */
.panBannerContainer{
	align-items: unset;
}

.beneficiaryBannerContainer{
	display: flex;
	flex-direction: column;
	padding: 12px;
	align-items: center;
	background-color: #F7F7F8;
	border-radius: 8px;
	width: 100%;
	position: absolute;
}
.bannerHead{
	font-size: 26px;
	font-family: 'geomanistMedium';
	text-align: center;
	color: #0061d3;
}
.bannerSubHead{
	font-size: 20px;
	font-family: 'geomanistRegular';
	text-align: center;
	margin-bottom: 0px;
}

@media only screen and (max-width: 1024px) {
	.forgotRow {
		margin-left: 12px;
	}
}

/* @media only screen and (max-width: 1440px) {
	.forgotRow {
		margin-left: 150px;
	}
} */

@media only screen and (max-width: 768px) {
	.stockalImage {
		height: 40px;
	}
	.LoginText {
		margin-top: 10px;
		font-size: 26px;
	}
	.googleButton {
		padding-left: 20px;
		width: 225px;
		height: 50px;
		font-size: 14px;
		line-height: 140%;
	}
	.emailInput {
		/* width: 480px; */
		height: 50px;
	}
	.passwordInput {
		/* width: 382px; */
		height: 50px;
	}
	.line {
		margin-top: 40px;
		width: 177px;
	}
	.lineText {
		padding-left: 15px;
		padding-right: 15px;
		font-size: 12px;
		line-height: 150%;
	}
	.button {
		font-size: 14px;
		line-height: 140%;
		margin-left: 20px;
		/* width: 78px;
		height: 50px; */
	}
	.forgotRow {
		margin-left: 128px;
		margin-top: 15px;
	}
}

@media (min-width: 767px) and (max-width: 768px) {
	.button {
		margin-left: 0px;
	}
}

@media only screen and (max-width: 480px) {
	.stockalImage {
		height: 30px;
	}
	.LoginText {
		margin-top: 10px;
		font-size: 22px;
	}
	.lineText {
		margin-top: 10px;
		font-size: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.line {
		margin-top: 27px;
		width: 104px;
	}
	.buttonCol {
		/* text-align: center; */
		text-align: -webkit-center;
		margin-bottom: 15px;
	}
	.faceBookCol {
		/* text-align: center; */
		text-align: -webkit-center;
	}
	.googleButton {
		width: 310px;
		height: 48px;
		font-size: 12px;
		line-height: 150%;
	}
	.emailInput {
		/* width: 310px; */
		height: 48px;
	}
	.passwordInput {
		/* width: 228px; */
		height: 48px;
	}
	.button {
		font-size: 12px;
		line-height: 150%;
		/* margin-left: 10px; */
		width: 80px;
		height: 48px;
	}
	.forgotRow {
		margin-left: 40px;
		margin-top: 7px;
	}
	/* .forgotText {
		font-size: 10px;
		line-height: 140%;
	} */
	.forgotSeperator {
		font-size: 10px;
		line-height: 140%;
	}
	.inputPaddingRight {
		padding-right: 15px;
	}
	.inputPaddingLeft {
		margin-top: 15px;
	}
	.bannerHead{
		font-size: 16px;
		font-family: 'geomanistMedium';
		text-align: center;
	}
	.bannerSubHead{
		font-size: 12px;
		font-family: 'geomanistRegular';
		text-align: center;
		margin-bottom: 0px;
	}
}

@media (min-width: 481px) and (max-width: 600px) {
	.bannerHead{
		font-size: 16px;
		font-family: 'geomanistMedium';
		text-align: center;
	}
	.bannerSubHead{
		font-size: 12px;
		font-family: 'geomanistRegular';
		text-align: center;
		margin-bottom: 0px;
	}
	.passwordInput {
		width: 97%;
	}
	.inputPaddingLeft {
		padding-top: 15px;
	}
	.button {
		font-size: 12px;
		line-height: 150%;
		width: 80px;
		height: 48px;
	}
}
@media (min-width: 601px) and (max-width: 768px) {
	.bannerHead{
		font-size: 20px;
		font-family: 'geomanistMedium';
		text-align: center;
	}
	.bannerSubHead{
		font-size: 14px;
		font-family: 'geomanistRegular';
		text-align: center;
		margin-bottom: 0px;
	}
	.passwordInput {
		width: 98%;
	}
	.inputPaddingLeft {
		padding-top: 15px;
	}
	.button {
		font-size: 12px;
		line-height: 150%;
		width: 80px;
		height: 48px;
	}
}

@media (max-width: 768px) {
	.marginResponsive {
		margin-top: 14px;
	}
}
@media (min-width: 768px) {
	.LoginText {
		margin-top: 20px;
		font-size: 32px;
	}
	.inputPaddingLeft {
		padding-top: 0;
	}
	.button {
		width: 90%;
		height: 62px;
		font-size: 18px;
	}
	.marginResponsive {
		margin-top: 0;
	}
	.stockalImage {
		height: 50px;
	}
	.passwordInput {
		width: 100%;
	}
}

@media (min-height: 200px) and (max-height: 700px) {
	.rememberRow {
		margin-top: 25px;
	}
}

@media only screen and (max-width: 480px) {
	.cardSignUp {
		border: 0px;
		box-shadow: none;
		width: 100%;
	}
	.cardBodySignup {
		padding: 24px;
	}
	.cardDiv {
		margin-top: 12px;
		display: block;
	}
}
