.container {
	position: relative;
}

.container input {
	font-family: 'Inter';
	padding: 20px;
	background: #ffffff;
	border: 2px solid rgba(22, 37, 66, 0.25);
	box-sizing: border-box;
	border-radius: 0px;
}

.container input:active,
.container input:hover,
.container input:focus {
	border: 2px solid #0061d3;
	box-shadow: none;
}

.container label {
	visibility: hidden;
	left: 8px;
	position: absolute;
	top: 0;
	opacity: 0;
	transition: 250ms;
}

.container input:placeholder-shown {
	/* font-family: 'Inter'; */
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}
.container input::placeholder{
  font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::-webkit-input-placeholder {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input::-moz-placeholder {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: rgba(22, 37, 66, 0.3) !important;
}

.container input:not(:placeholder-shown) + label {
	visibility: visible;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #ffffff;
	height: 25px;
	padding-top: 4px;
	padding-left: 10px;
	padding-right: 10px;
	background: #0061d3;
	border-radius: 12px;
	transform: translate(0, -50%);
	opacity: 1;
}

.container input:disabled {
	background-color: white;
}
