.sideBarButton {
	right: 0px;
	position: absolute;
	background: #ffffff;
	border: 1px solid #f0efef;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	top: 100px;
	width: 52px;
	height: 40px;
	outline: none !important;
	position: fixed;
	padding-bottom: 5px;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
}

@media (min-width: 320px) and (max-width: 700px) {
	.sideBarButton {
		display: none;
	}
}

@media(min-width:700px) {
	.sideBarButton {
		display: block;
		top:185px
	}
}

@media(min-width:992px) {
	.sideBarButton {
		top:156px;
	}
}