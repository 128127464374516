.button {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	width: 100%;
	height: 40px;
	border: 1px solid #0061d3;
	box-shadow: none;
	align-items: center;
	padding: 10px 20px;
	background: #ffffff;
	border-radius: 8px;
	color: #0061d3;
}

.button:not(:disabled):not(.disabled):active {
	background: #ffffff;
	color: #0061d3;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
}

.button:not(:disabled):not(.disabled):active:focus {
	background: #ffffff;
	color: #0061d3;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
}

.button.disabled,
.button:disabled {
	font-family: 'Noticia Text';
	color: #ffffff;
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.6),
			rgba(255, 255, 255, 0.6)
		),
		#0061d3;
	border-color: #007bff;
}

.button :disabled {
	opacity: 1;
}

.button:hover {
	background: #ffffff;
	color: #0061d3;
	border: 1px solid #0061d3;
	box-shadow: none;
}

.button:focus {
	background: #ffffff;
	color: #0061d3;
	border: 1px solid #0061d3;
	box-shadow: none;
}

@media only screen and (max-width: 480px) {
	.button {
		font-size: 12px;
	}
}
