@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';
.trendingCard {
  /* width: 20%; */
  border: 0px;
  border-bottom: 1px solid #F0EFEF;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent ;
  border-top: 1px solid transparent ;
}
.cardBody {
  padding-bottom: 32px;
  padding-top: 35px;
  padding-right: 19px;
  padding-left: 24px;
}
.trendingCard:hover {
  border: 1px solid #EFEFF1;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
  border-radius: 16px;
  z-index: 2;
}
.stockDiv {
  border: 0.928571px solid #EFEFF1;
  /* background-color: white; */
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.stockImage {
  /* border-radius: 50%; */
  width: 47px;
  height: 47px;
  padding-left: 10px;
  padding-top: 10px;
}
.stockTitle {
  font-family: 'geomanistmedium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1E1F24;
}
.stockName {
  font-family: 'geomanistregular';
  padding-top: 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #626777;
  height: 45px;
  text-transform: uppercase;
  /* white-space: nowrap;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.stockValue {
  font-family: 'geomanistbook';
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: 0.56px;
  color: #1E1F24;
  text-align: left;
  white-space: nowrap;
}
.imageCol {
}
.companyRes {
  text-align: initial;
}
.stockIncrement {
  font-family: 'geomanistbook';
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #13B948;
}
.redPercentChange{
  font-family: 'geomanistbook';
  /* text-align: right; */
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #FA274A;
}
.padding {
	padding-bottom: 26px;
  /* padding-top: 12px; */
}
.mobileResponsive {
}
.graphImage{
  width: 100%;
  height: 37px;
}

@media(max-width:576px) {
  .companyRes {
    padding-right: 0px !important;
  }
  .graph {
    padding-right: 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .trendingCard {
    border: 1px solid #F0EFEF;
    /* width: 62.67%; */
    width: 190px;
    border-radius: 8px;
    box-shadow: none !important;
  }
  .cardBody {
    height: auto;
    width: 100%;
    border-radius: 8px;
    padding: 20px 8px 20px 16px;
  }
  .paddingAnother {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
  }
  .stockImage {
    padding: 0px;
    border-radius: 50%;
    height: 39px;
    width: 39px;
  }
  .stockDiv {
    border: 0.928571px solid #EFEFF1;
    height: 40px;
    width: 40px;
    border-radius: 50px;
  }
  .imageCol {
    padding: 0px;
  }
  .companyRes {
    white-space: nowrap;
  }
  .stockTitle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
  }
  .stockName {
    height: 0px;
    padding-top: 0px;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.6px;
    color: #000000;
    opacity: 0.6;
  }
  .stockValue {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    white-space: nowrap;
  }
  .mobileResponsive {
    padding: 0px;
    padding-left: 3px;
  }
  .stockIncrement {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
  }
  .padding {
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .graphImage{
    width: 100%;
    height: 37px;
  }
}

@media (max-width: 990px) {
  .trendingCard {
    border: 1px solid #EFEFF1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
    border-radius: 16px;
  }
  .trendingCard:hover{
    border: 1px solid #EFEFF1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(30, 31, 36, 0.15);
    border-radius: 16px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .cardBody{
    padding-left: 20px;
    padding-right: 15px;
  }
}

@media (max-width:768px){
  .trendingCard {
    border: 1px solid #EFEFF1;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 8px;
  }
}

@media(min-width:576px) and (max-width:768px){
  .cardBody {
    padding: 18px;
  }

  .stockTitle {
    margin-left: 10px;
  }

  .stockName {
    margin-left: 10px;
    font-size: 13px;
  }
}

.halalRating>span>span {
  margin-right:5px;
}

.halalRating img {
  width: 11px;
}

.halalRating p {
  font-family: 'geomanistbook';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  color: #0DC014;
}

.halalRating {
  text-align: center;
}

@media(min-width:1200px) {
  .halalRating img {
    width: 13.5px;
  }
}
