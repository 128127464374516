@import './public/geomanist/geomanistRegular/stylesheet.css';
@import './public/geomanist/geomanistMedium/stylesheet.css';
@import './public/geomanist/geomanistBook/stylesheet.css';
.form-focus {
	/* height: 62px; */
	position: relative;
	margin-bottom: 0px;
}

.form-control {
	border: 2px solid rgba(22, 37, 66, 0.25);
	border-radius: 0px;
	font-family: 'geomanistregular';
	font-weight: 400;
	transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
}

.form-control[readonly] {
	background-color: white;
}

.form-focus .focus-label {
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 15px;
	top: -8px;
	z-index: 1;
	/* color: #b8b8b8; */
	color: rgba(22, 37, 66, 0.3);
	margin-bottom: 0;
	padding-top: 4px;
}
.form-focus.focused .focus-label {
	font-family: 'Inter';
	opacity: 1;
	top: -34px;
	font-size: 12px;
	line-height: 16.8px;
	z-index: 10;
	color: rgba(22, 37, 66, 0.75);
	left: 11px;
	background-color: #c5c8d0;
	border-radius: 12px;
	padding: 4px 10px;
}

.form-control:focus {
	box-shadow: none;
	border: 2px solid #0061d3;
}

.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
	font-family: 'Inter';
	opacity: 1;
	background-color: #0061d3;
	border-radius: 12px;
	font-weight: 400;
	top: -34px;
	color: #ffffff;
	font-size: 12px;
	padding: 4px 10px;
	line-height: 16.8px;
	/* padding-right: 10px; */
	z-index: 10;
	left: 11px;
}
.form-focus .form-control {
	height: 62px;
	padding: 6px 17px 6px;
}
.form-focus input[type='password'] {
	font-size: 22px;
}
.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}
.form-focus.select-focus .focus-label {
	font-family: 'Inter';
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}
.form-focus
	.select2-container--default
	.select2-selection--single
	.select2-selection__arrow {
	height: 48px;
	right: 7px;
}
.form-focus
	.select2-container--default
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.form-focus
	.select2-container--default.select2-container--open
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}
.form-focus
	.select2-container
	.select2-selection--single
	.select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}
.form-focus
	.select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}
.form-focus
	.select2-container--default
	.select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}

.form-control.is-invalid:focus {
	box-shadow: none;
}

.form-control.is-valid:focus {
	box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
	background-image: url('./public/images/inputError.png');
	background-position: right 20px center;
}

.form-control.is-valid,
.was-validated .form-control:valid {
	background-image: url('./public/images/inputSuccess.png');
	border: 2px solid rgba(22, 37, 66, 0.3);
	/* border: 2px solid #0061d3; */
	background-position: right 20px center;
}

.modal-content {
	border-radius: 0px !important;
	border: 0px !important;
}

.rdrCalendarWrapper {
	width: 100%;
}
.rdrMonth {
	width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
/* .form-group {
	margin-bottom: 2rem;
} */

.input-msg{
  color: #6b6666;
    font-size: 12px;
}
@media only screen and (max-width: 768px) {
	.form-focus .form-control {
		height: 62px;
	}
}

@media only screen and (max-width: 480px) {
	.form-focus .form-control {
		height: 48px;
	}
	.form-focus .focus-label {
		top: -11px;
		padding-top: 0px;
	}
}

@media (max-width: 580px) {
  .modal-backdrop.show{
    width: 105vw;
    height: 104vh;
  }
}
