@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.marginTop {
	margin: 0px;
	/* margin-top: 40px; */
}

.title {
	margin: 0px;
	margin-top: 64px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.lrsId {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.statusTop{
  margin:0px;
  margin-top: 13px;
}

.statusTime {
	margin-top: 7px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #505562;
}

.end {
	text-align: end;
}

.status {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.statusText {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}

.transactionDetail {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #005dd6;
	cursor: pointer;
	text-align: right;
	padding-right: 0px;
}

.expandIcon {
	margin-left: 6px;
	width: 8.75px;
	height: 4.58px;
	cursor: pointer;
}

.detailTitle {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #626777;
  display: flex;
  white-space: nowrap
}

.detailValue {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #1e1f24;
  display: flex;
  white-space: nowrap
}

.history {
	width: 100%;
	height: 12px;
	background: #efeff1;
	border-radius: 16px;
	position: relative;
}

.completed {
	/* width: 100px; */
	height: 12px;
	background: #13b948;
	border-radius: 16px;
}

.progressBarImage {
	position: absolute;
	top: -19px;
	left: -2%;
}

.progressBarComplete {
	position: absolute;
	left: 97%;
	top: -16px;
}

.lrsIdBoxWithdraw{
	display: inline-block;
    margin-left: 16px;
	background: #D3D4DA;
	border-radius: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	padding-right:10px;
	padding-left: 10px;
}

.lrsIdBoxDeposit{
	display: inline-block;
    margin-left: 16px;
	background: #ADFAB0;
	border-radius: 4px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	padding-right:10px;
	padding-left: 10px;
}
.lrsIdAmount{
  font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.remittanceContainer{
	padding-left: 120px;
    padding-right: 120px;
}

.centerRemittanceDetail{
	text-align: center;
}
.transactionHeading{
	text-align: right;
}

.mobilelrsIdBoxWithdraw{
	display: none;
}
.mobilelrsIdBoxDeposit{
	display: none;
}

.queSectionMobile{
	text-align: right;
	padding-right: 0px;
}

.redemptionRow{
	margin-top:16px;
  /* display: flex; */
  display: flex;
}

.hrMargin{
  margin-top: 32px;
  margin-bottom: 32px;
}

@media (max-width: 576px) {
	.centerRemittanceDetail{
		text-align: left;
	}
	.end {
		text-align: left;
	}
	.lrsIdBoxDeposit{
		display: none;
	}
	.lrsIdBoxWithdraw{
		display: none;
	}
	.mobilelrsIdBoxWithdraw{
		display: inline-block;
		margin-left: 10px;
		background: #D3D4DA;
		border-radius: 4px;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		padding-right:10px;
		padding-left: 10px;
	}
	.mobilelrsIdBoxDeposit{
		display: inline-block;
		margin-left: 10px;
		background: #ADFAB0;
		border-radius: 4px;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		padding-right:10px;
		padding-left: 10px;
	}
	.marginTop {
		margin: 0px;
		/* margin-top: 10px; */
	}
}

@media (max-width: 768px) {
  .centerRemittanceDetail{
		text-align: left;
	}
	.end {
		text-align: left;
	}
	.lrsIdBoxDeposit{
		display: none;
	}
	.lrsIdBoxWithdraw{
		display: none;
	}
	.mobilelrsIdBoxWithdraw{
		display: inline-block;
		margin-left: 10px;
		background: #D3D4DA;
		border-radius: 4px;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		padding-right:10px;
		padding-left: 10px;
	}
	.mobilelrsIdBoxDeposit{
		display: inline-block;
		margin-left: 10px;
		background: #ADFAB0;
		border-radius: 4px;
		font-family: 'geomanistregular';
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		padding-right:10px;
		padding-left: 10px;
	}
	.marginTop {
		margin: 0px;

	}

  .hrMargin{
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .lrsId{
    font-size: 16px;
  }
  .lrsIdAmount{
    font-family: 'geomanistmedium';
    font-size: 16px;
  }
  .statusTime {
    margin-top: 4px;
    font-family: 'geomanistregular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #505562;
  }
  .statusText {
    font-family: 'geomanistmedium';
    font-size: 14px;
    line-height: 20px;
  }
  .status {
    font-size: 14px;
    line-height: 20px;
  }

  .transactionDetail {
    font-size: 14px;
    line-height: 20px;
  }
  .statusTop{
    margin:0px;
    margin-top: 16px;
  }
  .detailTitle {
    font-size: 12px;
    line-height: 16px;
  }
  .detailValue{
    font-family: 'geomanistregular';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #1E1F24;
  }
}


/* Remittance new design */
.remittanceCard{
	border: 1px solid #D3D4DA;
	border-radius: 12px;
	margin-bottom: 16px;
	overflow: hidden;
}
.remittanceDetails{
	padding: 24px 20px;
	font-family: 'geomanistBook';
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 24px;
}
.remittanceDetails .main{
	font-size: 16px;
	color: #3C404A;
	margin-bottom: 4px;
}
.remittanceDetails .sub{
	font-size: 14px;
	color: #767C8F;
}
.transID{
	display: flex;
	gap: 6px;
	align-items: flex-start;
}
.transID img{
	margin-top: 4px;
}
.transID>span{
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cta{
	display: flex;
	justify-content: space-between;
	background: #F7F7F8;
	padding: 8px 24px;
	font-family: 'geomanistBook';
}
.cta .refID{
	font-size: 14px;
	color: #767C8F;
	display: flex;
	gap: 8px;
}
.textLink{
	color: #0061D3;
	cursor: pointer;
	font-size: 14px;
}
.statusLabel{
	display: flex;
	gap:4px;
	align-items: center;
}
.dot{
	width: 8px;
	height: 8px;
	border-radius: 50%;
}
.bg-success{
	background: #0DC014;
}
.success{
	color: #0DC014;
}
.bg-initiated{
	background: #767C8F;
}
.initiated{
	color: #767C8F;
}
.bg-pending{
	background: #FA5D05;
}
.pending{
	color: #FA5D05;
}
.bg-declined{
	background: #FA274A;
}
.declined{
	color: #FA274A;
}
.bg-suspense{
	background: #FA5D05;
}
.suspense{
	color: #FA5D05;
}

.remittanceModal :global(.modal-dialog){
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-top: 0px;
}
.remittanceModal :global(.modal-content){
	border-radius: 16px !important;
	overflow: hidden;
	width: 380px;
}
.remittanceModal :global(.modal-header){
	background: #F7F7F8;
	border-bottom: 1.5px solid #EFEFF1;
	padding: 24px 20px;
}
.remittanceModal :global(.modal-body){
	padding: 24px 20px;
}
.remittanceModal :global(.modal-footer){
	padding: 0px 20px 24px 20px;
	border: none;
}
.modalHeader{
	font-family: 'geomanistBook';
	font-size: 16px;
	color: #3C404A;
	display: flex;
	gap: 8px;
	align-items: center;
}
.modalHeader .info{
	width: 20px;
	height: 20px;
}
.refInput{
	border: 1.5px solid #D3D4DA;
	border-radius: 6px;
	padding: 10px 12px;
}
.refInput input{
	border: none;
	width: 100%;
	font-family: 'geomanistBook';
	font-size: 14px;
	color: #3C404A;
}
.refInput input::placeholder{
	font-family: 'geomanistRegular';
	font-size: 14px;
	color: #767C8F;
}
.deleteBody{
	font-family: 'geomanistRegular';
	font-size: 14px;
	color: #767C8F;
}
.footerContainer{
	display: flex;
	width: 100%;
	gap: 16px;
	margin: 0;
}
.footerContainer .confirmBtn {
    font-family: "geomanistBook";
    background: #0061D3;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    outline-color: #0061D3;
	width: 100%;
}

.footerContainer .confirmBtn:focus,
.footerContainer .confirmBtn:hover {
    background: #004EA9;
}
.footerContainer .cancelBtn{
    font-family: "geomanistBook";
    padding: 10px 20px;    
    border-radius: 8px;
    color: #0061D3;
    border: 1.5px solid #0061D3;
    background: #FFFFFF;
    outline-color: #0061D3;
	width: 100%;
}
.footerContainer .cancelBtn:focus, .footerContainer .cancelBtn:hover{
    border: 1.5px solid #004EA9;
    color: #004EA9;
}

@media (max-width: 575px){
	.remittanceDetails{
		padding: 12px;
	}
	.remittanceModal :global(.modal.fade .modal-dialog){
		transform: translate(0, 100%);
	}
	.remittanceModal :global(.modal-dialog){
		display: flex;
		align-items: flex-end;
		margin: 0px;
	}
	.remittanceModal :global(.modal-content){
		border-radius: 16px 16px 0px 0px !important;
		width: 100%;
	}
	.remittanceModal :global(.modal-body){
		padding: 0;
	}
	.remittanceModal{
		padding: 0px !important;
	}
	.remittanceModal .statusLabel{
		border-bottom: 1px solid #F7F7F8 !important;
	}
}

/* DropDown css */
.dropdownInput{
	font-family: 'geomanistBook';
	font-size: 14px;
	border: 1px solid #D3D4DA;
	border-radius: 6px;
	padding: 0px 12px;
	min-width: 166px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.dropdownWrapper{
	position: relative;
	top: 6px;
}
.dropdown{
	position: absolute;
	background: #FFFFFF;
	border: 1px solid #D3D4DA;
	box-shadow: 0px 10px 12px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 100%;
}
.dropdown .statusLabel, .remittanceModal .statusLabel{
	padding:12px 16px;
	line-height: normal;
	border-bottom: 1px solid #D3D4DA;
	cursor: pointer;
	font-family: 'geomanistBook';
	font-size: 14px;
}
.dropdown .statusLabel:last-child{
	border-bottom: none;
}