.modalPadding {
	padding: 50px;
	padding-top: 10px;
}

.modal-backdrop{
	opacity: 0px;
}

.modal-backdrop.show {
    opacity: .1 !important;
}

.modalDialog {
	display: none;
	position: fixed;
	margin: auto;
	max-width: fit-content;
	max-height: fit-content;
	/* width: 880px;
	max-width: 880px; */
	/* height: 900px; */
	right: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.modal-backdrop{
	opacity: none !important;
}

.modalRight:global(.fade .modal-dialog) {
	top: 0px;
	bottom: 0px;
	right: -320px;
	margin: 0px !important;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modalRight:global(.fade.show .modal-dialog) {
	right: 0;
	margin: 0px !important;
}

/* .modalDialog :global(.modal-content) {
	height: 100vh;
	max-height: 100vh !important;
} */

.modalHeader {
	border-bottom: 0px !important;
	padding: 0px;
	padding-top: 23px;
	padding-right: 23px;
	color: black;
}

@media (max-width: 576px) { 
	.modalDialog {
		display: inline-flex;
		position:fixed !important;
		top:auto !important;
		right:auto !important;
		left:auto !important;
		bottom:0 !important;
        /* margin: 0px !important; */
        max-width: 100%;
        /* height:100vh !important; */
        margin:0px !important;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
		width: 100%;
        transform: translate3d(0%, 0, 0);
	}
	
	.modal{
		height: auto;
		top:auto;
		right:auto;
		left:auto;
		bottom:0;
		overflow-x: hidden !important;
	}

	.modalDialog :global(.modal-content) {
		border-radius: 30px 30px 0px 0px !important;
	}

	.modalPadding {
		padding: 20px;
		padding-top: 10px;
	}

	.modal-body{
		overflow-x: hidden !important;
	}


}