.subHeader{
    font-family: "geomanistBook";
    font-size: 14px;
    color: #767C8F;
    margin-bottom: 20px;
}
.transferOption{
    font-family: "geomanistBook";
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #D3D4DA;
    border-radius: 8px;
    margin-bottom: 16px;
    background: transparent;
    width: 100%;
    align-items: center;
}
.transferOption:focus{
    outline-color: #3C404A;
}
.transferOption .primary{
    font-size: 14px;
    color: #3C404A;
}
.transferOption .secondary{
    font-family: "geomanistRegular";
    font-size: 12px;
    color: #767C8F;
}
.link{
    cursor: pointer;
}
.link:hover{
    text-decoration: underline;
}
.fastTag{
    padding: 3px 6px;
    background: #ECFAEC;
    border-radius: 4px;
    color: #0DC014;
    font-size: 12px;
    margin-left: 12px;
}