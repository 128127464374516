.genericLoading{
    height: calc(100vh - 144px);
    display: flex;
    justify-content: center;
}

.verifiedMessage{
    font-size: 20px;
    text-align: center;
    font-family: "geomanistRegular";
}

.reinitiateButton{
    color: white;
    border-radius: 8px;
    background-color: #005dd6;
    height: 40px;
    width: 150px;
    cursor: pointer;
    border: none;
}
.infoHead{
    font-size: 20px;
    font-family: "geomanistBook";   
}
.infoSubHead{
    font-size: 16px;
    font-family: "geomanistBook";   
    margin-top: 30px;
}
.SubPoints{
    font-size: 14px;
    padding-left: 0px;
    font-family: "geomanistRegular";
}
.newBankContainer{
    border: 1px solid #0061D3;
    border-radius: 8px;
    color: #0061D3;
    font-family: 'geomanistBook';
    font-size: 14px;
    padding: 12px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    background: transparent;
    float: right;
    margin-top: 20px;
    cursor: pointer;
}
.newBankContainer:focus{
    outline-color: #3C404A;
}

@media (max-width:600px){
    .newBankContainer{
        width: 100%;
        justify-content: center;
    }
}