@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.title {
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #005dd6;
	padding-top: 1px;
	cursor: pointer;
}

.seemoreIcon {
	margin-left: 6px;
	width: 8.75px;
	height: 4.58px;
	cursor: pointer;
}

.plan {
	background: linear-gradient(90deg, #e0980e 0%, #f4c66c 56.12%, #f2b133 100%);
	border-radius: 8px;
	border: 0px;
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 130%;
	letter-spacing: 0.6px;
	color: #302001;
	padding: 13px;
	padding-left: 20px;
	padding-right: 20px;
}

.earningGraph{
  margin-top: 56px;
  margin-bottom: 20px;
  font-family: 'geomanistmedium';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.6px;
color: #1E1F24;
}

.icon {
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
  display: inline-block;
}

.icon:after {

  animation: shine 2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 1;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
    transition-duration: 10s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}








.blurrImage{
  width: 100%;
}
.absoluteCover{
  padding-left: 31px;
  padding-right: 31px;
}
.cardBorder{
  background: #FFFFFF;
  border: 1px solid #D3D4DA;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(30, 31, 36, 0.07);
  border-radius: 16px;
}
.header{
  font-family: 'geomanistmedium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-top: 50px;
}
.description{
  font-family: 'geomanistRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #626777;
  margin-top: 8px;
}
.imagePadding{
  margin-left: 32px;
  margin-top: 23px;
  margin-bottom: 29px;
  width: 261px;
  height: 196px;
}
.buttonDist{
  margin-top: 16px;
}
.exclusiveImage{
  position: relative;
}
@media (min-width:992px) and (max-width: 1199px){
  .imagePadding{
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 200px;
    height: 150px;
  }
  .header{
    font-size: 14px;
    margin-top: 0px;
  }
  .description{
    font-size: 8px;
    margin-top: 0px;
  }
  .buttonDist{
    margin-top: 10px;
  }
  .plan {
    font-size: 8px;
    line-height: 130%;
    padding: 8px;
  }
}

@media (min-width:768px) and (max-width:991px){
  .imagePadding{
    margin-left: 32px;
    margin-top: 23px;
    margin-bottom: 29px;
    width: 200px;
    height: 150px;
  }
  .header{
    margin-top: 20px;
  }
}


@media (max-width: 767px){
  .blurrImage{
    height: 300px;
    /* object-fit: cover;
    object-position: bottom; */
  }
  .imagePadding{
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 200px;
    height: 150px;
  }
  .header{
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
  .description{
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
  }
  .cardBorder{
    /* margin-left:20px; */
    text-align: center;
  }
}

@media (max-width: 500px){
  .header{
   margin-top: 0px;
   font-size: 14px;
  }
  .imagePadding{
    width: auto;
    height: 110px;
  }
}

@media (min-width:426px) and  (max-width: 768px) {
    .earningGraph{
      margin-top: 48px;
    }
}
