
.card {
	width: 412px;
	height: 100px;
  padding-top: 20px;
}

.addfunds {
	background: #005dd6;
	border-radius: 8px;
	padding: 12px;
}

.addfundstext {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #ffffff;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}
.title {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
}
.description {
	margin-top: 16px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.6px;
	color: #767c8f;
}
.otp {
	margin-top: 24px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #505562;
}
.otpInput {
	margin-top: 8px;
  font-family: 'geomanistregular';
  padding:12px;
	background: #efeff1;
	height: 48px;
  width: 100%;
	/* width: 416px; */
	border-radius: 8px;
	border: none;
}
.otpInput::placeholder {
	padding-left: 10px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #767c8f;
}
.sec {
	margin-top: 24px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.6px;
	color: #3c404a;
}
.resend {
	margin-top: 24px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #767c8f;
}
.verify {
	margin-top: 40px;
	background: #005dd6;
	border-radius: 8px;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: 'geomanistbook';
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: #ffffff;
	border: none;
}


.otpErrorText {
	font-family: 'geomanistregular';
	color: #e61446;
	padding-top: 8px;
	margin-bottom: 0px;
	font-size: 14px;
}


.otpResend {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	letter-spacing: 0.6px;
	color: #005dd6;
}

@media (max-width: 750px){
.card{
  width: 100%;
}
}
