.container{
    width: 400px;
}
.container img{
    width: fit-content;
    height: fit-content;
}
.header{
    font-family: 'geomanistBook';
    padding: 24px;
    border-bottom: 1px solid #EFEFF1;
    height: 94px;
    display: flex;
    gap: 8px;
    position: fixed;
    top: 0;
    background: #FFFFFF;
    width: 100%;
    z-index: 10;
}
.header .primary{
    font-size: 16px;
    color: #3C404A;
}
.header .secondary{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
}
.backIcon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.body{
    margin-top: 71px;
    padding: 24px 24px 36px 24px;
    color: #767C8F;
}
.inputContainer{
    background: #F7F7F8;
    border-radius: 8px;
    padding: 24px 16px 16px;
    margin-bottom: 16px;
}
.inputContainer input, .inputContainer .inputWrap, .inputContainer .input::before, .inputContainer input::placeholder{
    font-family: 'geomanistRegular';
    font-size: 36px;
    color: #767C8F;
}
.inputWrap {
    position: relative;
    max-width: 200px;
    min-width: 52px;
}
.customInput {
    position: absolute;
    min-width: 100px;
    width: 100%;
    left: 0;
    top:0;
    background: #F7F7F8;
    border: none;
    padding: 0 0.25rem;
}
.widthMachine {
    padding: 0 0.25rem;
    visibility: hidden;
}
.inputContainer input::placeholder{
    color: #D3D4DA;
}
.inputContainer .input{
    display: flex;
    padding-bottom: 12px;
    justify-content: center;
}
.inputContainer .input::before{
    content: '$';
}
.inputContainer .hint{
    font-family: 'geomanistRegular';
    font-size: 14px;
    display: flex;
    justify-content: center;
}
.inputContainer .error{
    font-family: 'geomanistRegular';
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: #FA274A;
}
.accountContainer{
    padding-top: 16px;
}
.accountContainer .subHeader{
    font-family: 'geomanistBook';
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.accountContainer .optionContainer{
    border: 1px solid #D3D4DA;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    background: transparent;
    width: 100%;
}
.optionContainer:focus{
    outline-color: #3C404A;
}
.optionContainer .option{
    font-family: 'geomanistBook';
    font-size: 14px;
    margin-bottom: 4px;
    color: #3C404A;
}
.optionContainer .balance{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #767C8F;
}
.optionContainer .optionLogo{
    margin-right: 16px;
}
.investNote{
    margin-bottom: 16px;
    font-family: 'geomanistBook';
    font-size: 12px;
    color: #767C8F;
    display: flex;
    justify-content: center;
}
.investNote img{
    margin-right: 4px;
}
.investNote .textLink{
    margin-left: 4px;
    cursor: pointer;
    color: #0061D3;
}
.investNote .textLink:hover{
    text-decoration: underline;
}
.newBankContainer{
    border: 1px dashed #0061D3;
    border-radius: 8px;
    color: #0061D3;
    font-family: 'geomanistBook';
    font-size: 14px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    background: transparent;
    width: 100%;
}
.newBankContainer:focus{
    outline-color: #3C404A;
}
.newBankContainer .bankLogo, .addedBankContainer .bankLogo{
    margin-right: 16px;
}
.addedBankContainer .bankLogo{
    width: 24px;
    height: 24px;
}
.addedBankContainer{
    border: 1px solid #D3D4DA;
    border-radius: 8px;
    padding: 16px;
    font-family: 'geomanistBook';
    background: transparent;
    width: 100%;
    margin-bottom: 16px;
}
.addedBankContainer .bankDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addedBankContainer:focus{
    outline-color: #3C404A;
}

.deleteEnabled:focus{
    outline: none;
}
.deleteEnabled{
    cursor: default !important;
}
.addedBankContainer .bankName{
    font-size: 14px;
    color: #3C404A;
}
.addedBankContainer .accNumber{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #767C8F;
}  
.deleteContainer{
    border-top: 1px solid #D3D4DA;
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    margin-top: 16px;
}
.deleteOption{
    display: flex;
    width: 100%;
    gap: 16px;
    margin-top: 16px;
    height: 44px;
}
.deleteContainer .cancelBtn{
    border: 1.5px solid #767C8F;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: 'geomanistBook';
    font-size: 14px;
    background: transparent;
    width: 50%;
    color: #767C8F;
}
.deleteContainer .cancelBtn:focus, .deleteContainer .cancelBtn:hover{
    border: 1.5px solid #525663;
    color: #525663;
}
.deleteContainer span{
    text-align: left;
}
.deleteContainer .confirmBtn{
    background: #FA274A;
    border: 1.5px solid #FA274A;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: 'geomanistBook';
    font-size: 14px;
    width: 50%;
}
.deleteContainer .confirmBtn:focus, .deleteContainer .confirmBtn:hover{
    background: #CF203D;
}
.addFundModal :global(.modal-body){
    padding: 0px !important;
}
.addFundModal :global(#crossImage){
    z-index: 11;
    /* overwriting parent property for easy click */
    padding: 6px;
    right: 22px;
	top: 22px;
}
.noClose :global(#crossImage){
    display: none;
}
.link{
    color: #0061D3;
    cursor: pointer;
}
.link:hover, .link:focus{
    color:#004EA9;
}
.spinnerColor{
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
}
.spinnerColorDelete{
    border: .25em solid #FFFFFF;
    border-right-color: #D3D4DA;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.cancelContainer, .genericErrorContainer{
    display: flex;
    align-items: center;
    min-height: calc( 100vh - 86px);
}
.cancelContainer>div{
    padding: 24px;
    width: 100%;
}
.cancelContainer .cancelHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:24px;
    color: #3C404A;
    font-family: 'geomanistBook';
    font-size: 16px;
    margin-bottom: 8px;
}
.cancelContainer .cancelBody{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
}
.cancelContainer .cancelConfirmBtn{
    color: #FFFFFF;
    background: #FA274A;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    font-family: 'geomanistBook';
    border: 1.5px solid #FA274A;
    padding: 10px 20px;
    margin-right: 16px;
}
.cancelContainer .cancelConfirmBtn:focus, .cancelContainer .cancelConfirmBtn:hover{
    background: #CF203D;
}
.cancelContainer .cancelBtn{
    border: 1.5px solid #767C8F;
    border-radius: 8px;
    color: #767C8F;
    font-size: 14px;
    font-family: 'geomanistBook';
    width: 100%;
    padding: 10px 20px;
    background-color: transparent;
}
.cancelContainer .cancelBtn:focus, .cancelContainer .cancelBtn:hover{
    border: 1.5px solid #525663;
    color: #525663;
}
.loadingContainer{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #767C8F;
    margin-top: 44px;
    display: flex;
    gap:8px;
    justify-content: center;
    align-items: center;
}
.fetchErrorContainer{
    display: flex;
    flex-direction: column;
    background: #F7F7F8;
    border-radius: 8px;
    align-items: center;
    gap: 8px;
    padding: 48px 0;
    color: #3C404A;
    font-family: 'geomanistBook';
    font-size: 16px;
}
.fetchErrorContainer .linkContainer{
    font-size: 14px;
    color: #0061D3;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}
.errorLinkContainer{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #FA274A;
    text-align: left;
}
.deleteContainer .errorLinkContainer{
    font-size: 14px;
    display: flex;
    align-items: center;
}
.errorLinkContainer span{
    color: #0061D3;
    cursor: pointer;
    margin-left: 6px;
}
.genericErrorContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'geomanistBook';
    font-size: 16px;
    color: #3C404A;
}
.genericErrorContainer>img{
    margin-bottom: 24px;
}
.genericErrorContainer span{
    margin-bottom: 4px;
}
.genericErrorContainer .title{
  font-family: 'geomanistbook';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
text-align: center;
color: #3C404A;
margin-bottom:12px;
}
.genericErrorContainer .subTitle{
  font-family: 'geomanistregular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #767C8F;
margin-bottom:24px;
}
.genericErrorContainer .tryAgainContainer{
    font-size: 14px;
    color: #0061D3;
    display: flex;
    gap:6px;
    cursor: pointer;
}
.genericErrorLoading{
    display: flex;
    gap: 6px;
    color: #0061D3;
    align-items: center;
}
@media (max-width:600px){
    .container{
        width: auto;
    }
    .addFundModal :global(#crossImage){
        padding: 6px;
        right: 22px;
		top: 19px;
    }
    .addFundModal :global(.modal-dialog){
        height: 100% !important;
        min-height: 100%;
    }
    .addFundModal :global(.modal-content){
        max-height: calc(100% - 50px) !important;
    }
    .header{
        top: 50px;
        border-radius: 30px 30px 0px 0px;
    }
    .accountContainer{
        padding-bottom: 36px;
    }
}


.transferOption{
  font-family: "geomanistBook";
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #D3D4DA;
  border-radius: 8px;
  margin-bottom: 16px;
  background: transparent;
  width: 100%;
  align-items: center;
}
.transferOption:focus{
  outline-color: #3C404A;
}
.transferOption .primary{
  font-size: 14px;
  color: #3C404A;
}
.transferOption .secondary{
  font-family: "geomanistRegular";
  font-size: 12px;
  color: #767C8F;
}

.transferTypeBanks{
    display: flex;
}
.transferType{
    cursor: pointer;
    padding: 20px 0px 20px 0px;
    width: 33%;
    text-align: center;
}
.transferType .bankLogo{
    width: 40px;
    height: 40px;
    border-radius: 10%;
    margin-top: 6px;
}
.transferType .bankName{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    margin-top: 10px;
} 
.transferType .bankLogoWithoutPad{
    width: 40px;
    height: 40px;
    border-radius: 10%;
    margin-top: 6px;
}

.transferType .borderImage{
    border: 0.928571px solid #EFEFF1;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: auto;
}